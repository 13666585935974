import { useCallback } from "react";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useBookingWindowFromSearch from "hooks/useBookingWindow";
import useRouteName from "hooks/useRouteName";
import { trackExperimentStage } from "utils/et";
import { FLY_ANYWHERE_ROUTE_TO_STAGE } from "../constants";

const useTrackFlyAnywhere = () => {
  const bookingWindow = useBookingWindowFromSearch();
  const routeName = useRouteName();

  const routeStage = FLY_ANYWHERE_ROUTE_TO_STAGE[routeName];

  const trackFlyAnywhereWww = useCallback(() => {
    trackExperimentStage("flights_web_cat_fly_anywhere_www", 1); // pages with searchBox

    if (routeStage) {
      trackExperimentStage("flights_web_cat_fly_anywhere_www", routeStage); // Landing/Rebook/Not-found pages
    }

    trackExperimentStage("flights_web_cat_fly_anywhere_www", bookingWindow && bookingWindow > 30 ? 5 : 6); // booking window
    trackExperimentStage("flights_web_cat_fly_anywhere_www", isOfMetaOrigin() ? 7 : 8); // meta/direct
  }, [bookingWindow, routeStage]);

  const trackFlyAnywhereMdot = useCallback(() => {
    trackExperimentStage("flights_web_cat_fly_anywhere_mdot", 1); // pages with searchBox

    if (routeStage) {
      trackExperimentStage("flights_web_cat_fly_anywhere_mdot", routeStage); // Landing/Rebook/Not-found pages
    }

    trackExperimentStage("flights_web_cat_fly_anywhere_mdot", bookingWindow && bookingWindow > 30 ? 5 : 6); // booking window
    trackExperimentStage("flights_web_cat_fly_anywhere_mdot", isOfMetaOrigin() ? 7 : 8); // meta/direct
  }, [bookingWindow, routeStage]);

  return { trackFlyAnywhereWww, trackFlyAnywhereMdot };
};

export { useTrackFlyAnywhere };
