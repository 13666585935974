import { UIFlightSegment } from "@flights/types/client";

type GetLeastAvailableLuggagePerTrip = {
  cabinLuggage: boolean;
  personalItem: boolean;
  checkedInLuggage: boolean;
};

export function getLeastAvailableLuggagePerTrip(segments: UIFlightSegment[], supportsChildrenTickets?: boolean) {
  const result = segments.reduce<GetLeastAvailableLuggagePerTrip>(
    (output, segment) => {
      // Traveler references between different suppliers work differently. We have a global param for that.
      // For ETG we need to have a reference of 1 to mark adult, revisit this logic when adding new supplier
      const travellerCabinLuggage = segment.travellerCabinLuggage?.find((luggage) =>
        supportsChildrenTickets ? luggage.travellerReference === "1" : !!luggage.travellerReference
      );
      const travellerCheckedLuggage = segment.travellerCheckedLuggage?.find((luggage) =>
        supportsChildrenTickets ? luggage.travellerReference === "1" : !!luggage.travellerReference
      );

      const isCabinBagAvailable =
        travellerCabinLuggage?.luggageAllowance.luggageType === "HAND" &&
        travellerCabinLuggage?.luggageAllowance?.maxPiece !== 0;

      if (!isCabinBagAvailable) {
        output.cabinLuggage = false;
      }
      if (!travellerCabinLuggage?.personalItem) {
        output.personalItem = false;
      }
      if (!travellerCheckedLuggage) {
        output.checkedInLuggage = false;
      }
      return output;
    },
    {
      cabinLuggage: true,
      personalItem: true,
      checkedInLuggage: true
    }
  );

  return result;
}
