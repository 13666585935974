import { UISearchResultsBanner } from "@flights/types/client";
import { createTrackingUtils } from "..";
import { useEffect } from "react";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "hooks/useUserAgent";

const utils = createTrackingUtils({
  name: "flights_web_human_right_banner",
  stages: {
    SR: 1,
    SR_BANNER: 2,
    DIRECT: 3,
    META: 4,
    DESKTOP: 5,
    MDOT: 6
  },
  goals: {
    not_available: 1
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useHumanRightBanner = (humanRightBanner?: UISearchResultsBanner) => {
  const isDirect = !isOfMetaOrigin();
  const { isMobile } = useUserAgent();

  useEffect(() => {
    stages.SR();

    if (!humanRightBanner) {
      return;
    }
    stages.SR_BANNER();
    isDirect ? stages.DIRECT() : stages.META();
    isMobile ? stages.MDOT() : stages.DESKTOP();
  }, [isDirect, isMobile, humanRightBanner]);
};

export default utils;
