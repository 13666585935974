import React, { useMemo } from "react";
import { Stack, Text, StackProps } from "@bookingcom/bui-react";
import { Baggage, BaggageProps } from "./Baggage";

export type BaggageListProps = {
  title?: string;
  list: BaggageProps[];
  size?: BaggageProps["size"];
};

export function BaggageList(props: BaggageListProps) {
  const { title, list, size = "medium" } = props;

  const { gap } = useMemo(() => {
    const gap: StackProps["gap"] = size === "medium" ? 2 : 1;
    return { gap };
  }, [size]);

  return (
    <Stack gap={gap}>
      {title ? (
        <Stack.Item>
          <Text color="neutral_alt" variant="body_2">
            {title}
          </Text>
        </Stack.Item>
      ) : undefined}
      {list.map((baggage, idx) => (
        <Stack.Item key={baggage.type + baggage.title + idx}>
          <Baggage size={size} {...baggage} />
        </Stack.Item>
      ))}
    </Stack>
  );
}
