/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SheetContainer } from "@bookingcom/bui-react";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import { Text } from "@bookingcom/bui-react";
import {
  UIOrderExtras,
  UIPassenger,
  UIPriceBreakdown,
  UITraveller,
  UITravellerPrice,
  UISubsidizedFareType,
  UIFlightData,
  UIPrice
} from "@flights/types/client";
import BreakdownTrigger from "./components/BreakdownTrigger";
import FloatingPanel from "components/atoms/FloatingPanel";
import useUserAgent from "../../../hooks/useUserAgent";
import PriceBreakdown from "./PriceBreakdown";
import { MpProduct } from "@bookingcom/mp-flights";
import useStickyFooterSpacerHeight from "../../../hooks/useStickyFooterSpacerHeight";
import Frame from "../Frame";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import styles from "./PriceBreakdownSummary.module.css";
import flights_apex_web_flex_selection_cta from "utils/experiments/apex/flights_apex_web_flex_selection_cta";

interface Props {
  breakdown: UIPriceBreakdown;
  travellerPrices: UITravellerPrice[];
  travellers?: (UITraveller | UIPassenger)[];
  ancillaries?: UIOrderExtras;
  children?: React.ReactNode;
  sticky?: boolean;
  allowOpen?: boolean;
  errorMessage?: string;
  appliedSubsidizedFares?: UISubsidizedFareType[];
  totalTagName?: string;
  flightData?: UIFlightData;
  multiProducts?: MpProduct[];
  showPricePerPerson?: boolean;
  pricePerPerson?: UIPrice;
  paymentCollectionToPay?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  paymentCollectionPaid?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  isCTALoading?: boolean;
}

export default function PriceBreakdownSummary(props: Props) {
  const {
    breakdown,
    travellerPrices,
    ancillaries,
    travellers,
    children,
    sticky = false,
    allowOpen = true,
    errorMessage,
    appliedSubsidizedFares,
    totalTagName,
    flightData,
    multiProducts = [],
    showPricePerPerson = false,
    paymentCollectionToPay, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    paymentCollectionPaid, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    pricePerPerson,
    isCTALoading
  } = props;
  const [open, toggle] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useUserAgent();
  const i18n = useI18n() as I18nChildContext;
  const panelContainerRef = useRef<HTMLInputElement>(null);
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("price_breakdown", requestId);

  useStickyFooterSpacerHeight(panelContainerRef);

  const showBreakdown = () => {
    toggle(true);
    trackV2("click_price_details");
    history.push({
      ...location,
      hash: "price"
    });
  };

  const hideBreakdown = () => {
    toggle(false);
    resetBodyOverflow();
    history.goBack();
  };

  const resetBodyOverflow = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <React.Fragment>
      {sticky ? (
        // We always want the breakdown to be in front of the footer when open, BUI drawer has zIndex of 200 so we define the zIndex for this use case to be lower.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <FloatingPanel align="bottom" fixed className={styles.wrapper} style={{ zIndex: 199 }}>
          <div ref={panelContainerRef}>
            <Frame alignItems="flex-end" mt={3}>
              {errorMessage && (
                <Text className={styles.errorMsg} variant="body_2">
                  {errorMessage}
                </Text>
              )}
            </Frame>
            <BreakdownTrigger
              breakdown={breakdown}
              show={showBreakdown}
              hide={hideBreakdown}
              travellers={travellers}
              allowOpen={allowOpen}
              segments={flightData?.segments}
              travellerPrices={flightData?.travellerPrices}
              showPricePerPerson={showPricePerPerson}
              pricePerPerson={pricePerPerson}
              paymentCollectionToPay={paymentCollectionToPay} // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
              paymentCollectionPaid={paymentCollectionPaid} // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
              isCTALoading={isCTALoading}
            >
              {children}
            </BreakdownTrigger>
          </div>
        </FloatingPanel>
      ) : (
        <BreakdownTrigger
          breakdown={breakdown}
          show={showBreakdown}
          hide={hideBreakdown}
          travellers={travellers}
          allowOpen={allowOpen}
          segments={flightData?.segments}
          travellerPrices={flightData?.travellerPrices}
          showPricePerPerson={showPricePerPerson}
          pricePerPerson={pricePerPerson}
        >
          {children}
        </BreakdownTrigger>
      )}
      {travellers &&
        open &&
        (isMobile ? (
          <SheetContainer
            className={styles.drawer}
            onCloseTrigger={hideBreakdown}
            title={i18n.trans(t("flights_price_breakdown"))}
            closeAriaLabel={i18n.trans(t("close"))}
            active={open}
            onAfterOpen={() => {
              flights_apex_web_flex_selection_cta.goals.click_price_info();
            }}
          >
            <PriceBreakdown
              showTitle={false}
              totalPrice={breakdown}
              ancillaries={ancillaries}
              showFlightDetails
              travellers={travellers}
              travellerPrices={travellerPrices}
              onClose={hideBreakdown}
              appliedSubsidizedFares={appliedSubsidizedFares}
              totalTagName={totalTagName}
              flightData={flightData}
              multiProducts={multiProducts}
              paymentCollectionToPay={paymentCollectionToPay} // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
              paymentCollectionPaid={paymentCollectionPaid} // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
            />
          </SheetContainer>
        ) : (
          <PriceBreakdown
            showTitle={false}
            totalPrice={breakdown}
            ancillaries={ancillaries}
            showFlightDetails
            travellers={travellers}
            travellerPrices={travellerPrices}
            appliedSubsidizedFares={appliedSubsidizedFares}
            flightData={flightData}
            multiProducts={multiProducts}
          />
        ))}
    </React.Fragment>
  );
}
