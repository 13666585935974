import { useEffect, useState, useRef } from "react";

type ObserverOptions = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
};

/**
 * @param {<HTMLElement>} elem - Element we want to track.
 * @param {Array<any>} triggers - Optional array that will trigger useEffect to run again. Useful for conditional components & API calls.
 * @param {ObserverOptions} options - IntersectionObserver options
 * @returns {Boolean} - If the ref is in viewport or not.
 *
 * @example
 *  const bannerRef = useRef<HTMLDivElement>(null);
 *  const isBannerInViewport = useIsInViewport(bannerRef);
 *  <Banner ref={bannerRef}></Banner>
 */
export default function useIsInViewport(
  elem: HTMLElement | null,
  triggers: Array<any> = [],
  options?: ObserverOptions
) {
  const [isInViewport, setIsInViewport] = useState(false);
  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting), options);
  }, [options]);

  useEffect(() => {
    if (!elem) return;
    if (!observerRef.current) return;
    observerRef.current.observe(elem);
    return () => {
      observerRef.current?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elem, ...triggers]);

  return isInViewport;
}
