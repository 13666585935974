import { Stack, Text } from "@bookingcom/bui-react";
import { BaggageType } from "components/elements/Baggages/utils";
import BaggageIcon from "components/ui/Baggages/BaggageIcon";
import React from "react";

export type FareBaggageItem = { type: BaggageType; variant: "included" | "not_included"; label: string };

type Props = {
  baggages: Array<FareBaggageItem>;
};

const FareBaggageItemList = ({ baggages }: Props) => {
  return (
    <Stack gap={3}>
      {baggages.map(({ type, variant, label }) => {
        const color = variant === "not_included" ? "neutral_alt" : "neutral";
        return (
          <Stack key={type} direction="row" gap={4}>
            <Stack.Item>
              <BaggageIcon size="medium" color={color} type={type} notAvailable={variant === "not_included"} />
            </Stack.Item>

            <Stack.Item grow={true}>
              <Text variant="body_2" color={color}>
                {label}
              </Text>
            </Stack.Item>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default FareBaggageItemList;
