import { useMemo } from "react";
import useUserAgent from "./useUserAgent";
import { trackExperiment } from "utils/et";

export default function usePaxWithBaggages() {
  const { isMobile } = useUserAgent();

  const isPaxWithBaggages = useMemo(
    () =>
      (!isMobile && !!trackExperiment("flights_web_ddot_pax_with_baggages")) ||
      (isMobile && !!trackExperiment("flights_web_mdot_pax_with_baggages")),
    [isMobile]
  );

  return { isPaxWithBaggages };
}
