import CsxpWidgetInitializer, { CsxpWidgetProps } from "@bookingcom/cross-sell-loader-react";

import localstory from "localstory";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { DEFAULT_MARKETING_TRACKING_VARIABLES } from "../../../constants";
import useGlobalContext from "../../../hooks/useGlobalContext";
import useRouteName from "../../../hooks/useRouteName";
import useUserAgent from "../../../hooks/useUserAgent";
import useLocaleContext from "hooks/useLocaleContext";
import {
  TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
  TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS
} from "constants/experiments";

import {
  CsxpPlacementNames,
  CsxpPlacementPages,
  CsxpVerticals,
  getCsxpPlacementPageByRouteName,
  getCsxpSkeletonItemsByPlacementPlatform
} from "../../utils";
import {
  trackCustomGoal,
  trackExperiment,
  trackExperimentStage,
  trackGoal,
  trackGoalWithValue
} from "../../../utils/et";
import ErrorBoundary from "../../../components/elements/ErrorBoundary";
import CsxpLoadingAndErrorHandling from "./LoadingAndErrorCSXPHandling";
import { FlightContext } from "@bookingcom/cross-sell-loader-react/dist/CsxpWidget/useConfigValidation";

export interface Props {
  currencyCode?: CsxpWidgetProps["config"]["currencyCode"];
  reserveOrderId?: string;
  flightContext?: FlightContext;
  isModal?: boolean;
  setShowSheet?: (a: boolean) => void;
  variant?: string;
}

export function CSXPLoader({ isModal, currencyCode, reserveOrderId, flightContext, setShowSheet, variant }: Props) {
  const sessionStore = localstory(window.sessionStorage, "flights");
  const { isMobile } = useUserAgent();
  const { locale, env } = useGlobalContext();
  const { isRTL } = useLocaleContext();
  const [isCsxpInitialized, setIsCsxpInitialized] = useState(false);
  const setShowSheetRef = useRef(setShowSheet);

  useEffect(() => {
    const connector = document.getElementsByClassName("connector_container");
    if (connector.length) {
      trackCustomGoal(
        TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF,
        TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS.RENDERED_CROSS_SELL_CONNECTOR
      );
    }
  }, [isCsxpInitialized]);

  const sessionAid = sessionStore.get("aid") || DEFAULT_MARKETING_TRACKING_VARIABLES.aid;
  const sessionLabel = sessionStore.get("label") || DEFAULT_MARKETING_TRACKING_VARIABLES.label || undefined;

  const routeName = useRouteName();
  const csxpPlacement = getCsxpPlacementPageByRouteName(routeName, isModal, variant);
  const csxpPlacementName = csxpPlacement?.placementName;
  const csxpPlacementPage = csxpPlacement?.placementPage;
  const skeletonItems = getCsxpSkeletonItemsByPlacementPlatform(
    csxpPlacementPage,
    csxpPlacementName,
    isMobile ? "MDOT" : "DDOT"
  );

  const csxpWidgetProps: CsxpWidgetProps | null = useMemo(() => {
    // Required to have the page, placement and either a reserve Order or the flight context
    if (!csxpPlacementName || !csxpPlacementPage || (!reserveOrderId && !flightContext)) {
      return null;
    }

    return {
      config: {
        platform: isMobile ? "mdot" : "ddot",
        aid: sessionAid,
        label: sessionLabel,
        languageCode: locale || "en-us",
        currencyCode: currencyCode ? currencyCode : "EUR",
        placementPage: csxpPlacementPage,
        exposurePointData: {
          placement_name: csxpPlacementName,
          reservations: [
            {
              vertical: CsxpVerticals.FLIGHTS,
              reserve_order_id: reserveOrderId
            }
          ]
        },
        isRtl: isRTL,
        isDev: env !== "prod",
        customEt: {
          goal: trackGoal,
          goalWithValue: trackGoalWithValue,
          track: trackExperiment,
          stage: trackExperimentStage,
          customGoal: trackCustomGoal
        },
        flightContext: flightContext
      },
      onError: (error: any) => {
        throw error;
      }
    };
  }, [
    csxpPlacementName,
    csxpPlacementPage,
    reserveOrderId,
    flightContext,
    isMobile,
    sessionAid,
    sessionLabel,
    locale,
    currencyCode,
    isRTL,
    env
  ]);

  const crossSellWidgetInitializer = useMemo(() => {
    if (!csxpWidgetProps) {
      return null;
    }

    return (
      <>
        <CsxpWidgetInitializer
          {...csxpWidgetProps}
          onInitialize={() => {
            setIsCsxpInitialized(true);
          }}
          onError={(error: Error) => {
            const errorWithGroup = { errorGroup: "CROSS_SELL", error };
            reportError(errorWithGroup);
            setIsCsxpInitialized(true);
            setShowSheetRef.current ? setShowSheetRef.current(false) : null;
          }}
        />
      </>
    );
  }, [csxpWidgetProps]);

  return (
    <ErrorBoundary quiet={true}>
      {!isCsxpInitialized && (
        <CsxpLoadingAndErrorHandling
          isCsxpInitialized={isCsxpInitialized}
          skeletonItems={skeletonItems}
          hasBorder={
            csxpPlacementPage === CsxpPlacementPages.FLIGHTS_CHECKOUT3 && csxpPlacementName === CsxpPlacementNames.MODAL
          }
        />
      )}
      {crossSellWidgetInitializer}
    </ErrorBoundary>
  );
}

export default CSXPLoader;
