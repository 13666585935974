import { useCallback, useState } from "react";
import { VALID_SUPPLIERS, ORDER_TOKEN_VALIDITY, MANDATORY_SEARCH_PARAMS_ONEWAY } from "../constants";

// Name contains Latin 1 characters and an optional hyphen. Allow multiple space-separated names.
// see https://en.wikipedia.org/wiki/ISO/IEC_8859-1#Code_page_layout
export const isValidLatinNameOld = (name: string) => /^[a-z A-Z\u00C0-\u00ff]+(-[a-z A-Z\u00C0-\u00ff]+)?$/g.test(name);
export const isValidLatinName = (name: string) => /^[a-z A-Z\u00C0-\u00ff]+([-'][a-z A-Z\u00C0-\u00ff]+)?$/g.test(name);

export function isValidSupplier(input: string) {
  return VALID_SUPPLIERS.includes(input);
}

export function isTokenExpired(isodatetime: string) {
  const tokenCreationTime = Date.parse(isodatetime);
  const currentTime = Date.now();

  return (currentTime - tokenCreationTime) / 1000 > ORDER_TOKEN_VALIDITY;
}

/**  Verify we are not doing open redirection. @see https://jira.booking.com/jira/browse/FLIN-302 */
export function isValidRelativeURL(url: string): boolean {
  const baseUrl = "https://flights.booking.com/";

  // Remove leading slash if any (as we set redirectURL from location.path)
  const relativePath = url.replace(/^\/+/, "");

  // This works because new URL() will ignore the $baseUrl if $url is an absolute url,
  // hence they won't match later.
  const testURL = new URL(url, baseUrl);

  if (testURL.href == baseUrl + relativePath) {
    return true;
  }

  return false;
}

/**
 * DateInputBUI validators
 */
export interface DateRules {
  type: Validators;
  message: string;
}

export type Validators = "required" | "day" | "month" | "year";

const VALIDATORS: {
  [key in Validators]: (val: string, values?: string[]) => boolean;
} = {
  required: (val = "") => {
    return !!val.trim();
  },

  day: (val = "") => {
    return /^(0?[1-9]|1[0-9]|2[0-9]|3[0-1])$/.test(val || "");
  },
  month: (val = "") => {
    return /^(0?[1-9]|1[012])$/.test(val || "");
  },
  year: (val = "") => {
    return val.length < 4 ? false : /(?:19|20)[0-9]{2}/.test(val || "");
  }
};

export const getValidator = (type: Validators, errorMsg: any) => {
  return (val: string, values?: string[]) => {
    const isValid = values ? VALIDATORS[type](val, values) : VALIDATORS[type](val);

    if (!isValid) {
      return errorMsg;
    }
  };
};

export function useValidator(
  rules: Array<DateRules>
): [
  string,
  (value: any, required?: boolean, values?: string[]) => void,
  boolean,
  (value: string) => void,
  (value: boolean) => void
] {
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");

  const validate = useCallback(
    (value: string, required: boolean, values?: string[]) => {
      let valid = false;
      let newError = "";
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].type === "required" && required && !value) {
          newError = getValidator(rules[i].type, rules[i].message)(value);
          break;
        }
        if (values && values.length > 0) {
          newError = getValidator(rules[i].type, rules[i].message)(value, values);
          break;
        }
        if (value.length && getValidator(rules[i].type, rules[i].message)(value)) {
          newError = value ? getValidator(rules[i].type, rules[i].message)(value) : "";
          break;
        }
      }
      setError(newError);
      if (value && value.length) {
        valid = !newError;
      }
      setIsValid(valid);
    },
    [rules]
  );

  return [error, validate, isValid, setError, setIsValid];
}

//basic mandatory param check for search results page url
export const isValidSearchURL = (paramString: string, withPath?: boolean) => {
  if (withPath) {
    paramString = paramString.split("?")[1];
  }
  const searchParams = new URLSearchParams(paramString);
  const invalidParam = MANDATORY_SEARCH_PARAMS_ONEWAY.find((mandatoryParam) => {
    return !searchParams.get(mandatoryParam);
  });

  if (invalidParam) {
    return false;
  }

  if (searchParams.get("type") === "ONEWAY") {
    return true;
  } else {
    // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
    return searchParams.get("return") !== null;
  }
};
