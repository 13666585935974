import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";

export enum ActionTypes {
  show = "loadingScreen/show",
  hide = "loadingScreen/hide"
}

export const actions = {
  show: createAction(() => ({
    type: ActionTypes.show,
    payload: {}
  })),

  hide: createAction(() => ({
    type: ActionTypes.hide,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
