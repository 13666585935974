import { useCallback } from "react";
import { createTrackingUtils } from "..";
import useProfiler from "hooks/useProfiler";

const utils = createTrackingUtils({
  name: "flight_web_refresh_modal_background_v2",
  stages: {
    main: 1,
    direct: 2,
    meta: 3,
    desktop: 4,
    mobile: 5
  },
  goals: {
    user_saw_modal: 1
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackRefreshModalStages = () => {
  const { isDirect, isMeta, isDesktop, isMobile } = useProfiler();

  return useCallback(() => {
    stages.main();

    isDirect() && stages.direct();
    isMeta() && stages.meta();
    isDesktop() && stages.desktop();
    isMobile() && stages.mobile();
  }, [isDesktop, isDirect, isMeta, isMobile]);
};

export default utils;
