import { useEffect } from "react";
import { getAccommodations } from "../api/getAccommodations";
import { CarouselDataResponse, UIXComponentType } from "../types";
import { useSearchParamsData } from "./useSearchParamsData";
import useContextName from "hooks/useContextName";
import { useActions, useStore } from "../../store";
import { actions as crossSellActions } from "store/crossSell/actions";
import checkIsPastBooking from "utils/past-booking";
import useIsPbFlightDetails from "../hooks/useIsPbFlightDetails";
import useEvents from "../../hooks/useEvents";

export const useXsellActionsCall = (componentType: UIXComponentType, numberOfResults?: number) => {
  const store = useStore();
  const { order } = store.order;
  const data = useSearchParamsData(componentType, numberOfResults);
  const contextName = useContextName();
  const xSellActions = useActions(crossSellActions);
  const isPastBooking = checkIsPastBooking(order);
  const isPbFlightDetails = useIsPbFlightDetails();
  const { jsError } = useEvents();

  return useEffect(() => {
    if (!isPbFlightDetails && !isPastBooking && data) {
      const headers = {
        "X-Flights-Context-Name": contextName
      };
      xSellActions.isLoading(true);
      getAccommodations(data, headers || {})
        .then((res: CarouselDataResponse) => {
          xSellActions.fetchSuccess(res);
        })
        .catch(jsError)
        .finally(() => {
          xSellActions.isLoading(false);
        });
    }
  }, [contextName, data, isPbFlightDetails, isPastBooking, xSellActions, jsError]);
};
