import { post } from "cross-sell/services/clientApi";
import { ACCOMMODATIONS_SUMMARY_URL } from "cross-sell/constants";
import { UIXIndicativeRequestParams } from "../types";

export const getAccommodationsSummary = (
  data?: {
    metaInfo: { orderId?: string; geniusLevel: number; currencyCode: string };
    searchParams: UIXIndicativeRequestParams;
    imageOnly?: boolean;
  },
  headers?: object
) => {
  return post(ACCOMMODATIONS_SUMMARY_URL, headers || {}, {}, data);
};
