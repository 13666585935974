import { UIClientFetchError, UIFetchStatus, UISearchMinPriceInfo } from "@flights/types/client";

export type State = {
  fetchStatus: UIFetchStatus;
  flexibleDateMinPrice: UISearchMinPriceInfo[];
  error?: UIClientFetchError;
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  flexibleDateMinPrice: [],
  error: undefined
});
