import { UIClientFetchError, UIFetchStatus, UIRebookEligibilityInfo } from "@flights/types/client";

export interface State {
  fetchStatus: UIFetchStatus;
  error?: UIClientFetchError;
  rebookPolicy?: UIRebookEligibilityInfo;
}

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  rebookPolicy: undefined,
  error: undefined
});
