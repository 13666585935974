import {
  UIAvailableExtraProducts,
  UIBrandedFareFeatureName,
  UIIncludedProductBySegmentCompressed,
  UILuggageType
} from "@flights/types/client";
import { includedBaggageIcons } from "constants/icons";
import formatting from "./formatting";
import { I18nChildContext } from "@bookingcom/lingojs-react";
import { SimpleFeature } from "@flights/types/brandedFares";
import { getProductsIncludedInAllSegments } from "utils/includedProducts";

const BAGGAGE_ORDER: UILuggageType[] = ["PERSONAL_ITEM", "HAND", "CHECKED_IN"];

const BAGGAGE_FEATURE_NAMES: Record<UILuggageType, UIBrandedFareFeatureName> = {
  PERSONAL_ITEM: "PERSONAL_BAGGAGE",
  CHECKED_IN: "CHECK_BAGGAGE",
  HAND: "CABIN_BAGGAGE"
};

type BaggageFeaturesOptions = {
  onlyShowIncludedInAllSegments?: boolean;
  showSellableBaggage?: boolean;
  showExcludedBaggage?: boolean;
};

export const getBaggageFeaturesToRender = (
  i18n: I18nChildContext,
  includedProducts?: UIIncludedProductBySegmentCompressed,
  ancillaries?: UIAvailableExtraProducts,
  options: BaggageFeaturesOptions = {}
) => {
  const includedBaggageFeatures: SimpleFeature[] = [];
  const excludedBaggageFeatures: SimpleFeature[] = [];
  const luggageItems = getLuggageItems(includedProducts);

  if (!luggageItems) return { includedBaggageFeatures, excludedBaggageFeatures };

  for (const type of BAGGAGE_ORDER) {
    const includedBaggage = luggageItems.filter((item) => item.luggageType === type);
    const name = BAGGAGE_FEATURE_NAMES[type];
    const icon = includedBaggageIcons[type];
    const baseFeature = { name, icon };

    for (const item of includedBaggage) {
      includedBaggageFeatures.push({
        ...baseFeature,
        label: formatting.piecesOfLuggageWithWeight(item, i18n),
        availability: "INCLUDED"
      });
    }

    if (includedBaggage.length > 0) continue;

    const isSellable = isLuggageTypeSellable(type, ancillaries);

    if (isSellable && options.showSellableBaggage) {
      includedBaggageFeatures.push({
        ...baseFeature,
        label: formatting.sellableLuggage(type, i18n),
        availability: "SELLABLE"
      });
    }

    if (!isSellable && options.showExcludedBaggage) {
      excludedBaggageFeatures.push({
        ...baseFeature,
        label: formatting.excludedLuggage(type, i18n),
        availability: "NOT_INCLUDED"
      });
    }
  }

  return { includedBaggageFeatures, excludedBaggageFeatures };
};

const getLuggageItems = (
  includedProducts?: UIIncludedProductBySegmentCompressed,
  onlyShowIncludedInAllSegments?: boolean
) => {
  if (!includedProducts) return undefined;

  return onlyShowIncludedInAllSegments
    ? getProductsIncludedInAllSegments(includedProducts)
    : includedProducts.segments[0];
};

export const isLuggageTypeSellable = (type: UILuggageType, ancillaries?: UIAvailableExtraProducts): boolean => {
  switch (type) {
    case "HAND":
      return !!ancillaries?.cabinBaggage || !!ancillaries?.cabinBaggagePerTraveller;
    case "CHECKED_IN":
      return !!ancillaries?.checkedInBaggage?.options[0]?.luggageAllowance || !!ancillaries?.specialBags;
    default:
      return false;
  }
};
