import React from "react";

import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";

import useModalDialog from "hooks/useModalDialog";
import type { UICampaignBanner } from "@flights/types/client";

import { FlightCampaignTCBannerModalContent } from "./FlightCampaignTCBannerModalContent";
import styles from "./FlightCampaignTCBannerModal.module.css";

interface FlightCampaignTCBannerModalProps {
  isActive: boolean;
  campaignBanner: UICampaignBanner;
  onModalClose?: VoidFunction;
}

export const FlightCampaignTCBannerModal = ({
  isActive,
  campaignBanner,
  onModalClose
}: FlightCampaignTCBannerModalProps) => {
  const i18n = useI18n() as I18nChildContext;
  const Modal = useModalDialog();

  return (
    <Modal
      active={isActive}
      fill={true}
      onClose={onModalClose}
      closeAriaLabel={
        campaignBanner.action.closeButtonAccessibility || i18n.trans(t("a11y_flights_deal_banner_detail_close"))
      }
      title={
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderTitle}>{campaignBanner.action.title}</div>
          {campaignBanner.action.subtitle && (
            <div className={styles.modalHeaderSubtitle}>{campaignBanner.action.subtitle}</div>
          )}
        </div>
      }
    >
      <FlightCampaignTCBannerModalContent campaignBanner={campaignBanner} />
    </Modal>
  );
};
