/* istanbul ignore file */
/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { Fragment } from "react";
import GeniusBadge from "@bookingcom/bui-assets-react/genius/GeniusBadge";
import { Badge, Button, Card, Icon, Image, ReviewScore, Stack, Text } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { ArrowNavLeftIcon, ArrowNavRightIcon, GeoPinIcon } from "@bookingcom/bui-assets-react/streamline";
import { t } from "@bookingcom/lingojs-core";

import { UIXAccommodation, UIXAccommodationSearchParams } from "cross-sell/types";

import { useStarsRating } from "cross-sell/hooks/useStarsRating";
import { getHigherResImage } from "hooks/useHigherResolutionImages";
import useLocaleContext from "hooks/useLocaleContext";
import formatting from "utils/formatting";
import styles from "./ListAccommodationCard.module.css";
import { mcn } from "utils/mergeClassnames";

interface Props {
  accommodation: UIXAccommodation;
  searchParams: UIXAccommodationSearchParams;
  onCardClick?: () => void;
  isModal?: boolean;
  ref?: React.RefObject<HTMLDivElement> | null;
}

const ListAccommodationCard = (props: Props) => {
  const { searchParams, isModal, accommodation: config } = props;
  const { isRTL } = useLocaleContext();
  const i18n = useI18n() as I18nChildContext;
  const PAY_WITH_WALLET_TEXT = i18n.trans(t("a11y_flights_xsell_pb_modal_pay_with_wallet_label"));
  const hasSamePrice = config.formattedInitialPrice
    ? config.formattedInitialPrice === config.formattedDisplayPrice
    : true;

  const stars = useStarsRating(config);

  const trackClick = (e: React.MouseEvent<HTMLElement>, type?: string) => {
    e.preventDefault();
    if (props?.onCardClick) {
      props?.onCardClick();
    }
    const URL = type === "room" ? config.propertyTarget.target + "#availability" : config.propertyTarget.target;
    const blankWindow = window.open(URL, "_blank");
    if (blankWindow) {
      blankWindow.focus();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    trackClick(e);
  };

  const handleAvailabilityClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    trackClick(e, "room");
  };
  const handleContextMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (props?.onCardClick) {
      props?.onCardClick();
    }
  };

  return (
    <a
      className={styles.wrapper}
      onClick={handleClick}
      onContextMenu={handleContextMenuClick}
      href={config.propertyTarget.target}
      data-testid="accommodation-card"
      data-ismodal={isModal ? "true" : "false"}
      aria-label=""
      tabIndex={0}
    >
      <>
        <Card className={styles.accommodationCard}>
          <Stack direction="row" justifyContent="start" alignItems="start" className={styles.mainWrapper}>
            <Image
              height={isModal ? "124px" : "180px"}
              width={isModal ? "150px" : "180px"}
              src={getHigherResImage(config.photo, "square200", isModal ? "150x124" : "180x180")}
              borderRadius={200}
              alt={i18n.trans(
                t("flights_confirmation_genius_reviews", {
                  num_exception: config.totalScore?.numberReviews,
                  variables: { num_reviews: config.totalScore?.numberReviews || "" }
                })
              )}
              className={styles.image}
              fallback="image"
              fallbackImageSrc={config.photo}
            />
            <Stack.Item grow={true}>
              <Stack direction="column" alignItems="stretch" className={styles.innerWrapper} gap={0}>
                <Stack.Item className={styles.cardHeader}>
                  <div className={styles.cardTitle}>
                    <Text
                      variant={isModal ? "strong_1" : "headline_3"}
                      color="action"
                      attributes={{ title: config.name }}
                      className={styles.accommodationCardTitle}
                    >
                      <span className={styles.title}>{formatting.truncateWithEllipsis(config.name, 85)}</span>
                      {!isModal && (stars.length || config.geniusProperty || config.persuasionBlock) ? (
                        <span className={styles.accommodationCardTop}>
                          {stars.length ? (
                            <Stack.Item
                              className={styles.accommodationCardTopStarsWrapper}
                              attributes={{
                                "data-testid": "accommodation-stars"
                              }}
                            >
                              {stars}
                            </Stack.Item>
                          ) : null}
                          {config.persuasionBlock && config.persuasionBlock.isAd && (
                            <Stack.Item
                              className={styles.adBadge}
                              attributes={{
                                "data-testid": "accommodation-ad-badge"
                              }}
                            >
                              <Badge
                                text={i18n.trans(t("flights_xsell_pb_carousel_sponsored_ad_badge"))}
                                variant="outline"
                              />
                            </Stack.Item>
                          )}
                          {config.geniusProperty && (
                            <Stack.Item
                              className={styles.accommodationCardTopGeniusTag}
                              attributes={{
                                "data-testid": "accommodation-genius"
                              }}
                            >
                              <Icon svg={GeniusBadge} size="larger" ariaLabel="Genius" />
                            </Stack.Item>
                          )}
                          {config.acceptsWalletCredits && (
                            <Stack.Item
                              className={styles.payWallet}
                              attributes={{
                                "data-testid": "accommodation-pay-with-wallet"
                              }}
                            >
                              <Badge text={PAY_WITH_WALLET_TEXT} variant="brand-primary" />
                            </Stack.Item>
                          )}
                        </span>
                      ) : null}
                    </Text>
                    {!isModal && config.accommodationType ? (
                      <Text variant="small_1" color="neutral" className={styles.accommodationCardTopProductType}>
                        {config.accommodationType}
                      </Text>
                    ) : null}
                    {isModal ? (
                      <Stack direction="row" className={styles.titleDetails}>
                        {config.accommodationType ? (
                          <Text variant="small_1" color="neutral" className={styles.accommodationCardTopProductType}>
                            {config.accommodationType}
                          </Text>
                        ) : null}
                        {stars.length ? (
                          <Stack.Item
                            className={styles.accommodationCardTopStarsWrapper}
                            attributes={{
                              "data-testid": "accommodation-stars"
                            }}
                          >
                            {stars}
                          </Stack.Item>
                        ) : null}
                        {config.persuasionBlock && config.persuasionBlock.isAd && (
                          <Stack.Item
                            className={styles.adBadgeModal}
                            attributes={{
                              "data-testid": "accommodation-ad-badge"
                            }}
                          >
                            <Badge
                              text={i18n.trans(t("flights_xsell_pb_carousel_sponsored_ad_badge"))}
                              variant="outline"
                              className={mcn(styles.adBadgeInnerCard, styles.adBadgeInner)}
                            />
                          </Stack.Item>
                        )}
                        {config.geniusProperty && (
                          <Stack.Item
                            className={styles.accommodationCardTopGeniusTag}
                            attributes={{
                              "data-testid": "accommodation-genius"
                            }}
                          >
                            <GeniusBadge />
                          </Stack.Item>
                        )}
                        {config.acceptsWalletCredits && (
                          <Stack.Item
                            className={styles.payWallet}
                            attributes={{
                              "data-testid": "accommodation-pay-with-wallet"
                            }}
                          >
                            <Badge text={PAY_WITH_WALLET_TEXT} variant="brand-primary" />
                          </Stack.Item>
                        )}
                      </Stack>
                    ) : null}
                  </div>
                  {config.totalScore?.ratingScore && config.totalScore?.numberReviews ? (
                    <div className={styles.accommodationCardRating}>
                      <ReviewScore
                        score={`${config.totalScore.ratingScore}`}
                        scoreAriaLabel={`${config.totalScore.ratingScore}`}
                        reviewCount={i18n.trans(
                          t("flights_confirmation_genius_reviews", {
                            num_exception: config.totalScore.numberReviews,
                            variables: { num_reviews: config.totalScore.numberReviews }
                          })
                        )}
                        rating={config.totalScore.scoreDescription}
                        ratingReviewAriaLabel={config.totalScore.scoreDescription}
                        inline={false}
                        alignment="end"
                        size={isModal ? "small" : "medium"}
                      />
                    </div>
                  ) : null}
                </Stack.Item>

                <Stack direction="row" justifyContent="space-between" alignItems={"start"} className={styles.infoWrap}>
                  <Stack direction="column">
                    <Stack direction="column">
                      {!isModal && config.distanceFromCentre ? (
                        <Text color="neutral_alt" variant="body_2">
                          <Stack justifyContent="start" alignItems="center" direction="row" className={styles.distance}>
                            <Icon className={styles.icon} color="neutral" size="smaller" svg={GeoPinIcon} />
                            {i18n.trans(
                              t("flights_xsell_pb_carousel_distance", {
                                variables: {
                                  distance: config.distanceFromCentre?.toString()
                                }
                              })
                            )}
                          </Stack>
                        </Text>
                      ) : null}
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="column" className={styles.info}>
                        {config.mealplanDescription ? (
                          <Text
                            variant="strong_2"
                            color="constructive"
                            className={styles.breakfast}
                            attributes={{
                              "data-testid": "accommodation-original-amount"
                            }}
                          >
                            {config.mealplanDescription}
                          </Text>
                        ) : null}
                        {config.hasFreeCancellation ? (
                          <Fragment>
                            <Text
                              variant="strong_2"
                              color="constructive"
                              className={styles.breakfast}
                              attributes={{
                                "data-testid": "accommodation-original-amount"
                              }}
                            >
                              {config.showNoPrepaymentNeeded
                                ? i18n.trans(t("flights_xsell_pb_property_list_free_cancellation"))
                                : i18n.trans(t("flights_xsell_pb_carousel_free_cancellation"))}
                            </Text>
                            <Text
                              variant="small_1"
                              color="constructive"
                              className={styles.breakfast}
                              attributes={{
                                "data-testid": "accommodation-original-amount"
                              }}
                            >
                              {i18n.trans(t("flights_xsell_pb_property_list_cancel_later"))}
                            </Text>
                          </Fragment>
                        ) : null}
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="column" className={styles.priceWrapper}>
                    <div className={styles.accommodationCardPrice}>
                      <Text
                        variant={isModal ? "small_1" : "body_2"}
                        color="neutral_alt"
                        className={styles.accommodationCardPriceDescription}
                      >
                        {i18n.trans(t("flights_confirmation_genius_price"))}
                      </Text>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0}
                        className={styles.accommodationCardPricePriceWrapper}
                      >
                        {!hasSamePrice && config.formattedInitialPrice && (
                          <Text
                            variant="small_1"
                            color="destructive"
                            decoration="line-through"
                            className={styles.accommodationCardPriceOriginalAmount}
                            attributes={{
                              "data-testid": "accommodation-original-amount"
                            }}
                          >
                            {config.formattedInitialPrice}
                          </Text>
                        )}
                        <Text
                          variant={isModal ? "headline_3" : "strong_1"}
                          color="neutral"
                          attributes={{
                            "data-testid": "accommodation-amount"
                          }}
                        >
                          {config.formattedDisplayPrice}
                        </Text>
                      </Stack>

                      {searchParams.numberGuests && searchParams.numberNights ? (
                        <Text
                          variant={isModal ? "small_1" : "body_2"}
                          color="neutral_alt"
                          className={styles.accommodationCardPriceGuestNights}
                          data-testid="accommodation-guests"
                        >
                          {i18n.trans(
                            t("flights_pb_accessibility_guests_nights_label", {
                              num_exception: searchParams.numberGuests,
                              variables: { num_guests: searchParams.numberGuests }
                            })
                          )}{" "}
                          {i18n.trans(
                            t("flights_pb_accessibility_guests_nights_label_2", {
                              num_exception: searchParams.numberNights,
                              variables: { num_nights: searchParams.numberNights }
                            })
                          )}
                        </Text>
                      ) : null}
                    </div>
                  </Stack>
                </Stack>
                {!isModal ? (
                  <Stack direction="row" justifyContent="end" className={styles.ctaWrapper}>
                    <Button
                      className={styles.cta}
                      onClick={handleAvailabilityClick}
                      iconPosition="end"
                      text={i18n.trans(t("flights_xsell_pb_property_list_cta_see_availability"))}
                      icon={<Icon size="medium" color="white" svg={isRTL ? ArrowNavLeftIcon : ArrowNavRightIcon} />}
                      size="medium"
                    />
                  </Stack>
                ) : null}
              </Stack>
            </Stack.Item>
          </Stack>
        </Card>
      </>
    </a>
  );
};

export { ListAccommodationCard };
