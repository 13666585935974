import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_web_stops_filter_checkbox",
  stages: {
    search_results: 1,
    search_results_direct: 2, // Main,
    search_results_meta: 3,
    search_results_desktop: 4,
    search_results_mdot: 5,
    traveller_solo: 6,
    traveller_2plus: 7,
    traveller_with_children: 8
  },
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

type Params = {
  travellersCount: number;
  hasChildren: boolean;
  isMdot: boolean;
};

export const trackStopsFilterCheckbox = ({ travellersCount, hasChildren, isMdot }: Params) => {
  stages.search_results();
  isMdot ? stages.search_results_mdot() : stages.search_results_desktop();

  isOfMetaOrigin() ? stages.search_results_meta() : stages.search_results_direct();

  if (travellersCount === 1) {
    stages.traveller_solo();
  }

  if (travellersCount >= 2) {
    stages.traveller_2plus();
  }

  if (hasChildren) {
    stages.traveller_with_children();
  }
};
