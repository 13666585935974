import { addDays, isWithinInterval, parse } from "date-fns";

export function isFlightWithin30daysBookingWindow(departureDate: string) {
  const bookingWindow = { start: new Date(), end: addDays(new Date(), 30) };
  const departure = parse(departureDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());

  return isWithinInterval(departure, bookingWindow);
}

export const EU_COUNTRIES: string[] = [
  "ro",
  "pl",
  "pt",
  "be",
  "gb",
  "de",
  "lu",
  "is",
  "es",
  "hr",
  "rs",
  "it",
  "nl",
  "hu",
  "ee",
  "gr",
  "mt",
  "ch",
  "no",
  "bg",
  "cy",
  "at",
  "cz",
  "ua",
  "fr",
  "se",
  "ie",
  "dk",
  "fi"
];
