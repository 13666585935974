import { useCallback } from "react";
import { trackExperiment, trackExperimentStage } from "utils/et";
import useRouteName from "./useRouteName";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "./useUserAgent";
import { useStore } from "../store";
import { INFANT_MAX_AGE } from "../constants";

export default function useTrackBaggagesV2() {
  const { isMobile } = useUserAgent();
  const routeName = useRouteName();
  const isMeta = isOfMetaOrigin();
  const store = useStore();

  const { adults, children } = store.search;

  const trackStages = useCallback(() => {
    if (routeName !== "searchresults" && routeName !== "flightdetails") return;
    trackExperimentStage("flights_web_funnel_baggages_v2", 1);
    trackExperimentStage("flights_web_funnel_baggages_v2", isMeta ? 3 : 2);
    trackExperimentStage("flights_web_funnel_baggages_v2", isMobile ? 5 : 4);

    if (children.length === 0) {
      trackExperimentStage("flights_web_funnel_baggages_v2", adults === 1 ? 6 : 7);
    } else {
      const areInfants = children.some((age) => age <= INFANT_MAX_AGE);
      const areKids = children.some((age) => age > INFANT_MAX_AGE);

      areInfants && trackExperimentStage("flights_web_funnel_baggages_v2", 8);
      areKids && trackExperimentStage("flights_web_funnel_baggages_v2", 9);
    }
  }, [routeName, isMeta, isMobile, adults, children]);

  const isBaggagesV2 = useCallback(() => {
    trackStages();
    return !!trackExperiment("flights_web_funnel_baggages_v2");
  }, [trackStages]);

  return { trackStages, isBaggagesV2 };
}
