import { useCallback, useState } from "react";
import { trackGoalWithValue } from "utils/et";
import { TimerFunc, createTimer } from "utils/timer";
import useClientMetrics from "./useClientMetrics";

/**
 * The purpose of this hook is to measure the time it takes from showing the search results to the first click on flight
 * details.
 */
export default function useMeasureSearchToFlightDetails() {
  const { trackClientMetric } = useClientMetrics();
  const [isMeasured, setIsMeasured] = useState(false);
  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  const [timer, setTimer] = useState<null | TimerFunc>(null);

  const onFetchedResults = useCallback(() => {
    const timer = createTimer();
    setTimer(() => timer);
  }, [setTimer]);

  const onSelectFlight = useCallback(() => {
    if (!timer || isMeasured) {
      return;
    }

    const time = timer();

    trackGoalWithValue("flights_web_sr_time_to_first_click_flight_details", time);
    trackClientMetric("search_time_to_first_click_flight_details", time);
    setIsMeasured(true);
  }, [timer, isMeasured, trackClientMetric]);

  return { onFetchedResults, onSelectFlight };
}
