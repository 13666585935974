/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useState } from "react";
import { List, Link, Button } from "@bookingcom/bui-react";
import { useStore } from "../../../store";
import { useI18n, t, Trans } from "@bookingcom/lingojs-react";

import { UICarrierBaggagePolicies } from "@flights/types/client";
import useModalDialog from "hooks/useModalDialog";
import Frame from "../Frame";
import styles from "./SearchBaggagePolicies.module.css";

interface Props {
  text?: string;
}

const SearchBaggagePolicies: React.FunctionComponent<Props> = () => {
  const [active, setActive] = useState(false);
  const i18n = useI18n();
  const store = useStore();
  const Modal = useModalDialog();
  const { baggagePolicies } = store.searchResults;

  if (!baggagePolicies) {
    return null;
  }

  const baggageList = baggagePolicies.map((policy: UICarrierBaggagePolicies) => {
    return {
      content: (
        <Link
          href={policy.url}
          key={policy.code}
          text={policy.name}
          attributes={{ target: "_blank", "data-testid": "search_baggage_policies_modal_link" }}
        />
      )
    };
  });

  return (
    <>
      <Frame mb={4} className={styles.baggagePolicies} attributes={{ "data-testid": "search_baggage_policies" }}>
        <Trans
          tag="flights_sr_baggage_fee_discalimer"
          variables={{
            start_link: "<0>",
            end_link: "</0>"
          }}
          components={[
            <Button
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                setActive(true);
              }}
              className={styles.modalOpener}
              key={0}
            />
          ]}
        />
      </Frame>

      <Modal
        title={i18n.trans(t("flights_sr_baggage_modal_header"))}
        subtitle={i18n.trans(t("flights_sr_baggage_modal_text"))}
        active={active}
        onCloseTrigger={() => setActive(false)}
        position="center"
        showModalInMobile
        dataTestId="search_baggage_policies_modal"
      >
        <List divided={true} items={baggageList} />
      </Modal>
    </>
  );
};
export default SearchBaggagePolicies;
