import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UIClientFetchError, UIBrandedFare } from "@flights/types/client";

export enum ActionTypes {
  fetch = "brandedFares/fetch",
  fetchSuccess = "brandedFares/fetchSuccess",
  fetchError = "brandedFares/fetchError",
  reset = "brandedFares/reset"
}

export const actions = {
  fetch: createAction((baseOfferToken: string) => ({
    type: ActionTypes.fetch,
    payload: { baseOfferToken }
  })),

  fetchSuccess: createAction((brandedFaresOffers: Array<UIBrandedFare>, baseOfferToken: string) => ({
    type: ActionTypes.fetchSuccess,
    payload: { brandedFaresOffers, baseOfferToken }
  })),

  fetchError: createAction((error: UIClientFetchError, baseOfferToken: string) => ({
    type: ActionTypes.fetchError,
    payload: { error, baseOfferToken }
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
