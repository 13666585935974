import useBaggageForFare from "components/elements/Baggages/useBaggageForFare";
import Frame from "components/elements/Frame";
import React from "react";
import { UIBrandedFare, UIFlightData } from "@flights/types/client";
import lowerCase from "lodash/lowerCase";
import startCase from "lodash/startCase";
import FareBaggageItemList from "components/ui/Baggages/FareBaggageItemList";

type Props = {
  baseOffer: UIFlightData;
  fareOffer: UIBrandedFare;
};

const FareCardBaggageOverviewList = ({ baseOffer, fareOffer }: Props) => {
  const { getBaggageSRList } = useBaggageForFare({
    travellers: baseOffer.travellers,
    travellerPrices: fareOffer.travellerPrices,
    ancillaries: baseOffer.ancillaries,
    includedProductsBySegment: fareOffer.includedProductsBySegment,
    segments: baseOffer.segments
  });
  const fareName = startCase(lowerCase(fareOffer.brandedFareInfo?.fareName));
  const baggages = getBaggageSRList(fareName);

  if (!baggages || !baggages.length) {
    return null;
  }

  return (
    <Frame pb={3}>
      <FareBaggageItemList baggages={baggages} />
    </Frame>
  );
};

export default FareCardBaggageOverviewList;
