import { useCallback, useEffect, useMemo } from "react";
import { createTrackingUtils } from "..";
import { UIFlightData } from "@flights/types/client";
import useVirutalInterlining from "hooks/useVirutalInterlining";
import useProfiler from "hooks/useProfiler";
import useIsInViewport from "hooks/useIsInViewport";

const utils = createTrackingUtils({
  name: "flights_web_blackout_vi_alert_sr",
  stages: {
    sr_has_vi_flight: 1,
    main: 2, // Main stage - VI flight is in viewport

    // The stages below are based on stage 1
    direct: 3,
    meta: 4,
    desktop: 5,
    mobile: 6,
    solo: 7,
    non_solo: 8,
    sr_has_5_or_less_offers: 9
  },
  goals: {
    click_on_view_fd_with_vi: 1,
    checkout_start_with_vi: 2,
    check_and_pay_view_with_vi: 3,
    click_on_view_fd_without_vi: 4,
    cheapest_flight_is_vi: 5
  },
  goalsWithValue: []
});

const { stages, goals } = utils;

export const useTrackVIBlackoutStages = (flights: UIFlightData[], cheapestToken: string) => {
  const { isVirtualInterliningFlight } = useVirutalInterlining();
  const { isDirect, isMeta, isDesktop, isMobile, isSolo } = useProfiler();

  const viOfferIndex = useMemo(
    () => flights.findIndex((flight) => flight.segments.find((segment) => isVirtualInterliningFlight(segment))),
    [flights, isVirtualInterliningFlight]
  );

  const isOfferInViewport = useIsInViewport(
    viOfferIndex > -1 ? (document.querySelector(`#flight-card-${viOfferIndex}`) as HTMLElement) : null
  );

  useEffect(() => {
    if (isOfferInViewport) {
      stages.main();
    }
  }, [isOfferInViewport]);

  useEffect(() => {
    const hasVI = viOfferIndex > -1;

    if (hasVI) {
      stages.sr_has_vi_flight();
      isDirect() && stages.direct();
      isMeta() && stages.meta();
      isDesktop() && stages.desktop();
      isMobile() && stages.mobile();
      isSolo() && stages.solo();
      !isSolo() && stages.non_solo();
      flights.length <= 5 && stages.sr_has_5_or_less_offers();
    }
  }, [flights, isDesktop, isDirect, isMeta, isMobile, isSolo, isVirtualInterliningFlight, viOfferIndex]);

  useEffect(() => {
    const cheapestIsVI = flights
      .find((flight) => flight.token === cheapestToken)
      ?.segments.some((segment) => isVirtualInterliningFlight(segment));

    if (cheapestIsVI) {
      goals.cheapest_flight_is_vi();
    }
  }, [cheapestToken, flights, isVirtualInterliningFlight]);
};

export const useTrackClickOnFDVIBlackout = () => {
  const { isVirtualInterliningFlight } = useVirutalInterlining();

  return useCallback(
    (flight: UIFlightData) => {
      const flightHasVI = flight.segments.some((segment) => isVirtualInterliningFlight(segment));
      if (flightHasVI) {
        utils.goals.click_on_view_fd_with_vi();
      } else {
        utils.goals.click_on_view_fd_without_vi();
      }
    },
    [isVirtualInterliningFlight]
  );
};

export default utils;
