import { CartValidationError } from "@flights/types/client";

export type State = {
  code?: CartValidationError;
  // Keeping track of if the user has encountered an email or phone number validation error at least once during their funnel journey
  hadPhoneNumberError?: boolean;
  hadEmailError?: boolean;
  // Keeping track of if we've already tracked a phone number or email validation error in the current user session (don't want to track this twice in checkout)
  phoneNumberValidationTracked?: boolean;
  emailValidationTracked?: boolean;
};

export const getInitialState = (): State => ({
  code: undefined,
  hadPhoneNumberError: undefined,
  phoneNumberValidationTracked: undefined,
  hadEmailError: undefined,
  emailValidationTracked: undefined
});
