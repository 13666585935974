import React from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { useStore, useActions } from "../../../store";
import { actions as OrderActions } from "../../../store/order/actions";
import { actions as RebookPolicyActions } from "../../../store/rebookPolicy/actions";
import { useClientFetch } from "../../../hooks/useClientFetch";
import ErrorAlert from "../ErrorAlert";
import NotAvailableIllustration from "../../../assets/illustrations/not-available.svg";
import { useLocation } from "react-router-dom";
import styles from "./ConfirmationInnerLoader.module.css";

interface Props {
  cache?: boolean;
  token: string;
  children?: React.ReactNode;
  loadingSkeleton?: React.ReactNode;
  loadingCopyTag?: string;
  includeAvailableExtras?: boolean;
  // making asynchronous rebook policy call to not block getOrder
  fetchAsyncRebookPolicy?: boolean;
  // indicates if node should fetch cancellation options with (type = 2) or without (type = 1) refund offer reference
  cancellationOptionsType?: number;
}

const ConfirmationInnerLoader: React.FunctionComponent<Props> = (props) => {
  const i18n = useI18n();

  const {
    children,
    loadingSkeleton,
    loadingCopyTag = i18n.trans(t("flights_select_in_progress")),
    cache = false,
    fetchAsyncRebookPolicy = false,
    cancellationOptionsType = 0
  } = props;

  const orderActions = useActions(OrderActions);
  const rebookPolicyActions = useActions(RebookPolicyActions);
  const store = useStore();
  const query = new URLSearchParams(useLocation().search);

  const searchParams = new URLSearchParams();
  searchParams.set("pb", "1");
  if (props.includeAvailableExtras) {
    searchParams.append("includeAvailableExtras", "1");
  }

  if (cancellationOptionsType) {
    searchParams.append("cancellationOptionsType", cancellationOptionsType.toString());
  }

  const fetchFromCache = cache && !query.get("confirm_cancel") && !query.get("refresh");

  useClientFetch(`/api/order/${props.token}?${searchParams.toString()}`, {
    shouldGenerateNewClientsidePayload: true,
    condition: () => {
      if (!!query.get("confirm_cancel") || !!query.get("refresh")) {
        // re-fetch the order details after cancellation
        return true;
      } else if (cache && store.order.fetchStatus === "success") {
        // If it is okay to re-use data for this screen (cache = true, eg. order-details, when user comes from the
        // confirmation page) and the data is loaded, then do not even fire fetch action, simply render the screen with
        // all the data it has.
        return false;
      } else {
        return true;
      }
    },
    fetch: orderActions.fetch,
    error: orderActions.fetchError,
    success: orderActions.fetchSuccess,
    cache: fetchFromCache
  });

  useClientFetch(`/api/order/${props.token}/rebookPolicy`, {
    condition: () => fetchAsyncRebookPolicy,
    fetch: rebookPolicyActions.fetch,
    error: rebookPolicyActions.fetchError,
    success: rebookPolicyActions.fetchSuccess
  });

  if (store.order.fetchStatus === "failed") {
    return (
      <div>
        <ErrorAlert
          illustration={<NotAvailableIllustration />}
          title={i18n.trans(t("flights_error_timeout_header"))}
          body={i18n.trans(t("flights_error_timeout_subheader"))}
          buttonLabel={i18n.trans(t("flights_refresh_action"))}
          onClick={() => window.location.reload()}
        />
      </div>
    );
  }
  return (
    <>
      {store.order.fetchStatus === "success" ? (
        <React.Fragment>{children}</React.Fragment>
      ) : loadingSkeleton ? (
        <React.Fragment>{loadingSkeleton}</React.Fragment>
      ) : (
        <div className={styles.loading}>{loadingCopyTag}</div>
      )}
    </>
  );
};
export default ConfirmationInnerLoader;
