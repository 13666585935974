import { UISanctionScreening } from "@flights/types/client";
import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";

export enum ActionTypes {
  set = "sanctionScreening/set",
  reset = "sanctionScreening/reset"
}

export const actions = {
  set: createAction((sanctions: UISanctionScreening[]) => ({
    type: ActionTypes.set,
    payload: { sanctions }
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
