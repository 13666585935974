import { useEffect, useMemo } from "react";
import C360ReactTracker from "@bookingcom/c360-tracker-react";

import useUserAgent from "./useUserAgent";
import useGlobalContext from "./useGlobalContext";
import { DEFAULT_MARKETING_TRACKING_VARIABLES } from "../constants";
import { flightsSessionStore } from "utils/session-store";
import useEvents from "./useEvents";

interface C360Config {
  platform?: string;
  lang: string;
  currency?: string;
  aid?: string;
  isDev: boolean;
  pageviewId?: string;
  sessionId?: string;
  etSeed?: string;
  label?: string;
  csrfToken?: string;
  onErrorCallback?: (error: Error) => void;
}

const useC360Tracker = () => {
  const { isMobile } = useUserAgent();
  const { currencyCode, env, locale = "en-gb", sessionId } = useGlobalContext();
  const { jsError } = useEvents();

  const c360Config: C360Config = useMemo(() => {
    return {
      aid: flightsSessionStore.get("aid") || DEFAULT_MARKETING_TRACKING_VARIABLES.aid,
      currency: currencyCode,
      isDev: env !== "prod",
      label: flightsSessionStore.get("label") || DEFAULT_MARKETING_TRACKING_VARIABLES.label,
      lang: locale,
      onErrorCallback: (error) => jsError(error),
      platform: isMobile ? "mdot" : "ddot",
      sessionId
    };
  }, [currencyCode, env, isMobile, locale, sessionId, jsError]);

  useEffect(() => {
    C360ReactTracker.init(c360Config);
  }, []); // eslint-disable-line
};

export default useC360Tracker;
