/**
 * =============================================================================
 * Checkout placeholder provider
 * this utility to provide a way to render components in a pre-defined
 * placeholders from within other components.
 * currently used to render the price breakdown in trip summary header (desktop)
 * =============================================================================
 * this hack to avoid restructuring the entire checkout flow pages layouts.
 * =============================================================================
 */

import React, { createContext, useContext, useState, useCallback, PropsWithChildren, ReactNode } from "react";

const checkoutPlaceholderContext = createContext(
  undefined as unknown as {
    updateTripSummaryPlaceholder: (_: ReactNode | undefined) => void;
    tripSummaryPlaceholder: ReactNode | undefined;
  }
);

export const CheckoutPlaceholderProvider = ({ children }: PropsWithChildren<{}>) => {
  const [tripSummaryPlaceholder, setTripSummaryPlaceholder] = useState<ReactNode | undefined>(undefined);

  const updateTripSummaryPlaceholder = useCallback((content: ReactNode | undefined) => {
    setTripSummaryPlaceholder(content);
  }, []);

  return (
    <checkoutPlaceholderContext.Provider value={{ tripSummaryPlaceholder, updateTripSummaryPlaceholder }}>
      {children}
    </checkoutPlaceholderContext.Provider>
  );
};

export const useCheckoutPlaceholder = () => useContext(checkoutPlaceholderContext);
