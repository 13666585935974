/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useMemo } from "react";
import { RatingTypes } from "../constants";
import { CircleIcon, StarIcon } from "@bookingcom/bui-assets-react/streamline";
import { SquareRatingIcon } from "@bookingcom/bui-assets-react/fallback";
import { Icon } from "@bookingcom/bui-react";
import { UIXAccommodation } from "../types";
import styles from "./useStarsRating.module.css";

export const useStarsRating = (config: UIXAccommodation) => {
  return useMemo(() => {
    if (!config.starRatingData) return [];

    const starsList = config.starRatingData.value;
    let iconType: FC | null = null;

    switch (config.starRatingData.symbol) {
      case RatingTypes.STARS:
        iconType = StarIcon;
        break;
      case RatingTypes.DOTS:
        iconType = CircleIcon;
        break;
      case RatingTypes.TILES:
        iconType = SquareRatingIcon;
        break;
      case RatingTypes.NONE:
        break;
      default:
        break;
    }

    const icons = [];

    if (iconType) {
      for (let i = 0; i < Math.ceil(starsList); i++) {
        icons.push(<Icon className={styles.icon} key={i} size="smaller" svg={iconType} />);
      }
    }

    return icons;
  }, [config.starRatingData]);
};
