import React from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputCheckbox, InputCheckboxGroup, Stack, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import WithSuffix from "../WithSuffix";
import useUserAgent from "hooks/useUserAgent";
import { trackExperiment } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";

type StopsControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["multiSelectStops"]>;

type StopsProps = {
  value: StopsControllerProps["value"];
  options: StopsControllerProps["options"];
  onChange: StopsControllerProps["set"];
  /* start - flights_web_filters_count_blackout_desktop */
  hideCount?: boolean;
  /* end - flights_web_filters_count_blackout_desktop */
};

const MultiSelectStops = (props: StopsProps) => {
  const { options, value, onChange, hideCount = false } = props;
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const isRoundPrice =
    !isOfMetaOrigin() && (isMobile || (!isMobile && trackExperiment("flights_web_roundprice_sr_fd_direct_desktop")));
  /*enable round price only from direct not meta user*/
  return (
    <InputCheckboxGroup
      name="multiSelectStops"
      value={value?.map(String)}
      onChange={({ value }) => onChange(value.map(Number))}
    >
      <Stack gap={isMobile ? 4 : 2}>
        {options.map((option, i) => (
          <WithSuffix
            key={`${option.label}-${i}-${option.count}`}
            suffix={
              !hideCount && <span data-testid={`search_filter_stops_radio_values_${option.label}`}>{option.count}</span>
            }
          >
            <InputCheckbox
              value={String(option.value)}
              name={String(option.value)}
              label={
                <>
                  <Text variant="body_2">{option.label}</Text>
                  <Text tagName="span" color="neutral_alt" variant="body_2">
                    {i18n.trans(
                      t("flights_filters_lowest_price", {
                        variables: {
                          lowest_price: isRoundPrice ? option.roundPrice : option.price
                        }
                      })
                    )}
                  </Text>
                </>
              }
            />
          </WithSuffix>
        ))}
      </Stack>
    </InputCheckboxGroup>
  );
};

export default MultiSelectStops;
