import { AncillaryGroup, SimpleFeature, SimpleFeatureConfig } from "@flights/types/brandedFares";
import {
  CHANGEABLE_TICKET_FEATURES,
  REFUNDABLE_TICKET_FEATURES,
  SIMPLE_FEATURES_UI_CONFIG
} from "constants/brandedFares";
import { UIBrandedFareFeature, UIBrandedFareFeatureAvailability, UIBrandedFareInfo } from "@flights/types/client";

type RenderFeaturesOptions = {
  includeSellableFlexFeatures?: boolean;
  includeSellableNonFlexFeatures?: boolean;
};

export const getFeaturesToRender = (brandedFareInfo: UIBrandedFareInfo, options: RenderFeaturesOptions = {}) => {
  const sellableFeatures =
    options.includeSellableFlexFeatures || options.includeSellableNonFlexFeatures
      ? getWithAvailability(brandedFareInfo.sellableFeatures, "SELLABLE")
      : [];
  const nonIncludedFeatures = getWithAvailability(brandedFareInfo.nonIncludedFeatures, "NOT_INCLUDED");
  const included = getWithAvailability(brandedFareInfo.features, "INCLUDED");
  const includedFeatures = filterRenderableFeatures(included, sellableFeatures, options);

  return { includedFeatures, nonIncludedFeatures: filterRenderableFeatures(nonIncludedFeatures, [], options) };
};

const getWithAvailability = <T extends Pick<UIBrandedFareFeature, "availability">>(
  features: T[] | undefined,
  availability: UIBrandedFareFeatureAvailability
) => (features || []).filter((f) => f.availability === availability);

const filterRenderableFeatures = (
  features: UIBrandedFareFeature[],
  alternativeFeatures: UIBrandedFareFeature[] = [],
  options: RenderFeaturesOptions
) => {
  const compressed = compressSimilarFeatures(features);
  const alternativeCompressed = compressSimilarFeatures(alternativeFeatures);
  const renderableFeatures: SimpleFeature[] = [];

  for (const config of SIMPLE_FEATURES_UI_CONFIG) {
    const { name, group, icon } = config;

    const found = findFeature(config, compressed) || findFeature(config, alternativeCompressed);

    if (!found?.label) continue;

    const { label, availability, description, subtitle } = found;

    const isSellable = availability === "SELLABLE";
    const isFlexible = group === AncillaryGroup.FLEXIBILITY;

    if (isSellable && (isFlexible ? !options.includeSellableFlexFeatures : !options.includeSellableNonFlexFeatures))
      continue;

    renderableFeatures.push({ name, label, group, icon, availability, description, subtitle });
  }

  return renderableFeatures;
};

const findFeature = (config: SimpleFeatureConfig, features: UIBrandedFareFeature[]) =>
  features.find((f) => f.featureName === config.name);

const compressSimilarFeatures = (features: UIBrandedFareFeature[]): UIBrandedFareFeature[] => {
  const compressedFeatures: UIBrandedFareFeature[] = [];
  let changeable = false;
  let refundable = false;

  features.forEach((feature) => {
    const { featureName } = feature;
    if (CHANGEABLE_TICKET_FEATURES.includes(featureName)) {
      if (!changeable) {
        compressedFeatures.push(feature);
        changeable = true;
      }
    } else if (REFUNDABLE_TICKET_FEATURES.includes(featureName)) {
      if (!refundable) {
        compressedFeatures.push(feature);
        refundable = true;
      }
    } else {
      compressedFeatures.push(feature);
    }
  });

  return compressedFeatures;
};
