import React, { useState, useCallback, useMemo, PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { Tab, Text, Popover, useTheme, Icon, Stack, Card } from "@bookingcom/bui-react";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline";
import { SBSortingOrder } from "@bookingcom/flights-searchbox/@types/client";
import { actions as searchActions } from "../../../store/search/actions";
import { useActions } from "../../../store";
import useEventTracking from "../../../hooks/useEventTracking";
import useCreateUrl from "../../../hooks/useCreateUrl";
import useGlobalContext from "../../../hooks/useGlobalContext";
import { trackCustomGoal, trackExperimentStage } from "utils/et";
import styles from "./SearchTabs.module.css";
import useExpCopyBestNewWord from "hooks/useExpCopyBestNewWord";

type Props = PropsWithChildren<{
  showBestRankingInfo?: boolean;
  onChange?: (value: string) => void;
  fill?: boolean;
}>;

const SearchTabs: React.FunctionComponent<Props> = ({ showBestRankingInfo, onChange, children, fill }) => {
  const history = useHistory();
  const location = useLocation();
  const i18n = useI18n() as I18nChildContext;
  const actions = useActions(searchActions);
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const theme = useTheme();
  const { enhanceURLSearchParams } = useCreateUrl();
  const expCopyBestNewWord = useExpCopyBestNewWord();

  const initialSort = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get("sort") || "BEST";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [state, setState] = useState(initialSort);

  const selectTab = useCallback(
    (sortingOrder: SBSortingOrder) => {
      setState(sortingOrder);
      actions.setSortOrder(sortingOrder);
      setTimeout(function () {
        const params = new URLSearchParams(location.search);
        params.set("sort", sortingOrder);
        params.delete("page");
        if (sortingOrder == "BEST") {
          trackCustomGoal("flights_meta_land_on_cheapest_flights", 1); //sort by best
        }
        if (sortingOrder == "CHEAPEST") {
          trackCustomGoal("flights_meta_land_on_cheapest_flights", 2); //sort by cheapest
        }
        trackExperimentStage("flight_web_sr_traffic_aa_desktop", 3);

        enhanceURLSearchParams(params);
        history.replace(
          {
            pathname: location.pathname,
            search: params.toString()
          },
          { hideLoader: true }
        );
      });
      trackCustomGoal("flights_web_copy_best_new_word", 1);
      onChange && onChange(sortingOrder);
      trackV2("click_sort_by_link", sortingOrder);
    },
    [actions, history, location, enhanceURLSearchParams, onChange, trackV2]
  );

  const [bestPopoverActive, setBestPopoverActive] = React.useState(false);

  const bestTitle = expCopyBestNewWord.getSortingTitle() || i18n.trans(t("flights_search_sort_best"));
  const bestTooltip = expCopyBestNewWord.getSortingToltip() || i18n.trans(t("flights_search_sort_best_tooltip"));

  const triggerList = [
    {
      id: "BEST",
      linkAttributes: {
        id: "TAB-BEST",
        "data-testid": "search_tabs_BEST",
        "aria-label": `${bestTitle} - ${bestTooltip}`,
        onFocus: () => setBestPopoverActive(true),
        onBlur: () => setBestPopoverActive(false)
      },
      text: (
        <Stack direction="row">
          <Text variant="body_2">{bestTitle}</Text>
          {showBestRankingInfo && (
            <Popover hideArrow={true} triggerType="hover" active={bestPopoverActive}>
              <Popover.Trigger>
                {(attrs) => (
                  <Icon
                    svg={InfoSignIcon}
                    size="small"
                    color="neutral_alt"
                    className={styles.icon}
                    attributes={{
                      ...attrs,
                      onMouseEnter: () => setBestPopoverActive(true),
                      onMouseLeave: () => setBestPopoverActive(false)
                    }}
                  />
                )}
              </Popover.Trigger>
              <Popover.Content
                attributes={{
                  style: {
                    maxWidth: 320,
                    marginTop: theme.units.spacing_2x
                  }
                }}
              >
                <div>
                  <Text variant="body_2">{bestTooltip}</Text>
                </div>
              </Popover.Content>
            </Popover>
          )}
        </Stack>
      )
    },
    {
      id: "CHEAPEST",
      linkAttributes: {
        id: "TAB-CHEAPEST",
        "data-testid": "search_tabs_CHEAPEST",
        "aria-label": i18n.trans(t("flights_search_sort_cheapest"))
      },
      text: i18n.trans(t("flights_search_sort_cheapest"))
    },
    {
      id: "FASTEST",
      linkAttributes: {
        id: "TAB-FASTEST",
        "data-testid": "search_tabs_FASTEST",
        "aria-label": i18n.trans(t("flights_search_sort_fastest"))
      },
      text: i18n.trans(t("flights_search_sort_fastest"))
    }
  ];

  return (
    <Tab onTabChange={(id) => selectTab(id as SBSortingOrder)} activeTabId={state} fillEqually>
      <Card attributes={fill ? undefined : { style: { marginBottom: "var(--bui_spacing_4x)" } }} fill>
        <Tab.TriggerList>
          {triggerList.map(({ id, text, linkAttributes }) => (
            <Tab.Trigger key={id} id={id} text={text} linkAttributes={linkAttributes} />
          ))}
        </Tab.TriggerList>
      </Card>
      {triggerList.map(({ id }) => (
        <Tab.Panel key={id} id={id} attributes={{ "aria-labelledby": `TAB-${id}`, tabIndex: 0 }}>
          {children}
        </Tab.Panel>
      ))}
    </Tab>
  );
};

export default SearchTabs;
