/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import Stops from "../Input/Stops";
import useSearchFiltersController from "../useSearchFiltersController";
import { Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { trackExperiment } from "utils/et";
import MultiSelectStops from "../Input/MultiSelectStops";

export default function StopsFilter() {
  const i18n = useI18n();
  const { stops, multiSelectStops } = useSearchFiltersController();

  const isMultiStopsExperiment = !!trackExperiment("flights_web_stops_filter_checkbox");

  if (isMultiStopsExperiment && multiSelectStops.options.length === 0) return null;

  if (stops.options.length === 0) return null;

  return (
    <Stack attributes={{ "data-testid": "stops_filter" }}>
      <WithSuffix
        suffix={
          stops.touched || multiSelectStops.touched ? (
            <Link
              attributes={{ "data-testid": "search_filter_stops_reset" }}
              onClick={stops.touched ? stops.reset : multiSelectStops.reset}
            >
              {i18n.trans(t("flights_filter_reset"))}
            </Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filters_stops_title"))}</Text>
      </WithSuffix>
      {trackExperiment("flights_web_stops_filter_checkbox") ? (
        <MultiSelectStops
          value={multiSelectStops.value}
          options={multiSelectStops.options}
          onChange={multiSelectStops.set}
          hideCount={false}
        />
      ) : (
        <Stops value={stops.value} options={stops.options} onChange={stops.set} hideCount={false} />
      )}
    </Stack>
  );
}
