import React, { useCallback } from "react";
import { BaggageFromTo, BaggageInclusionType, BaggageStatus, BaggageType } from "./utils";
import { BaggageList, BaggageProps } from "components/ui/Baggages";
import { UIFlightData } from "@flights/types/client";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useBaggages from "./useBaggages";

type BaggagesSRProps = { flight: UIFlightData };

export default function BaggagesSR(props: BaggagesSRProps) {
  const { flight } = props;
  const copyHelper = useCopyHelper();
  const { getBaggagesStatus } = useBaggages(flight);
  const { cabin, checkedIn, personalItem } = getBaggagesStatus();
  const baggageData = useCallback(
    (item?: BaggageStatus): BaggageProps => ({
      type: item?.type || "cabin",
      status: copyHelper.status(item?.inclusionType, item?.fromTo),
      title: copyHelper.name(item?.type),
      isConstructive: item?.inclusionType === "included",
      notAvailable: item?.inclusionType === "not_available"
    }),
    [copyHelper]
  );
  return <BaggageList size="small" list={[baggageData(personalItem), baggageData(cabin), baggageData(checkedIn)]} />;
}

function useCopyHelper() {
  const i18n = useI18n();
  const _fromTo = useCallback(
    (fromTo: BaggageFromTo[]) => {
      const count = fromTo.length;
      const _and =
        count > 2
          ? i18n.trans(t("flights_sr_baggage_separator_last"))
          : i18n.trans(t("flights_sr_baggage_separator_and"));
      const _sprt = i18n.trans(t("flights_sr_baggage_separator"));
      const result = fromTo
        .map(({ from, to }, idx) => {
          const first = idx === 0;
          const last = idx === count - 1;
          let text = i18n.trans(
            t("flights_sr_baggage_included_iata", { variables: { depart_airport: from.code, arrive_airport: to.code } })
          );
          if (!first && !last) text = `${_sprt} ${text}`;
          if (!first && last) `${_and} ${text}`;
          return text;
        })
        .join(" ");
      return i18n.trans(t("flights_sr_baggage_included_flight")) + " " + result;
    },
    [i18n]
  );

  const status = useCallback(
    (status?: BaggageInclusionType, fromTo: BaggageFromTo[] = []) => {
      switch (status) {
        case "extra":
          return i18n.trans(t("flights_sr_baggage_extra_fee"));
        case "included":
          return i18n.trans(t("flights_sr_baggage_included"));
        case "not_available":
          return i18n.trans(t("flights_sr_baggage_not_available"));
        case "partial":
          return _fromTo(fromTo);
        default:
          return "-";
      }
    },
    [i18n, _fromTo]
  );

  const name = useCallback(
    (type?: BaggageType) => {
      switch (type) {
        case "personalItem":
          return i18n.trans(t("flights_sr_baggage_personal_item"));
        case "cabin":
          return i18n.trans(t("flights_sr_baggage_cabin_bag"));
        case "checkedIn":
          return i18n.trans(t("flights_sr_baggage_checked_bag"));
        default:
          return "-";
      }
    },
    [i18n]
  );

  return { status, name };
}
