let serviceDevOverrideUrls = {};
try {
  serviceDevOverrideUrls = require("./service_dependences.json");
} catch (error) {
  console.error("Error getting service urls:", error); // eslint-disable-line
}
export const SERVICE_DEV_OVERRIDE_URLS = serviceDevOverrideUrls;

export const FE_DEPENDECIES_SERVICES = Object.keys(serviceDevOverrideUrls);

export const getUrlsForService = (serviceName: string): string[] => {
  return Object.values(SERVICE_DEV_OVERRIDE_URLS[serviceName] || {});
};
