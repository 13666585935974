/**
 * Standard separator for list parameter values
 */
export const PARAM_LIST_SEPARATOR = ",";

/**
 * Separator for multistop IATA codes
 */
export const MULTISTOP_IATA_LIST_SEPARATOR = "|";

/**
 * Separates IATA code and type of a location in "from"/"to"-like string param values.
 * E.g. `MAD.AIRPORT`, `MAD.CITY` (yes, there're many IATA codes used for both city and its main airport), `JFK.AIRPORT`
 */
export const LOCATION_IATA_CODE_AND_TYPE_SEPARATOR = ".";

/**
 * Separator for multiple IATA codes in the URL only
 */
export const URL_IATA_LIST_SEPARATOR = "_";
