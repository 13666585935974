import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { SheetContainer } from "@bookingcom/bui-react";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";

import useUserAgent from "../../../hooks/useUserAgent";
import useGlobalContext from "../../../hooks/useGlobalContext";
import useRouteName from "../../../hooks/useRouteName";
import localstory from "localstory";
import { LANDING_PAGE_NAME_KEY } from "../../../hooks/useTrackPageLoad";
import styles from "./FeedbackModal.module.css";
import { getUserInfo } from "store/user/selectors";
import { getOrderId } from "store/order/selectors";
import { mcn } from "utils/mergeClassnames";

interface Props {
  active?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const FeedbackModal: React.FC<Props> = ({ active, onOpen, onClose }) => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n() as I18nChildContext;
  const { locale = "en-gb", userAgent } = useGlobalContext();
  const routeName = useRouteName();
  const userInfo = useSelector(getUserInfo);
  const orderId = useSelector(getOrderId);
  const localStore = localstory(window.sessionStorage, "flights");

  const iframeUrl = useMemo(() => {
    const urlBase = "https://surveys.booking.com/s3/";
    const urlPath = `BR-FlightsFeedback-${locale.toUpperCase()}`;

    const urlQuery = new URLSearchParams({
      pageName: routeName,
      deviceType: isMobile ? "mobile" : "desktop",
      browser: userAgent.browser || "",
      channel: localStore.get(LANDING_PAGE_NAME_KEY) || "",
      lang: locale.toUpperCase()
    });

    if (userInfo) {
      urlQuery.set("isLoggedIn", "1");
    }

    if (orderId) {
      urlQuery.set("orderNumber", orderId);
    }

    return `${urlBase}${urlPath}?${urlQuery.toString()}`;
  }, [isMobile, locale, routeName, orderId, userInfo, userAgent.browser, localStore]);

  return (
    <SheetContainer
      active={active}
      onOpen={onOpen}
      onCloseTrigger={onClose}
      closeAriaLabel={i18n.trans(t("close"))}
      position={isMobile ? "fullScreen" : "center"}
      className={mcn(styles.root, !isMobile && styles.rootDesktop)}
    >
      <div>
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
        <iframe
          title="Feedback"
          src={iframeUrl}
          scrolling="yes"
          tabIndex={0}
          className={mcn(styles.iframe, isMobile ? styles.iframeMobile : styles.iframeDesktop)}
        />
        {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
      </div>
    </SheetContainer>
  );
};
export default FeedbackModal;
