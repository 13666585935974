import React, { PropsWithChildren } from "react";
import GlobalContext, { ContextProps } from "./GlobalContext";
import { useLocation } from "react-router-dom";

const GlobalProvider = (props: PropsWithChildren<ContextProps>) => {
  const { children, ...otherProps } = props;
  const { search } = useLocation();
  const showCopyTags = search?.includes("show_tags=1") && props.isInternal;
  return <GlobalContext.Provider value={{ ...otherProps, showCopyTags }}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
