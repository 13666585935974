import { useCallback, useMemo } from "react";
import {
  getTravellersList,
  getBaggagesList,
  getExtraBaggagesList,
  getTravellersTypes,
  getStatus,
  getDetailedStatus,
  BaggagesDetailsBySegment
} from "./utils";
import { UIFlightData } from "@flights/types/client";
import { useSelector } from "react-redux";
import { getExtraProducts } from "store/extraProducts/selectors";
import useRouteName from "hooks/useRouteName";

export default function useBaggages(flight?: UIFlightData) {
  const routeName = useRouteName();

  const {
    travellers = [],
    travellerPrices = [],
    ancillaries = {},
    includedProductsBySegment = [],
    segments = []
  } = flight || {};

  /**
      This is just a quick fix for `flights_web_funnel_baggage_v2_checkout_extras` exp,
      if it goes full on `extraProducts` should come as a parameter (consistently with `flight` param).

      The issue is we must use `store.extraProducts` (instead of `flight.ancillaries`) on checkout steps.
      In Branded Fares scenario `flight.ancillaries` always contains ancillaries of a base fare, while non-base fare
      could be selected by user => `store.extraProducts` is the source of ancillaries truth during checkout.
   */
  const extraProducts = useSelector(getExtraProducts);

  const travellersList = useMemo(() => {
    return getTravellersList(travellers.length ? travellers : travellerPrices.length ? travellerPrices : []);
  }, [travellers, travellerPrices]);

  const travellerTypes = useMemo(() => {
    return getTravellersTypes(travellersList);
  }, [travellersList]);

  const baggagesList = useMemo(() => {
    return getBaggagesList({ segments, travellersList, includedProductsBySegment });
  }, [segments, travellersList, includedProductsBySegment]);

  const extraBaggagesList = useMemo(() => {
    const ancillariesToUse = ["checkout2", "checkout3"].includes(routeName) ? extraProducts : ancillaries;

    return getExtraBaggagesList({
      ancillaries: ancillariesToUse || {},
      travellersList
    });
  }, [routeName, extraProducts, ancillaries, travellersList]);

  const getBaggagesStatus = useCallback(() => {
    return getStatus({ segments, travellerTypes, baggagesList, extraBaggagesList });
  }, [segments, travellerTypes, baggagesList, extraBaggagesList]);

  const getBaggageDetails = useCallback(() => {
    return getDetailedStatus({ segments, travellerTypes, baggagesList, extraBaggagesList });
  }, [segments, travellerTypes, baggagesList, extraBaggagesList]);

  const getBaggageDetailsByTravellerReference = useCallback(
    (ref: string): BaggagesDetailsBySegment | undefined => {
      const traveller = travellersList.find((_) => _.travellerReference === ref);
      if (!traveller) return undefined;
      const baggageDetails = getDetailedStatus({ segments, travellerTypes, baggagesList, extraBaggagesList });
      return baggageDetails[traveller.travellerType];
    },
    [segments, travellersList, travellerTypes, baggagesList, extraBaggagesList]
  );

  return { getBaggagesStatus, getBaggageDetails, getBaggageDetailsByTravellerReference };
}
