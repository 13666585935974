import { useI18n } from "@bookingcom/lingojs-react";
import { UIFlightSegment, UILeg } from "@flights/types/client";
import { t } from "@bookingcom/lingojs-core";

export const isVirtualInterliningFlight = (segments: UIFlightSegment[] | UIFlightSegment) => {
  return Array.isArray(segments)
    ? segments.some((segment) => segment.isVirtualInterlining)
    : segments.isVirtualInterlining;
};

export const isVirtualInterliningProtectedFlight = (segments: UIFlightSegment[]) => {
  return segments.some((segment) => segment.legs.some((leg) => leg.vi?.protected));
};

export const isChangeOfAirportSegment = (segment: UIFlightSegment): boolean => {
  return segment.legs.some((leg) => leg.vi?.airportChange);
};

export default function useVirutalInterlining() {
  const i18n = useI18n();

  const getBaggageCopyForVI = (segments: UIFlightSegment[]) => {
    const cityNamesToCollectAndReCheckBaggage: string[] = [];
    segments.forEach((segment: UIFlightSegment) => {
      if (segment.isVirtualInterlining) {
        segment.legs.forEach((leg: UILeg) => {
          leg.vi?.protected &&
            cityNamesToCollectAndReCheckBaggage.push(leg.arrivalAirport.cityName || leg.arrivalAirport.city);
        });
      }
    });

    const uniqueCityNames = Array.from(new Set(cityNamesToCollectAndReCheckBaggage));

    switch (uniqueCityNames.length) {
      case 0:
        return "";
      case 1:
        return i18n.trans(
          t("flights_details_baggage_collection_details", {
            variables: {
              city_name: uniqueCityNames[0]
            }
          })
        );
      case 2:
        return i18n.trans(
          t("flights_details_baggage_collection_details_2", {
            variables: {
              city_name: uniqueCityNames[0],
              city_name2: uniqueCityNames[1]
            }
          })
        );
      case 3:
        return i18n.trans(
          t("flights_details_baggage_collection_details_3", {
            variables: {
              city_name: uniqueCityNames[0],
              city_name2: uniqueCityNames[1],
              city_name3: uniqueCityNames[2]
            }
          })
        );
      case 4:
        return i18n.trans(
          t("flights_details_baggage_collection_details_4", {
            variables: {
              city_name: uniqueCityNames[0],
              city_name2: uniqueCityNames[1],
              city_name3: uniqueCityNames[2],
              city_name4: uniqueCityNames[3]
            }
          })
        );
      default:
        return i18n.trans(
          t("flights_details_baggage_collection_details", {
            variables: {
              city_name: uniqueCityNames.join(", ")
            }
          })
        );
    }
  };

  return {
    isVirtualInterliningFlight,
    isChangeOfAirportSegment,
    getBaggageCopyForVI,
    isVirtualInterliningProtectedFlight
  };
}
