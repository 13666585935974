import { Stack, Text, Title } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useFormatPrice from "hooks/useFormatPrice";
import React from "react";
import {
  UIOrder,
  UIPrice,
  UIRebookPrice,
  UIRebookPriceAirlinePenalty,
  UITripRebookOption
} from "@flights/types/client";
import { isRebookPriceWithAirlineFees } from "utils/rebook/isRebookPriceWithAirlineFees";
import { useTicketPriceCopy } from "utils/useTicketPriceCopy";

type RebookBreakdownListProps = {
  tripRebookOption: UITripRebookOption;
  initialOrder: UIOrder;
  totalDifferenceForAllPassengers: string;
};

const isRebookPriceWithAirlinePenalty = (price: UIRebookPrice): price is UIRebookPriceAirlinePenalty => {
  return (price as UIRebookPriceAirlinePenalty).airlinePenalty !== undefined;
};

export const RebookBreakdownList = ({
  tripRebookOption,
  initialOrder,
  totalDifferenceForAllPassengers
}: RebookBreakdownListProps) => {
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  const rebookPrice = tripRebookOption.rebookPrice;
  const { totalDifference } = rebookPrice;
  const passengers = initialOrder.passengers;
  const ticketPriceCopy = useTicketPriceCopy(i18n, passengers, true);

  const rebookBreakdownItem = (costItemCopy: string, cost: UIPrice) => (
    <Stack justifyContent="space-between" direction="row">
      <Text variant="body_2" color="neutral_alt">
        {costItemCopy}
      </Text>
      <Text variant="body_2" color="neutral_alt">
        {formatPrice(cost)}
      </Text>
    </Stack>
  );

  return (
    <>
      {passengers && (
        <Stack justifyContent="space-between" direction="row">
          <Text variant="strong_2">{ticketPriceCopy}</Text>
          <Text variant="strong_2">{formatPrice(totalDifference)}</Text>
        </Stack>
      )}
      <Stack gap={4}>
        <Stack gap={0}>
          {isRebookPriceWithAirlinePenalty(rebookPrice) && (
            <>
              {rebookPrice.airlinePenalty &&
                rebookBreakdownItem(
                  i18n.trans(t("flights_pb_rebook_cost_breakdown_airline_penalty")),
                  rebookPrice.airlinePenalty
                )}
              {rebookPrice.fareDifference &&
                rebookBreakdownItem(
                  i18n.trans(t("flights_pb_rebook_cost_breakdown_fare_difference")),
                  rebookPrice.fareDifference
                )}
              {rebookPrice.taxDifference &&
                rebookBreakdownItem(
                  i18n.trans(t("flights_pb_rebook_cost_breakdown_tax_difference")),
                  rebookPrice.taxDifference
                )}
            </>
          )}
        </Stack>
        {isRebookPriceWithAirlineFees(rebookPrice) &&
          rebookPrice.airlineFees &&
          rebookBreakdownItem(i18n.trans(t("flights_pb_rebook_cost_breakdown_airline_fee")), rebookPrice.airlineFees)}
        <Stack gap={0}>
          <Stack justifyContent="space-between" direction="row">
            <Title
              title={i18n.trans(t("flights_pb_rebook_cost_breakdown_total"))}
              variant="headline_3"
              titleTagName="h4"
            />
            <Text variant="headline_3">{totalDifferenceForAllPassengers}</Text>
          </Stack>
          <Text color="neutral_alt" variant="body_2">
            {i18n.trans(t("flights_pb_rebook_cost_breakdown_taxes_inc"))}
          </Text>
        </Stack>
      </Stack>
    </>
  );
};
