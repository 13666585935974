/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import WorldwideFlightsItem from "./WorldwideFlightsItem";
import { Grid, GridColumn, Tab, Title, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { COMPANY_NAME } from "../../../constants";
import { UIWorldwideFlightInfo } from "@flights/types/client";
import Container from "../Container";
import { Panel, Trigger } from "@bookingcom/bui-react/components/Tab/Tab.types";
import trackPriorityRoutesExp, {
  trackBasicPriorityRoutesStages
} from "utils/experiments/meta/flights_web_remote_component_seo_interlinking";
import { InViewportTrackingWrapper } from "../InViewportWrapper";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import styles from "./WorldwideFlights.desktop.module.css";
import { getWorldwideFlights } from "store/worldwideFlights/selector";

const WorldwideFlights: React.FunctionComponent = () => {
  const theme = useTheme();
  const i18n = useI18n();
  const worldwideFlights = useSelector(getWorldwideFlights);
  const scrollRef = useRef<HTMLDivElement>(null);
  const flightsMap = useMemo(() => {
    const flightsMap = new Map<string, UIWorldwideFlightInfo[]>();

    worldwideFlights?.forEach((flight) => {
      flightsMap.set(flight.continentId, flightsMap.get(flight.continentId) || []);
      flightsMap.get(flight.continentId)?.push(flight);
    });

    return flightsMap;
  }, [worldwideFlights]);

  const continents = useMemo(() => {
    const continentIds: string[] = [];
    flightsMap.forEach((_, continentId: string) => {
      continentIds.push(continentId);
    });
    return continentIds;
  }, [flightsMap]);

  const continentNameTranslations: string[][] = continents.map((continentId) => {
    // This tricks the lingo static analyser to fetch translations for all available continents.
    return [continentId, i18n.trans(t("continent", continentId, "name"))];
  });

  const triggerList: Trigger[] = [];
  const panelList: Panel[] = [];

  continentNameTranslations.map((translation: string[]) => {
    triggerList.push({
      text: translation[1],
      id: translation[0]
    });
    panelList.push({
      id: translation[0],
      children: (
        <Grid className={styles.grid}>
          {flightsMap.get(translation[0])?.map((flightInfo: UIWorldwideFlightInfo, index: number) => (
            <GridColumn key={index} className={styles.activeGrid} size={4}>
              <WorldwideFlightsItem flight={flightInfo} />
            </GridColumn>
          ))}
        </Grid>
      )
    });
  });

  useEffect(() => {
    trackBasicPriorityRoutesStages(false);
  }, []);

  useTrackLandingPageViewport(scrollRef, "worldwide_flights");

  return continents.length > 0 ? (
    <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }} innerRef={scrollRef}>
      <InViewportTrackingWrapper onEnterViewport={trackPriorityRoutesExp.stages.desktop_in_viewport}>
        <Title
          className={styles.title}
          title={i18n.trans(
            t("seo_flights_new_index_lp_linking_block_header", { variables: { b_companyname: COMPANY_NAME } })
          )}
          subtitle={i18n.trans(t("seo_flights_new_index_lp_linking_block_subheader"))}
          variant="headline_2"
          titleTagName="h2"
        />
        <Tab defaultActiveTabId={triggerList[0].id}>
          <Tab.TriggerList>
            {triggerList.map((trigger) => (
              <Tab.Trigger key={trigger.id} {...trigger} />
            ))}
          </Tab.TriggerList>
          {panelList.map((panel) => (
            <Tab.Panel key={panel.id} id={panel.id}>
              <div className={styles.tabPanelContent}>{panel.children}</div>
            </Tab.Panel>
          ))}
        </Tab>
      </InViewportTrackingWrapper>
    </Container>
  ) : null;
};
export default WorldwideFlights;
