/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useState } from "react";
import AncillaryItem from "../CheckoutPaymentContentSummary/components/AncillaryItem";
import { Link, Text } from "@bookingcom/bui-react";
import { ATOLLogo } from "./ATOLLogo";
import ATOLModal from "./ATOLModal";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useATOL from "hooks/useATOL";
import { UIFlightSegment } from "@flights/types/client";

type ATOLProtectionProps = {
  segments: UIFlightSegment[];
  hideCTA?: boolean;
};

const ATOLProtection = (props: ATOLProtectionProps) => {
  const { hideCTA, segments } = props;
  const i18n = useI18n() as I18nChildContext;
  const { isATolProtectedCheckOut } = useATOL();
  const [active, setActive] = useState(false);
  const isAtol = isATolProtectedCheckOut();

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setActive(true);
    },
    [setActive]
  );

  const onClose = useCallback(() => {
    setActive(false);
  }, [setActive]);

  if (!isAtol) return null;

  return (
    <>
      <AncillaryItem
        icon={<ATOLLogo />}
        title={i18n.trans(t("flights_atol_details_header"))}
        subtitle={
          (
            <>
              <Text variant="body_2">{i18n.trans(t("flights_atol_pb_subheader"))}</Text>
              <Link onClick={onClick} href="#">
                {i18n.trans(t("flights_view_details_action"))}
              </Link>
            </>
          ) as unknown as string
        }
      />
      <ATOLModal hideCTA={!!hideCTA} segments={segments} active={active} onClose={onClose} />
    </>
  );
};

export default ATOLProtection;
