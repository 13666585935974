import { useSelector } from "react-redux";
import { UIPointOfSale } from "@flights/types/client";
import { getMarketingTrackingVariable } from "utils/marketing-url-params";
import useGlobalContext from "./useGlobalContext";
import useTrackFlightsWebUseOfferPos from "./useTrackFlightsWebUseOfferPosExp";
import { getFlightPointOfSale, getHasFlight } from "store/flightDetails/selectors";

/**
 * Checks if user is buying from respective country
 * =============================
 * how this work:
 * This hook checks if the user is buying from a specific country.
 * In some cases we need to show conditional code based on
 * where the user is buying from, for instance: We need to
 * show CPF field for users buying tickets from Brazil.
 */

const useIsSaleCountry = (country: UIPointOfSale) => {
  const { ipCountry } = useGlobalContext();
  const flightPointOfSale = useSelector(getFlightPointOfSale);
  const hasFlight = useSelector(getHasFlight);
  const flightsWebUseOfferPosExp = useTrackFlightsWebUseOfferPos();

  const metaSalesCountry = flightsWebUseOfferPosExp.isVariant && getMarketingTrackingVariable("salesCountry");

  return hasFlight
    ? flightPointOfSale === country
    : metaSalesCountry
    ? metaSalesCountry.toLowerCase() === country
    : ipCountry == country;
};

export default useIsSaleCountry;
