import { UICampaignBanner, UIGeniusCampaignBanner } from "@flights/types/client";

export type State = {
  discountBanner: UICampaignBanner | null; // @TODO: move indexBannerCampaign here from global context
  geniusAirlines: UIGeniusCampaignBanner | null;
};

// eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
export const getInitialState = (): State => ({
  discountBanner: null,
  geniusAirlines: null
});
