import { UISearchResults } from "@flights/types/client";
import clientFetch from "utils/client-fetch";
import useContextName from "./useContextName";
import { useSearchAPIParams } from "./useSearchAPIParams";
import { useEffect, useRef } from "react";
import { getFlightOfferString } from "utils/flight-offers";
import intersection from "lodash/intersection";
import { useTrackRefreshModalStages } from "utils/experiments/funnel/flight_web_refresh_modal_background_v2";
const debug = require("debug")("RefreshResults");

export function fetchFlights(contextName: string, url: string): Promise<UISearchResults> {
  return clientFetch<UISearchResults>(url, {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Flights-Context-Name": contextName
    }
  });
}

export default function useFlightSearchResultRefreshPopup(
  isEnhanceRefreshEnabled: boolean,
  frequency: number,
  flightOfferIds: string[],
  callback: (shouldShowSearchPopup: boolean) => void
): ReturnType<typeof setInterval> | null {
  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  const refreshTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const flightOffersRef = useRef<string[]>([]);
  flightOffersRef.current = flightOfferIds;
  const contextName = useContextName();
  const url = `/api/flights/?${useSearchAPIParams()}`;
  const trackStages = useTrackRefreshModalStages();

  const clearTimer = () => {
    debug("Timer cleared !");
    refreshTimer.current && clearInterval(refreshTimer.current);
  };

  useEffect(() => {
    debug(`Setting up the timer now.  Timer will get triggered in ${frequency / (1000 * 60)} minutes`);
    refreshTimer.current = setInterval(() => {
      debug("Timer triggered");
      const currentOffersIds = flightOffersRef.current;
      getShowSearchPopupCondition(isEnhanceRefreshEnabled, currentOffersIds, contextName, url)
        .then((result: boolean) => {
          result && clearTimer();
          callback(result);
        })
        .catch(() => {});

      trackStages();
    }, frequency);

    return () => {
      clearTimer();
    };
    // All other deps (url,frequency ...) are pretty much unnecessary for this timer.
    // The ES Lint check can be troublesome while passing Function and Array (useCallback,Stringify needs to be applied)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return refreshTimer.current;
}

export async function getShowSearchPopupCondition(
  isEnhanceRefreshEnabled: boolean,
  currentFlightOfferIds: string[],
  contextName: string,
  url: string
) {
  // If the flight_web_refresh_modal_background_v2 experiment is disabled, show the original experience of regular refresh
  if (!isEnhanceRefreshEnabled) {
    return true;
  }

  const newFlightOfferData = await fetchFlights(contextName, url);

  const newFlightOfferIds = newFlightOfferData.flightOffers.map((offer) => getFlightOfferString(offer));

  const shouldRefreshResults =
    newFlightOfferIds.length !== currentFlightOfferIds.length ||
    intersection(currentFlightOfferIds, newFlightOfferIds).length !== currentFlightOfferIds.length;

  return shouldRefreshResults;
}
