import React, { useCallback, useEffect, useMemo, FC, useRef, PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SearchRemoveIcon } from "@bookingcom/bui-assets-react/streamline";
import { Button, HiddenVisually, Icon, List, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { UIFlightData, UISearchResultsBanner } from "@flights/types/client";
import { WithTestID } from "@flights/types/testing";
import useCreateUrl from "../../../hooks/useCreateUrl";
import useSearchFilters from "../../../hooks/useSearchFilters";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "../../../utils/et";
import { useSelectorAppliedFilters } from "../../../store/selectors/appliedFilters";
import { getExpandedSearchAirports } from "../../../store/selectors/expandedSearch";
import { isSirfAvailable } from "../../../store/selectors/sirf";
import { useStore } from "../../../store";
import { Sidebar } from "../Container";
import FlightCard from "../FlightCard/FlightCard";
import SearchBaggagePolicies from "../SearchBaggagePolicies";
import SearchFilters from "../SearchFilters";
import SearchFiltersSummary from "../SearchFiltersSummary";
import SearchPagination from "../SearchPagination";
import SearchTabs from "../SearchTabs";
import SirfInfoBanner from "../SirfInfoBanner";
import SkeletonSearchDesktop, { SkeletonSearchContent } from "../Skeleton/SkeletonPages/SkeletonSearch.desktop";
import { FILTERS, DESKTOP_SIDEBAR_WIDTH } from "../../../constants";

import useClientCachedURL from "hooks/useClientCachedURL";
import useTrackExpRankerV3 from "hooks/useTrackExpRankerV3";
import useTrackExpRankerNN from "hooks/useTrackExpRankerNN";
import { DestinationUkraineWarning } from "../TravelAlert";
import NoDirectFlightsBanner from "./components/NoDirectFlightsBanner";
import { NoResultsFound } from "./components/NoResultsFound";
import RefreshResults from "./components/RefreshResults";
import { RemoveDirectFlights } from "./components/RemoveDirectFlights";
import { TooManyFilters } from "./components/TooManyFilters";
import { XSell } from "cross-sell";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import FlightCardToggle from "../FlightCard/FlightCardToggle";
import DSABanner from "../DSABanner/DSABanner";
import { getLocationParams } from "./utils";
import SortDropDown from "../SearchFilters/Filters/SortDropDown";
import useFlexTrackPriceDiff from "hooks/useFlexTrackPriceDiff";
import GeniusTCBanner from "cross-sell/components/GeniusTCBanner";
import { isFeatureRunningClientSide } from "utils/features";
import Frame from "../Frame";
import { FlightCampaignTCBanner } from "../FlightCampaignTCBanner";
import { flightsSessionStore } from "../../../utils/session-store";
import { trackIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_banner_campaign_index";
import { FlexibleDateBestPriceDesktop } from "../FlexibleDateBestPrice";
import flights_web_aa_fd_not_available_error from "utils/experiments/funnel/flights_web_aa_fd_not_available_error";
import styles from "./SearchResults.desktop.module.css";
import { useFlexDatesRectrictedPOS } from "../FlexibleDateBestPrice/useFlexDatesRectrictedPOS";
import { trackRoundPriceStageDesktop } from "utils/experiments/funnel/flights_web_roundprice_sr_fd_direct_desktop";
import { trackSortingToSide } from "utils/experiments/funnel/flights_web_ddot_side_sorting_2";
import { trackStopsFilterCheckbox } from "utils/experiments/funnel/flights_web_stops_filter_checkbox";
import useProfiler from "hooks/useProfiler";
import SearchResultBanner from "../SearchResultBanner/SearchResultBanner";
import trackHumanRightBanner, { useHumanRightBanner } from "utils/experiments/funnel/flights_web_human_right_banner";
import flights_web_ddot_last_available_seats_v3, {
  useTrackAvailableSeatsDesktop
} from "utils/experiments/funnel/flights_web_ddot_last_available_seats_v3";
import { RemoteAppleCardSR } from "components/remote";
import { useSwapPriceBaggage } from "utils/experiments/funnel/flights_web_swap_price_baggage_sr_desktop";
import { useTrackTimeFilterStages } from "utils/experiments/funnel/flights_web_flight_time_filter";
import { useTrackSwapPriceBaggageDesktop } from "utils/experiments/funnel/flights_web_swap_price_baggage_sr_desktop_2";
import NoSSR from "../../../app/NoSSR";
import flights_apex_web_ancillaries_micro_conversion_aa from "../../../utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import { useTrackDirectToNonstopUs } from "utils/experiments/funnel/flights_web_direct_to_nonstop_en_us";
import useRouteName from "hooks/useRouteName";
import { useTrackCtaActionReinforceDesktop } from "utils/experiments/funnel/flights_web_cta_action_reinforce_sr_desktop_v2";
import { useSelectDeselectDesktop } from "utils/experiments/funnel/flights_web_airlines_filter_select_deselect_desktop";
import flights_apex_web_expand_branded_fares_on_search from "utils/experiments/apex/flights_apex_web_expand_branded_fares_on_search";
import { useItineraryRedesignDesktop } from "utils/experiments/funnel/flights_web_sr_card_itinerary_redesign_desktop";
import useOfferCartOrderData from "hooks/useOfferCartOrderData";
import {
  useTrackClickOnFDVIBlackout,
  useTrackVIBlackoutStages
} from "utils/experiments/funnel/flights_web_blackout_vi_alert_sr";

type SearchResultsListProps = {
  flights: UIFlightData[];
  onSelectFlight: (flight: UIFlightData) => void;
  hasAppliedFilters?: boolean;
};

const _SearchResultsList = ({ flights, onSelectFlight, hasAppliedFilters }: SearchResultsListProps) => {
  const i18n = useI18n();
  const store = useStore();
  const {
    search,
    searchCriteria,
    searchResults: { campaignBanner, fetchStatus, availableFilters, flightDeals }
  } = store;
  const isInboundOutboundActive = trackExperiment("flights_web_inbound_outbound_sr") && search.type === "ROUNDTRIP";
  const appliedFilters = useSelectorAppliedFilters(store);
  const { indexPageCampaign } = useGlobalContext();
  const isRestricedFlexDatePOS = useFlexDatesRectrictedPOS();
  const { isLoggedOut } = useProfiler();
  const routeName = useRouteName();
  const params = new URLSearchParams(location.search);
  const currentSearchResultsPageNumber = params.get("page");

  //There isn't a page param for the first search
  const isFirstSearchResultsPage = currentSearchResultsPageNumber ? Number(currentSearchResultsPageNumber) === 1 : true;
  useTrackDirectToNonstopUs(routeName);
  useTrackCtaActionReinforceDesktop(flights);
  useSelectDeselectDesktop(fetchStatus, availableFilters.airlines?.length);
  useItineraryRedesignDesktop();

  /* start - flights_apex_web_expand_branded_fares_on_search */
  const falsePositiveBrandedFares = useRef(0);

  useEffect(() => {
    if (falsePositiveBrandedFares.current > 0) falsePositiveBrandedFares.current = 0;
  }, [flights]); // eslint-disable-line react-hooks/exhaustive-deps

  const addFalsePositiveBrandedFare = useCallback(() => {
    falsePositiveBrandedFares.current += 1;
    if (falsePositiveBrandedFares.current === 3)
      flights_apex_web_expand_branded_fares_on_search.stages.false_positives_gte_3();
  }, []);
  /* end - flights_apex_web_expand_branded_fares_on_search */

  useEffect(() => {
    flights_apex_web_ancillaries_micro_conversion_aa.trackSearchResultsStages(flights);
    flights_apex_web_expand_branded_fares_on_search.trackStages(flights, search.type);
    trackExperimentStage("flights_web_inbound_outbound_sr", 1);
  }, [flights]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search.type === "ONEWAY") {
      trackExperimentStage("flights_web_ddot_condensed_one_way_search_results", 1);
      trackExperimentStage("flights_web_ddot_condensed_one_way_search_results", isOfMetaOrigin() ? 3 : 2);
    }

    if (search.type === "ROUNDTRIP") {
      trackExperimentStage("flights_web_inbound_outbound_sr", 2);
      trackExperimentStage("flights_web_inbound_outbound_sr", 3);
      trackExperimentStage("flights_web_inbound_outbound_sr", isOfMetaOrigin() ? 5 : 6);
    }
  }, [search.type]);

  useEffect(() => {
    if (appliedFilters.length > 0) {
      trackExperimentStage("flights_web_inbound_outbound_sr", 7);
    }
  }, [appliedFilters.length]);

  const { adults, children } = searchCriteria;
  const travellersCount = adults + children.length;
  const availableSeatsFlightIndex =
    search.page === 1
      ? flights.findIndex((f) => (f?.seatAvailability?.numberOfSeatsAvailable || 0) >= travellersCount)
      : -1;

  useTrackAvailableSeatsDesktop({
    canRenderLastAvailableSeats: availableSeatsFlightIndex >= 0,
    travellersCount,
    availableSeatsFlightIndex: 4
  });

  useEffect(() => {
    const { adults, children } = searchCriteria;
    const travellersCount = adults + children.length;

    trackExperimentStage("flights_web_sr_full_luggage_data", 1);
    trackExperimentStage("flights_web_sr_full_luggage_data", isOfMetaOrigin() ? 3 : 2);
    trackExperimentStage("flights_web_sr_full_luggage_data", 4);
    trackExperimentStage("flights_web_sr_full_luggage_data", travellersCount > 1 ? 7 : 6);

    trackExperimentStage("flights_web_round_price_search_results_desktop", 1);
    trackExperimentStage("flights_web_round_price_search_results_desktop", isOfMetaOrigin() ? 3 : 2);
    trackExperimentStage("flights_web_round_price_search_results_desktop", 4);
    trackExperimentStage("flights_web_round_price_search_results_desktop", travellersCount > 1 ? 6 : 5);

    /* stage only for direct user*/
    trackRoundPriceStageDesktop({ isDirect: !isOfMetaOrigin(), travellersCount: travellersCount });

    flights_web_aa_fd_not_available_error.stages.search_results();

    trackStopsFilterCheckbox({ travellersCount, hasChildren: !!children.length, isMdot: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackExperimentStage("flight_web_sr_traffic_aa_desktop", 1);
    trackExperimentStage("flight_web_sr_traffic_aa_desktop", isOfMetaOrigin() ? 6 : 5);

    if (hasAppliedFilters) {
      trackExperimentStage("flight_web_sr_traffic_aa_desktop", 2);
    }
    if (store.user.userInfo && flightsSessionStore.get("genius_banner_seen")) {
      trackCustomGoal("flight_web_sr_traffic_aa_desktop", 5);
    }
  }, [hasAppliedFilters, store.user.userInfo]);

  useFlexTrackPriceDiff({
    diffPercentageTag: "flights_web_flexible_date_search_desktop_v2_sr_diff_percentage",
    diffHigherTag: "flights_web_flexible_date_search_desktop_v2_sr_diff_higher_count",
    diffLowerTag: "flights_web_flexible_date_search_desktop_v2_sr_diff_lower_count"
  });

  useEffect(() => {
    trackExperimentStage("flights_web_aa_partially_included_baggage_tracking", 1);
    trackExperimentStage("flights_web_aa_partially_included_baggage_tracking", isOfMetaOrigin() ? 6 : 2);
  }, []);

  useSwapPriceBaggage(search.type, searchCriteria.adults + searchCriteria.children.length);
  const FlightCardComponent = isInboundOutboundActive ? FlightCardToggle : FlightCard;

  const screenReaderText = () => {
    if (hasAppliedFilters && flights) {
      return i18n.trans(t("a11y_flights_sr_results_with_filter"));
    }

    if (!hasAppliedFilters && flights) {
      return i18n.trans(t("a11y_flights_sr_results_loaded"));
    }

    return "";
  };

  useEffect(() => {
    // Bucket of users who were exposed both to index and SR seasonal deal campaigns
    if (
      isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
      campaignBanner &&
      indexPageCampaign
    ) {
      trackIndexCampaign.goals.exposed_to_sr_campaign();
    }
  }, [campaignBanner, indexPageCampaign]);

  useTrackTimeFilterStages(appliedFilters.length);

  useTrackSwapPriceBaggageDesktop();

  useTrackVIBlackoutStages(flights, flightDeals?.find((deal) => deal.key === "CHEAPEST")?.offerToken || "");

  const shouldShowFlexDates =
    flights.length >= 5 &&
    store.search.cabinClass === "ECONOMY" &&
    isFirstSearchResultsPage &&
    !isRestricedFlexDatePOS &&
    !isOfMetaOrigin() &&
    trackExperiment("flights_web_flexible_date_search_desktop_v2") === 2;

  return (
    <div>
      <HiddenVisually>
        <div aria-live="polite">{screenReaderText()}</div>
      </HiddenVisually>
      <List attributes={{ style: { padding: 0 } }}>
        {flights.map((flight: UIFlightData, index: number) => {
          return (
            <div key={`${index}_${flight.token}`}>
              {isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              campaignBanner &&
              index === campaignBanner.position ? (
                <Frame mt={2} mb={4}>
                  <FlightCampaignTCBanner campaignBanner={campaignBanner} />
                </Frame>
              ) : null}
              <div id={`flightcard-${index}`} data-testid="searchresults_card">
                <FlightCardComponent
                  flight={flight}
                  onClick={onSelectFlight}
                  pricePerPerson
                  index={index}
                  searchType={search.type}
                  /* start - flights_apex_web_expand_branded_fares_on_search */
                  addFalsePositiveBrandedFare={addFalsePositiveBrandedFare}
                  /* end - flights_apex_web_expand_branded_fares_on_search */
                />
              </div>
              <Frame mb={4}>
                <FlexibleDateBestPriceDesktop shouldRender={shouldShowFlexDates && index === 0} />
              </Frame>
              {!campaignBanner && index === 1 ? (
                <>
                  {isLoggedOut() ? null : (
                    <Frame mt={2} mb={4}>
                      <XSell component="GeniusBanner" />
                      <SearchTrafficAAGeniusTrackerDesktop />
                    </Frame>
                  )}
                </>
              ) : null}
              {/* If GeniusCampaign is Enabled, show the Genius TC campaign on 3rd position */}
              {!isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              index === 1 &&
              campaignBanner ? (
                <Frame mt={2} mb={4}>
                  <GeniusTCBanner campaignBanner={campaignBanner} />
                  <SearchTrafficAAGeniusTrackerDesktop />
                </Frame>
              ) : null}
              {/**
               * If the position is larger than the number of cards displayed in the SR page,
               * then the T&C banner is shown after the last card.
               * More info: https://jira.booking.com/jira/browse/FPRICING-809
               */}
              {isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              campaignBanner &&
              campaignBanner.position >= flights.length && // campaign banner position is larger than the number of cards
              index >= flights.length - 1 ? ( // after the last flight card
                <Frame mt={2} mb={4}>
                  <FlightCampaignTCBanner campaignBanner={campaignBanner} />
                </Frame>
              ) : null}
              {/* If GeniusCampaign is Enabled, show the ABU campaign on 6th position */}
              {campaignBanner && index === 4 ? (
                <>
                  {isLoggedOut() ? null : (
                    <Frame mt={2} mb={4}>
                      <XSell component="GeniusBanner" />
                      <SearchTrafficAAGeniusTrackerDesktop />
                    </Frame>
                  )}
                </>
              ) : null}

              {index === 4 ? <RemoteAppleCardSR /> : undefined}
            </div>
          );
        })}
      </List>
    </div>
  );
};

const SearchResultsList = React.memo(_SearchResultsList);

type SearchResultsWithSidebarAndTabsProps = {
  sidebar?: boolean;
  children?: React.ReactNode;
  /* start - flights_web_ddot_side_sorting_2 */
  adultCount?: number;
  childrenCount?: number;
  humanRightBanner?: UISearchResultsBanner;
  /* end - flights_web_ddot_side_sorting_2 */
} & WithTestID;

// Temp fix for carousel increasing width
const attributes = { style: { width: `calc(100% - ${DESKTOP_SIDEBAR_WIDTH}px)` } };

const SearchResultsWithSidebarAndTabs = ({
  sidebar = true,
  children,
  "data-testid": testId,
  /* start - flights_web_ddot_side_sorting_2 */
  adultCount = 0,
  childrenCount = 0,
  humanRightBanner
}: /* end - flights_web_ddot_side_sorting_2 */
SearchResultsWithSidebarAndTabsProps) => {
  const isMeta = isOfMetaOrigin();

  useEffect(() => {
    trackExperimentStage("flights_web_ddot_side_sorting", 1);
    trackExperimentStage("flights_web_ddot_side_sorting", isMeta ? 3 : 2);

    trackSortingToSide({
      travellersCount: adultCount + childrenCount,
      hasChildren: !!childrenCount
    });
  }, [adultCount, childrenCount, isMeta]);

  return (
    <div className={styles.searchResults} data-testid={testId}>
      <NoSSR>
        <Frame direction="row">
          {sidebar && (
            <Sidebar fixScrollPadding pt={4}>
              {!!trackExperiment("flights_web_ddot_side_sorting") ||
              !!trackExperiment("flights_web_ddot_side_sorting_2") ? (
                <Frame pb={8}>
                  <SortDropDown />
                </Frame>
              ) : null}
              <Frame pb={3}>
                <SearchFiltersSummary />
              </Frame>
              <SearchFilters />
            </Sidebar>
          )}
          <Frame direction="column" grow={1} pt={4} pb={4} pl={6} attributes={attributes}>
            {!!trackHumanRightBanner.variant() && <SearchResultBanner banner={humanRightBanner} />}
            <DSABanner />
            {!trackExperiment("flights_web_ddot_side_sorting") &&
            !trackExperiment("flights_web_ddot_side_sorting_2") ? (
              <SearchResultsTabsContainer>{children}</SearchResultsTabsContainer>
            ) : (
              <Frame>{children}</Frame>
            )}
          </Frame>
        </Frame>
      </NoSSR>
    </div>
  );
};

const SearchResultsTabsContainer = (props: PropsWithChildren<{}>) => (
  <SearchTabs showBestRankingInfo={true}>{props.children}</SearchTabs>
);

type FailedSearchResultsProps = {
  expandToCitySearch: () => void;
  i18n: I18nChildContext;
};

const FailedSearchResults = ({ expandToCitySearch, i18n }: FailedSearchResultsProps) => {
  const store = useStore();
  const { search } = store;
  return (
    <Frame mt={6} mb={6} attributes={{ style: { minHeight: "40vh" } }}>
      <Frame m={3} alignItems="center">
        <Icon svg={SearchRemoveIcon} size="small" className={styles.searchRemoveIcon} />
        <Text variant="headline_2">{i18n.trans(t("flights_search_no_results_header"))}</Text>
      </Frame>
      <Frame alignItems="center">
        <Text variant="body_2">{i18n.trans(t("flights_search_no_results_copy_desktop"))} </Text>
      </Frame>
      {search.type !== "MULTISTOP" && (
        <Frame alignItems="center" m={6}>
          <Button onClick={expandToCitySearch}>{i18n.trans(t("flights_search_no_results_nearbyairports_cta"))}</Button>
        </Frame>
      )}
    </Frame>
  );
};

interface SearchResultsDesktopSectionProps {
  airports: string;
  onSelectFlight: () => void;
  hasAppliedFilters?: boolean;
}

const SearchResultsDesktopSection = ({
  airports,
  onSelectFlight,
  hasAppliedFilters
}: SearchResultsDesktopSectionProps) => {
  const history = useHistory();
  const location = useLocation();
  const { createUrl } = useCreateUrl();
  const { applyFilters } = useSearchFilters();
  const store = useStore();
  const { search, searchResults } = store;
  const { flights = [], subsidizedFaresSummary } = searchResults;
  const i18n = useI18n() as I18nChildContext;
  const { cacheLastSuccessfulSearchUrl } = useClientCachedURL();
  const trackExpRankerV3 = useTrackExpRankerV3();
  const trackExpRankerNN = useTrackExpRankerNN();
  const offerCartOrderData = useOfferCartOrderData();
  const trackClickOnFDVIBlackout = useTrackClickOnFDVIBlackout();

  useEffect(() => {
    applyFilters(search.filtersDraft);
  }, [search.filtersDraft, applyFilters]);

  const showFlightDetails = useCallback(
    async (flight: any) => {
      onSelectFlight();
      trackClickOnFDVIBlackout(flight);

      const nSeatsAvailable = flight?.seatAvailability?.numberOfSeatsAvailable || 0;
      const travellersCount = flight?.travellers?.length || 1;

      if (nSeatsAvailable >= travellersCount && travellersCount <= 2) {
        flights_web_ddot_last_available_seats_v3.goals.open_fd_with_last_available_seats();
      }
      trackCustomGoal("flights_meta_land_on_cheapest_flights", 4); //select flight
      offerCartOrderData.set(flight.token, {
        offer: flight.token,
        cabinClass: searchResults.searchCriteria?.cabinClass
      });
      const url = createUrl(`/flights/${airports}/${flight.token}/?${getLocationParams(search.type, location.search)}`);
      history.push(url);
    },
    [
      onSelectFlight,
      trackClickOnFDVIBlackout,
      offerCartOrderData,
      searchResults.searchCriteria?.cabinClass,
      createUrl,
      airports,
      search.type,
      location.search,
      history
    ]
  );

  cacheLastSuccessfulSearchUrl(airports);
  trackExpRankerV3.trackStages();
  trackExpRankerNN.trackStages();
  return (
    <>
      <NoDirectFlightsBanner />
      <DestinationUkraineWarning />
      {isSirfAvailable(subsidizedFaresSummary) && (
        <SirfInfoBanner
          bannerName="search-results"
          bannerContent={i18n.trans(t("flights_sr_spanish_discount_banner"))}
        />
      )}
      <SearchBaggagePolicies />
      <SearchResultsList flights={flights} onSelectFlight={showFlightDetails} hasAppliedFilters={hasAppliedFilters} />
      <SearchPagination />
      <RefreshResults flights={flights} />
    </>
  );
};

type ComposedSearchResultsProps = SearchResultsDesktopSectionProps;

const ComposedSearchResults = ({ airports, onSelectFlight }: ComposedSearchResultsProps) => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const { createUrl } = useCreateUrl();
  const { applyFilters } = useSearchFilters();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const {
    search,
    searchResults: { flights = [], fetchStatus, error, availableFilters, banners },
    flexibleDateMinPrice: { fetchStatus: flexibleDatePriceFetchStatus },
    searchCriteria: { adults, children }
  } = store;
  const appliedFilters = useSelectorAppliedFilters(store);
  const isRestricedFlexDatePOS = useFlexDatesRectrictedPOS();
  const humanRightBanner = banners?.find((banner: UISearchResultsBanner) => banner.id === "LEGAL_NOTICE_HUMAN_RIGHTS");
  useHumanRightBanner(humanRightBanner);
  useEffect(() => {
    applyFilters(search.filtersDraft);
  }, [search.filtersDraft, applyFilters]);

  const i18n = useI18n() as I18nChildContext;

  const expandedSearchAirports = useMemo(() => {
    const { searchSegments } = search;
    /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
    /**
     * TODO: Update ExpandedSeach for multistop cases?
     */
    const { from, to } = searchSegments[0];
    return getExpandedSearchAirports(from, to);
  }, [search]);

  const expandToCitySearch = useCallback(() => {
    if (expandedSearchAirports) {
      const { fromCities, toCities, fromLocationName, toLocationName } = expandedSearchAirports;
      const params = new URLSearchParams(location.search);
      params.set("from", fromCities);
      params.set("to", toCities);
      params.set("fromLocationName", fromLocationName);
      params.set("toLocationName", toLocationName);

      history.replace(createUrl(`/flights/${fromCities}-${toCities}/?${params}`));
      window.location.reload();
    }
  }, [history, location, expandedSearchAirports, createUrl]);

  useEffect(() => {
    if (["loading", "initial"].includes(fetchStatus)) return;
    if (flights.length === 0) trackV2("show_empty_search_results");
  }, [flights, fetchStatus, trackV2]);

  const firstLoad = !availableFilters.durationMin;
  const noFlightsFound = (error && error["code"] === "SEARCH_SEARCHFLIGHTS_NO_FLIGHTS_FOUND") || flights.length === 0;
  const canExtendSearch = appliedFilters.length === 0 && noFlightsFound && expandedSearchAirports;

  const canRemoveDirectFlightsFilter = appliedFilters.includes(FILTERS.stops) && noFlightsFound;
  let resultingComp = undefined;

  const flexibleFetchConditionalStatus = useMemo(() => {
    if (
      store.search.cabinClass === "ECONOMY" &&
      !isRestricedFlexDatePOS &&
      !isOfMetaOrigin() &&
      !!trackExperiment("flights_web_flexible_date_search_desktop_v2")
    ) {
      return {
        loading: flexibleDatePriceFetchStatus === "initial" || flexibleDatePriceFetchStatus === "loading",
        success: flexibleDatePriceFetchStatus === "success"
      };
    }

    return {
      loading: false,
      success: true
    };
  }, [flexibleDatePriceFetchStatus, isRestricedFlexDatePOS, store.search.cabinClass]);

  if (fetchStatus === "initial" || fetchStatus === "loading" || flexibleFetchConditionalStatus.loading) {
    if (firstLoad) {
      // this is the result of SSR
      return <SkeletonSearchDesktop />;
    }
    // need sidebar from now on and no SSR
    resultingComp = <SkeletonSearchContent />;
  }

  if (fetchStatus === "failed" && canExtendSearch) {
    // this can also be SSR
    return <FailedSearchResults expandToCitySearch={expandToCitySearch} i18n={i18n} />;
  }

  // case of success should go here, i.e. the composed result
  if (fetchStatus === "success" && flexibleFetchConditionalStatus.success) {
    if (flights.length > 0) {
      // No SSR for this case, why not ? because api is called from client side
      resultingComp = (
        <SearchResultsDesktopSection
          airports={airports}
          onSelectFlight={onSelectFlight}
          hasAppliedFilters={appliedFilters.length > 0}
        />
      );
    } else if (canRemoveDirectFlightsFilter) {
      // can be generated from SSR
      return (
        <>
          {!!trackHumanRightBanner.variant() && <SearchResultBanner banner={humanRightBanner} />}
          <RemoveDirectFlights />
        </>
      );
    }
  }

  if (!resultingComp) {
    // this is a fallthrough default when everything else fails
    if (appliedFilters.length > 0) {
      // need sidebar here too
      resultingComp = <TooManyFilters />;
    } else {
      return <NoResultsFound />;
    }
  }

  return (
    <SearchResultsWithSidebarAndTabs
      data-testid="searchresults_list"
      /* start - flights_web_ddot_side_sorting_2 */
      adultCount={adults}
      childrenCount={children.length}
      /* end - flights_web_ddot_side_sorting_2 */
      humanRightBanner={humanRightBanner}
    >
      {resultingComp}
    </SearchResultsWithSidebarAndTabs>
  );
};

const SearchTrafficAAGeniusTrackerDesktop: FC = () => {
  const store = useStore();

  useEffect(() => {
    if (!store.user.userInfo) {
      trackExperimentStage("flight_web_sr_traffic_aa_desktop", 4);
      flightsSessionStore.set("genius_banner_seen", true);
    }
  }, [store.user.userInfo]);

  return null;
};

export default React.memo(ComposedSearchResults);
