import { createTrackingUtils } from "utils/experiments/index";

type TrackStage = {
  isDirect: boolean;
  travellersCount: number;
};

const et = "flights_web_roundprice_sr_fd_direct_desktop";
export const utils = createTrackingUtils({
  name: et,

  stages: {
    main: 1, // Direct index
    solo: 2,
    plus_2: 3
  },
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

export const trackRoundPriceStageDesktop = ({ isDirect, travellersCount }: TrackStage) => {
  if (isDirect) {
    stages.main();

    if (travellersCount === 1) {
      stages.solo();
    } else {
      stages.plus_2();
    }
  }
};
