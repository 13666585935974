import React, { FC, ReactNode } from "react";
import { Text, Title } from "@bookingcom/bui-react";
import useUserAgent from "../../../hooks/useUserAgent";
import Frame from "../Frame";
import styles from "./FlightDetailsInfoSection.module.css";
import { mcn } from "utils/mergeClassnames";

const FlightDetailsInfoSection: FC<{
  content: ReactNode;
  header?: ReactNode;
  subheader?: ReactNode;
  hideBorders?: boolean;
}> = ({ content, header, subheader, hideBorders }) => {
  const { isMobile } = useUserAgent();

  return (
    <Frame
      direction={isMobile ? "column" : "row"}
      pt={4}
      pb={4}
      className={hideBorders ? mcn(styles.root, styles.rootNoBorder) : styles.root}
    >
      {header && (
        <Title
          className={styles.headingWrapper}
          title={header}
          subtitle={
            subheader && (
              <Text className={styles.subheader} variant="body_2">
                {subheader}
              </Text>
            )
          }
          variant="strong_1"
          titleTagName="h3"
          attributes={{ "data-testid": "flight_details_info_section_title" }}
        />
      )}
      <div className={styles.contentWrapper}>{content}</div>
    </Frame>
  );
};

export default FlightDetailsInfoSection;
