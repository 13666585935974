import { createTrackingUtilsV2 } from "..";
import flights_apex_web_bf_selection_cta_ddot from "./flights_apex_web_bf_selection_cta_ddot";
import flights_apex_web_bf_selection_cta_mdot from "./flights_apex_web_bf_selection_cta_mdot";
import flights_apex_web_move_branded_fare_price_to_top from "./flights_apex_web_move_branded_fare_price_to_top";

const utils = createTrackingUtilsV2({
  name: "flights_apex_web_bf_icons_blackout",
  stages: {
    bf_page: 1,
    desktop: 2,
    mdot: 3,
    has_exclusions: 4,
    has_sellables: 5,
    in_price_to_top_exp: 6,
    in_bf_cta_ddot: 7,
    in_bf_cta_mdot: 8
  },
  goals: {
    change_to_higher_fare: 1,
    change_to_lower_fare: 2,
    select_upsell_fare: 3,
    next_visible: 4
  },
  goalsWithValue: []
});

const trackStages = (isMobile: boolean) => {
  utils.stages.bf_page();

  if (isMobile) utils.stages.mdot();
  if (!isMobile) utils.stages.desktop();

  if (!isMobile && flights_apex_web_bf_selection_cta_ddot.variant()) utils.stages.in_bf_cta_ddot();
  if (isMobile && flights_apex_web_bf_selection_cta_mdot.variant()) utils.stages.in_bf_cta_mdot();
  if (flights_apex_web_move_branded_fare_price_to_top.variant()) utils.stages.in_price_to_top_exp();
};

export default { ...utils, trackStages };
