import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UIClientFetchError, UISearchMinPriceInfo } from "@flights/types/client";

export enum ActionTypes {
  fetch = "flexibleDateMinPrice/fetch",
  fetchSuccess = "flexibleDateMinPrice/fetchSuccess",
  fetchError = "flexibleDateMinPrice/fetchError",
  reset = "flexibleDateMinPrice/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((data: UISearchMinPriceInfo[]) => ({
    type: ActionTypes.fetchSuccess,
    payload: data
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: error
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
