import { FlightsErrorReporter } from "../utils/report-error";
import { useCallback, useEffect } from "react";
import clientFetch from "../utils/client-fetch";
import useContextName from "./useContextName";
import useGlobalContext from "./useGlobalContext";

export type UIFlogEvent = {
  message: string;
  payload?: any;
};

export default function useEvents() {
  const contextName = useContextName();
  const { requestId, etStateBlob } = useGlobalContext();

  const report = useCallback((type: "grumble" | "squeak" | "warning", event: UIFlogEvent) => {
    void clientFetch(`/events/${type}`, {
      method: "post",
      body: JSON.stringify(event),
      headers: {
        "Content-Type": "application/json",
        "X-Booking-Referrer": location.href || ""
      }
    });
  }, []);

  useEffect(() => {
    FlightsErrorReporter.getInstance().resetErrorReporter(contextName, requestId, etStateBlob);
  }, [contextName, requestId, etStateBlob]);

  // Get singleton instance of error reporter
  const jsError = useCallback((error: Error) => {
    FlightsErrorReporter.getInstance().sendError(error);
  }, []);

  const grumble = useCallback(
    (message: string, payload?: any) => {
      report("grumble", { message, payload });
    },
    [report]
  );

  const squeak = useCallback(
    (message: string) => {
      report("squeak", { message });
    },
    [report]
  );

  const warning = useCallback(
    (message: string) => {
      report("warning", { message });
    },
    [report]
  );

  return { grumble, squeak, warning, jsError };
}
