import {
  UIFetchStatus,
  UIFlightData,
  UISearchResultsAggregation,
  UIClientFetchError,
  UICarrierBaggagePolicies,
  UISubsidizedFaresSummary,
  UIAtolProtectedStatus,
  UISearchResultsFlightDeal,
  UICampaignBanner,
  UISearchCabinClassExtension,
  UISearchResultsSearchCriteria,
  UISearchResultsBanner
} from "@flights/types/client";

export type State = {
  [x: string]: any;
  campaignBanner?: UICampaignBanner;
  fetchStatus: UIFetchStatus;
  flights: UIFlightData[];
  atolProtectedStatus?: UIAtolProtectedStatus;
  availableFilters: UISearchResultsAggregation;
  subsidizedFaresSummary: UISubsidizedFaresSummary;
  cabinClassExtension?: UISearchCabinClassExtension;
  baggagePolicies?: UICarrierBaggagePolicies[];
  error?: UIClientFetchError;
  flightDeals?: UISearchResultsFlightDeal[];
  directFlightsOnlyFilterIgnored?: boolean;
  searchCriteria?: UISearchResultsSearchCriteria;
  searchId?: string;
  /* start - flights_web_inbound_outbound_sr */
  selectedInboundFlight?: UIFlightData;
  /* end - flights_web_inbound_outbound_sr */
  banners?: UISearchResultsBanner[];
  /* start - flights_apex_web_expand_branded_fares_on_search */
  fetchedAt?: number;
  /* end - flights_apex_web_expand_branded_fares_on_search */
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  flights: [],
  availableFilters: {},
  subsidizedFaresSummary: [],
  error: undefined,
  campaignBanner: undefined,
  searchCriteria: undefined,
  searchId: undefined,
  banners: undefined
});
