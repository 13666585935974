import { createTrackingUtils } from "..";
import { useEffect } from "react";
import { isOfMetaOrigin } from "utils/marketing-url-params";

const utils = createTrackingUtils({
  name: "flights_web_swap_price_baggage_sr_desktop",
  stages: {
    SR: 1,
    DIRECT: 2,
    META: 3,
    ONE_WAY: 4,
    ROUND_TRIP: 5,
    SOLO: 6,
    TWO_PLUS: 7
  },
  goals: {
    not_available: 1
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useSwapPriceBaggage = (flightType: String, travellerCount: Number) => {
  const isDirect = !isOfMetaOrigin();

  useEffect(() => {
    stages.SR();

    isDirect ? stages.DIRECT() : stages.META();
    flightType === "ONEWAY" ? stages.ONE_WAY() : undefined;
    flightType === "ROUNDTRIP" ? stages.ROUND_TRIP() : undefined;
    travellerCount === 1 ? stages.SOLO() : stages.TWO_PLUS();
  }, [flightType, isDirect, travellerCount]);
};
export default utils;
