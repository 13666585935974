import { createTrackingUtils } from "..";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { UIFlightData } from "@flights/types/client";
import { useMemo } from "react";
import useProfiler from "hooks/useProfiler";

const utils = createTrackingUtils({
  name: "flights_web_cta_action_reinforce_sr_desktop_v2",
  stages: {
    search_results: 1,
    direct: 2,
    meta: 3,
    sr_expandable_offer: 4
  },
  goals: {
    explore_ticket_options: 1
  },
  goalsWithValue: []
});

const { stages, goals } = utils;

export const useTrackCtaActionReinforceDesktop = (flights: UIFlightData[]) => {
  const { isMobile } = useProfiler();
  const isBrandedFare = useMemo(() => flights.some((flight) => flight.requestableBrandedFares), [flights]);
  if (!isMobile()) {
    stages.search_results();
    isOfMetaOrigin() ? stages.meta() : stages.direct();
    isBrandedFare && stages.sr_expandable_offer();
  }
};
export const trackGoalCtaActionReinforceDesktop = (fareSelectorClosed: boolean) => {
  fareSelectorClosed && goals.explore_ticket_options();
};
export default utils;
