import useGlobalContext from "hooks/useGlobalContext";
import { createTrackingUtils } from "..";
import useProfiler from "hooks/useProfiler";

const utils = createTrackingUtils({
  name: "flights_web_direct_to_nonstop_en_us",
  stages: {
    idx_sr_fd: 1, // main
    desktop: 2,
    mdot: 3,
    direct: 4,
    meta: 5,
    check_pay: 6
  },
  goals: { check_pay_view_fd: 1 },
  goalsWithValue: []
});
const { stages, goals } = utils;

const targetRoutName = {
  searchresults: stages.idx_sr_fd,
  home: stages.idx_sr_fd,
  flightdetails: stages.idx_sr_fd,
  checkout3: stages.check_pay
};
export const useTrackDirectToNonstopUs = (routeName: string) => {
  const { locale } = useGlobalContext();
  const { isDirect, isDesktop } = useProfiler();

  if (locale == "en-us") {
    if (targetRoutName[routeName]) {
      targetRoutName[routeName]();
      if (routeName !== "checkout3") {
        isDesktop() ? stages.desktop() : stages.mdot();
        isDirect() ? stages.direct() : stages.meta();
        //if not check_pay, need to check the whole stages flow //
      }
    }
  }
};

export const goalTrackDirectToNonstopUs = () => {
  goals.check_pay_view_fd();
};
export default utils;
