import { useCallback, useState } from "react";
import { useActions, StoreState, useStore } from "../store";
import { useHistory } from "react-router-dom";
import { actions as searchActions } from "../store/search/actions";
import { UIFlightTimeItemAgg } from "@flights/types/client";
import deepEqual from "deep-equal";
import { trackExperiment } from "utils/et";

type FilterState = StoreState["search"]["filters"];

function getUpdatedSearchParams(filters: FilterState, resetPage: boolean = true) {
  const qs = new URLSearchParams(location.search);

  if (trackExperiment("flights_web_stops_filter_checkbox")) {
    if (filters.multiSelectStops != undefined) {
      qs.set("stops", filters.multiSelectStops.join(","));
    } else {
      qs.delete("stops");
    }
  } else {
    if (filters.stops != undefined) {
      qs.set("stops", "" + filters.stops);
    } else {
      qs.delete("stops");
    }
  }

  if (filters.airlines && filters.airlines.length > 0) {
    qs.set("airlines", filters.airlines.join(","));
  } else {
    qs.delete("airlines");
  }

  if (filters.duration) {
    qs.set("duration", `${filters.duration}`);
  } else {
    qs.delete("duration");
  }

  if (filters.departureIntervals) {
    qs.delete("depInt");
    filters.departureIntervals.forEach((interval) => {
      qs.append("depInt", interval.join("-"));
    });
  } else {
    qs.delete("depInt");
  }

  if (filters.arrivalIntervals) {
    qs.delete("arrInt");
    filters.arrivalIntervals.forEach((interval) => {
      qs.append("arrInt", interval.join("-"));
    });
  } else {
    qs.delete("arrInt");
  }

  if (filters.flightTimes?.length) {
    qs.delete("depTimeInt");
    qs.delete("arrTimeInt");
    let departures: string = "";
    let arrivals: string = "";
    filters.flightTimes.forEach((interval) => {
      const currentDeparture = getflightTimeIntervalSelection(interval.departure);
      const currentArrival = getflightTimeIntervalSelection(interval.arrival);
      departures = departures.length === 0 ? `${currentDeparture}` : `${departures}|${currentDeparture}`;
      arrivals = arrivals.length === 0 ? `${currentArrival}` : `${arrivals}|${currentArrival}`;
    });
    qs.append("depTimeInt", departures);
    qs.append("arrTimeInt", arrivals);
  } else {
    qs.delete("depTimeInt");
    qs.delete("arrTimeInt");
  }

  if (filters.shortLayoverConnection) {
    qs.delete("shortLayoverConnection");
    qs.append("shortLayoverConnection", String("1"));
  } else {
    qs.delete("shortLayoverConnection");
  }

  if (!!filters.includedBaggage?.length) {
    qs.delete("includedBaggage");
    qs.append("includedBaggage", filters.includedBaggage.join(","));
  } else {
    qs.delete("includedBaggage");
  }

  if (!!filters.maxBudget) {
    qs.delete("maxBudget");
    qs.append("maxBudget", String(filters.maxBudget));
  } else {
    qs.delete("maxBudget");
  }

  if (!!filters.maxBudgetPerAdult) {
    qs.delete("maxBudgetPerAdult");
    qs.append("maxBudgetPerAdult", String(filters.maxBudgetPerAdult));
  } else {
    qs.delete("maxBudgetPerAdult");
  }

  if (!!filters.airports?.length) {
    qs.delete("airports");
    qs.append("airports", filters.airports.join(","));
  } else {
    qs.delete("airports");
  }

  if (!!filters.preferSameAirport) {
    qs.delete("preferSameAirport");
    qs.append("preferSameAirport", filters.preferSameAirport);
  } else {
    qs.delete("preferSameAirport");
  }

  if (!!filters.maxLayoverDuration) {
    qs.delete("maxLayoverDuration");
    qs.append("maxLayoverDuration", String(filters.maxLayoverDuration));
  } else {
    qs.delete("maxLayoverDuration");
  }

  if (resetPage) {
    qs.delete("page");
  }

  return qs;
}

const getflightTimeIntervalSelection = (interval: UIFlightTimeItemAgg[]): string => {
  const selectedIntervals = interval.filter((inter) => inter.selected);
  return selectedIntervals.length > 0
    ? selectedIntervals
        .map((selectedInterval) => {
          const start = selectedInterval.start?.split(":")[0];
          const end = selectedInterval.end?.split(":")[0];
          return `${start}-${end}`;
        })
        .join(",")
    : "00-23"; //If no intervals are selected we return the full duration.
};

const useSearchFilters = () => {
  const actions = useActions(searchActions);
  const history = useHistory();
  const store = useStore();

  // Because the filters could be reset using "Reset all" button, we aren't able to track those store changes.
  // Keeping a copy in the local state helps us to know if filters were reset.
  const [currentFilters] = useState<FilterState>(store.search.filters);

  const applyFilters = useCallback(
    (filters: FilterState) => {
      // Checking if filters were changed to reset user to the 1st page.
      const shouldResetPage = !deepEqual(currentFilters, filters);
      const qs = getUpdatedSearchParams(filters, shouldResetPage);
      actions.applyFilters();
      history.replace(
        {
          pathname: location.pathname,
          search: qs.toString()
        },
        { hideLoader: true }
      );
    },
    [actions, history, currentFilters]
  );

  return {
    applyFilters
  };
};

export default useSearchFilters;
