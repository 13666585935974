import { UIFetchStatus, UIFlightData, UIClientFetchError } from "@flights/types/client";

export type State = {
  fetchStatus: UIFetchStatus;
  flight: UIFlightData | undefined;
  error?: UIClientFetchError;
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  flight: undefined,
  error: undefined
});
