import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { isInCheckoutFlowImprovements } from "../utils/experiments/apex/flights_apex_web_checkout_flow_improvements";
import { actions as extraProductActions } from "store/extraProducts/actions";
import { UIAvailableExtraProducts, UIProductSelected } from "@flights/types/client";
import { flightsLocalStore } from "../utils/local-store";
import useEvents from "./useEvents";
import { useRouteMatch } from "react-router-dom";
import { isSirfAvailable } from "../store/selectors/sirf";
import usePointOfSale from "./usePointOfSale";
import { getSubsidizedFares } from "store/flightDetails/selectors";
import { getSearchFetchStatus } from "store/searchResults/selectors";
import { getSelectedExtraProducts } from "store/extraProducts/selectors";

const SELECTED_EXTRA_PRODUCTS_PREFIX = "selectedExtraProducts_";

const usePersistSelectedExtraProducts = () => {
  const dispatch = useDispatch();
  const selectedExtraProducts = useSelector(getSelectedExtraProducts);
  const subsidizedFares = useSelector(getSubsidizedFares) || [];
  const searchFetchStatus = useSelector(getSearchFetchStatus);

  // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
  const checkoutStepsRouteMatch = useRouteMatch<{ 0: string; flightToken: string; cartToken: string }>(
    "/checkout/*/:flightToken/:cartToken"
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
  const flightDetailsRouteMatch = useRouteMatch<{ 0: string; flightToken: string }>("/flights/*/:flightToken");

  const offerToken = (checkoutStepsRouteMatch || flightDetailsRouteMatch)?.params?.flightToken;
  const page = checkoutStepsRouteMatch?.params["0"];
  const { jsError } = useEvents();
  const pointOfSale = usePointOfSale();
  const isCheckoutFlowImprovementsVariant =
    isInCheckoutFlowImprovements(pointOfSale) && !isSirfAvailable(subsidizedFares);

  useEffect(() => {
    if (!isCheckoutFlowImprovementsVariant || !flightsLocalStore) {
      return;
    }
    // persist selected extraProducts state from localStorage to the Redux store
    if (offerToken) {
      const selectedExtraProductsInLocalStorage = getSelectedExtraProductsFromStorage(offerToken, jsError);

      if (isEmpty(selectedExtraProducts) && !isEmpty(selectedExtraProductsInLocalStorage)) {
        saveSelectedExtraProductsToStore(selectedExtraProductsInLocalStorage, dispatch);
      }
    }
  }, [offerToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // clear both store and localStorage when the offer token changes

    if (!isCheckoutFlowImprovementsVariant || !flightsLocalStore) {
      return;
    }

    const selectedProductsExistInStorage = !!flightsLocalStore.get(`${SELECTED_EXTRA_PRODUCTS_PREFIX}${offerToken}`);
    if (page !== "fare" && offerToken && !selectedProductsExistInStorage) {
      dispatch(extraProductActions.resetSelectedExtraProducts());
      clearSelectedExtraProductsStorage(offerToken);
    }
  }, [extraProductActions, offerToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isCheckoutFlowImprovementsVariant || !flightsLocalStore) {
      return;
    }

    // move selected extraProducts state to localStorage on every change
    if (offerToken) {
      const selectedExtraProductsInLocalStorage = getSelectedExtraProductsFromStorage(offerToken, jsError);

      if (!isEmpty(selectedExtraProducts) || !isEmpty(selectedExtraProductsInLocalStorage)) {
        flightsLocalStore.set(`${SELECTED_EXTRA_PRODUCTS_PREFIX}${offerToken}`, JSON.stringify(selectedExtraProducts), {
          ttl: 30 * 60 * 1000
        });
      }
    }
  }, [isCheckoutFlowImprovementsVariant, jsError, offerToken, selectedExtraProducts]);

  useEffect(() => {
    // clear extra products in storage on search
    if (isCheckoutFlowImprovementsVariant && searchFetchStatus === "loading") {
      clearAllSelectedExtraProductsStorage();
    }
  }, [isCheckoutFlowImprovementsVariant, searchFetchStatus]);
};

function getSelectedExtraProductsFromStorage(offerToken: string, jsError: (error: Error) => void) {
  if (!flightsLocalStore) return;

  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  let selectedExtraProductsInLocalStorage = null;

  try {
    selectedExtraProductsInLocalStorage = JSON.parse(
      flightsLocalStore.get(`${SELECTED_EXTRA_PRODUCTS_PREFIX}${offerToken}`) || "null"
    );
  } catch (err) {
    jsError(err);
  }

  return selectedExtraProductsInLocalStorage;
}

function saveSelectedExtraProductsToStore(
  selectedExtraProductsInLocalStorage: Record<keyof UIAvailableExtraProducts, UIProductSelected[]>,
  dispatch: ReturnType<typeof useDispatch>
) {
  Object.entries(selectedExtraProductsInLocalStorage).forEach(([productType, products]) => {
    dispatch(extraProductActions.setProducts(productType as keyof UIAvailableExtraProducts, products));
  });
}

function clearSelectedExtraProductsStorage(offerToken: string) {
  if (!flightsLocalStore) {
    return;
  }

  flightsLocalStore
    .keys()
    ?.filter(
      (key) =>
        key !== `${SELECTED_EXTRA_PRODUCTS_PREFIX}${offerToken}` && key.startsWith(SELECTED_EXTRA_PRODUCTS_PREFIX)
    )
    .forEach((key) => flightsLocalStore && flightsLocalStore.unset(key));
}

export function clearAllSelectedExtraProductsStorage() {
  if (!flightsLocalStore) {
    return;
  }

  flightsLocalStore
    .keys()
    ?.filter((key) => key.startsWith(SELECTED_EXTRA_PRODUCTS_PREFIX))
    .forEach((key) => flightsLocalStore && flightsLocalStore.unset(key));
}

export default usePersistSelectedExtraProducts;
