import React from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import { trackExperiment } from "utils/et";
import Container from "components/elements/Container";
import Frame from "components/elements/Frame";
import useUserAgent from "hooks/useUserAgent";

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
const renderComponent = (Component: React.ComponentType, isVariant: boolean, isMobile: boolean) => {
  if (isVariant) {
    return isMobile ? (
      <Frame attributes={{ style: { width: "100%" } }} p={4} mt={2} mb={2}>
        <Component />
      </Frame>
    ) : (
      <Container pt={6}>
        <Component />
      </Container>
    );
  }

  return <Component />;
};

const GeniusSignInBanner = () => {
  const { isMobile } = useUserAgent();

  const isVariant = !!trackExperiment("gte_index_allweb_sign_in_banner_x_vertical");

  const { Component } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/FlightsGeniusSignInBanner",
    type: "capla"
  });

  return renderComponent(Component, isVariant, isMobile);
};

export default GeniusSignInBanner;
