import { UICarrier, UIFlightSegment } from "@flights/types/client";

export const getUniqueCarriers = (segments: UIFlightSegment[]) => {
  const carriers = new Map<string, UICarrier>();

  for (const segment of segments) {
    for (const leg of segment.legs) {
      for (const carrier of leg.carriersData) {
        carriers.set(carrier.code, carrier);
      }
    }
  }

  return Array.from(carriers.values());
};

export function hasCarrier(segments: UIFlightSegment[], airlines: string[]) {
  const carriers = getUniqueCarriers(segments);
  return carriers.some((carrier) => airlines.includes(carrier.code));
}

export function getMarketingCarriers(segments: UIFlightSegment[]) {
  const carriers = new Set<string>();
  for (const segment of segments) {
    for (const leg of segment.legs) {
      if (leg.flightInfo?.carrierInfo?.marketingCarrier) {
        carriers.add(leg.flightInfo.carrierInfo.marketingCarrier);
      }
    }
  }
  return Array.from(carriers);
}
