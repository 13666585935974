export * from "keycode-js";

export function onKey(keyCode: number, fn: () => void) {
  return function (e: { which: number }) {
    if (e.which === keyCode) {
      // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
      fn.call(null);
    }
  };
}

export function onEnterKey(key: string, fn: () => void) {
  if (key === "Enter") {
    // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
    fn.call(null);
  }
}
