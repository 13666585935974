import { UIPriceBreakdown, UIPrice, UIBcomPricingItem } from "@flights/types/client";
import isUndefined from "lodash/isUndefined";
import { getObjectKeys } from "./objects";

const NUBMER_OF_DIGITS_AS_LARGE_NUMBER_PRICE = 6;

export const priceToNumber = (price: UIPrice): number => {
  const { units, nanos } = price;
  return units + nanos / Math.pow(10, 9);
};

export const getEmptyBreakdown = (currencyCode: string = ""): UIPriceBreakdown => {
  return {
    total: {
      currencyCode,
      nanos: 0,
      units: 0
    },
    baseFare: {
      currencyCode,
      nanos: 0,
      units: 0
    },
    fee: {
      currencyCode,
      nanos: 0,
      units: 0
    },
    tax: {
      currencyCode,
      nanos: 0,
      units: 0
    },
    discount: {
      currencyCode,
      nanos: 0,
      units: 0
    },
    totalWithoutDiscount: {
      currencyCode,
      nanos: 0,
      units: 0
    }
  };
};

export function sumPrice(a: UIPrice, b: UIPrice) {
  if (a.currencyCode !== b.currencyCode)
    throw new Error(`Incompatible currency codes: ${a.currencyCode} not equal to ${b.currencyCode}`);

  return normalizePrice({
    currencyCode: a.currencyCode,
    units: a.units + b.units,
    nanos: a.nanos + b.nanos
  });
}

export function subtractPrice(a: UIPrice, b: UIPrice) {
  if (a.currencyCode !== b.currencyCode)
    throw new Error(`Incompatible currency codes: ${a.currencyCode} not equal to ${b.currencyCode}`);

  return normalizePrice({
    currencyCode: a.currencyCode,
    units: a.units - b.units,
    nanos: a.nanos - b.nanos
  });
}

/**
 * @description This function takes two lists of bcomPricingItems and returns a list that represents the sum of the bcomPricingItems by itemType.
 * If an itemType exists in one list but not in the other, the bcomPricingItem from the existing list is used.
 *
 * @param {UIBcomPricingItem[]} a - The first list of UIBcomPricingItem objects.
 * @param {UIBcomPricingItem[]} b - The second list of UIBcomPricingItem objects.
 * @returns {UIBcomPricingItem[]} - The resulting list of UIBcomPricingItem objects after summing the amounts of the same itemType.
 */

export const sumBcomPricingItems = (a: UIBcomPricingItem[], b: UIBcomPricingItem[]) => {
  const itemTypes = [...new Set([...a.map(({ itemType }) => itemType), ...b.map(({ itemType }) => itemType)])];

  return itemTypes.reduce<UIBcomPricingItem[]>((acc, itemType) => {
    const aItem = a.find((item) => item.itemType === itemType);
    const bItem = b.find((item) => item.itemType === itemType);
    const item = aItem || bItem;

    if (!item) {
      return acc;
    }

    const amount = aItem && bItem ? sumPrice(aItem.amount, bItem.amount) : aItem ? aItem.amount : bItem?.amount;

    if (amount) acc.push({ ...item, amount });

    return acc;
  }, []);
};

export const sumPriceBreakdown = (prices: UIPriceBreakdown[]): UIPriceBreakdown => {
  if (prices.length == 0) {
    throw new Error("No input");
  }
  const currencyCode = prices[0].baseFare.currencyCode;

  const isTypeUIPrice = (input: unknown): input is UIPrice => {
    return (
      !!input &&
      typeof input === "object" &&
      (input as UIPrice).nanos !== undefined &&
      (input as UIPrice).units !== undefined
    );
  };

  const isTypeUIBcomPricingItem = (input: unknown): input is UIBcomPricingItem => {
    return (
      !!input &&
      typeof input === "object" &&
      (input as UIBcomPricingItem).name !== undefined &&
      isTypeUIPrice((input as UIBcomPricingItem).amount)
    );
  };

  const isTypeUIBcomPricingItems = (input: unknown): input is UIBcomPricingItem[] => {
    return !!input && Array.isArray(input) && input.every((item) => isTypeUIBcomPricingItem(item));
  };

  const isTypeBoolean = (input: unknown): input is boolean => {
    return typeof input === "boolean";
  };

  const setUIPriceBreakdownValue = <T extends UIPriceBreakdown, K extends keyof T, V extends T[K]>(
    priceBreakdown: T,
    key: K,
    value: V
  ) => (priceBreakdown[key] = value);

  const output = getEmptyBreakdown(currencyCode);
  const summedPrices = prices.reduce((acc, priceBreakdown) => {
    const keys = getObjectKeys(priceBreakdown);

    keys.forEach((key) => {
      const accValue = acc[key];
      const pbdValue = priceBreakdown[key];

      // keep acc[key] undefined if both of the values are undefined
      if (!(isUndefined(accValue) && isUndefined(pbdValue))) {
        if (isTypeUIBcomPricingItems(accValue) && isTypeUIBcomPricingItems(pbdValue)) {
          setUIPriceBreakdownValue(acc, key, sumBcomPricingItems(accValue, pbdValue));
        } else if (isTypeBoolean(accValue) && isTypeBoolean(pbdValue)) {
          setUIPriceBreakdownValue(acc, key, Boolean(accValue) || Boolean(pbdValue));
        } else if (isTypeUIPrice(accValue) && isTypeUIPrice(pbdValue)) {
          setUIPriceBreakdownValue(acc, key, sumPrice(accValue, pbdValue));
        } else {
          // assign priceBreakdown[key] to acc[key] if priceBreakdown[key] is not undefined
          if (!isUndefined(pbdValue)) {
            setUIPriceBreakdownValue(acc, key, pbdValue);
          }
        }
      }
    });
    return acc;
  }, output);

  // We have to calculate the sum of the rounded price by adding the not rounded numbers together first:
  if (summedPrices.totalRounded) {
    summedPrices.totalRounded = roundPrice(summedPrices.total);
  }

  if (summedPrices.totalWithoutDiscountRounded && summedPrices.totalWithoutDiscount) {
    summedPrices.totalWithoutDiscountRounded = roundPrice(summedPrices.totalWithoutDiscount);
  }

  return summedPrices;
};

export const roundPrice = (price: UIPrice) => ({
  ...price,
  nanos: 0,
  units: price.nanos > 0 ? price.units + 1 : price.units
});

export const arePricesDifferent = (firstPrice: UIPrice, secondPrice: UIPrice) => {
  return (
    firstPrice.currencyCode !== secondPrice.currencyCode ||
    firstPrice.nanos !== secondPrice.nanos ||
    firstPrice.units !== secondPrice.units
  );
};

export const isPriceWithLargeNumber = (price: UIPrice) =>
  price.units.toString().length > NUBMER_OF_DIGITS_AS_LARGE_NUMBER_PRICE;

export const isPriceZero = (price: UIPrice) => price.nanos === 0 && price.units === 0;

export const getZeroUIPrice = (currencyCode: UIPrice["currencyCode"]): UIPrice => ({
  currencyCode,
  nanos: 0,
  units: 0
});

/**
 * Normalize UIPrice according to the Money type
 * Reference: https://cloud.google.com/recommender/docs/reference/rest/Shared.Types/Money
 */
export const normalizePrice = (price: UIPrice): UIPrice => {
  const output = { ...price };

  if (output.nanos <= -1e9 || output.nanos >= 1e9) {
    const carry = Math.floor(output.nanos / 1e9);
    output.units += carry;
    output.nanos -= carry * 1e9;
  }

  if (output.units < 0 && output.nanos > 0) {
    output.units += 1;
    output.nanos -= 1e9;
  } else if (output.units > 0 && output.nanos < 0) {
    output.units -= 1;
    output.nanos += 1e9;
  }

  return output;
};
