import C360ReactTracker from "@bookingcom/c360-tracker-react";
import { UIXc360Data } from "../cross-sell/types";

const c360SendEvent = (data: UIXc360Data, grumble: (message: string, payload?: UIXc360Data) => void) => {
  if (!C360ReactTracker.c360Config) {
    grumble("c360SendEvent: C360 ReactTracker is not initialised. Event was not send.", { ...data });
    return;
  } else {
    return C360ReactTracker.sendEvent(data);
  }
};

export default c360SendEvent;
