import React, { useEffect, useRef, useMemo } from "react";
import FlightCardMobile from "./components/FlightCardBound";
import FlightCardDesktop, { FlightCardBoundProps } from "./components/FlightCardBound.desktop";
import useUserAgent from "hooks/useUserAgent";
import { trackCustomGoal, trackExperimentStage } from "utils/et";
import Frame from "../Frame";
import useTrackViAA from "hooks/useTrackViAA";
import useIsInViewport from "hooks/useIsInViewport";
import useBaggages from "../Baggages/useBaggages";
import { isOfMetaOrigin } from "../../../utils/marketing-url-params";
import { t, useI18n } from "@bookingcom/lingojs-react";

type Props = FlightCardBoundProps;

const _FlightCard = (props: Props) => {
  const { flight, index, onClick, bigLogo, searchType } = props;
  const { isMobile } = useUserAgent();
  const FlightCard = isMobile ? FlightCardMobile : FlightCardDesktop;
  const { getBaggagesStatus } = useBaggages(flight);
  const i18n = useI18n();

  // flights_web_aa_virtual_interlining_tracking -- start
  const trackViAA = useTrackViAA();
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(ref.current);
  useEffect(() => {
    trackViAA.ViAAInStage1();
    if (!isInViewport) return;
    trackViAA.ViAAInStage2(flight);
  }, [isInViewport, trackViAA, flight]);
  // flights_web_aa_virtual_interlining_tracking -- end

  const isSomeBaggagePartiallyIncluded = useMemo(() => {
    const { personalItem, cabin, checkedIn } = getBaggagesStatus();
    const is = [personalItem, cabin, checkedIn].some((bag) => {
      return bag?.inclusionType === "partial";
    });

    return is;
  }, [getBaggagesStatus]);

  useEffect(() => {
    if (isInViewport && isSomeBaggagePartiallyIncluded) {
      trackExperimentStage("flights_web_aa_partially_included_baggage_tracking", isOfMetaOrigin() ? 7 : 3);
    }
  }, [isInViewport, isSomeBaggagePartiallyIncluded]);

  return (
    <Frame
      mt={2}
      mb={4}
      elementRef={ref}
      attributes={{ role: "group", "aria-label": i18n.trans(t("a11y_flights_search_card_begin")) }}
    >
      <FlightCard
        flight={flight}
        onClick={() => {
          if (isMobile) {
            trackCustomGoal("flight_web_sr_traffic_aa_mdot", 1);
          } else {
            trackCustomGoal("flight_web_sr_traffic_aa_desktop", 1);
          }
          trackViAA.ViAAInStage3(flight);
          trackViAA.ViAAInStage4(flight);
          flight?.badges?.forEach((b) => {
            if (b.type === "BEST") trackCustomGoal("flights_web_copy_best_new_word", 1);
          });
          onClick(flight);
        }}
        pricePerPerson
        index={index}
        bigLogo={bigLogo}
        searchType={searchType}
        /* start - flights_apex_web_expand_branded_fares_on_search */
        addFalsePositiveBrandedFare={props.addFalsePositiveBrandedFare}
        /* end - flights_apex_web_expand_branded_fares_on_search */
      />
    </Frame>
  );
};

const FlightCard = React.memo(_FlightCard);

export default FlightCard;
