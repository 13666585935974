import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import FlightCardCarriers, { CarrierNames } from "./FlightCardCarriers";
import FlightCardCarriersV2 from "./FlightCardCarriersV2";
import FlightCardPrice from "./FlightCardPrice";
import { Button, Card, Icon } from "@bookingcom/bui-react";
import { FlightBadgeVariant, UIFlightData, UIFlightSegment, UIOrder, UITripRebookOption } from "@flights/types/client";

import BaseFareAttributes from "./BaseFareAttributes";
import BaseFareName from "./BaseFareName";
import FlightCardLuggage from "./FlightCardLuggage";
import FlightCardSegment from "./FlightCardSegment";

import useLocaleContext from "hooks/useLocaleContext";
import { trackExperiment } from "utils/et";
import useAirlineLogos from "../../../../hooks/useAirlineLogos";
import VIAlert from "../../VIAlert";
import { useStore } from "../../../../store";
import { SBSearchType } from "@bookingcom/flights-searchbox/@types/client";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline";
import { AnimatePresence, motion } from "framer-motion";
import { FareSelectorDesktop } from "components/elements/FareSelector/components/FareSelector.desktop";
import useIsInViewport from "hooks/useIsInViewport";
import useFetchBrandedFares from "components/elements/FareSelector/hooks/useFetchBrandedFares";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import FlightCardFullLuggageData from "./FlightCardFullLuggageData";
import { isFeatureRunningClientSide } from "utils/features";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame from "components/elements/Frame";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import styles from "./FlightCardBound.desktop.module.css";
import useTrackBaggagesV2 from "hooks/useTrackBaggagesV2";
import BaggagesSR from "components/elements/Baggages/BaggagesSR";
import trackSwapPriceBaggage from "utils/experiments/funnel/flights_web_swap_price_baggage_sr_desktop";
import debounce from "lodash/debounce";
import FlightCardLuggagePopover from "./FlightCardLuggagePopover";
import trackCtaActionReinforceDesktop, {
  trackGoalCtaActionReinforceDesktop
} from "utils/experiments/funnel/flights_web_cta_action_reinforce_sr_desktop_v2";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import flights_apex_web_expand_branded_fares_on_search from "utils/experiments/apex/flights_apex_web_expand_branded_fares_on_search";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { mcn } from "utils/mergeClassnames";
import FlightCardSegmentDesktop from "./FlightCardSegment.desktop";
import flights_web_sr_card_itinerary_redesign_desktop, {
  trackGoalItineraryRedesignDesktop
} from "utils/experiments/funnel/flights_web_sr_card_itinerary_redesign_desktop";
import { getMarketingCarriers } from "utils/carriers";
import flights_web_ddot_last_available_seats_v3 from "utils/experiments/funnel/flights_web_ddot_last_available_seats_v3";
import flights_web_blackout_vi_alert_sr from "utils/experiments/funnel/flights_web_blackout_vi_alert_sr";
import { trackRebookPriceReviewM3 } from "utils/experiments/post-booking/flights_web_pb_rebook_price_review_m3";

export type FlightCardBoundProps = {
  flight: UIFlightData;
  onClick: (flight: UIFlightData) => void;
  pricePerPerson?: boolean;
  index: number;
  bigLogo?: boolean;
  searchType?: SBSearchType;
  rebookProps?: {
    tripRebookOption: UITripRebookOption;
    initialOrder: UIOrder;
    align: "start" | "end";
  };
  /* start - flights_web_inbound_outbound_sr */
  outboundIndex?: number;
  inboundFlight?: true;
  variant?: "normal" | "condensed";
  open?: boolean;
  inboundFlightsContent?: ReactNode;
  inboundFlightsCount?: number;
  forceButtonFocusOnMount?: boolean;
  /* end - flights_web_inbound_outbound_sr */
  addFalsePositiveBrandedFare?: () => void;
};

const FlightCardBound: FC<FlightCardBoundProps> = (props) => {
  const {
    flight,
    onClick,
    pricePerPerson,
    index,
    bigLogo,
    searchType,
    rebookProps,
    /* start - flights_web_inbound_outbound_sr */
    outboundIndex,
    inboundFlight,
    variant = "normal",
    open,
    inboundFlightsContent,
    inboundFlightsCount,
    forceButtonFocusOnMount = false,
    /* end - flights_web_inbound_outbound_sr */
    /* start - flights_apex_web_expand_branded_fares_on_search */
    addFalsePositiveBrandedFare
    /* end - flights_apex_web_expand_branded_fares_on_search */
  } = props;
  const expandBrandedFaresByDefault = !!flights_apex_web_expand_branded_fares_on_search.variant();
  const showFareSelectorDefault = expandBrandedFaresByDefault && !!flight.requestableBrandedFares;
  const hideExploreOptionsButton = flights_apex_web_expand_branded_fares_on_search.variant() === 3;
  const i18n = useI18n() as I18nChildContext;
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const { isRTL } = useLocaleContext();
  const { searchCriteria, searchResults, brandedFares } = useStore();
  const { adults, children } = searchCriteria;
  const flightCardBoundRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsInViewport(flightCardBoundRef.current, undefined, { threshold: 0.7 });
  const isInViewport = useIsInViewport(flightCardBoundRef.current);
  const [showFareSelector, toggleShowFareSelector] = useState(showFareSelectorDefault);

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_www")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  // Condensed is used for ETs:
  // flights_web_ddot_condensed_one_way_search_results
  // flights_web_inbound_outbound_sr
  const isCondensed =
    variant === "condensed" ||
    (searchType && searchType === "ONEWAY" && !!trackExperiment("flights_web_ddot_condensed_one_way_search_results"));
  const [showDivider, setShowDivider] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const travellersCount = adults + children.length;
  const isOutboundFlightCard = typeof open !== "undefined";

  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);

  const carriers = getMarketingCarriers(flight.segments);

  const { isBaggagesV2 } = useTrackBaggagesV2(); // flights_web_funnel_baggages_v2
  const isActionReinforce = !!trackCtaActionReinforceDesktop.variant();
  const brandedFaresAvailable = (brandedFares.fareOffersWithDetails[flight.token]?.brandedFareOffers?.length || 0) > 0;
  const hideBrandedFaresBeforeAvailable = isFeatureRunningClientSide("FLIGHTS_ONLY_SHOW_BF_ON_SR_CTA_WHEN_AVAILABLE");

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    !!flight.requestableBrandedFares,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers,
    addFalsePositiveBrandedFare
  );

  const trackFlightCardsVisibleExpandedDebounced = useMemo(
    () => debounce(flights_apex_web_expand_branded_fares_on_search.trackFlightCardsVisible, 2000),
    []
  );

  useEffect(() => {
    trackFlightCardsVisibleExpandedDebounced(isVisible, index);
  }, [isVisible, index, searchResults.flights.length, trackFlightCardsVisibleExpandedDebounced]);

  useEffect(() => {
    if (open) setShowDivider(true);
  }, [open]);

  useEffect(() => {
    if (forceButtonFocusOnMount && buttonRef.current) {
      buttonRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDivider = useCallback(() => setShowDivider(false), []);

  const hasMoreThanOneInboundFlight = (flight.outboundFlightInfo?.inboundFlightsCount || 0) > 1;

  const cardId =
    typeof outboundIndex === "number" ? `flight-outbound-${outboundIndex}-card-${index}` : `flight-card-${index}`;

  const toggleButtonLabel = useMemo(() => {
    if (open) {
      return inboundFlightsCount === 1
        ? t("flights_sr_rt_return_button_hide_single")
        : t("flights_sr_rt_return_button_hide");
    }

    return inboundFlightsCount === 1
      ? t("flights_sr_rt_return_button_view_single")
      : t("flights_sr_rt_return_button_view");
  }, [inboundFlightsCount, open]);

  const viewDetailsButtonLabel = t("flights_apex_sr_flex_cta_main");
  const hasFlexibleTicketBadge = flight.ancillaries?.flexibleTicket && !flight.requestableBrandedFares;
  const isSwapPriceBagge = !!trackSwapPriceBaggage.variant();
  const showFlightCardBadges = flight?.badges?.length || campaignBadges.length > 0 || hasFlexibleTicketBadge;

  const priceBlockAlignment = useMemo(() => {
    if (isCondensed) {
      return "flex-end";
    }

    if (trackExperiment("flights_web_swap_price_baggage_sr_desktop_2")) {
      return "center";
    }

    return undefined;
  }, [isCondensed]);

  const flightPriceComponent = rebookProps ? (
    <RebookCostBreakdown {...rebookProps} breakdownOpen={breakdownOpen} setBreakdownOpen={setBreakdownOpen} />
  ) : (
    <FlightCardPrice
      flight={flight}
      breakdownOpen={breakdownOpen}
      setBreakdownOpen={setBreakdownOpen}
      showPricePerPerson={pricePerPerson && adults + children.length > 1}
      showAvailableSeats={adults + children.length <= 2 && !!flights_web_ddot_last_available_seats_v3.variant()}
      totalPriceLabel={
        isOutboundFlightCard
          ? i18n.trans(travellersCount > 1 ? t("flights_sr_rt_price_per") : t("flights_sr_price_round_trip"))
          : undefined
      }
      priceFrom={isOutboundFlightCard && hasMoreThanOneInboundFlight}
      hidePriceBreakDown={isOutboundFlightCard}
      popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
    />
  );

  return (
    <div id={cardId} ref={flightCardBoundRef}>
      <Card fill className={mcn(open && styles.cardShadow)} variant={shouldPinOfferOnSr ? "elevated" : "neutral"}>
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame p={4} direction="row" className={showDivider ? styles.divider : undefined}>
          <Frame
            attributes={{
              style: {
                width: !!trackExperiment("flights_web_sr_full_luggage_data") ? "56%" : "64%"
              }
            }}
            justifyContent="flex-start"
          >
            {showFlightCardBadges && (
              <FlightCardBadges>
                {campaignBadges.map((badge, idx) => (
                  <FlightCardBadge
                    key={`keyCampaignBadge-${idx}`}
                    alternative={false}
                    isInteractive={false}
                    {...badge}
                  />
                ))}

                {flight?.badges?.map(({ text, variant, type }, idx) => (
                  <FlightCardBadge key={text + idx} text={text} style={variant} alternative type={type} />
                ))}

                {hasFlexibleTicketBadge && (
                  <FlightCardBadge
                    style={FlightBadgeVariant.CONSTRUCTIVE}
                    alternative={true}
                    text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                    attributes={{
                      "aria-hidden": true
                    }}
                  />
                )}
              </FlightCardBadges>
            )}

            <BaseFareAttributes flight={flight} />

            {flight.segments.map((segment, segmentIndex) => {
              return (
                <FlightCardSegmentDetails key={segmentIndex} idx={segmentIndex} segment={segment} bigLogo={bigLogo} />
              );
            })}

            {isCondensed && (
              <FlightCardLuggage
                segments={flight.segments}
                horizontal={true}
                brandedFareInfo={flight.brandedFareInfo}
              />
            )}
          </Frame>

          <Frame
            direction="column"
            className={mcn(
              styles.priceBlock,
              !!trackExperiment("flights_web_sr_full_luggage_data") && styles.priceBlockWider
            )}
            justifyContent={priceBlockAlignment}
            attributes={{ style: { width: !!trackExperiment("flights_web_funnel_baggages_v2") ? "40%" : undefined } }}
          >
            {isSwapPriceBagge && (
              <div
                style={{ textAlign: isRTL ? "left" : "right", marginBottom: "var(--bui_spacing_4x)" }}
                className={mcn(!!trackExperiment("flights_web_sr_full_luggage_data") && styles.buttonArea)}
              >
                <BaseFareName flight={flight} />
                {flightPriceComponent}
              </div>
            )}
            {rebookProps?.tripRebookOption && trackRebookPriceReviewM3.variant() === 1 ? (
              <FlightCardLuggage segments={rebookProps.tripRebookOption.segments} />
            ) : (
              !trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") &&
              !trackExperiment("flights_web_sr_full_luggage_data") &&
              !isCondensed &&
              (isBaggagesV2() ? (
                <div style={{ marginBottom: "var(--bui_spacing_3x)" }}>
                  <BaggagesSR flight={flight} />
                </div>
              ) : (
                <FlightCardLuggage segments={flight.segments} brandedFareInfo={flight.brandedFareInfo} />
              ))
            )}

            {!trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") &&
              !!trackExperiment("flights_web_sr_full_luggage_data") &&
              !isCondensed && (
                <FlightCardFullLuggageData
                  includedLuggagePerSegment={flight.includedProducts?.segments}
                  segments={flight.segments}
                  ancillaries={flight.ancillaries}
                  brandedFareInfo={flight.brandedFareInfo}
                />
              )}

            <div
              style={{ textAlign: isRTL ? "left" : "right" }}
              className={mcn(!!trackExperiment("flights_web_sr_full_luggage_data") && styles.buttonArea)}
            >
              {!isSwapPriceBagge && <BaseFareName flight={flight} /> && flightPriceComponent}

              {!!trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") && (
                <FlightCardLuggagePopover flight={flight} />
              )}

              {isOutboundFlightCard ? (
                <Button
                  text={i18n.trans(toggleButtonLabel)}
                  variant={isActionReinforce ? "primary" : "secondary"}
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  onClick={() => {
                    flights_apex_web_expand_branded_fares_on_search.goals.proceed_with_base_offer();
                    onClick(flight);
                    trackGoalItineraryRedesignDesktop(flight.segments);
                  }}
                  className={isSwapPriceBagge ? styles.buttonSwapPriceBaggage : styles.button}
                  attributes={{
                    "aria-describedby": cardId,
                    "aria-expanded": open
                  }}
                />
              ) : (
                <Button
                  ref={buttonRef}
                  attributes={{
                    "data-testid": "flight_card_bound_select_flight",
                    "aria-describedby": cardId,
                    "aria-label": `${i18n.trans(viewDetailsButtonLabel)} ${
                      hasFlexibleTicketBadge ? i18n.trans(t("flights_apex_sr_flex_badge")) : ""
                    }`
                  }}
                  text={i18n.trans(viewDetailsButtonLabel)}
                  onClick={() => {
                    flights_apex_web_expand_branded_fares_on_search.goals.proceed_with_base_offer();
                    trackGoalItineraryRedesignDesktop(flight.segments);
                    onClick(flight);
                  }}
                  variant={inboundFlight || isActionReinforce ? "primary" : "secondary"}
                  className={isSwapPriceBagge ? styles.buttonSwapPriceBaggage : styles.button}
                />
              )}

              {flight.requestableBrandedFares && !hideExploreOptionsButton && (
                <Button
                  attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
                  text={
                    showFareSelector
                      ? i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                      : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                  }
                  onClick={() => {
                    toggleShowFareSelector(!showFareSelector);
                    trackGoalCtaActionReinforceDesktop(!showFareSelector);

                    if (!showFareSelector) {
                      flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                    } else {
                      flights_apex_web_expand_branded_fares_on_search.goals.collapse_fares();
                      flights_apex_web_expand_branded_fares_on_search.goalsWithValue.collapse_branded_fares_on_sr(1);
                    }
                  }}
                  variant="tertiary"
                  iconPosition="end"
                  wide={true}
                  icon={
                    <Icon
                      svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={`${styles.showFaresButton} ${
                    !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                  }`}
                />
              )}
            </div>
          </Frame>
        </Frame>

        {showFareSelector && <FareSelectorDesktop flight={flight} />}

        {isOutboundFlightCard && (
          <AnimatePresence onExitComplete={removeDivider} initial={false}>
            {open && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Frame mb={4} pt={4} pr={4}>
                  {inboundFlightsContent}
                </Frame>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Card>
    </div>
  );
};

const FlightCardSegmentDetails: FC<{ segment: UIFlightSegment; idx: number; bigLogo?: boolean }> = ({
  segment,
  idx,
  bigLogo
}) => {
  const { selectFlightCarriers: carriers } = useAirlineLogos(segment);
  const segmentCarriersClass = bigLogo ? styles.segmentCarriersV2 : styles.segmentCarriers;
  const segmentDetailsClass = bigLogo ? styles.segmentDetailsV2 : styles.segmentDetails;
  const segmentVIAlertClass = bigLogo ? styles.segmentVIAlertV2 : styles.segmentVIAlert;
  const isItineraryRedesign = !!flights_web_sr_card_itinerary_redesign_desktop.variant();
  return (
    <>
      <Frame direction="row" className={styles.segment} justifyContent="space-between" alignItems="center">
        <Frame className={segmentCarriersClass} justifyContent="space-between" alignItems="flex-start">
          {bigLogo ? (
            <FlightCardCarriersV2 segment={segment} idx={idx} />
          ) : (
            <FlightCardCarriers segment={segment} idx={idx} />
          )}
        </Frame>
        <Frame className={segmentDetailsClass}>
          {isItineraryRedesign ? (
            <FlightCardSegmentDesktop segment={segment} idx={idx} />
          ) : (
            <FlightCardSegment segment={segment} idx={idx} />
          )}
        </Frame>
      </Frame>

      {!flights_web_blackout_vi_alert_sr.variant() && (
        <Frame className={segmentVIAlertClass}>
          <VIAlert segments={segment} align="center" isSearchResults />
        </Frame>
      )}

      <Frame mt={isItineraryRedesign ? 2 : 3}>
        <CarrierNames carriers={carriers} idx={idx} />
      </Frame>
    </>
  );
};

const FlightCardDesktop = React.memo(FlightCardBound);
export default FlightCardDesktop;
