import { StoreState } from "store";

export const getSubsidizedFares = (state: StoreState) => state.flightDetails.flight?.subsidizedFares;
export const getFlightPointOfSale = (state: StoreState) => state.flightDetails.flight?.pointOfSale;
export const getHasFlight = (state: StoreState) => !!state.flightDetails.flight;
export const getFlight = (state: StoreState) => state.flightDetails.flight;
export const getBrandedFareOffers = (state: StoreState) => state.flightDetails.flight?.brandedFareOffers;
export const getAncillaries = (state: StoreState) => state.flightDetails.flight?.ancillaries;
export const getFlightDetailsFetchStatus = (state: StoreState) => state.flightDetails.fetchStatus;
export const getFlightDetailsSegments = (state: StoreState) => state.flightDetails.flight?.segments;
