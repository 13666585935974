import React, { useCallback, useMemo } from "react";

import { Actionable, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";

import { FeedbackModal } from "../Feedback";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
import { RouteName } from "app/routes";
import useToggle from "hooks/useToggle";
import styles from "./FeedbackButton.module.css";
import NoSSR from "../../../app/NoSSR";

const FeedbackButton: React.FC = () => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n() as I18nChildContext;
  const { visible: modalActive, show: showModal, hide: hideModal } = useToggle();
  const routeName = useRouteName();
  const displayFeedbackButton = useMemo(() => {
    if (isMobile) {
      return false;
    }

    const whiteListedRoutes: RouteName[] = ["home", "searchresults", "pricechange", "fly-anywhere"];
    return whiteListedRoutes.includes(routeName);
  }, [routeName, isMobile]);

  const openFeedbackModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      showModal();
    },
    [showModal]
  );

  if (!displayFeedbackButton) {
    return <></>;
  }

  return (
    <>
      <div className={styles.feedbackButton}>
        <Actionable onClick={openFeedbackModal}>
          <Text variant="body_2" className={styles.feedbackButtonText}>
            <span>{i18n.trans(t("flights_action_give_feedback"))}</span>
          </Text>
        </Actionable>
      </div>

      <FeedbackModal active={modalActive} onClose={hideModal} />
    </>
  );
};

const __ControlFeedback = () => {
  return (
    <NoSSR>
      <FeedbackButton />
    </NoSSR>
  );
};

export default __ControlFeedback;
