import useIsInViewport from "hooks/useIsInViewport";
import React, { useRef, useEffect, useCallback } from "react";
import { useStore } from "../../../store";
import flights_web_flexible_date_search_one_way_mdot, {
  trackMdotFlexStages
} from "utils/experiments/funnel/flights_web_flexible_date_search_one_way_mdot";
import FlexibleDateBestPriceInner from "./FlexibleDateBestPriceInner";
import { useGetFlexDatesData } from "./useGetFlexDatesData";
import useBookWindowFromSearch from "hooks/useBookingWindowFromSearch";

const FlexibleDateBestPrice = ({ shouldRender }: { shouldRender: boolean }) => {
  const data = useGetFlexDatesData();
  const {
    searchCriteria: { adults, children },
    flexibleDateMinPrice: { flexibleDateMinPrice = [] }
  } = useStore();
  const bookingWindow = useBookWindowFromSearch();
  const hiddenElementRef = useRef(null);
  const isInViewport = useIsInViewport(hiddenElementRef.current);
  const goalsTrackedRef = useRef(false);
  const inViewportTrackedRef = useRef(false);
  const scrollRef = useRef(false);

  useEffect(() => {
    if (data) {
      trackMdotFlexStages({ travellersCount: adults + children.length, bookingWindow });

      if (!inViewportTrackedRef.current && isInViewport) {
        flights_web_flexible_date_search_one_way_mdot.goals.flex_is_in_view();
        inViewportTrackedRef.current = true;
      }

      if (!goalsTrackedRef.current) {
        const originalPrice = flexibleDateMinPrice[3];

        if (originalPrice?.priceRounded?.units !== data.currentBestPrice.units) {
          flights_web_flexible_date_search_one_way_mdot.goalsWithValue.flights_web_flexible_date_search_one_way_mdot_mismatch_sr_and_cache(
            1
          );
        }

        goalsTrackedRef.current = true;
      }
    }
  }, [adults, bookingWindow, children.length, data, flexibleDateMinPrice, isInViewport]);

  const onCarouselScroll = useCallback(() => {
    if (!scrollRef.current) {
      flights_web_flexible_date_search_one_way_mdot.goalsWithValue.flights_web_flexible_date_search_one_way_mdot_users_scrolls_carousel(
        1
      );
      scrollRef.current = true;
    }
  }, []);

  const onItemClick = useCallback(() => {
    flights_web_flexible_date_search_one_way_mdot.goals.click_on_item();
  }, []);

  if (!shouldRender || !data) return null;

  const { dates, currentBestPrice, cheaperCount, isOneWay, departureDate, returnDate } = data;

  return (
    <>
      <div ref={hiddenElementRef} aria-hidden></div>

      <FlexibleDateBestPriceInner
        carouselSize="large"
        onCarouselScroll={onCarouselScroll}
        onItemClick={onItemClick}
        dates={dates}
        currentBestPrice={currentBestPrice}
        cheaperCount={cheaperCount}
        isOneWay={isOneWay}
        departureDate={departureDate}
        returnDate={returnDate}
        hidePriceFromHeader={true}
      />
    </>
  );
};

export default FlexibleDateBestPrice;
