import { Actions, ActionTypes } from "./actions";
import { getInitialState, State } from "./state";
import { merge } from "lodash";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flight: undefined,
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flight: action.payload.flight,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        flight: undefined
      };

    case ActionTypes.selectBrandedFare:
      const { flight: baseOffer } = state;
      const { fareOffer } = action.payload;
      if (!baseOffer) {
        throw Error("ActionTypes.selectBrandedFare: base flight offer is missing");
      }

      return {
        ...state,
        // We need to keep `baseOffer` keys that don't differ between fares (like `segments` or `extraProducts`)
        // and are not presented in `fareOffer`,
        // while overriding the others that differ (like `token` or `priceBreakdown`).
        flight: merge({}, baseOffer, fareOffer),
        fetchStatus: "success",
        error: undefined
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
