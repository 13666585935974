/**
 *
 * FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
 *
 */

import { UIClientFetchError, UIFetchStatus, UIPrice } from "@flights/types/client";

export interface State {
  fetchStatus: UIFetchStatus;
  error?: UIClientFetchError;
  paymentCollection?: {
    remainingAmount?: UIPrice;
    paymentId?: string;
    addOnOrderId?: string;
    orderId?: string;
    isFullAmountRemaining?: boolean;
  };
}

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  paymentCollection: undefined,
  error: undefined
});
