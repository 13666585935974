import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flights: [],
        fetchStatus: "loading",
        error: undefined,
        /* start - flights_apex_web_expand_branded_fares_on_search */
        fetchedAt: undefined
        /* end - flights_apex_web_expand_branded_fares_on_search */
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flights: action.payload.flightOffers,
        atolProtectedStatus: action.payload.atolProtectedStatus,
        cabinClassExtension: action.payload.cabinClassExtension,
        subsidizedFaresSummary: action.payload.subsidizedFaresSummary || [],
        availableFilters: action.payload.aggregation,
        baggagePolicies: action.payload.baggagePolicies,
        flightDeals: action.payload.flightDeals,
        fetchStatus: "success",
        error: undefined,
        directFlightsOnlyFilterIgnored: !!action?.payload?.directFlightsOnlyFilterIgnored,
        campaignBanner: action.payload.campaignBanner,
        banners: action.payload.banners,
        searchCriteria: action.payload.searchCriteria,
        searchId: action.payload.searchId,
        /* start - flights_apex_web_expand_branded_fares_on_search */
        fetchedAt: performance.now()
        /* end - flights_apex_web_expand_branded_fares_on_search */
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload,
        flights: [],
        availableFilters: {}
      };

    case ActionTypes.reset:
      return getInitialState();

    /* start - flights_web_inbound_outbound_sr */
    case ActionTypes.setSelectedInboundFlight:
      return {
        ...state,
        selectedInboundFlight: action.payload
      };

    case ActionTypes.resetSelectedInboundFlight:
      return {
        ...state,
        selectedInboundFlight: undefined
      };
    /* end - flights_web_inbound_outbound_sr */

    default:
      return state;
  }
};

export { reducer, getInitialState };
