import React, { useEffect } from "react";
import SearchToolbar from "../SearchToolbar";
import { SearchOverview } from "../SearchOverView";
import { useI18n } from "@bookingcom/lingojs-react";
import useLocaleContext from "hooks/useLocaleContext";
import useSearchbox from "hooks/useSearchbox";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import { Container } from "@bookingcom/bui-react";
import SearchboxController from "@bookingcom/flights-searchbox/component-controllers/SearchboxController";
import { SearchBoxVerticalCollapsible } from "@bookingcom/flights-searchbox/components/SearchBox";
import useProfiler from "hooks/useProfiler";
import { UIFlyAnywhereFilterTripType } from "@flights/types/fly-anywhere";

const SearchHeader = ({
  hideToolbar = false,
  flyAnywhere
}: {
  hideToolbar?: boolean;
  flyAnywhere?: { tripType?: UIFlyAnywhereFilterTripType["id"]; sortBy?: string };
}) => {
  const i18n = useI18n();
  const profiler = useProfiler();
  const { isRTL } = useLocaleContext();
  const searchBoxProps = useSearchbox();
  const { trackFlyAnywhereMdot } = useTrackFlyAnywhere();

  useEffect(() => {
    trackExperimentStage("flights_web_mdot_sr_full_sb", 1);
    trackExperimentStage("flights_web_mdot_sr_full_sb", profiler.isDirect() ? 3 : 4);
  }, [profiler]);
  return (
    <>
      {!!trackExperiment("flights_web_mdot_sr_full_sb") ? (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              backgroundColor: "var(--bui_color_brand_primary_background_dynamic)",
              height: "34px"
            }}
          />
          <Container>
            <SearchboxController
              i18n={i18n}
              {...searchBoxProps}
              isRTL={isRTL}
              trackFlyAnywhereMdot={() => trackFlyAnywhereMdot()}
              onSearch={(searchParams) => {
                trackCustomGoal("flights_web_mdot_sr_full_sb", 3);
                searchBoxProps.onSearch({ ...searchParams, ...flyAnywhere });
              }}
            >
              {() => <SearchBoxVerticalCollapsible />}
            </SearchboxController>
          </Container>
        </div>
      ) : (
        <SearchOverview />
      )}
      {hideToolbar ? null : <SearchToolbar />}
    </>
  );
};
export default SearchHeader;
