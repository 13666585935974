/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useEffect, useMemo } from "react";
import { Card, Icon, Image, Stack, Text, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import GeniusBadge from "@bookingcom/bui-assets-react/genius/GeniusBadge";
import { useI18n } from "@bookingcom/lingojs-react";
import { useStore } from "../../../store";
import useRouteName from "hooks/useRouteName";
import { percentageDiscount } from "../../utils";
import useUserAgent from "hooks/useUserAgent";
import { trackExperimentStage } from "utils/et";
import useLoader from "../../hooks/useLoader";
import styles from "./GeniusBanner.module.css";

const GeniusBanner: FC = () => {
  const store = useStore();
  const i18n = useI18n();
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const loader = useLoader("genius");

  const { order } = store.order;
  const { crossSell, isLoading } = store.crossSell;
  const cityName =
    crossSell?.searchParams?.destination?.cityName ||
    order?.airOrder?.flightSegments?.[0]?.arrivalAirport?.cityName ||
    undefined;
  const passengerFirstName = order?.passengers?.[0].firstName || "";
  const { userInfo } = store.user;
  const geniusLevel = userInfo?.geniusLevel;

  const showIllustration = routeName !== "checkout-ticket-type" && routeName !== "checkout3";

  const isPostBooking =
    routeName === "confirmation" ||
    routeName === "pb-order-details" ||
    routeName === "pb-flight-details" ||
    routeName === "pb-checkout-confirmation";

  /* istanbul ignore next */
  const bannerHeader: string = useMemo(() => {
    if (!isPostBooking && !userInfo) {
      return i18n.trans(
        t("flights_genius_deal_logout_banner_title", {
          variables: {
            num_percent: percentageDiscount(geniusLevel)
          },
          num_exception: 1
        })
      );
    }

    if (passengerFirstName && cityName) {
      return i18n.trans(
        t("flights_xsell_confirmation_genius_header_name", {
          variables: {
            user_name: passengerFirstName,
            num_percent: percentageDiscount(geniusLevel),
            city_name: cityName
          }
        })
      );
    }

    if (cityName && userInfo) {
      return i18n.trans(
        t("flights_confirmation_genius_header", {
          variables: {
            num_percent: percentageDiscount(geniusLevel),
            city_name: cityName
          }
        })
      );
    } else if (cityName) {
      return i18n.trans(
        t("flights_confirmation_genius_header_sign_in", {
          variables: {
            num_percent: percentageDiscount(geniusLevel),
            city_name: cityName
          }
        })
      );
    } else {
      return i18n.trans(
        t("flights_genius_deal_logout_banner_title", {
          variables: {
            num_percent: percentageDiscount(geniusLevel)
          },
          num_exception: 1
        })
      );
    }
  }, [cityName, isPostBooking, passengerFirstName, userInfo, i18n, geniusLevel]);

  /* istanbul ignore next */
  const bannerSubheader: string = useMemo(() => {
    if (!isPostBooking && !userInfo) {
      return i18n.trans(
        t("flights_genius_deal_logout_banner_body", {
          variables: {
            num_percent: percentageDiscount(geniusLevel)
          },
          num_exception: 1
        })
      );
    }

    if (cityName && userInfo) {
      return i18n.trans(t("flights_confirmation_genius_subheader"));
    } else if (cityName) {
      return i18n.trans(t("flights_confirmation_genius_subheader_sign_in"));
    } else if (userInfo) {
      return i18n.trans(
        t("trip_promo_genius_so_flights_funnel_subhead_signed_in", {
          variables: {
            num_percent: percentageDiscount(geniusLevel)
          }
        })
      );
    } else {
      return i18n.trans(
        t("flights_genius_deal_logout_banner_body", {
          variables: {
            num_percent: percentageDiscount(geniusLevel)
          }
        })
      );
    }
  }, [isPostBooking, cityName, userInfo, i18n, geniusLevel]);

  // flights_web_ddot_sticky_price_breakdown -- start
  // ================================================
  const isCheckoutPages = useMemo(() => {
    return !isMobile && routeName.startsWith("checkout");
  }, [routeName, isMobile]);

  useEffect(() => {
    if (isCheckoutPages) trackExperimentStage("flights_web_ddot_sticky_price_breakdown", 4);
  }, [isCheckoutPages]);
  // ================================================
  // flights_web_ddot_sticky_price_breakdown -- end

  if (isLoading) {
    return loader;
  }

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center" className={styles.wrapper}>
        <Stack.Item grow>
          <Stack gap={0} className={styles.genius} direction="row" alignItems="center">
            <Icon svg={GeniusBadge} size="medium" ariaLabel="Genius" />

            <Text tagName="span" color="neutral_alt" variant="small_1" className={styles.text}>
              {i18n.trans(t("flights_search_promotion_label_trip_savings"))}
            </Text>
          </Stack>
          <Title
            tagName="h2"
            variant="strong_1"
            title={bannerHeader}
            titleAttributes={{
              "data-testid": "banner-header"
            }}
            subtitle={bannerSubheader}
            subtitleAttributes={{
              "data-testid": "banner-subheader"
            }}
          />
        </Stack.Item>

        {showIllustration ? (
          <Image
            asset={{
              setName: "illustrations-traveller",
              assetName: "GeniusGiftBoxDiscount"
            }}
            className={styles.illustration}
            height="80px"
            width="120px"
            alt="Genius"
            attributes={{
              role: "presentation"
            }}
          />
        ) : null}
      </Stack>
    </Card>
  );
};

export default GeniusBanner;
