import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtils } from "..";

const etName = "flights_web_flexible_date_search_one_way_mdot";

const createCustomGoal = <T extends string>(name: T): `${typeof etName}_${T}` => `${etName}_${name}`;

const utils = createTrackingUtils({
  name: etName,
  stages: {
    search_results: 1, // All users on SR
    main: 2,
    direct: 3,
    meta: 4,
    solo: 5,
    "2_plus": 6,
    book_window_0_14: 7,
    book_window_15_plus: 8
  },
  goals: {
    flex_is_in_view: 1,
    click_on_item: 2
  },
  goalsWithValue: (
    [
      "sr_diff_percentage",
      "sr_diff_higher_count",
      "sr_diff_lower_count",
      "mismatch_sr_and_cache",
      "users_scrolls_carousel"
    ] as const
  ).map(createCustomGoal)
});

const { stages } = utils;

type Params = {
  travellersCount: number;
  bookingWindow: number | undefined;
};
export const trackMdotFlexStages = ({ travellersCount, bookingWindow }: Params) => {
  stages.main();

  if (isOfMetaOrigin()) {
    stages.meta();
  } else {
    stages.direct();
  }

  if (travellersCount > 1) {
    stages["2_plus"]();
  } else {
    stages.solo();
  }

  if (bookingWindow) {
    if (bookingWindow >= 0 && bookingWindow <= 14) {
      stages.book_window_0_14();
    }

    if (bookingWindow >= 15) {
      stages.book_window_15_plus();
    }
  }
};

export default utils;
