import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { CarouselDataResponse } from "cross-sell/types";

export enum ActionTypes {
  isLoading = "crossSell/isLoading",
  trackedAd = "crossSell/trackedAd",
  trackedAdLoading = "crossSell/trackedAdLoading",
  fetchSuccess = "crossSell/fetchSuccess"
}

export const actions = {
  isLoading: createAction((loading: boolean) => ({
    type: ActionTypes.isLoading,
    payload: { loading }
  })),
  trackedAd: createAction((trackedAd: boolean) => ({
    type: ActionTypes.trackedAd,
    payload: { trackedAd }
  })),
  trackedAdLoading: createAction((trackedAdLoading: boolean) => ({
    type: ActionTypes.trackedAdLoading,
    payload: { trackedAdLoading }
  })),

  fetchSuccess: createAction((crossSell: CarouselDataResponse) => ({
    type: ActionTypes.fetchSuccess,
    payload: { crossSell }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
