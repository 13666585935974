import useAirlineLogos from "hooks/useAirlineLogos";
import React, { useMemo } from "react";
import { UIFlightSegment } from "@flights/types/client";
import styles from "./FlightCardCarriersV2.module.css";

const FlightCardCarriersV2 = (props: { segment: UIFlightSegment; idx: number }) => {
  const { segment } = props;
  const { airlinesWithGridPosition } = useAirlineLogos(segment);
  const logos = useMemo(() => {
    const _logos = new Set<string>();
    airlinesWithGridPosition.forEach((p) => _logos.add(p.logo));
    return [..._logos].splice(0, 4);
  }, [airlinesWithGridPosition]);

  return (
    <div className={styles.wrapper}>
      <div className={logos.length > 1 ? styles.grid : undefined}>
        {logos.map((url) => (
          <div
            key={url}
            className={styles.logo}
            data-ui-logo-container="1"
            style={{ backgroundImage: `url("${url}")` }}
          />
        ))}
      </div>
    </div>
  );
};
export default FlightCardCarriersV2;
