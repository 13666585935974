export const EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK = "bb_flights_prompt_travel_purpose";
export const TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF =
  "trips_initiative_ddot_mdot_flight_to_abu_xsell_pb_tf_migration";
export const TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF =
  "trips_initiative_ddot_mdot_flight_to_abu_xsell_pb_connector_tf";
export const TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF =
  "trips_initiative_ddot_mdot_flight_to_abu_xsell_abu_usp_tf";

export const FLIGHTS_VOUCHERS_ALL_PLATFORMS_AA_STAGES = {
  POST_BOOKING_PAGE: 0,
  M_DOT: 1,
  IOS: 2, // For apps only
  ANDROID: 3,
  GUEST_USER: 4,
  CONFIRMATION_PAGE: 5,
  MANAGE_BOOKING_PAGE: 6,
  DESKTOP: 7
};

export const TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_STAGES = {
  POST_BOOKING_PAGE: 1,
  DESKTOP: 2,
  M_DOT: 3
};

export const TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_STAGES = {
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES: 1,
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES_DDOT: 2,
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES_MDOT: 3,
  FLIGHT_CONFIRMATION_PAGE_DDOT: 4,
  ORDER_DETAILS_PAGE_DDOT: 5,
  FLIGHT_CONFIRMATION_PAGE_MDOT: 6,
  FREE_CANCELLATION: 7,
  MEAL_PLAN: 8,
  DISTANCE_FROM_CENTRE: 9
};

export const TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_STAGES = {
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES: 1,
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES_DDOT: 2,
  FLIGHT_CONFIRMATION_AND_ORDER_DETAILS_PAGES_MDOT: 3,
  FLIGHT_CONFIRMATION_PAGE_DDOT: 4,
  FLIGHT_CONFIRMATION_PAGE_MDOT: 5,
  ORDER_DETAILS_PAGE_DDOT: 6,
  ORDER_DETAILS_PAGE_MDOT: 7
};

export const TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_STAGES = {
  ALL_USERS: 1,
  DDOT: 2,
  MDOT: 3
};

export const TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS = {
  CLICKED_CROSS_SELL_CARD: 1,
  CLICKED_CROSS_SELL_CTA: 2,
  VALID_RESPONSE_RECEIVED: 3,
  ERROR_OR_EMPTY_RESPONSE_RECEIVED: 4
};

export const TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF_GOALS = {
  RENDERED_CROSS_SELL_CONNECTOR: 1,
  CLICKED_CROSS_SELL_CONNECTOR: 2,
  CLICKED_CROSS_SELL_CONNECTOR_DISMISS: 3
};

export const TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_GOALS = {
  CLICKED_ON_LINK: 1,
  VALID_RESPONSE_RECEIVED: 2,
  EMPTY_OR_ERROR_RESPONSE_RECEIVED: 3
};

/**
 * Do you need to access the variant of the experiment on the client-side?
 * This is where you should pass it.
 */
export const CLIENT_SIDE_EXPERIMENTS = {
  frontend: [
    "flights_ace_web_fast_track_post_booking_purchase_flow",
    "flights_ace_web_fast_track",
    "flights_ace_web_cfar_target_markets",
    "flights_ace_web_cfar_test_markets",
    "flights_apex_web_checkout_flow_improvements",
    "flights_apex_web_bf_icons_blackout",
    "flights_apex_web_flex_selection_cta",
    "flights_apex_web_flex_ticket_recommendation_ancillary_page",
    "flights_apex_web_insurance_on_flight_details",
    "flights_apex_web_seatmap_amenities",
    "flights_apex_web_expand_branded_fares_on_search",
    "flights_pricing_route_all_mor_traffic_web",
    "flights_apex_web_ancillaries_micro_conversion_aa",
    "flights_apex_web_compact_branded_fares_for_mdot_sr",
    "flights_apex_web_bf_selection_cta_ddot",
    "flights_apex_web_bf_selection_cta_mdot",
    "flights_apex_web_flex_ticket_recommendation_v3",
    "flights_apex_web_move_branded_fare_price_to_top",
    "flights_apex_web_bf_recommendation",
    "flights_fare_calendar_v2",
    "flihgts_web_ddot_flight_details_modal_sticky_footer",
    "flights_web_ddot_side_sorting",
    "flights_web_checkout_cta_text",
    "flights_web_mdot_sr_hide_sorting",
    "webshell_mobile_web_header_like_app",
    "web_shell_header_logo_links",
    "flights_meta_landings_v2_mdot_aa",
    "flights_meta_landings_v2_desktop_aa",
    "flights_web_aa_checkout_traffic",
    EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK,
    "flights_web_tech_pax_components",
    "flights_web_mdot_aa_pages_traffic",
    "flights_web_ddot_aa_pages_traffic",
    "flights_web_tech_insurance_server_side_translation",
    "flights_web_bp_tech_insurance_server_side_translation",
    "mp_flights_insurance_mvp",
    "mp_flights_insurance_traffic_aa",
    "flights_web_ddot_load_more_sr",
    "flights_web_ddot_sticky_price_breakdown",
    "flights_web_mdot_trip_summary",
    "flights_web_ddot_checkout_pages_main_cta_copy",
    "flights_web_flexible_date_search",
    "flights_web_mdot_sr_full_sb",
    "flights_web_ddot_checkout_header_with_price",
    "flights_web_ddot_sb_multi_select_chip",
    "flights_web_mdot_sb_multi_select_chip",
    "flights_web_ddot_condensed_one_way_search_results",
    "flights_web_filters_budget",
    "flights_web_flight_mismatch_cabin_class",
    "flights_web_solo_total_price_subtitle_copy",
    "flights_web_multi_total_price_subtitle_copy",
    "flights_web_aa_virtual_interlining_tracking",
    "flights_web_filters_budget_per_adult",
    "flights_web_ddot_last_available_seats_v3",
    "flights_web_fd_load_ancillaries_from_search_direct_desktop",
    "flights_web_fd_load_ancillaries_from_search_direct_mdot",
    "flights_web_fd_select_baggage_desktop",
    "flights_web_fd_select_baggage_mdot",
    "flights_web_apex_ancillaries_aa",
    "flights_web_legal_linked_travel_arrangement",
    "flights_web_inbound_outbound_sr",
    "flights_unknown_inactive_locations_supply_redirect",
    "flights_web_price_per_person_4",
    "flights_web_traveller_cpf_field",
    "flights_web_traveller_docs_aa",
    "flights_web_cs_usp",
    "flights_web_cs_usp_index",
    "flights_web_sr_full_luggage_data",
    "flight_web_refresh_modal_background_v2",
    "flights_web_aa_bookers_vs_explorers",
    "flights_web_round_price_search_results_desktop",
    "flights_web_round_price_search_results_mdot",
    "flights_new_price_change_flow_aa_web",
    "flights_new_price_change_flow_web",
    "flights_alternative_fares_for_sold_out_errors_aa_web",
    "flights_web_search_header_ui_update_mdot",
    "flights_web_airlines_filter_price",
    "flights_web_funnel_grouped_pax_v1_1",
    "flights_web_condensed_pax_multi_v1_2_desktop",
    "flights_web_condensed_pax_solo_v1_2_desktop",
    "flight_web_sr_traffic_aa_desktop",
    "flight_web_sr_traffic_aa_mdot",
    "flights_web_ddot_pax_with_baggages",
    "flights_web_mdot_pax_with_baggages",
    "flights_web_ddot_sidebar_blackout",
    "flights_web_fd_move_co2_desktop",
    "flights_web_fd_replace_skeleton_with_spinner_desktop",
    "flights_web_funnel_baggages_v2",
    "flights_web_funnel_baggage_v2_checkout",
    "flights_web_funnel_baggage_v2_checkout_extras",
    "flights_web_contextualized_sr_loading_desktop",
    "flights_web_contextualized_sr_loading_mdot",
    "flights_web_update_checkout_title_desktop",
    "flights_meta_web_landing_in_checkout",
    "flights_web_share_flight_link",
    "flights_web_share_flight_landing_aa",
    "flight_web_fd_traffic_aa_desktop",
    "flights_pb_userid_aa",
    "flight_web_fd_traffic_aa_mdot",
    "flights_web_aa_partially_included_baggage_tracking",
    "flights_web_remote_component_seo_interlinking",
    "flights_web_cat_banner_campaign_index",
    "flights_web_ddot_recent_search_with_filters",
    "flights_web_sr_card_itinerary_redesign_mdot",
    "flights_web_aa_fd_not_available_error",
    "flights_web_meta_host_cookie_redirect",
    "flights_web_pilot_q1_blackout",
    "flights_web_mdot_home_recent_search_carousel",
    "flights_acq_fe_mercator_airports_web",
    "flights_web_ddot_sb_bg_color",
    "flights_web_copy_best_new_word",
    "flights_web_one_way_combination_fd",
    "flights_web_roundprice_sr_fd_direct_desktop",
    "flights_web_desktop_sb_combine_travellers_cabin_class",
    "flights_web_cat_price_loading_fd",
    "flights_cat_meta_price_discrepancy_aa",
    "flights_cat_direct_sr_fd_price_discrepancy_aa",
    "flights_apex_web_bf_price_display",
    "flights_web_blackout_sorting_mdot",
    "flights_web_ddot_side_sorting_2",
    "flights_web_stops_filter_checkbox",
    "flights_ace_web_extras_page_optimisation",
    "flights_web_flexible_date_search_desktop_v2",
    "flights_web_lp_cat_covid_banner_removal",
    "flights_web_human_right_banner",
    "flights_web_pb_test_utils_v2",
    "flights_web_cat_apple_rc_tech",
    "flights_web_flexible_date_search_one_way_mdot",
    "flights_web_swap_price_baggage_sr_desktop",
    "flights_web_cat_homepage_header_update_www",
    "lxw_flights_city_to_city_redirects",
    "flights_web_filter_format_time",
    "flights_apexapps_web_restrict_mobiletravelplan",
    "flights_web_flight_time_filter",
    "flights_web_sr_filter_top_filters_checkbox",
    "flights_web_swap_price_baggage_sr_desktop_2",
    "flights_web_surface_pax_solo_traveller_mdot",
    "flights_web_cat_genius_launch_sheet_rc_tech",
    "flights_web_cta_action_reinforce_sr_desktop_v2",
    "flights_web_cta_action_reinforce_sr_mdot_v2",
    "flights_web_direct_to_nonstop_en_us",
    "flights_web_cat_not_found_page",
    "flights_web_airlines_filter_select_deselect_desktop",
    "flights_web_cat_genius_airlines_campaign_index",
    "flights_web_flights_fd_close_visual_update",
    "flights_web_cat_pin_flight_www",
    "flights_web_cat_pin_flight_mdot",
    "flights_web_seat_selection_card_update_mdot",
    "flights_web_sr_card_itinerary_redesign_desktop",
    "flights_web_cat_aa_home_page_traffic_client",
    "gte_index_allweb_sign_in_banner_x_vertical",
    "flights_web_seat_selection_card_update_desktop",
    "flights_web_cat_fly_anywhere_www",
    "flights_web_cat_fly_anywhere_mdot",
    "flights_us_mor_web",
    "flights_web_price_format_user_locale",
    "d_xm_flights_conf_survey",
    "m_xm_flights_conf_survey",
    "flights_acq_be_translation_web",
    "flights_web_blackout_vi_alert_sr",
    "flights_web_cat_theme_based_destinations_www",
    "flights_web_cat_theme_based_destinations_mdot",
    "flights_price_change_loading_text",
    "flights_web_route_happy_amenities_mdot",
    "flights_web_route_happy_amenities_desktop",
    "flights_web_cat_apple_rc_tech_index",
    "flights_web_seat_selection_card_update_mdot_v2",
    "flights_web_fare_calendar_desktop_v2",
    "flights_web_fare_calendar_mdot_v2"
  ] as const,
  soylentEmailAddress: [
    "flights_web_pb_rebook_price_review_m2",
    "flights_web_pb_rebook_price_review_m3",
    "flights_web_pb_cant_find_booking",
    "flights_web_pb_optimise_help_centre_landing_experience",
    "flights_web_pb_get_the_app_bd_ddot",
    "flights_web_pb_get_the_app_bd_mdot",
    "flights_web_pb_get_the_app_conf_ddot",
    "flights_web_pb_get_the_app_conf_mdot",
    "flights_web_pb_checkin_page_m4"
  ] as const,
  // @deprecated. Please use etV2 tracking library and put your experiments in soylentEmailAddress array instead of pbSoylentExperiments
  // More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
  pbSoylentExperiments: [
    //Do not remove App version < 44 for Android and App version < 44.2 for ios still use track experiment ,
    //And need it in header to display refund information
    "flights_pb_universal_refunds_web_apps_emails_v2",
    "flights_cross_sell_universal_voucher_campaign_q4",
    "flights_web_cross_sell_nearby_destinations",
    "trips_initiative_ddot_mdot_flight_to_abu_xsell_abu_usp_tf",
    TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
    TRIPS_INITIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_CONNECTOR_TF
  ] as const
};
