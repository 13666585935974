/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t, Trans } from "@bookingcom/lingojs-react";
import useGlobalContext from "../../../hooks/useGlobalContext";
import useCreateUrl from "../../../hooks/useCreateUrl";
import { COVID_INFORMATION_PAGE_URL } from "../../../constants";
import { trackCustomGoal } from "../../../utils/et";
import styles from "./TravelAlert.module.css";

const TravelAlert: React.FunctionComponent = () => {
  const i18n = useI18n() as I18nChildContext;
  const { features, locale } = useGlobalContext();
  const { createUrl } = useCreateUrl();
  const showAlert = features?.FLIGHTS_TRAVEL_ALERT;

  return showAlert ? (
    <div>
      <div className={styles.travelAlert}>
        <Trans
          tag="flights_covid_banner_v1"
          variables={{
            start_link: "<0>",
            end_link: "</0>"
          }}
          components={[
            <a
              href={createUrl(`${COVID_INFORMATION_PAGE_URL}?lang=${locale}`)}
              target="_blank"
              aria-label={i18n.trans(t("flights_accessibility_covid_learn_more"))}
              rel="noopener noreferrer"
              className={styles.travelAlertLink}
              key={0}
              onClick={() => {
                trackCustomGoal("flights_web_index_covid_banner_alignment", 1);
              }}
            />
          ]}
        />
      </div>
    </div>
  ) : null;
};
export default TravelAlert;
