import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useRemoteComponent } from "@bookingcom/remote-component";
import { trackExperiment, trackExperimentStage, trackGoalWithValue } from "utils/et";
import useEvents from "hooks/useEvents";
import useUserAgent from "hooks/useUserAgent";
import { getUserInfo } from "store/user/selectors";
import useFakeRemoteComponent from "./useFakeRemoteComponent";

const GeniusLaunchSheet = () => {
  const startTime = useMemo(() => performance.now(), []);

  const { isMobile } = useUserAgent();
  const { grumble } = useEvents();
  const userInfo = useSelector(getUserInfo);

  const isGeniusLaunchSheetExp = !!trackExperiment("flights_web_cat_genius_launch_sheet_rc_tech");
  const useRC = isGeniusLaunchSheetExp ? useRemoteComponent : useFakeRemoteComponent;

  const { Component, isLoading, error } = useRC({
    project: "web-shell",
    service: "header-mfe",
    path: "/LaunchSheetFlightsIndex",
    type: "capla"
  });

  useEffect(() => {
    trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 1);

    if (isMobile) {
      trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 3);
    } else {
      trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 2);
    }

    if (userInfo?.geniusLevel === 1) {
      trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 4);
    } else if (userInfo?.geniusLevel === 2) {
      trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 5);
    } else if (userInfo?.geniusLevel === 3) {
      trackExperimentStage("flights_web_cat_genius_launch_sheet_rc_tech", 6);
    }
  }, [isMobile, userInfo]);

  useEffect(() => {
    if (!isLoading && !error) {
      const endTime = performance.now();
      trackGoalWithValue("flights_web_cat_genius_launch_sheet_rc_tech_load_time", Math.round(endTime - startTime));
    }

    if (!isLoading && error && !isEmpty(error)) {
      grumble("RC error: GeniusLaunchSheet", { error: error.toString() });
    }
  }, [startTime, isLoading, error, grumble]);

  return <Component />;
};

export default GeniusLaunchSheet;
