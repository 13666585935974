/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { Suspense } from "react";

import { BUIProvider } from "@bookingcom/bui-react";
import travellerTheme from "@bookingcom/bui-react/themes/traveller_ex";
import ErrorReporter from "@bookingcom/react-error-reporter";
import { FlightsErrorReporter } from "../utils/report-error";

import AriaLive from "../components/elements/AriaLive";
import FeedbackButton from "../components/elements/FeedbackButton";
import GenericErrorReport from "../components/elements/GenericErrorReport";
import Trackers from "../components/elements/Trackers";

import useGlobalContext from "../hooks/useGlobalContext";
import useRouteChangeTracking from "../hooks/useRouteChangeTracking";
import usePageviewIsNotABounce from "../hooks/usePageviewIsNotABounce";
import useUserAgent from "../hooks/useUserAgent";

import isRTL from "../utils/isRTL";
import { setMarketingTrackingVariables } from "../utils/marketing-url-params";
import Layout from "./Layout";
import useC360Tracker from "hooks/useC360Tracker";
import { RemoteHeader } from "components/remote";
import usePersistSelectedExtraProducts from "../hooks/usePersistSelectedExtraProducts";
import useWebShellRouteChange from "hooks/useWebShellRouteChange";
import NoSSR from "./NoSSR";

const InternalTools = React.lazy(
  () => import(/* webpackChunkName: "internal-tools" */ "../components/elements/InternalTools")
);

const App: React.FunctionComponent = function () {
  const { isMobile } = useUserAgent();
  const { isInternal, marketingTrackingVariables, lang } = useGlobalContext();
  const jsErrorReporter = FlightsErrorReporter.getInstance().errorReporter;

  //send route changes for marketing tracking
  useRouteChangeTracking();

  //send route changes for web performance tracking
  useWebShellRouteChange();

  //pageview is not a bounce ET goals
  usePageviewIsNotABounce();

  //store marketing params in session store
  setMarketingTrackingVariables(marketingTrackingVariables);

  useC360Tracker();

  usePersistSelectedExtraProducts();

  return (
    <BUIProvider
      theme={travellerTheme}
      defaultViewportSize={isMobile ? "small" : "large"}
      defaultRTL={!!(lang && isRTL(lang))}
    >
      <ErrorReporter errorDisplayComponent={GenericErrorReport} reporter={jsErrorReporter}>
        <RemoteHeader />
        <Layout />
        <Trackers />
        <FeedbackButton />
        <AriaLive />
        {/* {isWebview && <ScrollToTopOnNavigation />} */}
      </ErrorReporter>
      <NoSSR>
        {isInternal && (
          <Suspense fallback={null}>
            <InternalTools />
          </Suspense>
        )}
      </NoSSR>
    </BUIProvider>
  );
};

export { App as default };
