import { useCallback } from "react";
import { CABIN_CLASS_MAP } from "../constants";
import { useI18n } from "@bookingcom/lingojs-react";
import capitalize from "lodash/capitalize";

export default function useCabinClassName() {
  const i18n = useI18n();

  const getCabinClassName = useCallback(
    (cabinClass: string = ""): string => {
      const mappedClassName = CABIN_CLASS_MAP[cabinClass];
      /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
      // TODO : make sure alll cabin classes are covered with copy tags
      return !!mappedClassName ? i18n.trans(mappedClassName) : capitalize(cabinClass);
    },
    [i18n]
  );

  return getCabinClassName;
}
