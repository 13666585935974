import { UIIncludedProductBySegmentCompressed } from "@flights/types/client";
import intersectionBy from "lodash/intersectionBy";

export function getProductIconsIncludedInAllSegments(includedProducts: UIIncludedProductBySegmentCompressed) {
  return intersectionBy(...includedProducts.segments, "luggageType");
}

export function getProductsIncludedInAllSegments(includedProducts: UIIncludedProductBySegmentCompressed) {
  return intersectionBy(...includedProducts.segments, (product) => product.luggageType + product.maxWeightPerPiece);
}
