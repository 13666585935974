import React, { useMemo } from "react";
import BaggageIcon from "./BaggageIcon";
import { Stack, StackProps, Text, TextProps } from "@bookingcom/bui-react";
import { IconProps } from "@bookingcom/bui-react";

export type BaggageProps = {
  description?: string | string[];
  direction?: StackProps["direction"];
  notAvailable?: boolean;
  status?: string;
  title: string;
  type: "personalItem" | "cabin" | "checkedIn";
  isConstructive?: boolean;
  size?: "medium" | "small";
};

export function Baggage(props: BaggageProps) {
  const { title, description, isConstructive, notAvailable, type, status, direction = "row", size = "medium" } = props;

  const { color, iconSize, textVariant, textColor, iconGap } = useMemo(() => {
    const color: IconProps["color"] = notAvailable ? "neutral_alt" : "neutral";
    const iconSize: IconProps["size"] = size === "medium" ? "large" : "medium";
    const textVariant: TextProps["variant"] = size === "medium" ? "body_2" : "small_1";
    const textColor: TextProps["color"] = isConstructive ? "constructive" : color;
    const iconGap: StackProps["gap"] = size === "medium" ? 4 : 2;
    return { color, iconSize, textVariant, textColor, iconGap };
  }, [size, notAvailable, isConstructive]);

  return (
    <Stack direction="row" gap={iconGap}>
      <Stack.Item>
        <BaggageIcon size={iconSize} color={color} type={type} notAvailable={notAvailable} />
      </Stack.Item>
      <Stack.Item grow>
        <Stack gap={direction === "row" ? 2 : 0} direction={direction} tagName="p">
          <Stack.Item tagName="span">
            <Stack gap={0} tagName="span">
              <Stack.Item tagName="span">
                <Text variant={textVariant} tagName="span" color={color}>
                  {title}
                </Text>
              </Stack.Item>
              {Array.isArray(description) ? (
                description?.map((line) => (
                  <Stack.Item key={line} tagName="span">
                    <Text variant={textVariant} tagName="span" color={color}>
                      {line}
                    </Text>
                  </Stack.Item>
                ))
              ) : description ? (
                <Stack.Item tagName="span">
                  <Text variant={textVariant} tagName="span" color={color}>
                    {description}
                  </Text>
                </Stack.Item>
              ) : undefined}
            </Stack>
          </Stack.Item>
          {status ? (
            <Stack.Item
              grow
              tagName="span"
              attributes={{ style: { textAlign: direction === "row" ? "end" : "start" } }}
            >
              <Text variant={textVariant} tagName="span" color={textColor}>
                {status}
              </Text>
            </Stack.Item>
          ) : undefined}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
