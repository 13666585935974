import currencyDefaultLocales from "./default-locales.min.json";
import currencyLocales from "./locales.json";
import currencySymbolsMinimal from "./symbols.min.json";

// Helper Functions
const toFixed = (n: number, precision: number) =>
  (+(Math.round(+(n + "e" + precision)) + "e" + -precision)).toFixed(precision);

export const getCurrencySymbol = (currency: string) => {
  if (
    typeof window !== "undefined" &&
    window.__GLOBAL_CONTEXT__ &&
    window.__GLOBAL_CONTEXT__.ipCountry !== "us" &&
    currency === "USD"
  ) {
    return "US$";
  }
  return currencySymbolsMinimal[currency] ?? currency;
};

// encodePattern Function - returns a few simple characteristics of the pattern provided
const encodePattern = (pattern: string) => {
  let decimalPlaces = 0;
  let frontPadding = "";
  let backPadding = "";
  const groupLengths: Array<number> = [];

  let patternStarted = false;
  let decimalsStarted = false;
  let patternEnded = false;

  let currentGroupLength = 0;
  let zeroLength = 0;

  for (var i = 0; i < pattern.length; ++i) {
    var c = pattern[i];

    if (!patternStarted && ["#", "0", ",", "."].includes(c)) {
      patternStarted = true;
    }

    if (!patternStarted) {
      frontPadding += c;
    }

    switch (c) {
      case "#":
        ++currentGroupLength;
        break;

      case "0":
        if (decimalsStarted) {
          ++decimalPlaces;
        } else {
          ++currentGroupLength;
          ++zeroLength;
        }
        break;

      case ",":
        groupLengths.push(currentGroupLength);
        currentGroupLength = 0;
        break;

      case ".":
        groupLengths.push(currentGroupLength);
        decimalsStarted = true;
        break;
    }

    if (patternStarted && !["#", "0", ",", "."].includes(c)) {
      patternEnded = true;

      if (!decimalsStarted) {
        groupLengths.push(currentGroupLength);
      }
    }

    if (patternEnded) {
      backPadding += c;
    }
  }

  return {
    decimalPlaces,
    frontPadding,
    backPadding,
    groupLengths,
    zeroLength
  };
};

// Format function
const format = (
  n: number,
  f: {
    symbol: string;
    decimal: string;
    group: string;
    decimalPlaces: number;
    frontPadding: string;
    backPadding: string;
    groupLengths: number[];
    zeroLength: number;
  }
) => {
  const splitNumber = toFixed(Math.abs(n), f.decimalPlaces).split(".");
  let segment = "";
  let cursor = splitNumber[0].length;
  let groupIndex = f.groupLengths.length - 1;

  // i.e. we actually have some sort of grouping in the values
  if (f.groupLengths.length > 1) {
    while (cursor > 0) {
      if (groupIndex <= 0) {
        groupIndex = 1;
      } // Always reset to the first group length if the number is big

      var currentGroupLength = f.groupLengths[groupIndex];

      var start = cursor - currentGroupLength;

      segment = splitNumber[0].substring(start, cursor) + f.group + segment;

      cursor -= currentGroupLength;

      --groupIndex;
    }
  }
  segment = segment.substring(0, segment.length - 1);

  segment = segment.length < f.zeroLength ? new Array(f.zeroLength - segment.length + 1).join("0") + segment : segment;

  var formattedNumber = f.frontPadding + segment + (splitNumber[1] ? f.decimal + splitNumber[1] : "") + f.backPadding;

  return formattedNumber.replace("!", f.symbol);
};

// Currency localizer inspired by https://github.com/xDae/react-currency-formatter
// with changes for "lv" where the format was wrong
const getFormatter = (options: FormattingOptions = { currency: "EUR" }) => {
  const currency = options.currency.toUpperCase();

  let locale = options.locale ? currencyLocales[options.locale] : currencyLocales[currencyDefaultLocales[currency]];

  if (locale && locale.h) {
    locale = currencyLocales[locale.h]; // Locale inheritance
  }

  if (!locale) {
    locale = currencyLocales["en"]; // Yes, it's "en", not "en-gb" (see locales.json)
  }

  let symbol = options.symbol || getCurrencySymbol(currency);

  if (!symbol) {
    symbol = currency; // In case we don't have the symbol, just use the ccy code
  }

  const pattern = options.pattern || locale.p;
  const decimal = options.decimal || locale.d;
  const group = options.group || locale.g;

  // Use encode function to work out pattern
  var patternArray = pattern.split(";");
  const positiveFormat = { ...encodePattern(patternArray[0]), symbol, decimal, group };

  const negativeFormat = {
    ...(patternArray[1] ? encodePattern(patternArray[1]) : encodePattern("-" + patternArray[0])),
    symbol,
    decimal,
    group
  };
  const decimalPlaces = options.decimalPlaces;

  if (decimalPlaces || decimalPlaces === 0) {
    positiveFormat.decimalPlaces = decimalPlaces;
    negativeFormat.decimalPlaces = decimalPlaces;
  }

  const zero = patternArray[2] || format(0, positiveFormat);

  return (n: number) => {
    let formattedNumber;
    if (n > 0) {
      formattedNumber = format(n, positiveFormat);
    } else if (n == 0) {
      formattedNumber = zero.replace("!", symbol);
    } else {
      formattedNumber = format(n, negativeFormat);
    }
    return formattedNumber;
  };
};

type FormattingOptions = {
  currency: string;
  locale?: string;
  pattern?: string;
  decimal?: string;
  group?: string;
  symbol?: string;
  decimalPlaces?: number;
};

export const localizer = (number: number, options: FormattingOptions) => getFormatter(options)(number);
