import React, { memo, useEffect, useRef } from "react";
import FareCardDesktop from "../../FlightCard/components/FareCard.desktop";
import { t } from "@bookingcom/lingojs-core";
import { Carousel, Divider, Grid } from "@bookingcom/bui-react";
import { UIFlightData } from "@flights/types/client";
import { SkeletonFlightCard } from "../../Skeleton/SkeletonFlightCard";
import { useI18n } from "@bookingcom/lingojs-react";
import { convertFlightToFare } from "../utils";
import useMeasureBrandedFaresWaitTime from "../hooks/useMeasureBrandedFaresWaitTime";
import useBrandedFares from "../hooks/useBrandedFares";
import Frame from "components/elements/Frame";
import useTrackFirstFarePriceMismatch from "components/elements/FareSelector/hooks/useTrackFirstFarePriceMismatch";
import useEqualRowHeight from "hooks/useEqualRowHeight";
import useIsInViewport from "hooks/useIsInViewport";
import flights_apex_web_expand_branded_fares_on_search from "utils/experiments/apex/flights_apex_web_expand_branded_fares_on_search";

type Props = {
  flight: UIFlightData;
};

const _FareSelectorDesktop = ({ flight }: Props) => {
  const i18n = useI18n();
  const { fetchStatus, brandedFareOffers } = useBrandedFares(flight.token);
  const hasBrandedFareOffers = !!brandedFareOffers && brandedFareOffers?.length > 0;
  const containerRef = useRef<HTMLDivElement>(null);

  useEqualRowHeight(containerRef, "data-fare-card-row", [brandedFareOffers, fetchStatus]);
  useMeasureBrandedFaresWaitTime(fetchStatus, hasBrandedFareOffers);
  useTrackFirstFarePriceMismatch(flight, brandedFareOffers);

  /* start - flights_apex_web_expand_branded_fares_on_search */
  const isInViewport = useIsInViewport(containerRef.current, [fetchStatus, brandedFareOffers], { threshold: 0.2 });
  const viewLoaderStartTime = useRef<number>(-1);
  const loadingInViewport = isInViewport && fetchStatus === "loading";

  useEffect(() => {
    const timerRunning = viewLoaderStartTime.current !== -1;

    if (loadingInViewport && !timerRunning) viewLoaderStartTime.current = performance.now();
    if (!loadingInViewport && timerRunning) {
      const time = performance.now() - viewLoaderStartTime.current;
      flights_apex_web_expand_branded_fares_on_search.goalsWithValue.branded_fares_on_sr_loader_visible_ms(time);
      viewLoaderStartTime.current = -1;
    }
  }, [loadingInViewport]);

  // Make sure we also measure on unmount
  useEffect(
    () => () => {
      if (viewLoaderStartTime.current !== -1) {
        const time = performance.now() - viewLoaderStartTime.current;
        flights_apex_web_expand_branded_fares_on_search.goalsWithValue.branded_fares_on_sr_loader_visible_ms(time);
      }
    },
    []
  );
  /* end - flights_apex_web_expand_branded_fares_on_search */

  return (
    <div ref={containerRef} id="flights-fare-selector">
      <Divider />
      <Frame direction="row" m={4} attributes={{ style: { maxWidth: "682px" } }}>
        {hasBrandedFareOffers ? (
          <div style={{ width: "100%" }}>
            <Carousel
              size="larger"
              nextButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_next_button_aria_label"))}
              previousButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_previous_button_aria_label"))}
              onAfterNavigate={() => {
                flights_apex_web_expand_branded_fares_on_search.goals.scroll_fares();
              }}
            >
              {brandedFareOffers.map((brandedFareOffer, i) => (
                <FareCardDesktop
                  key={brandedFareOffer.token}
                  baseOffer={flight}
                  fareOffer={brandedFareOffer}
                  index={i}
                  numberOfOffers={brandedFareOffers.length}
                  ancillaries={flight.ancillaries}
                />
              ))}
            </Carousel>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <Grid>
              <Grid.Column size={4}>
                <FareCardDesktop
                  fareOffer={convertFlightToFare(flight, i18n)}
                  baseOffer={flight}
                  loadingStatus={fetchStatus}
                />
              </Grid.Column>

              {fetchStatus === "loading" && (
                <Grid.Column size={4}>
                  <SkeletonFlightCard />
                </Grid.Column>
              )}
            </Grid>
          </div>
        )}
      </Frame>
    </div>
  );
};

export const FareSelectorDesktop = memo(_FareSelectorDesktop);
