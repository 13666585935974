import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtilsV2 } from "../index";

const utils = createTrackingUtilsV2({
  name: "flights_apex_web_flex_selection_cta",
  stages: {
    ticket_type_page: 1,
    desktop: 2,
    mdot: 3,
    meta: 4,
    cfar_shown: 5,
    cfar_visible: 6,
    flex_cfar_sr: 7
  },
  goals: {
    click_price_info: 1,
    back_from_pax: 2,
    try_click_card_ddot: 3
  },
  goalsWithValue: []
});

const trackStages = (isMobile: boolean, isCfarShown: boolean, isCfarInView: boolean) => {
  utils.stages.ticket_type_page();
  isMobile ? utils.stages.mdot() : utils.stages.desktop();
  if (isOfMetaOrigin()) utils.stages.meta();
  if (isCfarShown) utils.stages.cfar_shown();
  if (isCfarInView) utils.stages.cfar_visible();
};

export default { ...utils, trackStages };
