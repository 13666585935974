import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../store";
import useEventTracking from "hooks/useEventTracking";
import useGlobalContext from "hooks/useGlobalContext";
import useTrackerContext from "hooks/useTrackerContext";
import useClientMetrics from "hooks/useClientMetrics";
import { trackGoal } from "utils/et";
import { transformToc360CData } from "../utils";
import { UIXComponentType } from "cross-sell/types";

export const useEventsTracking = (isInViewport: boolean, componentType: UIXComponentType) => {
  const [trackedViewport, setTrackedViewport] = useState(false);

  const store = useStore();
  const { order } = store.order;
  const { isLoading, crossSell: carouselData } = store.crossSell;
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("xsell", requestId);
  const { trackExternal } = useTrackerContext();
  const { trackClientMetric } = useClientMetrics();

  const recResultsId = useMemo(
    () => carouselData?.accommodations?.map((accommodation) => accommodation.recResultsId),
    [carouselData?.accommodations]
  );

  useEffect(() => {
    if (!isLoading && carouselData?.accommodations.length) {
      trackGoal("flights_web_xsell_render");

      /* istanbul ignore next */
      if (recResultsId) {
        trackV2("xsell_rendered", {
          rec_results_id: recResultsId,
          orderid: order?.orderId || "",
          num_rec: carouselData?.accommodations.length,
          request_id: carouselData?.meta.requestId || "",
          componentType: componentType,
          soylentId: order?.soylentEmail || ""
        });
        trackClientMetric("xsell_rendered");
      }
    }
  }, [
    carouselData?.accommodations.length,
    carouselData?.meta.requestId,
    order?.orderId,
    recResultsId,
    trackV2,
    isLoading,
    order?.soylentEmail,
    componentType,
    trackClientMetric
  ]);

  useEffect(() => {
    /* istanbul ignore next */
    if (!isLoading && isInViewport) {
      if (carouselData?.accommodations) {
        trackExternal({
          type: "c360",
          args: [transformToc360CData({ type: "viewed", accommodations: carouselData.accommodations })]
        });
      }

      if (carouselData?.accommodations.length) {
        if (!trackedViewport) {
          trackGoal("flights_web_xsell_view");
          setTrackedViewport(true);
        }

        if (recResultsId) {
          trackV2("xsell_view", {
            rec_results_id: recResultsId,
            orderid: order?.orderId || "",
            num_rec: carouselData?.accommodations?.length,
            request_id: carouselData?.meta.requestId || "",
            componentType: componentType,
            soylentId: order?.soylentEmail || ""
          });
          trackClientMetric("xsell_view");
        }
      }
    }
  }, [
    carouselData,
    carouselData?.meta.requestId,
    isInViewport,
    order?.orderId,
    recResultsId,
    requestId,
    trackExternal,
    trackV2,
    isLoading,
    componentType,
    trackedViewport,
    order?.soylentEmail,
    trackClientMetric
  ]);
};
