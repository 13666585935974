import Debug from "debug";
import ExperimentEngine from "@bookingcom/experiment-tracking-clientside";
import { ExperimentEngineInterface } from "@bookingcom/experiment-tracking-clientside/dist/src/ExperimentEngine";

import * as watchEt from "./watch-et";
import clientFetch from "./client-fetch";

const debug = Debug("utils:etV2");

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
// todo: @mruseev remove this after debugging
// temporary duplicated from useEvents hook in order to debug new tracking library in non react function
const logGrumble = (message: string, payload: any) =>
  clientFetch("/events/grumble", {
    method: "post",
    body: JSON.stringify({
      message,
      payload
    }),
    headers: {
      "Content-Type": "application/json",
      "X-Booking-Referrer": location.href || ""
    }
  });

const etV2Fallback: ExperimentEngineInterface & { sinkData: () => void } = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track: (hashedTagname: string) => {
    return 0;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stage: (hashedTagname: string, id: number) => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customGoal(hashedTagname: string, goal: number) {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goal(goalName: string) {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goalWithValue(goalName: string, value: number): boolean {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateStateBlobAndVariants(clientsidePayload: string) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sinkData() {}
};

export const etV2 = (function () {
  if (typeof window !== "undefined") {
    try {
      const experimentEngineInstance = ExperimentEngine.getInstance({
        // Library implements debounce and sinks batches of tracking data.
        sinkUrl: `${window?.__GLOBAL_CONTEXT__?.apiPrefix || ""}/track/sink`,
        clientsidePayload: (typeof window !== "undefined" && window?.__GLOBAL_CONTEXT__?.clientsidePayload) || "",
        sinkTimeoutMs: 500
      });

      return {
        track: (hashedTagname: string) => {
          debug("Tracking experiment", hashedTagname);

          watchEt.dispatchName(hashedTagname);
          return experimentEngineInstance.track(hashedTagname);
        },
        stage: (hashedTagname: string, id: number) => {
          debug("Tracking stage", id, "for experiment", hashedTagname);
          watchEt.log([
            {
              type: "stage",
              name: hashedTagname,
              value: id
            }
          ]);

          return experimentEngineInstance.stage(hashedTagname, id);
        },
        customGoal: (hashedTagname: string, goal: number) => {
          debug("Tracking custom goal", goal, "for experiment", hashedTagname);
          watchEt.log([
            {
              type: "customGoal",
              name: hashedTagname,
              value: goal
            }
          ]);

          return experimentEngineInstance.customGoal(hashedTagname, goal);
        },
        goal: (goalName: string) => {
          debug("Tracking goal", goalName);
          watchEt.log([
            {
              type: "goal",
              name: goalName
            }
          ]);

          return experimentEngineInstance.goal(goalName);
        },
        goalWithValue: (goalName: string, value: number) => {
          debug("Tracking goal with value", goalName, "with value", value);
          watchEt.log([
            {
              type: "goalWithValue",
              name: goalName,
              value
            }
          ]);

          return experimentEngineInstance.goalWithValue(goalName, value);
        },
        updateStateBlobAndVariants: (clientsidePayload: string) => {
          debug("Updating state blob and variants with clientside payload", clientsidePayload);

          return experimentEngineInstance.updateStateBlobAndVariants(clientsidePayload);
        },
        sinkData: () => {
          return experimentEngineInstance.sinkData();
        }
      };
    } catch (error) {
      void logGrumble("EtV2 threw an error while initialising experiment engine instance", {
        clientsidePayload: window.__GLOBAL_CONTEXT__.clientsidePayload,
        error
      });
    }
  }

  return etV2Fallback;
})();
