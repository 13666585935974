/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { Actionable, Text, Popover, SheetContainer, Icon, Button, Stack, Title } from "@bookingcom/bui-react";

import { useActions, useStore } from "../../../../store";
import { UIFlightData } from "@flights/types/client";
import { InfoSignIcon, QuestionMarkCircleIcon } from "@bookingcom/bui-assets-react/streamline";
import useFormatPrice from "hooks/useFormatPrice";
import PriceBreakdown from "../../PriceBreakdown";
import useUserAgent from "../../../../hooks/useUserAgent";
import usePriceBreakDownForPreOptedAncillaries from "hooks/usePriceBreakDownForPreOptedAncillaries";
import useLocaleContext from "../../../../hooks/useLocaleContext";
import useGlobalContext from "hooks/useGlobalContext";
import getLowestAdultTravellerPrice from "utils/lowest-adult-traveller-price";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import FlightAvailableSeats from "./FlightAvailableSeats";
import useEventTracking from "hooks/useEventTracking";
import useRouteName from "hooks/useRouteName";
import { isPriceWithLargeNumber } from "utils/price";
import Frame from "components/elements/Frame";
import styles from "./FlightCardPrice.module.css";
import { mcn } from "utils/mergeClassnames";
import { getFlexibleTicketFullPrice } from "../../CheckoutTicketTypeInner/ticketTypeUtils";
import { getExtOriginMarketingTrackingVariable } from "utils/marketing-url-params";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import flights_web_fd_select_baggage from "utils/experiments/funnel/flights_web_fd_select_baggage";

type Props = {
  flight: UIFlightData;
  breakdownOpen: boolean;
  isRenderedInModal?: boolean;
  shouldAlwaysShowBreakdown?: boolean;
  alignment?: "end" | "start";
  popoverDirection?: "bottom" | "top";
  popoverCloseAriaLabel: string;
  totalPriceLabel?: ReactNode;
  setBreakdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showTotalPriceIncludingAncillaries?: boolean;
  showPricePerPerson?: boolean;
  travellerCountInPricePerPersonCopy?: boolean;
  showAvailableSeats?: boolean;
  priceFrom?: boolean;
  hidePriceBreakDown?: boolean;
  isFlexTicket?: boolean;
};

const resetBodyOverflow = () => {
  document.body.style.overflow = "auto";
};

const FlightCardPrice = (props: Props) => {
  const {
    flight,
    breakdownOpen,
    setBreakdownOpen,
    shouldAlwaysShowBreakdown = false,
    alignment = "end",
    popoverDirection = "bottom",
    totalPriceLabel,
    showPricePerPerson = false,
    showTotalPriceIncludingAncillaries = false,
    showAvailableSeats = false,
    priceFrom = false,
    hidePriceBreakDown = false,
    travellerCountInPricePerPersonCopy = false,
    isRenderedInModal = false,
    isFlexTicket,
    popoverCloseAriaLabel
  } = props;
  const { priceDisplayRequirements, priceBreakdown, travellers, travellerPrices } = flight;
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();
  const { isRTL } = useLocaleContext();
  const store = useStore();
  const { adults, children } = store.searchCriteria;
  const { requestId } = useGlobalContext();
  const { formatPrice } = useFormatPrice();
  const routeName = useRouteName();
  const trackV2 = useEventTracking("flight_details", requestId);
  let travellersList;

  const isOfMetaOrigin = () => getExtOriginMarketingTrackingVariable();
  const isMeta = isOfMetaOrigin();

  const { getAncillaries, getTotalPrice } = usePriceBreakDownForPreOptedAncillaries(isMeta ? flight : undefined);
  const ancillariesForPriceBreakDown = getAncillaries();
  const totalPrice = showTotalPriceIncludingAncillaries ? getTotalPrice(priceBreakdown) : priceBreakdown;
  const priceTaxBreakdown = priceBreakdown.moreTaxesAndFees;
  const [showPopover, setShowPopover] = useState(false);
  const adultTravellerPrice = getLowestAdultTravellerPrice(travellerPrices);
  const ariaLiveActions = useActions(AriaLiveActions);

  //For copy requirement we need to generate a "like" traveller object.
  if (!travellers || travellers.length === 0) {
    const adultsArray = Array(adults).fill({ type: "ADULT" });
    const childrenArray = Array(children.length).fill({ type: "KID" });
    travellersList = [...adultsArray, ...childrenArray];
  }
  const shouldShowBreakdown = hidePriceBreakDown
    ? false
    : Boolean(shouldAlwaysShowBreakdown || priceDisplayRequirements?.includes("AIR_PRICE_BREAKDOWN"));
  const roundPrice =
    !isOfMetaOrigin() && ((!isMobile && !!trackExperiment("flights_web_roundprice_sr_fd_direct_desktop")) || isMobile);
  /*enable round price only from direct not meta user*/

  const handleInteraction = (e: React.SyntheticEvent, status: boolean) => {
    e.stopPropagation();
    setBreakdownOpen(status);
    isMobile && resetBodyOverflow();
  };

  const isStrikedThroughPricesEnabled = flight.priceBreakdown.showPriceStrikethrough;

  const priceBreakDownIcon = isMobile ? (
    <Actionable
      onClick={(e) => {
        trackExperimentStage("flight_web_sr_traffic_aa_mdot", 7);
        if (routeName === "flightdetails") trackV2("click_price_breakdown");
        handleInteraction(e, true);

        flights_web_fd_select_baggage.getExp(true).goals.open_price_breakdown();
      }}
      attributes={{
        "aria-label": i18n.trans(t("flights_a11y_aria_label_brice_breakdown_question_mark")),
        "data-testid": "flight_card_price_open_price_popover"
      }}
      className={styles.breakDownIconWrapper}
    >
      {!!trackExperiment("flights_web_price_per_person_4") ? (
        <Icon svg={InfoSignIcon} size="smaller" className={styles.breakDownInfoIcon} />
      ) : (
        <Icon svg={QuestionMarkCircleIcon} size="small" className={styles.breakDownIcon} />
      )}
    </Actionable>
  ) : (
    <Popover
      position={popoverDirection}
      active={showPopover}
      onOpen={() => {
        setShowPopover(true);
        if (routeName === "flightdetails") trackV2("click_price_breakdown");
        if (store.search.type === "ONEWAY") {
          trackCustomGoal("flights_web_ddot_condensed_one_way_search_results", 2);
        }
        trackExperimentStage("flight_web_sr_traffic_aa_desktop", 7);
        flights_web_fd_select_baggage.getExp(false).goals.open_price_breakdown();
      }}
      onClose={() => {
        setShowPopover(false);
      }}
      closeAriaLabel={popoverCloseAriaLabel}
    >
      <Popover.Trigger>
        {(attributes) => (
          <Button
            variant="tertiary-neutral"
            size="medium"
            attributes={{
              "aria-label": i18n.trans(t("flights_a11y_aria_label_brice_breakdown_question_mark")),
              "data-testid": "flight_card_price_open_price_popover",
              ...attributes
            }}
          >
            <Icon svg={QuestionMarkCircleIcon} className={styles.breakDownIcon} />
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Content attributes={{ style: { width: 360 }, role: "dialog", "aria-modal": "true" }}>
        <Stack data-testid="flight_card_price_breakdown_popover">
          <Title
            titleTagName="h3"
            variant="headline_3"
            className={styles.popoverTitle}
            title={i18n.trans(t("flights_price_breakdown"))}
          />
          <div className={styles.offsetBreakdownMargin}>
            <PriceBreakdown
              totalPrice={totalPrice}
              ancillaries={ancillariesForPriceBreakDown}
              travellers={travellers && travellers.length > 0 ? travellers : travellersList}
              travellerPrices={travellerPrices}
              showTitle={false}
              showFlightDetails
              fullBreakdownAvailable={false}
              priceTaxBreakdown={priceTaxBreakdown}
              flightData={flight}
            />
          </div>
        </Stack>
      </Popover.Content>
    </Popover>
  );

  const formattedTotalPrice = useMemo(() => {
    if (isFlexTicket) {
      const flexibleFullPrice = getFlexibleTicketFullPrice(flight);

      return formatPrice(
        roundPrice && flexibleFullPrice.totalRounded ? flexibleFullPrice.totalRounded : flexibleFullPrice.total,
        {
          decimalPlaces: roundPrice ? 0 : undefined
        }
      );
    }

    return formatPrice(roundPrice && totalPrice.totalRounded ? totalPrice.totalRounded : totalPrice.total, {
      decimalPlaces: roundPrice ? 0 : undefined
    });
  }, [isFlexTicket, formatPrice, flight, roundPrice, totalPrice.totalRounded, totalPrice.total]);

  const formattedPricePerTravellerString = useMemo(
    () =>
      !!adultTravellerPrice
        ? formatPrice(
            roundPrice && adultTravellerPrice.travellerPriceBreakdown.totalRounded
              ? adultTravellerPrice.travellerPriceBreakdown.totalRounded
              : adultTravellerPrice.travellerPriceBreakdown.total,
            { decimalPlaces: roundPrice ? 0 : undefined }
          )
        : "",
    [adultTravellerPrice, formatPrice, roundPrice]
  );

  const pricePerPersonExplanation = useMemo(() => {
    if (isHungaryDepartingRyanAirFlight(flight.segments)) {
      return i18n.trans(
        t("flights_ryanair_hungary_sr_price", {
          variables: {
            price: formattedTotalPrice
          }
        })
      );
    } else if (!!trackExperiment("flights_web_price_per_person_4") && travellerCountInPricePerPersonCopy) {
      const numberOfPassengers = flight.travellerPrices.length;
      if (numberOfPassengers == 1) {
        return i18n.trans(
          t("flights_search_passenger_count_mix", {
            num_exception: 1,
            variables: { num_travellers: 1 }
          })
        );
      } else {
        return i18n.trans(
          t("flights_meta_fd_price_qualifier_all_travellers", {
            variables: {
              currency_price: formattedTotalPrice,
              num_travellers: flight.travellerPrices.length
            },
            num_exception: flight.travellerPrices.length
          })
        );
      }
    } else {
      return i18n.trans(
        t("flights_sr_total_inc_price", {
          variables: { total_price: formattedTotalPrice }
        })
      );
    }
  }, [flight.segments, flight.travellerPrices.length, i18n, formattedTotalPrice, travellerCountInPricePerPersonCopy]);

  const priceExplanation = useMemo(() => {
    if (isHungaryDepartingRyanAirFlight(flight.segments)) {
      return i18n.trans(t("flights_ryanair_hungary_sr_price_single"));
    } else {
      return totalPriceLabel || i18n.trans(t("flights_price_qualifier_total_for_all"));
    }
  }, [flight.segments, i18n, totalPriceLabel]);

  const strikedThroughPrice = useMemo(
    () =>
      isStrikedThroughPricesEnabled && roundPrice && priceBreakdown.totalWithoutDiscountRounded
        ? formatPrice(priceBreakdown.totalWithoutDiscountRounded, { decimalPlaces: 0 })
        : isStrikedThroughPricesEnabled && priceBreakdown.totalWithoutDiscount
        ? formatPrice(priceBreakdown.totalWithoutDiscount)
        : null,
    [
      formatPrice,
      isStrikedThroughPricesEnabled,
      roundPrice,
      priceBreakdown.totalWithoutDiscount,
      priceBreakdown.totalWithoutDiscountRounded
    ]
  );
  useEffect(() => {
    if (isStrikedThroughPricesEnabled && roundPrice) {
      if (strikedThroughPrice === formattedTotalPrice) {
        trackCustomGoal("flights_web_roundprice_sr_fd_direct_desktop", 1);
      }
    }
  }, [
    formatPrice,
    formattedTotalPrice,
    isMobile,
    isStrikedThroughPricesEnabled,
    priceBreakdown.totalWithoutDiscount,
    priceBreakdown.totalWithoutDiscountRounded,
    roundPrice,
    strikedThroughPrice
  ]);
  const priceAriaLabel = useMemo(() => {
    if (showPricePerPerson && !!adultTravellerPrice) {
      const priceLabel = priceFrom
        ? i18n.trans(
            t("flights_sr_rt_from_price", {
              variables: {
                price: formattedPricePerTravellerString
              }
            })
          )
        : formattedPricePerTravellerString;

      if (isHungaryDepartingRyanAirFlight(flight.segments)) {
        return `${priceLabel} ${i18n.trans(t("flights_ryanair_hungary_sr_price_single"))}`;
      }

      if (isStrikedThroughPricesEnabled && strikedThroughPrice) {
        return i18n.trans(
          t("a11y_flights_deal_price_new", { variables: { new_price: formattedPricePerTravellerString } })
        );
      }

      return priceLabel;
    }

    const priceLabel = priceFrom
      ? i18n.trans(
          t("flights_sr_rt_from_price", {
            variables: { price: formattedTotalPrice }
          })
        )
      : formattedTotalPrice;

    const travellersCount = adults + children.length;

    if (isHungaryDepartingRyanAirFlight(flight.segments)) {
      return `${priceLabel} ${t("flights_ryanair_hungary_bp_price_per", {
        variables: { total_price: priceLabel, num_travellers: travellersCount }
      })}`;
    }

    if (isStrikedThroughPricesEnabled && strikedThroughPrice) {
      return [
        i18n.trans(
          t("a11y_flights_deal_price_old_solo", {
            variables: {
              old_price: strikedThroughPrice
            }
          })
        ),
        i18n.trans(t("a11y_flights_deal_price_new", { variables: { new_price: formattedTotalPrice } }))
      ].join(", ");
    }

    return priceLabel;
  }, [
    adultTravellerPrice,
    adults,
    children.length,
    flight.segments,
    formattedPricePerTravellerString,
    formattedTotalPrice,
    i18n,
    isStrikedThroughPricesEnabled,
    priceFrom,
    showPricePerPerson,
    strikedThroughPrice
  ]);

  const totalPriceDisplay = useMemo(() => {
    if (showPricePerPerson && !!adultTravellerPrice) {
      return priceFrom
        ? i18n.trans(
            t("flights_sr_rt_from_price", {
              variables: {
                price: formattedPricePerTravellerString
              }
            })
          )
        : formattedPricePerTravellerString;
    }

    const shouldShowStrikedThroughPriceOnTop =
      priceBreakdown.showPriceStrikethrough && (isMobile || !isRenderedInModal);

    return (
      <div className={mcn(!shouldShowStrikedThroughPriceOnTop && styles.priceContainer)}>
        {strikedThroughPrice && shouldShowStrikedThroughPriceOnTop && (
          <Text
            variant="body_2"
            decoration="line-through"
            color="destructive"
            className={mcn(styles.strikedPrice, styles.strikedPriceOnTop)}
          >
            {strikedThroughPrice}
          </Text>
        )}

        {priceFrom
          ? i18n.trans(
              t("flights_sr_rt_from_price", {
                variables: { price: formattedTotalPrice }
              })
            )
          : formattedTotalPrice}

        {strikedThroughPrice && !shouldShowStrikedThroughPriceOnTop && (
          <Text
            variant="small_1"
            decoration="line-through"
            color="destructive"
            className={mcn(styles.strikedPrice, styles.strikedPriceFD)}
          >
            {strikedThroughPrice}
          </Text>
        )}
      </div>
    );
  }, [
    showPricePerPerson,
    adultTravellerPrice,
    priceBreakdown.showPriceStrikethrough,
    isMobile,
    strikedThroughPrice,
    priceFrom,
    i18n,
    isRenderedInModal,
    formattedTotalPrice,
    formattedPricePerTravellerString
  ]);

  const priceExplanationAriaLabel = useMemo(() => {
    const travellersCount = adults + children.length;

    let priceExplanation = i18n.trans(t("flights_price_qualifier_total_for_all"));

    if (isHungaryDepartingRyanAirFlight(flight.segments)) {
      priceExplanation = i18n.trans(
        t("flights_ryanair_hungary_bp_price_per", {
          variables: { total_price: formattedTotalPrice, num_travellers: travellersCount }
        })
      );
    } else if (isStrikedThroughPricesEnabled && priceBreakdown.totalWithoutDiscount) {
      return i18n.trans(
        t("a11y_flights_deal_price_total", {
          variables: {
            total_price: formattedTotalPrice,
            old_price: formatPrice(priceBreakdown.totalWithoutDiscount)
          }
        })
      );
    }

    return `${formattedTotalPrice} ${priceExplanation}`;
  }, [
    adults,
    children.length,
    flight.segments,
    formatPrice,
    formattedTotalPrice,
    i18n,
    isStrikedThroughPricesEnabled,
    priceBreakdown.totalWithoutDiscount
  ]);

  const ariaExplanationAttributes = useMemo(() => {
    const travellersCount = adults + children.length;
    const attributes = {};

    if (travellersCount === 1) {
      attributes["aria-hidden"] = true;
    } else {
      attributes["aria-label"] = priceExplanationAriaLabel;
    }

    return attributes;
  }, [adults, children.length, priceExplanationAriaLabel]);

  const strikedThroughPricesShouldStack = isMobile && isPriceWithLargeNumber(priceBreakdown.total);

  const shouldHidePriceExplanation = useMemo<boolean>(() => {
    const isSolo = [...travellerPrices].length === 1;
    if (!["searchresults", "flightdetails"].includes(routeName) || !isSolo) {
      return false;
    }
    return true;
  }, [travellerPrices, routeName]);

  useEffect(() => {
    // `flights_web_fd_select_baggage` exp
    ariaLiveActions.setMessage({
      message: i18n.trans(
        t("a11y_flights_aria_live_checkout_new_total_price", {
          variables: { price: priceAriaLabel }
        })
      ),
      type: "polite"
    });
  }, [priceAriaLabel]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        {showAvailableSeats && <FlightAvailableSeats flight={flight} />}
        <Frame
          direction="row"
          justifyContent={alignment === "start" ? "flex-start" : "flex-end"}
          attributes={{ role: "region", "aria-label": priceAriaLabel }}
        >
          <Title
            variant={showPricePerPerson && !!adultTravellerPrice && !priceFrom && isMobile ? "strong_1" : "headline_3"}
            attributes={{ "aria-hidden": true, "data-testid": "flight_card_price_main_price" }}
            className={styles.mainPrice}
            titleTagName="div"
            title={totalPriceDisplay}
          />
          {shouldShowBreakdown && priceBreakDownIcon}
        </Frame>
        <Text
          variant={showPricePerPerson && !!adultTravellerPrice && !priceFrom && isMobile ? "body_2" : "small_1"}
          color={trackExperiment("flights_web_swap_price_baggage_sr_desktop_2") ? undefined : "neutral_alt"}
          attributes={{
            "data-testid": "flight_card_price_total_price",
            ...ariaExplanationAttributes
          }}
        >
          {showPricePerPerson && !!adultTravellerPrice ? (
            <div>
              {isStrikedThroughPricesEnabled && priceBreakdown.totalWithoutDiscount && (
                <>
                  <Text
                    variant={isMobile ? "body_2" : "small_1"}
                    color="destructive"
                    decoration="line-through"
                    className={mcn(
                      styles.strikedPrice,
                      strikedThroughPricesShouldStack ? styles.strikedPriceOnTop : styles.priceInline
                    )}
                  >
                    {strikedThroughPrice}
                  </Text>
                  {/**
                   * To address the bug on RTL price display for multi-travellers
                   * which the currency of the original price is shown within the
                   * read-out of the discounted price
                   *
                   * More info: https://jira.booking.com/jira/browse/FPRICING-807
                   */}
                  {isRTL && (
                    <Text className={styles.priceInline} variant="body_2">
                      {" "}
                    </Text>
                  )}
                </>
              )}
              <Text className={styles.priceInline} variant="body_2">
                {pricePerPersonExplanation}
              </Text>
            </div>
          ) : (
            <>{!shouldHidePriceExplanation && priceExplanation}</>
          )}
        </Text>
      </div>
      {isMobile && breakdownOpen && (
        <SheetContainer
          onCloseTrigger={() => setBreakdownOpen(false)}
          title={i18n.trans(t("flights_price_breakdown"))}
          closeAriaLabel={i18n.trans(t("close"))}
          active={true}
          className={styles.drawer}
        >
          <PriceBreakdown
            totalPrice={totalPrice}
            ancillaries={ancillariesForPriceBreakDown}
            travellers={travellers && travellers.length > 0 ? travellers : travellersList}
            travellerPrices={travellerPrices}
            showTitle={false}
            showFlightDetails
            fullBreakdownAvailable={false}
            priceTaxBreakdown={priceTaxBreakdown}
            flightData={flight}
          />
        </SheetContainer>
      )}
    </>
  );
};

export default FlightCardPrice;
