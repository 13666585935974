import { useCallback } from "react";
import { useStore } from "../store";
import { UIFlightData, UIAtolProtectedStatus, UITripRebookOption } from "@flights/types/client";

export default function useATOL() {
  const store = useStore();
  const airOrderSegments = store?.order?.order?.airOrder?.flightSegments;
  const flightDetailsSegments = store?.flightDetails?.flight?.segments;
  const orderToken = store?.order?.order?.orderToken;

  const isATolProtectedSR = useCallback((atolProtectedStatus?: UIAtolProtectedStatus) => {
    return Boolean(atolProtectedStatus && atolProtectedStatus !== "NONE");
  }, []);

  const isATolProtectedFD = useCallback((flight: UIFlightData | UITripRebookOption) => {
    return flight.segments.some(({ isAtolProtected }) => isAtolProtected);
  }, []);

  const isATolProtectedCheckOut = useCallback(() => {
    const segments = airOrderSegments || flightDetailsSegments || [];
    return segments.some(({ isAtolProtected }) => isAtolProtected);
  }, [flightDetailsSegments, airOrderSegments]);

  const getAtolPdfUrl = useCallback(() => {
    return orderToken ? `/api/order/${orderToken}/documents/atol-certificate?downloadable=1` : undefined;
  }, [orderToken]);

  return {
    isATolProtectedSR,
    isATolProtectedFD,
    isATolProtectedCheckOut,
    getAtolPdfUrl
  };
}
