import { Request } from "express";
import { UICabinClass, UIPointOfSale } from "@flights/types/client";
import { UILuggageType } from "@flights/types/client";
import { TranslationMessage } from "@bookingcom/lingojs-core/build/t";
import { t } from "@bookingcom/lingojs-core";
import { RouteName } from "app/routes";

export const PLATFORM_ENV = process.env.PLATFORM_ENV;

export const PAYMENT_COMPONENT_COOKIE_ID = PLATFORM_ENV === "prod" ? "pc_payer_id" : "dqs_pc_payer_id";
export const FLIGHTS_HOSTNAME = "flights.booking.com";
export const STAGING_HOSTNAME = "flights-staging.prod.booking.com";

export const BOOKING_SSO_COOKIE_NAME = PLATFORM_ENV === "prod" ? "bkng_sso_session" : "dqs_bkng_sso_session";
export const ACCOUNTS_PORTAL_URL =
  PLATFORM_ENV === "prod" ? "https://account.booking.com" : "https://account.dqs.booking.com";

export const ANALYTICS_SESSION_COOKIE_NAME = PLATFORM_ENV === "prod" ? "fasc" : "dqs_fasc";

//for running it on dev, put the oauth-client.json next to your repo
export const OAUTH_CONFIG_FILE_PATH = PLATFORM_ENV
  ? "/var/run/secrets/booking.com/oauth-client.json"
  : `${require("path").resolve(__dirname, "../")}/oauth-client.json`;

export const LOCAL_SECRETS_FILE_PATH = `${require("path").resolve(__dirname, "../")}/local-keys.json`;

// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const FLIGHTS_BASE_URL = (req: Request) => {
  if (process.env.NODE_ENV === "development") {
    const protocol = Boolean(process.env.ENABLE_SSL) ? "https" : "http";
    // For IAM whitelist, we need to setup a localhost alias to a whitelisted url like https://flights-local.dev.booking.com
    // @see https://gitlab.booking.com/flights/main/-/wikis/Frontend/Architecture/Server#note-testing-sso-on-localhost
    return `${protocol}://${req.hostname}:${Number(process.env.PORT)}`;
  }

  switch (PLATFORM_ENV) {
    case "prod":
      return req.hostname === STAGING_HOSTNAME ? `https://${STAGING_HOSTNAME}` : "https://flights.booking.com";
    case "dqs":
      return "https://flights.dqs.booking.com";
    default:
      return `https://${req.hostname}`;
  }
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const FLIGHTS_OAUTH_CALLBACK_BASE_URL = (req: Request): string => `${FLIGHTS_BASE_URL(req)}/api/oauth`;
export const PB_URL_PARAM_SHOW_FARE_RULES = "showFareRules";
export const X_BOOKING_FLIGHTS_WIREMOCK = "X-Booking-Flights-WireMock";
export const MOCK_SCENARIO_ENABLED = "mock-scenario-enabled";
export const MOCK_SCENARIO_NAME = "mock-scenario-name";
// this header is used on backend to monitor the source of email resend action
export const X_BOOKING_RESEND_CONFIRMATION_EMAIL_REFERRER = "X-Booking-Resend-Confirmation-Email-Referrer";

//  Request tracing XCall field names
export const XCALL_BOOKING_REQUEST_ID = "request_id";
export const XCALL_BOOKING_PARENT_REQUEST_ID = "parent_request_id";
export const XCALL_BOOKING_REQUEST_TREE_ID = "request_tree_id";

// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const ACCOUNTS_PORTAL_CALLBACK_PATH = (req: Request): string => {
  return FLIGHTS_OAUTH_CALLBACK_BASE_URL(req) + "/callback/v3";
};

export const DESKTOP_SIDEBAR_WIDTH = 360;
export const FLOATING_PANEL_MARGIN = 64;

export const SEARCH_PAGE_SIZE = 15; // # of result cards in search page
export const MAX_SEARCH_PAGE_SIZE = 60;

// for Non-Live countries we want to authorise them if they have been authorised in the last 30 minutes
// via a meta landing that has the same aid the current request has. We will use session service data for this.
export const NON_LIVE_IP_AUTHORISATION_DURATION = 30 * 60 * 1000; // 30 minutes in ms

export const STICKY_FOOTER_SPACER_ID = "sticky-footer-spacer";

export const CABIN_CLASS_MAP: { [P in UICabinClass]: TranslationMessage } = {
  ECONOMY: t("flights_cabin_class_economy_option"),
  PREMIUM_ECONOMY: t("flights_cabin_class_premium_option"),
  BUSINESS: t("flights_cabin_class_business_option"),
  PREMIUM_BUSINESS: t("flights_cabin_class_premium_business"),
  FIRST: t("flights_cabin_class_first_option"),
  PREMIUM_FIRST: t("flights_cabin_class_premium_first")
};

export const INCLUDED_BAGGAGE_ORDER: UILuggageType[] = ["PERSONAL_ITEM", "HAND", "CHECKED_IN"];

export const preserveURLParams = [
  "aid",
  "label",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "lang",
  "locale",
  "ext-tr",
  "ext-src",
  "ext-origin",
  "ext-fwd",
  "adplat",
  "offerInstanceId"
];

export const BOOKING_DEFAULT_AFFILIATE_ID = "304142";
export const FLIGHTS_DEFAULT_LABEL = "flights-booking-direct";
export const FLIGHTS_UNKNOWN_LABEL = "flights-booking-unknown";
export const FLIGHTS_SHARE_DIRECT_LABEL = "flights-share-direct";
export const FLIGHTS_SHARE_META_LABEL = "flights-share-meta";

//PPC conversion tracking: https://jira.booking.com/jira/browse/FLIGHTS-497
export const GOOGLE_TAG_MANAGER_CONTAINER_ID = "AW-1070314322";
export const GOOGLE_TAG_MANAGER_CONTAINER_LABEL = "7zaICNHurMQBENLmrv4D";

export const DEFAULT_MARKETING_TRACKING_VARIABLES = {
  aid: BOOKING_DEFAULT_AFFILIATE_ID,
  label: ""
};

export const SPACER = "·";
export const SPACER_PADDED = ` ${SPACER} `;
export const DASH_SPACER = "-";
export const DASH_SPACER_PADDED = ` ${DASH_SPACER} `;

export const SMALL_SCREEN_375 = "@media (max-width: 375px)";

export const FILTERS = {
  duration: "DURATION",
  airlines: "AIRLINES",
  stops: "NUMBER_OF_STOPS",
  journeyTime: "JOURNEY_TIME_FILTER",
  flightTimes: "FLIGHT_TIMES_FILTER",
  shortLayoverConnection: "LAYOVER_CONNECTION",
  budget: "MAX_BUDGET",
  budgetPerAdult: "MAX_BUDGET_PER_ADULT",
  includedBaggage: "INCLUDED_BAGGAGE",
  layoverDuration: "LAYOVER_DURATION",
  airports: "AIRPORTS_FILTER",
  preferSameAirport: "PREFER_SAME_AIRPORT"
};

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/* TODO(asamilyak): remove `mobileTravelPlan` and `checkoutFlexibleTicket` here once we fully migrate to Checkout 2.0
        and get rid of pre opted ancillaries during checkout process.
        By then we won't need one-ancillary to many-query-params mapping (for flexible ticket),
        and this mapping can be converted to `{ [key in UIPreOptInProductType]: string }` type
*/
export const PRE_OPTED_ANCILLARIES_QUERY_PARAMS = {
  mobileTravelPlan: "sms",
  flexibleTicket: "flexibleTicket",
  checkedInBaggage: "checkedInBaggage",
  cabinBaggagePerTraveller: "cabinBaggagePerTraveller", // `flights_web_fd_select_baggage` exp
  checkoutFlexibleTicket: "checkoutFlexibleTicket",
  cancelForAnyReason: "cancelForAnyReason",
  standardTicket: "standardTicket"
};

export const BRANDED_FARES_QUERY_PARAMS = {
  baseOfferToken: "baseOfferToken",
  selectedFareIndex: "selectedFareIndex"
};

export const ALL_EU_COUNTRIES = [
  "BE",
  "EL",
  "LT",
  "PT",
  "BG",
  "ES",
  "LU",
  "RO",
  "CZ",
  "FR",
  "HU",
  "SI",
  "DK",
  "HR",
  "MT",
  "SK",
  "DE",
  "IT",
  "NL",
  "FI",
  "EE",
  "CY",
  "AT",
  "SE",
  "IE",
  "LV",
  "PL",
  "GR"
];

export const ALL_EFTA_COUNTRIES = ["IS", "LI", "NOR", "CH"]; //European Free Trade Association

export const FLOW_TYPE_SUPPLIER_3DS = "supplier3ds";

export const VALID_SUPPLIERS = ["ETG"];

export const DEFAULT_SUPPLIER = "ETG";

export const ORDER_TOKEN_VALIDITY = 86400; // seconds in 24hrs

export const validDateStringLength = 10; //"yyyy-mm-dd" format

// We have only 2 types of travellers in supplier's data - adult and child.
// But sometimes we need to show some minor pieces of UI (e.g. copies) only for infants, hence this constant.
export const INFANT_MAX_AGE = 1;
export const KID_MAX_AGE = 17;

export const GOOGLE_FLIGHTS_EXT_ORIGIN_PARAM_VALUES = ["gfs", "gfsapi"];
export const GOOGLE_SEARCH_WIDGET_EXT_ORIGIN_PARAM_VALUES = ["google-widget"];
export const SKYSCANNER_EXT_ORIGIN_PARAM_VALUES = ["skyscanner"];
export const KAYAK_EXT_ORIGIN_PARAM_VALUES = ["kayak"];

export const RTL_LANGUAGES = ["ar", "he"];

export const COVID_INFORMATION_PAGE_URL = "https://www.booking.com/covid-19.html";

export const VI_TERMS_AND_CONDITIONS_LINK =
  "https://secure.booking.com/help/faq/40/RmxpZ2h0cyAtIEZBUSAtIFNlbGYgVHJhbnNmZXI?category=flights_faq_topic_booking&source=flights_vi";
export const MARKETING_TOPICS = ["cross-sell", "seo"];

export const IMAGE_STATIC_ASSETS_URL = "https://q-xx.bstatic.com";

export const COMPANY_NAME = "Booking.com";

export const FLIGHTS_INDEX_BASE_URL = "https://www.booking.com/flights/index";

export const ACCOMMODATIONS_DOMAIN_URL = "https://www.booking.com";

export const ETG_VALIDATION_INVALID_PHONE = "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_PHONE";
export const ETG_VALIDATION_INVALID_EMAIL_DOMAIN =
  "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_EMAIL_DOMAIN";
export const ETG_VALIDATION_INVALID_EMAIL_SYNTAX =
  "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_EMAIL_SYNTAX";

export const COMPANY_HOSTNAME = "www.booking.com";

export const ETG_SALES_CHANNEL_PPC_VALUE = "ppc";

export const SESSION_STORAGE_KEY_LAST_SUCCESSFUL_SEARCH = "last_successful_search_params";

export const MANDATORY_SEARCH_PARAMS_ONEWAY = ["type", "adults", "cabinClass", "from", "to", "depart"];
//const MANDATORY_SEARCH_PARAMS_ROUNDTRIP = [...MANDATORY_SEARCH_PARAMS_ONEWAY, "return"];

export const INSURANCE_PROVIDER_NAME = "SOLID Insurance";
export const INSURANCE_PROVIDER_ADDRESS = "Box 22068, 250 22 Helsingborg, Sweden, Corp ID No 516401-8482";

export const SEO_ROUTES = ["home", "landing"];

export const SEATMAP_EXTRA_TYPE = "SEATING_SEATMAP";

export const LANDING_OFFER_FROM_META_KEY = "landing_offer_from_meta";
export const LANDING_BRANDED_OFFER_FROM_META = "landing_branded_offer_from_meta";
export const PRICE_ACCURACY_TAG_FIRED_OFFER = "price_accuracy_tag_fired_offer";
export const PRICE_DISCREPANCY_LOGGED_OFFER = "price_discrepancy_logged_offer";
export const RETRY_NETWORK_ERROR_FOR_GAPD = "flight_details_api_network_error";
export const META_LANDING_URL_KEY = "meta_landing_url";

export const META_LOADING_SCREEN_RENDERED = "meta_loader_screen_rendered";

export const PUBLIC_PATH = "https://cf.bstatic.com/psb/flights/";

export const SUPPLY_TYPE_MERCHANT = "MERCHANT";
export const SUPPLY_TYPE_AGENCY = "AGENCY";

export const INSTALLMENTS_FAQ_URL = "https://secure.booking.com/help/faqs_list/40/flights-faq-topic-payment";

/**
 * @deprecated
 * ===========
 * - flights.frontend hit the ceiling of 200k events
 * - see : https://u.booking.com/JKQjyq
 * - for new events use `GRAPHITE_METRIC_AGGREGATION_NAMESPACE_{TEAM_NAME}`
 */
export const GRAPHITE_METRIC_AGGREGATION_NAMESPACE = "flights.frontend";
export const GRAPHITE_METRIC_AGGREGATION_NAMESPACE_FUNNEL = "flights.frontend_funnel";

export const SAVED_PAX_FORM_KEY_NAME = "pax_saved";
export const FLIGHT_OFFER_DEFAULT_POS: UIPointOfSale = "ie";
export const SESSION_SEARCH_HISTORY_MAX_RESULTS = 6;
export const SANCTIONED_COUNTRIES_FALLBACK = ["ru", "cu", "ir", "sd", "by", "kp", "ve", "sy"]; // prefer country-configuration.json msv

//https://docs.google.com/spreadsheets/d/18Ik2u1kLjRccVRFT56LQMf_FvPhbCZMjlwlpqLjaj9E/edit#gid=392204482
export const META_USE_OFFER_POS_EXCLUDED_COUNTRIES_FALLBACK = ["id", "hu", "bh", "qa", "om", "ph"];

export const PAYMENT_AGENCY_GROUP_FALLBACK = [
  "us",
  "ua",
  "ae",
  "cl",
  "au",
  "kw",
  "in",
  "hr",
  "co",
  "bg",
  "pe",
  "kz",
  "br",
  "mo",
  "my",
  "sa",
  "kr"
];
export const PAYMENT_MERCHANT_GROUP_FALLBACK = [
  "nl",
  "ie",
  "gb",
  "de",
  "es",
  "fr",
  "it",
  "at",
  "be",
  "ro",
  "fi",
  "mt",
  "cy",
  "se",
  "no",
  "dk",
  "pl",
  "ch",
  "hu",
  "is",
  "cz",
  "pt",
  "th",
  "ph",
  "qa",
  "lu",
  "rs",
  "bh",
  "hk",
  "om",
  "jp",
  "ca",
  "id",
  "nz",
  "mx",
  "ee",
  "gr",
  "za"
];

export const FLIGHT_DETAILS_RETRYABLE_ERROR_CODES = ["UPSTREAM_SUPPLIER_NOT_AVAILABLE"];
export const FARE_RULES_AVAILABLE_CARRIERS = ["U2", "DS", "HV", "TO", "FR", "RK"];
export const OUTBOUND_FLIGHTS_SEARCH_PARAMS = "outboundFlight"; // flights_web_inbound_outbound_sr
export const VOID_EXCLUDED_COUNTRIES_FALLBACK = ["id", "ph", "qa", "om", "bh", "ro", "rs", "is"];

export const CUSTOMER_SUPPORT_COUNTRIES_FALLBACK = [
  /** Phone and/or LiveChat support */
  "gb",
  "nl",
  "us",
  "ie",
  "au",
  "is",
  "qa",
  "hu",
  "cz",
  "ae",
  "ph",
  "th",
  "rs",
  "bh",
  "my",
  "id",
  "in",
  "om",
  "ca",
  "nz",
  "hr",
  "ee",
  "bg",
  "sk",
  "sg",
  "sa",
  "jo",
  "kr",
  "za",
  "mt",
  "jp",
  "kw",
  /** LiveChat support only */
  "hk",
  "pt",
  "br",
  "fr",
  "be",
  "dk",
  "no",
  "se",
  "ro",
  "cl",
  "pe",
  "mx",
  "co"
];

// used in autocomplete and search result
// keep everything in lower case
export const COUNTRY_REGION_RESTRICTION: Record<string, { all: boolean; regions: string[] }> = {
  cu: { all: true, regions: [] },
  ir: { all: true, regions: [] },
  kp: { all: true, regions: [] },
  sy: { all: true, regions: [] },
  ua: { all: false, regions: ["kherson", "zaporizhzhya", "zaporizhya", "zaporozhe", "zaporozhye"] }
};

export const FILTERS_STOPS_MAX = 1;

export const EEA_COUNTRY_CODE_LIST = [
  "at",
  "be",
  "bg",
  "cy",
  "cz",
  "de",
  "dk",
  "ee",
  "el",
  "es",
  "fi",
  "fr",
  "hr",
  "hu",
  "ie",
  "is",
  "it",
  "li",
  "lt",
  "lu",
  "lv",
  "mt",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "se",
  "si",
  "sk"
];

export const SCHENGEN_COUNTRY_CODE_LIST = [
  "at",
  "be",
  "hr",
  "cz",
  "dk",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "hu",
  "is",
  "it",
  "lv",
  "li",
  "lt",
  "lu",
  "mt",
  "no",
  "pl",
  "pt",
  "sk",
  "si",
  "es",
  "se",
  "ch",
  "nl"
];

export const UNSUPPORTED_PDF_LOCALES = ["hi", "ja", "ko", "th", "zh-cn", "zh-tw", "he", "ar"];

export const CANCELLATION_STATUS_TRANSFORM = {
  CANCELLATION_STATUS_PENDING: "PENDING_CANCEL",
  CANCELLATION_STATUS_CANCELLED: "CANCELLED"
};

export const ORDER_CONSISTENCY_TRANSFORM = {
  CONSISTENT: "CONSISTENT",
  INCONSISTENT: "INCONSISTENT"
};

export const RYANAIR_HC_LINK =
  "https://secure.booking.com/help/faq/40/RmxpZ2h0cyAtIEZBUXMgLSBSeWFuYWlyIDEwOTQ1Mw?category=flights_faq_topic_make_booking";

export const CO2E_EMISSIONS_HC_LINK =
  "https://secure.booking.com/help/faq/40/RmxpZ2h0cyAtIEZBUXMgLSBDTzIgMTA5ODcw?category=flights_faq_topic_booking&render_html=1";

export const ACC_REMOTE_COMPONENT_HOST = "accommodationsapi";
export const ACC_REMOTE_COMPONENT_DQS_BASE_URL = "https://accommodations.dqs.booking.com";
export const ACC_REMOTE_COMPONENT_PROD_BASE_URL = "https://accommodations.booking.com";
export const ACC_REMOTE_COMPONENT_ENDPOINT = "/orca/translate-components";
export const ORCA_REMOTE_COMPONENT_HOST = "b-capla-orca";
export const ORCA_REMOTE_COMPONENT_DQS_BASE_URL = "https://orca.dqs.booking.com";
export const ORCA_REMOTE_COMPONENT_PROD_BASE_URL = "https://orca.prod.booking.com";
export const ORCA_REMOTE_COMPONENT_ENDPOINT = "/translate/v1/remote-components";
export const REMOTE_COMPONENT_PROXY_ENDPOINT = "/rc/translate-components";

export const EUROPEAN_COUNTRIES = [
  "be",
  "bg",
  "cz",
  "dk",
  "de",
  "ee",
  "ie",
  "el",
  "es",
  "fr",
  "hr",
  "it",
  "cy",
  "lv",
  "lt",
  "lu",
  "hu",
  "mt",
  "nl",
  "at",
  "pl",
  "pt",
  "ro",
  "si",
  "sk",
  "fi",
  "se"
];

export const LEGAL_LINKED_TRAVEL_ARRANGEMENT_COUNTRIES = [
  "at",
  "be",
  "bg",
  "ch",
  "cy",
  "cz",
  "de",
  "dk",
  "ee",
  "es",
  "fi",
  "fr",
  "gb",
  "gr",
  "hr",
  "hu",
  "ie",
  "is",
  "it",
  "li",
  "lt",
  "lu",
  "lv",
  "mt",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "se",
  "si",
  "sk"
];

export const FLIGHTS_UNKNOWN_URL_REGEX = /^\/flights\/[^\/?]+$/;

export const KAYAK_ADS = "kayak_ads";

export const RECENT_SEARCH_NEEDED_SEARCH_PARAMS_KEYS = [
  "type",
  "adults",
  "cabinClass",
  "children",
  "from",
  "to",
  "fromCountry",
  "toCountry",
  "fromLocationName",
  "toLocationName",
  "depart",
  "return",
  "multiStopDates"
];

export const RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS = [
  "stops",
  "airlines",
  "duration",
  "depInt",
  "arrInt",
  "depTimeInt",
  "arrTimeInt",
  "flightTimesResolution",
  "maxBudget",
  "maxBudgetPerAdult",
  "maxDuration",
  "maxLayoverDuration",
  "airports",
  "preferSameAirport"
];

export const ANYWHERE = "Anywhere";

export const TRIP_TYPE = {
  ROUNDTRIP: "ROUND_TRIP",
  ONEWAY: "ONE_WAY"
};

export const FLY_ANYWHERE_ROUTE_TO_STAGE: Partial<{ [key in RouteName]: number }> = {
  home: 2,
  searchresults: 3,
  landing: 4,
  "not-found": 4,
  "pb-change-flight": 4
};
