import memoize from "lodash/memoize";
import { UIPrice } from "@flights/types/client";
import { getCurrencySymbol, localizer } from "./currency-localizer/localizer";
import { abbreviateNumber } from "./number";

/**
 * This utility is used client and server side.
 * make sure to use `useFormatPrice` in client side.
 */
export type FormatOptions = {
  isDiscount?: boolean;
  decimalPlaces?: number;
  useNativeFormatting?: boolean;
};

export const priceIsZero = (price: UIPrice) => price.units === 0 && price.nanos === 0;

// NOTE: Only memoizes on locale, currency and maximumFractionDigits for performance reasons, extend the key when needed.
const NumberFormatMemoized = memoize(
  Intl.NumberFormat,
  // This function creates the key to use for memoization.
  (locale, options) => String(locale) + String(options?.currency) + String(options?.maximumFractionDigits)
);

export const priceToValue = ({ nanos, units }: UIPrice): number => {
  const sign = Math.sign(units !== 0 ? units : nanos) || 1;
  const normalizedNanos = sign * Math.abs(nanos / 1e9);

  return units + normalizedNanos;
};

export const formatPrice = (price: UIPrice, locale?: string, formatOptions?: FormatOptions): string => {
  let value = priceToValue(price);

  if (formatOptions?.isDiscount) {
    value = 0 - value;
  }

  if (formatOptions?.useNativeFormatting) {
    return NumberFormatMemoized(locale, {
      style: "currency",
      currency: price.currencyCode,
      maximumFractionDigits: formatOptions?.decimalPlaces
    }).format(value);
  }

  return localizer(value, {
    currency: price.currencyCode,
    /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
    //fixme: locale is not valid, does not have country, thus use point of sale for now
    locale,
    decimalPlaces: formatOptions?.decimalPlaces
  });
};

export const formatAbbreviatedPrice = (price: UIPrice, preferredMaxLength: number = 5) => {
  const value = priceToValue(price);
  const currencySymbol = getCurrencySymbol(price.currencyCode);
  let digits = 2;
  let abbreviatedValue = abbreviateNumber(value, digits);

  while (abbreviatedValue.length > preferredMaxLength && digits > 0) {
    digits -= 1;
    abbreviatedValue = abbreviateNumber(value, digits);
  }

  return `${currencySymbol} ${abbreviatedValue.toUpperCase()}`;
};
