import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UISearchResults, UIClientFetchError, UIFlightData } from "@flights/types/client";

export enum ActionTypes {
  fetch = "searchResults/fetch",
  fetchSuccess = "searchResults/fetchSuccess",
  fetchError = "searchResults/fetchError",
  reset = "searchResults/reset",
  /* start - flights_web_inbound_outbound_sr */
  setSelectedInboundFlight = "searchResults/setSelectedInboundFlight",
  resetSelectedInboundFlight = "searchResults/resetSelectedInboundFlight"
  /* end - flights_web_inbound_outbound_sr */
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((data: UISearchResults) => ({
    type: ActionTypes.fetchSuccess,
    payload: data
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: error
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  })),

  /* start - flights_web_inbound_outbound_sr */
  setSelectedInboundFlight: createAction((data: UIFlightData) => ({
    type: ActionTypes.setSelectedInboundFlight,
    payload: data
  })),

  resetSelectedInboundFlight: createAction(() => ({
    type: ActionTypes.resetSelectedInboundFlight,
    payload: {}
  }))
  /* end - flights_web_inbound_outbound_sr */
};

export type Actions = ActionsUnion<typeof actions>;
