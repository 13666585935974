export function abbreviateNumber(number: number, decimals: number = 0): string {
  const decimalPlaces = 10 ** decimals;
  const abbreviations = ["k", "m", "b", "t"];
  let formattedNumber = String(number);

  for (let i = abbreviations.length - 1; i >= 0; i--) {
    const treshold = 10 ** ((i + 1) * 3);

    if (treshold <= number) {
      formattedNumber = String(Math.round((number * decimalPlaces) / treshold) / decimalPlaces);

      if (number === 1000 && i < abbreviations.length - 1) {
        formattedNumber = "1";
        i++;
      }

      formattedNumber += abbreviations[i];
      break;
    }
  }

  return formattedNumber;
}
