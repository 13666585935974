import useCreateUrl from "hooks/useCreateUrl";
import { useCallback } from "react";
import { useStore } from "../../../store";

export const useCreateFlexUrl = () => {
  const { createUrlWithApiPrefix } = useCreateUrl();
  const store = useStore();

  const {
    search: { type, searchSegments }
  } = store;

  return useCallback(
    (offset: number, departureDate: string, returnDate?: string) => {
      const searchParams = new URLSearchParams(location.search);
      switch (type) {
        case "ROUNDTRIP":
          searchParams.set("depart", departureDate);
          returnDate && searchParams.set("return", returnDate);
          break;
        case "ONEWAY":
          searchParams.set("depart", departureDate);
          break;
        case "MULTISTOP":
          const currentDates = searchSegments.map((segment, index) => {
            if (index === 0) {
              return departureDate;
            }
            if (index === searchSegments.length - 1) {
              return returnDate;
            }
            const date = new Date(segment.departureDate || "");
            date.setDate(date.getDate() + offset);
            return date.toISOString().split("T")[0];
          });
          searchParams.set("multiStopDates", currentDates.join("|"));
          break;
        default:
          break;
      }

      const from = searchParams.get("from");
      const to = searchParams.get("to");
      const url = `/flights/${from}-${to}/?` + searchParams.toString();

      return createUrlWithApiPrefix(url);
    },
    [createUrlWithApiPrefix, searchSegments, type]
  );
};
