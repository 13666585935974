import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_bf_price_display",
  stages: {
    all_users_on_bf: 1,
    ddot_users_on_bf: 2,
    mdot_users_on_bf: 3,
    meta_users_on_bf: 4,
    upsell_fare_pre_selected_from_sr: 5,
    lowest_fare_pre_selected_from_sr: 6,
    two_or_more_travellers: 7,
    next_button_visible: 8,
    solo_travellers: 9
  },
  goals: {
    changes_bf_option_to_lower_fare: 1,
    clicks_on_carousel_button: 2,
    change_to_third_or_higher_option: 3,
    less_or_equal_to_1_cards_on_mdot: 4,
    less_or_equal_to_2_cards_on_mdot: 5
  },
  goalsWithValue: []
});

export default utils;

const { goals } = utils;

export const trackFlightCardsVisible = (isInViewport: boolean, index: number, totalSearchResults: number) => {
  const isLast = totalSearchResults - 1 === index;
  if (window.scrollY > 0) return;
  if (isInViewport && isLast) {
    if (index < 1) goals.less_or_equal_to_1_cards_on_mdot();
    if (index < 2) goals.less_or_equal_to_2_cards_on_mdot();
  } else if (!isInViewport) {
    if (index === 1) goals.less_or_equal_to_1_cards_on_mdot();
    if (index === 2) goals.less_or_equal_to_2_cards_on_mdot();
  }
};
