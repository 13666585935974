import {
  BaggageRollIcon,
  CertifiedRibbonIcon,
  FastTrackIcon,
  MoneyIncomingIcon,
  PlaneTicketReturnIcon,
  RefreshIcon,
  RestaurantSeatIcon,
  SeatRegularIcon,
  TravelInsuranceIcon,
  TreeIcon
} from "@bookingcom/bui-assets-react/streamline";
import { UIBrandedFareFeatureName } from "@flights/types/client";
import { AncillaryGroup, SimpleFeatureConfig } from "@flights/types/brandedFares";
import { t } from "@bookingcom/lingojs-core";

/**
 * The order of this list is used for sorting in the UI.
 */
export const SIMPLE_FEATURES_UI_CONFIG: SimpleFeatureConfig[] = [
  // Change
  {
    name: "CHANGE_FEE",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_055"),
    sellableFeatureCopy: t("flights_apex_fare_feature_055_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_055_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "CHANGEABLE_TICKET",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_change_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_059_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_change_flights_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "CHANGEABLE_TICKET_SR",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_apex_sr_flex_ticket_benefit"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_change_flights_excluded"),
    sellableFeatureCopy: t("flights_apex_fare_feature_059_sellable"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "CHANGE_ANYTIME",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_change_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_068_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_change_flights_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "CHANGE_BEFORE_DEPARTURE",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_change_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_06i_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_change_flights_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "CHANGE_AFTER_DEPARTURE",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_change_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_06j_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_change_flights_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "SAME_DAY_CHANGE",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_08s"),
    sellableFeatureCopy: t("flights_apex_fare_feature_08s_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_08s_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "SAME_DAY_AS_ORIGINAL_FLT",
    icon: PlaneTicketReturnIcon,
    includedFeatureCopy: t("flights_fare_feature_08r"),
    sellableFeatureCopy: t("flights_apex_fare_feature_08r_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_08r_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },

  // Refund
  {
    name: "REFUNDABLE_TICKET",
    icon: MoneyIncomingIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_cancel_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_056_sellable"),
    nonIncludedFeatureCopy: t("flights_fare_feature_generic_cancel_flights_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "REFUND_BEFORE_DEPARTURE",
    icon: MoneyIncomingIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_cancel_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_06k_sellable"),
    nonIncludedFeatureCopy: t("flights_apex_fare_feature_06k_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },
  {
    name: "REFUND_AFTER_DEPARTURE",
    icon: MoneyIncomingIcon,
    includedFeatureCopy: t("flights_fare_feature_generic_cancel_flights"),
    sellableFeatureCopy: t("flights_apex_fare_feature_06l_sellable"),
    nonIncludedFeatureCopy: t("flights_apex_fare_feature_06l_excluded"),
    group: AncillaryGroup.FLEXIBILITY
  },

  // Seat
  {
    name: "PRE_RESERVED_SEAT_ASSIGNMENT",
    icon: SeatRegularIcon,
    includedFeatureCopy: t("flights_fare_feature_0b5"),
    nonIncludedFeatureCopy: t("flights_apex_fare_feature_0b5_excluded"),
    sellableFeatureCopy: t("flights_apex_fare_feature_0b5_sellable"),
    group: AncillaryGroup.ON_THE_FLIGHT
  },
  {
    name: "BASIC_SEAT",
    icon: SeatRegularIcon,
    includedFeatureCopy: t("flights_fare_feature_050"),
    nonIncludedFeatureCopy: t("flights_fare_feature_050_excluded"),
    sellableFeatureCopy: t("flights_apex_fare_feature_050_sellable"),
    group: AncillaryGroup.ON_THE_FLIGHT
  },
  {
    name: "PREMIUM_SEAT",
    icon: SeatRegularIcon,
    includedFeatureCopy: t("flights_fare_feature_057"),
    nonIncludedFeatureCopy: t("flights_apex_fare_feature_05z_excluded"),
    sellableFeatureCopy: t("flights_apex_fare_feature_05z_sellable"),
    group: AncillaryGroup.ON_THE_FLIGHT
  },

  // Travel services
  {
    name: "DELAYED_ARRIVAL_WARRANTY",
    icon: TravelInsuranceIcon,
    includedFeatureCopy: t("flights_fare_feature_030"),
    nonIncludedFeatureCopy: t("flights_fare_feature_030_excluded")
  },
  {
    name: "DATE_CHANGE_WARRANTY",
    icon: TravelInsuranceIcon,
    includedFeatureCopy: t("flights_fare_feature_03r"),
    nonIncludedFeatureCopy: t("flights_fare_feature_03r_excluded")
  },
  {
    name: "NO_SHOW_FEE",
    icon: MoneyIncomingIcon,
    includedFeatureCopy: t("flights_fare_feature_0nn"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0nn_excluded")
  },
  {
    name: "NAME_CHANGE",
    icon: RefreshIcon,
    includedFeatureCopy: t("flights_fare_feature_0l7"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0l7_excluded")
  },
  {
    name: "PREMIUM_TRIP_INSURANCE",
    icon: TravelInsuranceIcon,
    includedFeatureCopy: t("flights_fare_feature_0pm"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0pm_excluded")
  },
  {
    name: "TRIP_INSURANCE",
    icon: TravelInsuranceIcon,
    includedFeatureCopy: t("flights_fare_feature_0bg"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0bg_excluded")
  },
  {
    name: "PRIORITY_BAGGAGE",
    icon: BaggageRollIcon,
    includedFeatureCopy: t("flights_fare_feature_0lf"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0lf_excluded")
  },
  {
    name: "FAST_TRACK",
    icon: FastTrackIcon,
    includedFeatureCopy: t("flights_fare_feature_029"),
    nonIncludedFeatureCopy: t("flights_fare_feature_029_excluded")
  },
  {
    name: "PRIORITY_CHECK_IN",
    icon: FastTrackIcon,
    includedFeatureCopy: t("flights_fare_feature_03p"),
    nonIncludedFeatureCopy: t("flights_fare_feature_03p_excluded"),
    group: AncillaryGroup.AT_THE_AIRPORT
  },
  {
    name: "PRIORITY_BOARDING",
    icon: FastTrackIcon,
    includedFeatureCopy: t("flights_fare_feature_0gc"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0gc_excluded"),
    group: AncillaryGroup.AT_THE_AIRPORT
  },
  {
    name: "PRIORITY_SECURITY",
    icon: FastTrackIcon,
    includedFeatureCopy: t("flights_fare_feature_0lw"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0lw_excluded"),
    group: AncillaryGroup.AT_THE_AIRPORT
  },
  {
    name: "LOUNGE_ACCESS",
    icon: RestaurantSeatIcon,
    includedFeatureCopy: t("flights_fare_feature_0bx"),
    nonIncludedFeatureCopy: t("flights_fare_feature_0bx_excluded"),
    group: AncillaryGroup.AT_THE_AIRPORT
  },
  { name: "CARBON_OFFSET", icon: TreeIcon },
  {
    name: "MILEAGE_ACCRUAL",
    icon: CertifiedRibbonIcon,
    includedFeatureCopy: t("flights_apex_fare_feature_057"),
    nonIncludedFeatureCopy: t("flights_apex_fare_feature_057_excluded")
  }
];

export const CHANGEABLE_TICKET_FEATURES: UIBrandedFareFeatureName[] = [
  "CHANGEABLE_TICKET",
  "CHANGE_ANYTIME",
  "CHANGE_BEFORE_DEPARTURE",
  "CHANGE_AFTER_DEPARTURE"
];

export const REFUNDABLE_TICKET_FEATURES: UIBrandedFareFeatureName[] = [
  "REFUNDABLE_TICKET",
  "REFUND_BEFORE_DEPARTURE",
  "REFUND_AFTER_DEPARTURE"
];
