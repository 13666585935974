import { UIFetchStatus, UIClientFetchError, UIOrder, UIOrderCreated, UIAddOnOrder } from "@flights/types/client";

export type State = {
  fetchStatus: UIFetchStatus;
  order?: UIOrder;
  orderCreated?: UIOrderCreated;
  addOnOrder?: UIAddOnOrder;
  error?: UIClientFetchError;
  orderCreatedByPriceChange?: boolean;
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  order: undefined,
  orderCreated: undefined,
  addOnOrder: undefined,
  error: undefined,
  orderCreatedByPriceChange: false
});
