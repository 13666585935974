import useClientMetrics from "hooks/useClientMetrics";
import { useEffect } from "react";
import { getBrandedFarePriceMismatchMetricName } from "components/elements/FareSelector/utils";
import { UIBrandedFare, UIFlightData } from "@flights/types/client";

export default function useTrackFirstFarePriceMismatch(
  flight: UIFlightData,
  brandedFareOffers: UIBrandedFare[] | undefined
) {
  const { trackClientMetric } = useClientMetrics();

  useEffect(() => {
    if (brandedFareOffers && brandedFareOffers.length > 0) {
      const firstFare = brandedFareOffers[0];

      trackClientMetric(
        getBrandedFarePriceMismatchMetricName(flight.priceBreakdown.total, firstFare.priceBreakdown.total)
      );
    }
  }, [brandedFareOffers, flight, trackClientMetric]);
}
