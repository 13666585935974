import React from "react";
import {
  BagPersonalItemIcon,
  BagPersonalItemNotAvailableIcon,
  BagHoldIcon,
  BagHoldNotAvailableIcon,
  CabinTrolleyIcon,
  CabinTrolleyNotAvailableIcon
} from "@bookingcom/bui-assets-react/streamline";
import { Icon, IconProps } from "@bookingcom/bui-react";

type BaggageIconProps = Omit<IconProps, "svg"> & {
  type: "cabin" | "checkedIn" | "personalItem";
  notAvailable?: boolean;
};

const availableIconMap = {
  cabin: CabinTrolleyIcon,
  checkedIn: BagHoldIcon,
  personalItem: BagPersonalItemIcon
};

const notAvailableIconMap = {
  cabin: CabinTrolleyNotAvailableIcon,
  checkedIn: BagHoldNotAvailableIcon,
  personalItem: BagPersonalItemNotAvailableIcon
};

export default function BaggageIcon(props: BaggageIconProps) {
  const { type, notAvailable, ...rest } = props;
  const iconMap = notAvailable ? notAvailableIconMap : availableIconMap;
  const svg = iconMap[type];

  return <Icon svg={svg} {...rest} />;
}
