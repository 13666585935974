import invert from "lodash/invert";
import { ETTrackingType } from "@flights/types/experiments";
import { ExperimentConfig } from ".";

const isInternal = process.env.BUILD_TARGET === "client" && !!window?.__GLOBAL_CONTEXT__?.isInternal;

const names: Record<string, Partial<Record<ETTrackingType, Record<string, string>>>> = {};

export const get = (experiment: string, type: ETTrackingType, value: number | undefined) =>
  ((names[experiment] || {})[type] || {})[value || ""];

export const set = (config: ExperimentConfig<string, string, string>) => {
  if (!isInternal) return;

  names[config.name] = {
    stage: invert(config.stages) as Record<string, string>,
    customGoal: invert(config.goals) as Record<string, string>
  };
};
