import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { UIFetchStatus } from "@flights/types/client";
import { useEffect } from "react";

const utils = createTrackingUtils({
  name: "flights_web_airlines_filter_select_deselect_desktop",
  stages: {
    search_results: 1,
    direct: 2,
    meta: 3,
    solo: 4,
    couple: 5,
    family: 6
  },
  goals: {
    select_all: 1,
    deselect_all: 2
  },
  goalsWithValue: []
});
const { stages, goals } = utils;

export const useSelectDeselectDesktop = (fetchStatus: UIFetchStatus, airlineLength: number | undefined) => {
  const { isMeta, isDesktop, isSolo, isCouple, isFamily } = useProfiler();
  /* sr fetch success and has rendered airline filter */
  useEffect(() => {
    if (isDesktop() && fetchStatus == "success" && airlineLength && airlineLength > 0) {
      stages.search_results();
      isMeta() ? stages.meta() : stages.direct();
      isSolo() && stages.solo();
      isCouple() && stages.couple();
      isFamily() && stages.family();
    }
  }, [airlineLength, fetchStatus, isCouple, isDesktop, isFamily, isMeta, isSolo]);
};

export const trackGoalSelectAll = () => goals.select_all();

export const trackGoalDeselectAll = () => goals.deselect_all();

export default utils;
