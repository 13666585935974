import { useEffect } from "react";
import { isFeatureRunningClientSide } from "utils/features";
import { UIIncludedProductBySegmentCompressed } from "@flights/types/client";
import useClientMetrics from "hooks/useClientMetrics";
import useEvents from "hooks/useEvents";

const useTrackInconsistentBaggageFeatures = (includedProducts?: UIIncludedProductBySegmentCompressed) => {
  const { trackClientMetric } = useClientMetrics();
  const { grumble } = useEvents();

  useEffect(() => {
    if (includedProducts && includedProducts.segments.length > 0 && !includedProducts.areAllSegmentsIdentical) {
      const allSegments = includedProducts.segments;
      trackClientMetric("checkout_branded_fares_inconsistent_included_luggage");
      if (isFeatureRunningClientSide("FLIGHTS_ENABLE_GRUMBLE_FOR_INCONSISTENT_BAGGAGES"))
        grumble("checkout_branded_fares_inconsistent_included_luggage", { allSegments });
    }
  }, [includedProducts, trackClientMetric, grumble]);
};

export default useTrackInconsistentBaggageFeatures;
