import { CABIN_CLASS_MAP, SPACER_PADDED } from "../constants";
import { t, I18n } from "@bookingcom/lingojs-core";
import {
  UIMassUnit,
  UISizeUnit,
  UILuggageAllowance,
  UIOccupancyAndCabinClass,
  UIFlightSegment,
  UICabinClass,
  UILuggageType
} from "@flights/types/client";

export type SimpleI18n = {
  trans: typeof I18n.prototype.trans;
};

type LuggageAllowanceCopy = {
  title: string;
  description?: string;
  descritpionWithCount: string[];
};

type LuggageAllowanceFormatting = {
  title: string;
  size?: string;
  weightPerPiece?: string;
  totalWeight?: string; // for WEIGHT_BASED checked-in luggage
};

function occupancyAndCabinClass(data: UIOccupancyAndCabinClass, i18n: SimpleI18n): string {
  const cabinClass = CABIN_CLASS_MAP[data.cabinClass];
  const travelers = data.adults + data.children.length;
  if (cabinClass) {
    return i18n.trans(
      t("flights_search_traveller_criteria", {
        variables: {
          passenger_count: i18n.trans(
            t("flights_search_passenger_count_mix", {
              num_exception: travelers,
              variables: { num_travellers: travelers }
            })
          ),
          cabin_class: i18n.trans(cabinClass)
        }
      })
    );
  } else {
    return "";
  }
}

function cabinClassFromSegment(segment: UIFlightSegment, i18n: SimpleI18n): string {
  const CLASSES_UI_RENDERING_ORDER: UICabinClass[] = ["FIRST", "BUSINESS", "PREMIUM_ECONOMY", "ECONOMY"];

  // Getting the sorted & unique list of all applied classes in the segment
  // 1. Get the full list of all cabin classes in the segment
  // 2. Make the list unique
  // 3. Sort from the highest cabin class to the lowest
  const segmentAppliedCabinClasses = [...new Set(segment.legs.map(({ cabinClass }) => cabinClass))].sort(
    (a, b) => CLASSES_UI_RENDERING_ORDER.indexOf(a) - CLASSES_UI_RENDERING_ORDER.indexOf(b)
  );

  return segmentAppliedCabinClasses
    .map((cabinClass) => i18n.trans(CABIN_CLASS_MAP[cabinClass]))
    .join(i18n.trans(t("flights_cabin_class_separator")) + " ");
}

function luggageAllowanceCopy(allowance: UILuggageAllowance, i18n: SimpleI18n): LuggageAllowanceCopy {
  let title;
  let description;
  let descritpionWithCount;

  if (allowance.luggageType === "CHECKED_IN") {
    const { maxPiece, maxWeightPerPiece, massUnit } = allowance;

    title = i18n.trans(t("flights_ancillary_baggage_checked"));
    switch (allowance.ruleType) {
      case "PIECE_BASED": {
        let weightCopy;
        if (maxWeightPerPiece) {
          weightCopy = i18n.trans(
            t("flights_ancillary_baggage_max_weight", {
              variables: { bag_weight: weight(maxWeightPerPiece, i18n, massUnit) }
            })
          );
        }
        const countCopy = i18n.trans(
          t("flights_ancillary_baggage_count", {
            variables: {
              nbsp: "\u00a0",
              num_exception: maxPiece,
              num_bags: maxPiece
            }
          })
        );
        description = weightCopy;
        descritpionWithCount = description ? [description, countCopy] : [countCopy];
        break;
      }
      case "WEIGHT_BASED": {
        description = i18n.trans(
          t("flights_ancillary_baggage_max_weight", {
            variables: { bag_weight: weight(allowance.maxTotalWeight, i18n, massUnit) }
          })
        );
        descritpionWithCount = [description];
        break;
      }
      default:
        throw new Error("Unknown rule type");
    }
  } else if (allowance.luggageType === "HAND") {
    const { maxPiece, sizeRestrictions, maxWeightPerPiece, massUnit } = allowance;

    title = i18n.trans(t("flights_ancillary_baggage_cabin"));

    let sizeCopy;
    if (sizeRestrictions) {
      const { maxWidth, maxHeight, maxLength } = sizeRestrictions;
      if (maxWidth && maxHeight && maxLength) {
        sizeCopy = dimensions(maxHeight, maxWidth, maxLength, i18n, sizeRestrictions?.sizeUnit);
      }
    }

    let weightCopy;
    if (maxWeightPerPiece) {
      weightCopy = weight(maxWeightPerPiece, i18n, massUnit);
    }

    const countCopy = i18n.trans(
      t("flights_ancillary_baggage_count", {
        variables: {
          nbsp: "\u00a0",
          num_exception: maxPiece,
          num_bags: maxPiece
        }
      })
    );

    // when all both size and weight are available
    if (sizeCopy && weightCopy) {
      description = i18n.trans(
        t("flights_ancillary_baggage_max_weight_dimensions", {
          variables: {
            bag_weight: weightCopy,
            bag_dimensions: sizeCopy
          }
        })
      );
    } else if (weightCopy) {
      // if only weight is available
      description = i18n.trans(
        t("flights_ancillary_baggage_max_weight", {
          variables: {
            bag_weight: weightCopy
          }
        })
      );
    } else if (sizeCopy) {
      // if only dimensions are available
      description = i18n.trans(
        t("flights_ancillary_baggage_max_dimensions", {
          variables: {
            bag_dimensions: sizeCopy
          }
        })
      );
    }
    descritpionWithCount = description ? [description, countCopy] : [countCopy];
  } else {
    throw new Error("Unknown luggage type");
  }
  return {
    title,
    description,
    descritpionWithCount
  };
}

function luggageAllowanceCopyV2(
  allowance: UILuggageAllowance,
  i18n: SimpleI18n,
  shouldOmitMaxForWeightCopy?: boolean
): LuggageAllowanceFormatting {
  /*
    This is 2nd version of luggage allowance formatting, it generates new phrasing needed for new flight details.
    E.g. "3 cabin bags" and "Up to 10 kg each".
    It's different from what we have in `luggageAllowanceCopy()`.
    We'll migrate to the new luggage design and new phrasing in other parts of the product (incl. emails),
    so let's monitor the migration progress and remove `luggageAllowanceCopy()`
    to get rid of inconsistency if/when it's safe to do.
  */

  const title = piecesOfLuggage(allowance, i18n);

  let size;
  let weightPerPiece;
  let totalWeight;

  const { maxWeightPerPiece, massUnit, maxPiece } = allowance;

  switch (allowance.luggageType) {
    case "PERSONAL_ITEM":
      size = i18n.trans(t("flights_personal_item_disclaimer", { num_exception: maxPiece }));
      break;

    case "HAND":
      const { sizeRestrictions = {} } = allowance;
      const { maxWidth, maxHeight, maxLength, sizeUnit } = sizeRestrictions;

      if (maxWidth && maxHeight && maxLength) {
        size = dimensions(maxHeight, maxWidth, maxLength, i18n, sizeUnit);
      }
      if (maxWeightPerPiece && massUnit) {
        weightPerPiece = shouldOmitMaxForWeightCopy
          ? weight(maxWeightPerPiece, i18n, massUnit)
          : maxWeight(maxWeightPerPiece, i18n, massUnit);
      }

      break;

    case "CHECKED_IN":
      if (allowance.ruleType === "PIECE_BASED") {
        if (maxWeightPerPiece && massUnit) {
          weightPerPiece = shouldOmitMaxForWeightCopy
            ? weight(maxWeightPerPiece, i18n, massUnit)
            : maxWeight(maxWeightPerPiece, i18n, massUnit);
        }
      } else if (allowance.ruleType === "WEIGHT_BASED") {
        const { maxTotalWeight } = allowance;
        if (maxTotalWeight && massUnit) {
          totalWeight = shouldOmitMaxForWeightCopy
            ? weight(maxTotalWeight, i18n, massUnit)
            : maxWeight(maxTotalWeight, i18n, massUnit);
        }

        if (maxWeightPerPiece && massUnit) {
          weightPerPiece = i18n.trans(
            t("flights_baggage_weight_each", {
              variables: {
                max_weight: weight(maxWeightPerPiece, i18n, massUnit)
              }
            })
          );
        }
      }
      break;

    default:
      const { luggageType } = allowance;
      throw Error(`Unknown luggage type: ${luggageType}`);
  }

  return {
    title,
    size,
    weightPerPiece,
    totalWeight
  };
}

export function maxWeight(weightValue: number, i18n: SimpleI18n, unit: UIMassUnit = "KG"): string {
  const weightCopy = weight(weightValue, i18n, unit);

  return i18n.trans(
    t("flights_ancillary_baggage_max_weight", {
      variables: { bag_weight: weightCopy }
    })
  );
}

export function weight(weightValue: number, i18n: SimpleI18n, unit: UIMassUnit = "KG"): string {
  return unit?.toLowerCase() === "kg"
    ? i18n.trans(
        t("flights_baggage_size_kg", {
          variables: { nbsp: "\u00a0", num_exception: weightValue, num_weight: weightValue }
        })
      )
    : i18n.trans(
        t("flights_baggage_size_lbs", {
          variables: { nbsp: "\u00a0", num_exception: weightValue, num_weight: weightValue }
        })
      );
}

function dimensions(height: number, width: number, length: number, i18n: SimpleI18n, unit?: UISizeUnit): string {
  const u = unit?.toLowerCase();

  if (u === "cm") {
    return i18n.trans(
      t("flights_baggage_dimensions_cm", {
        variables: { num_exception: height, num_height: height, num_width: width, num_length: length }
      })
    );
  } else if (u === "inch") {
    return i18n.trans(
      t("flights_baggage_dimensions_inch", {
        variables: { num_exception: height, num_height: height, num_width: width, num_length: length }
      })
    );
  } else {
    return [height, width, length].join(SPACER_PADDED);
  }
}

export function applyUnitDecimal(num: number): number {
  return Number(num.toFixed(1));
}

function luggage(allowance: UILuggageAllowance, i18n: SimpleI18n): string {
  switch (allowance.luggageType) {
    case "PERSONAL_ITEM":
      return i18n.trans(t("flights_apex_sr_baggage_personal_item"));
    case "HAND":
      return i18n.trans(t("flights_apex_sr_baggage_cabin_bag"));
    case "CHECKED_IN":
      return i18n.trans(t("flights_apex_sr_baggage_checked_bag"));
  }
}

function sellableLuggage(luggageType: UILuggageType, i18n: SimpleI18n): string {
  switch (luggageType) {
    case "PERSONAL_ITEM":
      return i18n.trans(t("flights_apex_fare_feature_personal_item_sellable", { num_exception: 2 }));
    case "HAND":
      return i18n.trans(t("flights_apex_fare_feature_cabin_bag_sellable", { num_exception: 2 }));
    case "CHECKED_IN":
      return i18n.trans(t("flights_apex_fare_feature_checked_bag_sellable", { num_exception: 2 }));
  }
}

function excludedLuggage(luggageType: UILuggageType, i18n: SimpleI18n): string {
  switch (luggageType) {
    case "PERSONAL_ITEM":
      return i18n.trans(t("flights_apex_fare_feature_personal_item_excluded", { num_exception: 2 }));
    case "HAND":
      return i18n.trans(t("flights_fare_feature_cabin_excluded", { num_exception: 2 }));
    case "CHECKED_IN":
      return i18n.trans(t("flights_fare_feature_checked_excluded", { num_exception: 2 }));
  }
}

function piecesOfLuggage(allowance: UILuggageAllowance, i18n: SimpleI18n): string {
  const { luggageType, maxPiece } = allowance;
  const lingoParams = { num_exception: maxPiece, variables: { num_bags: maxPiece } };

  switch (luggageType) {
    case "PERSONAL_ITEM":
      return i18n.trans(t("flights_baggage_details_count_personal", lingoParams));
    case "HAND":
      return maxPiece
        ? i18n.trans(t("flights_baggage_details_count_cabin", lingoParams))
        : i18n.trans(t("flights_ancillary_baggage_cabin"));
    case "CHECKED_IN":
      return i18n.trans(t("flights_baggage_details_count_checked", lingoParams));
  }
}

function piecesOfLuggageWithWeight(allowance: UILuggageAllowance, i18n: SimpleI18n): string {
  const { maxPiece, maxWeightPerPiece, massUnit } = allowance;

  switch (allowance.luggageType) {
    case "PERSONAL_ITEM":
      return i18n.trans(
        t("flights_baggage_details_count_personal", { num_exception: maxPiece, variables: { num_bags: maxPiece } })
      );

    case "HAND":
      if (maxWeightPerPiece) {
        return i18n.trans(
          t("flights_ancillaries_cabin_bag_option", {
            num_exception: maxPiece,
            variables: { num_bags: maxPiece, weight: weight(maxWeightPerPiece, i18n, massUnit) }
          })
        );
      } else {
        return piecesOfLuggage(allowance, i18n);
      }

    case "CHECKED_IN":
      const maxWeight = allowance.ruleType === "PIECE_BASED" ? maxWeightPerPiece : allowance.maxTotalWeight;
      if (maxWeight) {
        return i18n.trans(
          t("flights_ancillaries_checkin_bag_option", {
            num_exception: maxPiece,
            variables: { num_bags: maxPiece, weight: weight(maxWeight, i18n, massUnit) }
          })
        );
      } else {
        return piecesOfLuggage(allowance, i18n);
      }
  }
}

function durationInMinutes(departure: string, arrival: string): number {
  if (!arrival || !departure) return 0;
  const duration = Date.parse(arrival) - Date.parse(departure);
  return Math.ceil(duration / 1000 / 60);
}

function minutesLeft(minutes: number) {
  return minutes % 60;
}

function minutesPadded(minutes: number) {
  return minutesLeft(minutes).toString().padStart(2, "0");
}

interface ConvertedMinutesToHours {
  sign: string;
  hours: number;
  minutes: number;
}

export const convertMinutesToHours = (minutes: number): ConvertedMinutesToHours => {
  const sign = minutes < 0 ? "-" : "";

  if (minutes === 0) {
    return {
      sign,
      hours: 0,
      minutes: 0
    };
  }

  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const remainingMinutes = minutesLeft(absMinutes);

  return {
    sign,
    hours,
    minutes: remainingMinutes
  };
};

function flightDuration(minutes: number, i18n: SimpleI18n) {
  const convertedMinutesToHours = convertMinutesToHours(minutes);

  if (convertedMinutesToHours.minutes === 0 && convertedMinutesToHours.hours === 0) {
    return i18n.trans(
      t("flights_duration_mins", {
        variables: { num_exception: 0, num_mins: minutesPadded(0) }
      })
    );
  }

  const result = [convertedMinutesToHours.sign];

  if (convertedMinutesToHours.hours > 0) {
    result.push(
      i18n.trans(
        t("flights_duration_hours", {
          variables: {
            num_exception: convertedMinutesToHours.hours,
            num_hours: convertedMinutesToHours.hours.toString()
          }
        })
      )
    );
  }

  if (convertedMinutesToHours.minutes > 0) {
    if (convertedMinutesToHours.hours > 0) {
      result.push(" ");
    }

    result.push(
      i18n.trans(
        t("flights_duration_mins", {
          variables: {
            num_exception: convertedMinutesToHours.minutes,
            num_mins: minutesPadded(convertedMinutesToHours.minutes)
          }
        })
      )
    );
  }

  return result.join("");
}

function flightDurationAria(minutes: number, i18n: SimpleI18n) {
  const convertedMinutesToHours = convertMinutesToHours(minutes);

  if (convertedMinutesToHours.hours === 0 && convertedMinutesToHours.minutes === 0) {
    return i18n.trans(
      t("a11y_flights_duration_minutes_announce", {
        variables: { num_exception: 0, num_minutes: minutesPadded(0) }
      })
    );
  }

  const result = [convertedMinutesToHours.sign];

  if (convertedMinutesToHours.hours > 0) {
    result.push(
      i18n.trans(
        t("a11y_flights_duration_hours_announce", {
          variables: {
            num_exception: convertedMinutesToHours.hours,
            num_hours: convertedMinutesToHours.hours.toString()
          }
        })
      )
    );
  }

  if (convertedMinutesToHours.minutes > 0) {
    if (convertedMinutesToHours.hours > 0) {
      result.push(" ");
    }

    result.push(
      i18n.trans(
        t("a11y_flights_duration_minutes_announce", {
          variables: {
            num_exception: convertedMinutesToHours.minutes,
            num_minutes: minutesPadded(convertedMinutesToHours.minutes)
          }
        })
      )
    );
  }
  return result.join("");
}

function stops(count: number, i18n: SimpleI18n) {
  const copyVariables = { num_exception: count, variables: { num_stops: count } };
  if (count === 0) {
    return i18n.trans(t("flights_filter_stops_none", { ...copyVariables }));
  } else {
    return i18n.trans(t("flights_route_num_stops", { ...copyVariables }));
  }
}

// USAGE:
// this is based on the client (browser).
// it will return different values when SSR
function utcTimeOffset(d: Date = new Date()): { offset: number; offsetSign: "+" | "-" } {
  return {
    offset: Math.abs(d.getTimezoneOffset()) / 60,
    offsetSign: Math.sign(-1 * d.getTimezoneOffset()) >= 0 ? "+" : "-"
  };
}

function truncateWithEllipsis(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }

  if (maxLength <= 3) {
    return "...";
  }

  const truncatedString = str.slice(0, maxLength - 3);
  return `${truncatedString}...`;
}

export default {
  occupancyAndCabinClass,
  cabinClassFromSegment,
  luggageAllowanceCopy,
  luggageAllowanceCopyV2,
  luggage,
  sellableLuggage,
  excludedLuggage,
  piecesOfLuggage,
  piecesOfLuggageWithWeight,
  durationInMinutes,
  flightDuration,
  flightDurationAria,
  weight,
  dimensions,
  applyUnitDecimal,
  utcTimeOffset,
  stops,
  truncateWithEllipsis
};
