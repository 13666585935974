import React, { useEffect } from "react";
import posed from "react-pose";

import { useTheme } from "@bookingcom/bui-react";

import { WithTestID } from "@flights/types/testing";
import NotAvailableIllustration from "../../../assets/illustrations/not-available.svg";

import useUserAgent from "hooks/useUserAgent";
import { useActions, useStore } from "../../../store";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import Frame from "../Frame";
import styles from "./LoadingScreen.module.css";
import NoSSR from "../../../app/NoSSR";
import { mcn } from "utils/mergeClassnames";

interface Props {
  illustration?: React.FC<{ style: React.CSSProperties }>;
  title?: string;
  children?: React.ReactNode;
  visibleDefault?: boolean;
  childrenFullHeight?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
const Overlay = posed.div({
  visible: {
    applyAtStart: { display: "block" },
    opacity: 1
  },
  hidden: {
    applyAtEnd: { display: "none" },
    opacity: 0
  }
});

const LoadingScreen = (props: Props & WithTestID) => {
  const { illustration: Illustration, title, children, visibleDefault, childrenFullHeight } = props;
  const { isMobile } = useUserAgent();
  const theme = useTheme();
  const store = useStore();
  const { visible } = store.loadingScreen;
  const ariaLiveActions = useActions(AriaLiveActions);
  const inlineSvgStyles = { maxWidth: "100%", maxHeight: "100%" };

  useEffect(() => {
    if (visible && title) {
      ariaLiveActions.setMessage({
        message: title,
        type: "assertive"
      });
    } else {
      ariaLiveActions.reset();
    }
  }, [ariaLiveActions, visible, title]);

  return (
    <NoSSR>
      <Overlay
        className={mcn(styles.loadingScreen, !isMobile && styles.loadingScreenDesktop)}
        pose={visible || visibleDefault ? "visible" : "hidden"}
        data-testid={props["data-testid"]}
        role="alertdialog"
        aria-label={title}
      >
        <Frame direction="column" attributes={{ style: { height: "100%" } }}>
          <Frame
            grow={1}
            justifyContent="center"
            attributes={{ style: { backgroundColor: theme.colors.color_background_base_alt, overflow: "hidden" } }}
          >
            {Illustration ? (
              <Illustration style={inlineSvgStyles} />
            ) : (
              <NotAvailableIllustration style={inlineSvgStyles} />
            )}
          </Frame>
          <Frame
            attributes={{
              style: { background: theme.colors.color_background_base, height: childrenFullHeight ? "auto" : "25%" }
            }}
            justifyContent="center"
            p={4}
          >
            {children}
          </Frame>
        </Frame>
      </Overlay>
    </NoSSR>
  );
};
export default LoadingScreen;
