import { createTrackingUtils } from "utils/experiments";
import { UIFlightData } from "@flights/types/client";
import { SBSearchType } from "@bookingcom/flights-searchbox/@types/client";

const utils = createTrackingUtils({
  name: "flights_apex_web_expand_branded_fares_on_search",
  stages: {
    main: 1,
    one_way: 2,
    round_trip: 3,
    bf_count_3_to_5: 4,
    bf_count_gte_8: 5,
    false_positives_gte_3: 6,
    offers_visible_lt_1: 7,
    offers_visible_gte_1: 8,
    offers_visible_gt_1: 9
  },
  goals: {
    proceed_with_base_offer: 1,
    proceed_with_fare: 2,
    hover_fare: 3,
    collapse_fares: 4,
    scroll_fares: 5
  },
  goalsWithValue: ["branded_fares_on_sr_loader_visible_ms", "collapse_branded_fares_on_sr", "on_search_results_page_ms"]
});

const trackStages = (flights: UIFlightData[], searchType: SBSearchType) => {
  utils.stages.main();

  const bfCount = flights.filter((f) => f.requestableBrandedFares).length;

  if (bfCount >= 3 && bfCount <= 5) utils.stages.bf_count_3_to_5();
  if (bfCount >= 8) utils.stages.bf_count_gte_8();
  if (searchType === "ONEWAY") utils.stages.one_way();
  if (searchType === "ROUNDTRIP") utils.stages.round_trip();
};

const trackFlightCardsVisible = (isInViewport: boolean, index: number) => {
  if (window.scrollY > 0) return;
  if (!isInViewport && index === 0) utils.stages.offers_visible_lt_1();
  if (isInViewport && index === 0) utils.stages.offers_visible_gte_1();
  if (isInViewport && index > 0) utils.stages.offers_visible_gt_1();
};

export default { ...utils, trackStages, trackFlightCardsVisible };
