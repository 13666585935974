import React, { useCallback, useState, useEffect } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n, Trans } from "@bookingcom/lingojs-react";
import { useHistory } from "react-router-dom";
import { SearchRemoveIcon } from "@bookingcom/bui-assets-react/streamline";
import { actions as searchActions } from "../../../../store/search/actions";
import { useActions, useStore } from "../../../../store";

import { Button, Icon, Text, useTheme } from "@bookingcom/bui-react";
import useSearchFilters from "../../../../hooks/useSearchFilters";
import useCreateUrl from "../../../../hooks/useCreateUrl";
import Frame from "components/elements/Frame";
import styles from "./RemoveDirectFlights.module.css";

export const RemoveDirectFlights: React.FunctionComponent = () => {
  const theme = useTheme();
  const history = useHistory();
  const { createUrl } = useCreateUrl();
  const i18n = useI18n() as I18nChildContext;
  const actions = useActions(searchActions);
  const store = useStore();
  const { search } = store;
  const { applyFilters } = useSearchFilters();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    if (shouldUpdate) {
      applyFilters(search.filtersDraft);
    }
  });

  const removeDirectFlightsFilter = useCallback(() => {
    actions.setFiltersDraft({ ...search.filters, stops: undefined });
    setShouldUpdate(true);
  }, [actions, search.filters]);

  return (
    <Frame mt={6} mb={6} attributes={{ style: { minHeight: "40vh" } }}>
      <Frame m={3} alignItems="center">
        <Icon svg={SearchRemoveIcon} size="large" className={styles.searchRemoveIcon} />
        <Text variant="headline_3" className={styles.centerStyle}>
          {i18n.trans(t("flights_search_no_results_header"))}
        </Text>
      </Frame>
      <Text variant="body_2" className={styles.centerStyle}>
        <Trans
          tag="flights_noresults_include_stops_message"
          variables={{ start_link: "<0>", end_link: "</0>" }}
          components={[
            <a
              key={0}
              href="##"
              style={{ color: theme.colors.color_action_foreground }}
              onClick={removeDirectFlightsFilter}
            />
          ]}
        />
      </Text>
      <Frame alignItems="center" m={6}>
        <Button
          onClick={() => {
            actions.resetFiltersButKeep({ stops: undefined });
            history.push(createUrl("/"));
          }}
        >
          {i18n.trans(t("flights_search_change_search_button"))}
        </Button>
      </Frame>
    </Frame>
  );
};
