import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { UIFlightSegment } from "@flights/types/client";

const utils = createTrackingUtils({
  name: "flights_web_sr_card_itinerary_redesign_desktop",
  stages: {
    sr: 1,
    direct: 2,
    meta: 3,
    one_way: 4,
    round_trip: 5,
    multi_city: 6,
    solo: 7,
    couples: 8,
    family: 9
  },
  goals: { click_only_direct: 1, click_only_nondirect: 2, click_direct_nondirect_mix: 3 },
  goalsWithValue: []
});

const { stages, goals } = utils;

export const useItineraryRedesignDesktop = () => {
  const { isCouple, isOneWay, isRountrip, isMultiStop, isSolo, isFamily, isDirect, isMeta } = useProfiler();
  stages.sr();
  isDirect() && stages.direct();
  isMeta() && stages.meta();
  isOneWay() && stages.one_way();
  isRountrip() && stages.round_trip();
  isMultiStop() && stages.multi_city();
  isSolo() && stages.solo();
  isCouple() && stages.couples();
  isFamily() && stages.family();
};

export const trackGoalItineraryRedesignDesktop = (segments: UIFlightSegment[]) => {
  const areAllSegmentsDirect = segments.every(({ legs }) => legs.length === 1);
  const areAllSegmentsNonDirect = segments.every(({ legs }) => legs.length > 1);
  const areSegmentsMixed = !areAllSegmentsDirect && !areAllSegmentsNonDirect;

  areAllSegmentsDirect && goals.click_only_direct();
  areAllSegmentsNonDirect && goals.click_only_nondirect();
  areSegmentsMixed && goals.click_direct_nondirect_mix();
};

export default utils;
