import {
  UIFetchStatus,
  UIAvailableExtraProducts,
  UIClientFetchError,
  UIProductSelection,
  UIPreOptInSelected,
  UIPageLoadTime
} from "@flights/types/client";

type ExtraProductsState = {
  fetchStatus: UIFetchStatus;
  extraProducts?: UIAvailableExtraProducts;
  selected: UIProductSelection;
  error?: UIClientFetchError;
  preOptedAncillaries: UIPreOptInSelected;
  flexibilitySelected: boolean;
  showFlexibilityError: boolean;
  pageLoadTime: UIPageLoadTime;
};

export const getInitialState = (): ExtraProductsState => ({
  fetchStatus: "initial",
  extraProducts: {},
  selected: {},
  error: undefined,
  preOptedAncillaries: {},
  flexibilitySelected: false,
  showFlexibilityError: false,
  pageLoadTime: {}
});

export type State = ExtraProductsState;
