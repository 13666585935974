import { Card, Text } from "@bookingcom/bui-react";
import { t, Trans, useI18n } from "@bookingcom/lingojs-react";
import React, { useCallback, useEffect, useRef } from "react";
import { trackCustomGoal } from "utils/et";
import { onEnterKey } from "../../../utils/keyboard";
import FeedbackModal from "./FeedbackModal";
import useToggle from "hooks/useToggle";
import styles from "./Feedback.module.css";
import { mcn } from "utils/mergeClassnames";
import useGlobalContext from "hooks/useGlobalContext";
import useIsInViewport from "hooks/useIsInViewport";

type Props = {
  onClick?: () => void;
  roundedBorder?: boolean;
};

const Feedback: React.FC<Props> = ({ onClick, roundedBorder }) => {
  const { userAgent } = useGlobalContext();
  const feedbackEntryBoundRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(feedbackEntryBoundRef.current);
  const { visible: modalActive, show: showModal, hide: hideModal } = useToggle();
  const i18n = useI18n();

  const xmExpTag = userAgent.isMobile ? "m_xm_flights_conf_survey" : "d_xm_flights_conf_survey";

  const onInvitationClick = useCallback(() => {
    trackCustomGoal("flights_web_ddot_sticky_price_breakdown", 2);
    trackCustomGoal(xmExpTag, 3);
    showModal();
    onClick && onClick();
  }, [showModal, onClick, xmExpTag]);

  useEffect(() => {
    if (isInViewport) {
      trackCustomGoal(xmExpTag, 2);
    }
  }, [xmExpTag, isInViewport]);

  return (
    <>
      <div
        ref={feedbackEntryBoundRef}
        className={mcn(styles.container, roundedBorder && styles.containerRounded)}
        onClick={onInvitationClick}
        onKeyPress={(e) => onEnterKey(e.key, () => onInvitationClick())}
        tabIndex={0}
        role="button"
        aria-label={i18n.trans(t("flights_feedback_header"))}
        title={i18n.trans(t("flights_feedback_header"))}
      >
        <Card>
          <Text variant="strong_2" color="callout" className={styles.link} tagName="h2">
            <Trans tag="flights_feedback_header" />
          </Text>
          <Text variant="body_2">
            <Trans tag="flights_feedback_message" />
          </Text>
        </Card>
      </div>

      <FeedbackModal active={modalActive} onClose={hideModal} />
    </>
  );
};

export default Feedback;
