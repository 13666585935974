import React, { useEffect } from "react";

import { useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import TooManyFiltersIllustration from "../../../../assets/illustrations/too-many-filters.svg";
import Frame from "components/elements/Frame";
import { trackCustomGoal } from "../../../../utils/et";
import useUserAgent from "../../../../hooks/useUserAgent";

export const TooManyFilters = () => {
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();

  useEffect(() => {
    if (isMobile) {
      trackCustomGoal("flight_web_sr_traffic_aa_mdot", 4);
    } else {
      trackCustomGoal("flight_web_sr_traffic_aa_desktop", 4);
    }
  }, [isMobile]);

  return (
    <Frame justifyContent="center">
      <Frame
        attributes={{
          style: {
            ...theme.fonts.small.font_emphasized_2,
            color: theme.colors.color_foreground_alt,
            textAlign: "center"
          }
        }}
        m={4}
      >
        {i18n.trans(t("flights_filter_error_no_results"))}
      </Frame>
      <Frame grow={1} m={4}>
        <Frame>
          <TooManyFiltersIllustration style={{ maxHeight: "300px" }} />
        </Frame>
      </Frame>
    </Frame>
  );
};
