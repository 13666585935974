import { UIFetchStatus, UICartDetails, UIClientFetchError } from "@flights/types/client";

export type State = {
  fetchStatus: UIFetchStatus;
  cartDetails?: UICartDetails;
  error?: UIClientFetchError;
  addProductFetchStatus?: UIFetchStatus;
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  addProductFetchStatus: "initial",
  cartDetails: undefined,
  error: undefined
});
