import React from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputRadio, Stack, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import WithSuffix from "../WithSuffix";
import useUserAgent from "hooks/useUserAgent";
import { trackExperiment } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";

type StopsControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["stops"]>;

type StopsProps = {
  value: StopsControllerProps["value"];
  options: StopsControllerProps["options"];
  onChange: StopsControllerProps["set"];
  /* start - flights_web_filters_count_blackout_desktop */
  hideCount?: boolean;
  /* end - flights_web_filters_count_blackout_desktop */
};

export default function Stops(props: StopsProps) {
  const { options, value, onChange, hideCount = false } = props;
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const isRoundPrice =
    !isOfMetaOrigin() && (isMobile || (!isMobile && trackExperiment("flights_web_roundprice_sr_fd_direct_desktop")));
  /*enable round price only from direct not meta user*/
  return (
    <Stack gap={isMobile ? 4 : 2}>
      {options.map((option, i) => (
        <WithSuffix
          key={`${option.label}-${i}-${option.count}`}
          suffix={
            !hideCount && <span data-testid={`search_filter_stops_radio_values_${option.label}`}>{option.count}</span>
          }
        >
          <InputRadio
            value={String(option.value)}
            name="filters-stops-option"
            onChange={({ value }) => onChange(Number(value))}
            checked={option.value === value}
            attributes={{ "data-testid": `search_filter_stops_radio_${option.label}` }}
            label={
              <>
                <Text variant="body_2">{option.label}</Text>
                <Text tagName="span" color="neutral_alt" variant="body_2">
                  {i18n.trans(
                    t("flights_filters_lowest_price", {
                      variables: {
                        lowest_price: isRoundPrice ? option.roundPrice : option.price
                      }
                    })
                  )}
                </Text>
              </>
            }
          />
        </WithSuffix>
      ))}
    </Stack>
  );
}
