/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Actionable,
  Text,
  SheetContainer,
  InputRadio,
  Bubble,
  ActionBar,
  useTheme,
  Divider,
  Title,
  Button,
  Stack,
  Icon,
  Chip
} from "@bookingcom/bui-react";
import { SortIcon, FilterFunnelIcon } from "@bookingcom/bui-assets-react/streamline";
import { useI18n, I18nChildContext, Trans } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { SBSortingOrder } from "@bookingcom/flights-searchbox/@types/client";
import { useSelectorAppliedFilters } from "../../../store/selectors/appliedFilters";
import { useStore, useActions } from "../../../store";
import { actions as searchActions } from "../../../store/search/actions";
import useCreateUrl from "../../../hooks/useCreateUrl";
import useSearchFilters from "../../../hooks/useSearchFilters";
import SearchFilters from "../SearchFilters";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import useModalDialog from "hooks/useModalDialog";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import useUserAgent from "hooks/useUserAgent";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import Frame from "../Frame";
import styles from "./SearchToolbar.module.css";
import { mcn } from "utils/mergeClassnames";
import useExpCopyBestNewWord from "hooks/useExpCopyBestNewWord";

type ToolbarItemProps = {
  IconSVG: React.FC<{}>;
  text: string;
  number?: number;
  onClick: () => void;
  ariaLabel?: string;
};

type RadioButtonChangeHandlerProps = {
  id: SBSortingOrder;
  checked: boolean;
};

type RadioButtonProps = {
  label: string;
  id: SBSortingOrder;
  checked: boolean;
  onChange: (props: RadioButtonChangeHandlerProps) => void;
  children?: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
const ToolbarItem: React.FunctionComponent<ToolbarItemProps> = ({ IconSVG, text, number, onClick, ariaLabel }) => {
  return (
    <Actionable
      onClick={onClick}
      attributes={{
        "aria-label": ariaLabel,
        style: {
          width: "100%",
          justifyContent: "center",
          alignItems: "center"
        },
        "data-testid": `search_toolbar_${ariaLabel}`
      }}
    >
      <Stack direction="row" alignItems="center">
        <Icon svg={IconSVG} size="small" />
        <Text variant="body_2">{text}</Text>
        {number && number > 0 ? <Bubble text={number} ariaLabel={`${number} applied filters`} /> : null}
      </Stack>
    </Actionable>
  );
};

const RadioButton: React.FunctionComponent<RadioButtonProps> = ({ label, id, checked, onChange, children }) => {
  return (
    <div
      onClick={() => {
        onChange({ id, checked: true });
      }}
      role="radio"
      aria-checked={checked}
      tabIndex={0}
    >
      <InputRadio
        label={label}
        name={id}
        key={id}
        checked={checked}
        attributes={{ "data-testid": `search_toolbar_radio_${label}` }}
      />
      {children}
    </div>
  );
};

const SearchToolbar: React.FunctionComponent = () => {
  const i18n = useI18n() as I18nChildContext;
  const { enhanceURLSearchParams } = useCreateUrl();
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const appliedFilters = useSelectorAppliedFilters(store);
  const { applyFilters } = useSearchFilters();
  const theme = useTheme();
  const actions = useActions(searchActions);
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const expCopyBestNewWord = useExpCopyBestNewWord();

  const {
    searchResults: {
      availableFilters: { filteredTotalCount = 0, totalCount = 0 }
    }
  } = store;

  const initialSort = useMemo(() => {
    const q = new URLSearchParams(location.search);
    return q.get("sort") || "BEST";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [state, setState] = useState({ selected: initialSort });
  const [isSortingDrawerShown, setIsSortingDrawerShown] = useState(false);
  const [isFilterModalShown, setIsFilterModalShown] = useState(false);
  const [isCriteriaModified, setCriteriaModifiedStatus] = useState(false);
  const { isMobile } = useUserAgent();
  const isMeta = isOfMetaOrigin();
  const Modal = useModalDialog();
  const sortCopy = i18n.trans(t("flights_filter_sort"));
  const filterCopy = i18n.trans(t("flights_filter_button"));
  const doneCopy = i18n.trans(t("flights_action_done"));
  const bestCopy =
    (isSortingDrawerShown && expCopyBestNewWord.getSortingTitle()) || i18n.trans(t("flights_search_sort_best"));
  const cheapestCopy = i18n.trans(t("flights_search_sort_cheapest"));
  const fastestCopy = i18n.trans(t("flights_search_sort_fastest"));
  const filtersAppliedCopy = i18n.trans(
    t("flights_num_search_filters_applied", {
      num_exception: appliedFilters.length,
      variables: { num_filters: String(appliedFilters.length) }
    })
  );
  const resultsCountCopy =
    appliedFilters.length > 0
      ? i18n.trans(
          t("flights_number_filtered_results", {
            num_exception: totalCount,
            variables: { num_results: String(filteredTotalCount), num_total_results: String(totalCount) }
          })
        )
      : i18n.trans(
          t("flights_number_total_results", {
            num_exception: filteredTotalCount,
            variables: { num_results: String(totalCount) }
          })
        );
  const ctaCopy = i18n.trans(t("flights_action_done"));

  const handleSortModalClose = () => {
    setIsSortingDrawerShown(false);
    // execute the sorters only when there is change from initial value
    if (!isCriteriaModified) {
      return;
    }
    const params = new URLSearchParams(location.search);
    params.set("sort", state.selected);
    params.delete("page");

    if (state.selected == "BEST") {
      trackCustomGoal("flights_meta_land_on_cheapest_flights", 1); //sort by best
    }
    if (state.selected == "CHEAPEST") {
      trackCustomGoal("flights_meta_land_on_cheapest_flights", 2); //sort by cheapest
    }

    trackExperimentStage("flight_web_sr_traffic_aa_mdot", 3);

    enhanceURLSearchParams(params);
    history.replace({
      pathname: location.pathname,
      search: params.toString()
    });
  };

  const onRadioButtonChange = ({ id }: RadioButtonChangeHandlerProps) => {
    trackCustomGoal("flights_web_copy_best_new_word", 1);
    // check if selected sort order is different from current
    if (id !== initialSort) {
      setCriteriaModifiedStatus(true);
    } else {
      setCriteriaModifiedStatus(false);
    }
    setState({ selected: id });
    actions.setSortOrder(id);
  };

  const onCloseFilters = () => {
    setIsFilterModalShown(false);
    applyFilters(store.search.filtersDraft);
  };

  const isStuck = window.scrollY >= 72;

  useEffect(() => {
    if (!isMobile) return;
    const count = store?.searchCriteria?.children?.length || 0 + store?.searchCriteria?.adults || 0;
    trackExperimentStage("flights_web_mdot_sr_hide_sorting", 1);
    trackExperimentStage("flights_web_mdot_sr_hide_sorting", isMeta ? 3 : 2);
    if (count === 1) trackExperimentStage("flights_web_mdot_sr_hide_sorting", 4);
    if (count === 2) trackExperimentStage("flights_web_mdot_sr_hide_sorting", 5);
    if (count > 2) trackExperimentStage("flights_web_mdot_sr_hide_sorting", 6);
  }, [isMobile, isMeta, store]);

  return (
    <div className={styles.wrapper}>
      <Frame
        direction="row"
        justifyContent="space-around"
        p={trackExperiment("flights_web_blackout_sorting_mdot") ? 4 : undefined}
        className={mcn(styles.toolbarBg, !trackExperiment("flights_web_blackout_sorting_mdot") && styles.toolbar)}
      >
        {!trackExperiment("flights_web_mdot_sr_hide_sorting") &&
        !trackExperiment("flights_web_blackout_sorting_mdot") ? (
          <>
            <ToolbarItem
              IconSVG={SortIcon}
              text={sortCopy}
              onClick={() => {
                setIsSortingDrawerShown(true);
              }}
              ariaLabel={i18n.trans(t("flights_filter_sort"))}
            />
            <SheetContainer
              title={sortCopy}
              closeAriaLabel="Close sort drawer"
              active={isSortingDrawerShown}
              onCloseTrigger={handleSortModalClose}
              position="bottom"
              footer={
                <Stack>
                  <Divider />
                  <Stack className={styles.button}>
                    <div data-testid="search_toolbar_sort_modal_done">
                      <Button text={doneCopy} wide={true} size="large" onClick={handleSortModalClose} />
                    </div>
                  </Stack>
                </Stack>
              }
            >
              <Frame mb={4}>
                <RadioButton
                  label={bestCopy}
                  id="BEST"
                  checked={state.selected === "BEST"}
                  onChange={onRadioButtonChange}
                >
                  <Frame attributes={{ style: { paddingLeft: "29px", color: theme.colors.color_foreground_alt } }}>
                    <Text variant="small_1">
                      {(isSortingDrawerShown && expCopyBestNewWord.getSortingToltip()) || (
                        <Trans tag="flights_search_sort_best_tooltip" />
                      )}
                    </Text>
                  </Frame>
                </RadioButton>
              </Frame>

              <Frame mb={6}>
                <RadioButton
                  label={cheapestCopy}
                  id="CHEAPEST"
                  checked={state.selected === "CHEAPEST"}
                  onChange={onRadioButtonChange}
                />
              </Frame>

              <Frame mb={3}>
                <RadioButton
                  label={fastestCopy}
                  id="FASTEST"
                  checked={state.selected === "FASTEST"}
                  onChange={onRadioButtonChange}
                />
              </Frame>
            </SheetContainer>
          </>
        ) : null}
        {trackExperiment("flights_web_blackout_sorting_mdot") ? (
          <Chip
            variant="action"
            label={filterCopy}
            icon={FilterFunnelIcon}
            attributes={{
              "aria-label": i18n.trans(t("flights_filter_button")),
              style: { width: "100%", justifyContent: "center" }
            }}
            onClick={() => {
              trackV2("click_filters");
              setIsFilterModalShown(true);
            }}
            checked={!!appliedFilters.length}
            bubble={{
              text: appliedFilters.length ? appliedFilters.length : undefined
            }}
          />
        ) : (
          <ToolbarItem
            IconSVG={FilterFunnelIcon}
            text={filterCopy}
            number={appliedFilters.length}
            onClick={() => {
              trackV2("click_filters");
              setIsFilterModalShown(true);
            }}
            ariaLabel={i18n.trans(t("flights_filter_button"))}
          />
        )}

        <Modal
          active={isFilterModalShown}
          onClose={onCloseFilters}
          fullHeight
          hideClose
          footer={
            <ActionBar fillEqually button={{ text: ctaCopy, onClick: onCloseFilters, size: "large" }}>
              <Title title={filtersAppliedCopy} subtitle={resultsCountCopy} />
            </ActionBar>
          }
          fill={true}
        >
          <div className={styles.modalContent}>
            <Frame mt={3} mb={3}>
              <Text variant="headline_1">{i18n.trans(t("flights_filter_name"))}</Text>
            </Frame>
            <SearchFilters />
          </div>
        </Modal>
      </Frame>
      {isStuck && <Divider />}
    </div>
  );
};

export default SearchToolbar;
