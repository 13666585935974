/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";

import { Icon, Link } from "@bookingcom/bui-react";
import { ArrowNavUpIcon, ArrowNavDownIcon } from "@bookingcom/bui-assets-react/streamline";
import Frame from "../Frame";

type Props = {
  displayedRowsCount: number;
  setDisplayedRowsCount: (count: number) => void;
  defaultDisplayedRowsCount: number;
  allRowsCount: number;
  showMoreCopy: string;
  showLessCopy: string;
};

const SearchFiltersDisplayController: React.FunctionComponent<Props> = ({
  displayedRowsCount,
  setDisplayedRowsCount,
  defaultDisplayedRowsCount,
  allRowsCount,
  showMoreCopy,
  showLessCopy
}) => {
  if (allRowsCount <= defaultDisplayedRowsCount) {
    return null;
  }

  const showExpand = displayedRowsCount <= defaultDisplayedRowsCount;

  const expandAllFilters = () => {
    setDisplayedRowsCount(allRowsCount);
  };
  const collapseFilters = () => {
    setDisplayedRowsCount(defaultDisplayedRowsCount);
  };

  const onClick = () => {
    showExpand ? expandAllFilters() : collapseFilters();
  };

  const copy = showExpand ? showMoreCopy : showLessCopy;

  const icon = showExpand ? ArrowNavDownIcon : ArrowNavUpIcon;

  return (
    <Link onClick={onClick}>
      <Frame direction="row">
        {copy}
        <Frame direction="column" justifyContent="center">
          <Icon svg={icon} size="small" color="action" />
        </Frame>
      </Frame>
    </Link>
  );
};

export default SearchFiltersDisplayController;
