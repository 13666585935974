/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Icon, Text } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useRouteName from "hooks/useRouteName";
import { CheckmarkIcon } from "@bookingcom/bui-assets-react/streamline";
import Frame from "components/elements/Frame";
import styles from "./PriceTransparencyText.module.css";

const PriceTransparencyText = () => {
  const currentRoute = useRouteName();

  const i18n = useI18n();

  const getPriceTransparencyCopy = (): string | null => {
    switch (currentRoute) {
      case "checkout3":
        return i18n.trans(t("flights_check_and_pay_no_hidden_fees_usp")); //payment
      case "checkout-ticket-type":
      case "checkout-seat-selection":
      case "checkout2": //ancillaries
      case "checkout": //pax
        return i18n.trans(t("flights_funnel_no_hidden_fees_usp"));
      default:
        return null;
    }
  };
  const priceTransparencyText = getPriceTransparencyCopy();

  if (!priceTransparencyText) {
    return null;
  }

  return (
    <Frame direction="row" mt={3} mb={3} className={styles.container}>
      <Icon size="small" svg={CheckmarkIcon} className={styles.icon}></Icon>

      <Text variant="body_2" tagName="span">
        {priceTransparencyText}
      </Text>
    </Frame>
  );
};

export default PriceTransparencyText;
