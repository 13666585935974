import React from "react";
import { Icon, Stack, Text } from "@bookingcom/bui-react";
import { WarningIcon } from "@bookingcom/bui-assets-react/streamline";
import flights_web_sr_card_itinerary_redesign_desktop from "utils/experiments/funnel/flights_web_sr_card_itinerary_redesign_desktop";
interface AlertProps {
  text: string;
  variant?: "small_1" | "small_2" | "body_2" | "emphasized_2";
}

export const Alert: React.FunctionComponent<AlertProps> = ({ text, variant = "small_1" }) => {
  const isItineraryRedesign = !!flights_web_sr_card_itinerary_redesign_desktop.variant();
  const iconSize = variant === "small_2" ? "smaller" : isItineraryRedesign ? "smallest" : "small";
  return (
    <Stack direction="row" gap={variant === "small_2" ? 1 : 2} alignItems="center">
      <Icon size={iconSize} color="callout" svg={WarningIcon} />
      <Text variant={variant} color="callout">
        {text}
      </Text>
    </Stack>
  );
};

export default Alert;
