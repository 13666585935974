/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect } from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";
import BudgetPerAdult from "../Input/BudgetPerAdult";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "hooks/useUserAgent";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { useStore } from "../../../../store";

export default function BudgetPerAdultFilter() {
  const i18n = useI18n();
  const { budgetPerAdult } = useSearchFiltersController();
  const { options } = budgetPerAdult;
  const canRender = options.max.units !== 0 && options.min.units !== 0 && options.min.units !== options.max.units;

  // exp: flights_web_filters_budget_per_adult --------------------------- start
  const isMeta = isOfMetaOrigin();
  const { isMobile } = useUserAgent();
  const store = useStore();
  const {
    searchCriteria: { adults, children }
  } = store;
  const travellersCount = (adults || 0) + (children || []).length;

  useEffect(() => {
    if (!canRender) return;
    trackExperimentStage("flights_web_filters_budget_per_adult", 1);
    trackExperimentStage("flights_web_filters_budget_per_adult", isMobile ? 3 : 2);
    trackExperimentStage("flights_web_filters_budget_per_adult", isMeta ? 5 : 4);
    trackExperimentStage("flights_web_filters_budget_per_adult", travellersCount === 1 ? 6 : 7);
  }, [canRender, isMobile, isMeta, travellersCount]);

  if (!trackExperiment("flights_web_filters_budget_per_adult")) return null;
  // exp: flights_web_filters_budget_per_adult ----------------------------- end

  if (!canRender) return null;

  return (
    <Stack attributes={{ "data-testid": "budget_per_adult_filter" }}>
      <WithSuffix
        suffix={
          budgetPerAdult.touched ? (
            <Link onClick={budgetPerAdult.reset}>{i18n.trans(t("flights_filter_reset"))}</Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filter_price_title"))}</Text>
      </WithSuffix>
      <BudgetPerAdult
        min={budgetPerAdult.options.min}
        max={budgetPerAdult.options.max}
        value={budgetPerAdult.value}
        onChange={budgetPerAdult.set}
      />
    </Stack>
  );
}
