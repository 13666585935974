import { UIBrandedFare, UIFetchStatus, UIClientFetchError } from "@flights/types/client";

export type BaseFareOfferToken = string;
export type BrandedFare = {
  baseOfferToken?: string;
  fetchStatus: UIFetchStatus;
  error?: UIClientFetchError;
  brandedFareOffers?: Array<UIBrandedFare>;
};

export type State = {
  fareOffersWithDetails: Record<BaseFareOfferToken, BrandedFare>;
};

export const getInitialState = (): State => ({
  fareOffersWithDetails: {}
});
