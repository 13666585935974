import { ActionsUnion, createAction } from "@bookingcom/flights-core/store";
import { UISearchCriteria } from "@flights/types/client";

export enum ActionTypes {
  setSearchCriteria = "searchCriteria/setSearchCriteria"
}

export const actions = {
  setSearchCriteria: createAction((data: UISearchCriteria) => ({
    type: ActionTypes.setSearchCriteria,
    payload: data
  }))
};

export type Actions = ActionsUnion<typeof actions>;
