import { useStore } from "../../store";
import { UIXIndicativeRequestParams } from "../types";
import useGlobalContext from "../../hooks/useGlobalContext";

export const useIndicativeSearchData = (isFlightDetails?: boolean) => {
  const store = useStore();
  const { search, searchResults, user, flightDetails } = store;
  const { currencyCode } = useGlobalContext();

  const firstFlightSegments = isFlightDetails ? flightDetails.flight?.segments : searchResults.flights[0].segments;

  if (!firstFlightSegments) return;

  const flightSegments = firstFlightSegments.map((segment) => ({
    departureTime: segment.departureTime,
    arrivalTime: segment.arrivalTime,
    from: {
      ...segment.departureAirport
    },
    to: {
      ...segment.arrivalAirport
    }
  }));

  const { userInfo } = user;
  const geniusLevel = userInfo?.geniusLevel ?? 0;

  const searchParams: UIXIndicativeRequestParams = {
    adults: search.adults || 0,
    children: search.children.length || 0,
    childrenAges: search.children || [],
    flightSegments
  };

  return {
    searchParams,
    imageOnly: isFlightDetails,
    metaInfo: {
      geniusLevel: geniusLevel,
      currencyCode: currencyCode || "EUR",
      orderId: undefined
    }
  };
};
