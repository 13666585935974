/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { UIBrandedFareInfo, UIIncludedProductBySegmentCompressed } from "@flights/types/client";
import React, { useMemo } from "react";
import { getFeaturesToRender } from "../../CheckoutFareInner/utils/features";
import { Icon } from "@bookingcom/bui-react";
import { includedBaggageIcons } from "constants/icons";
import { getProductIconsIncludedInAllSegments } from "../../../../utils/includedProducts";
import Frame from "components/elements/Frame";

type FareFeaturesIconsProps = {
  includedProducts?: UIIncludedProductBySegmentCompressed;
  brandedFareInfo: UIBrandedFareInfo;
  limit?: number;
};

const FareFeaturesIcons = (props: FareFeaturesIconsProps) => {
  const { includedProducts, brandedFareInfo, limit } = props;
  const { includedFeatures } = useMemo(() => getFeaturesToRender(brandedFareInfo), [brandedFareInfo]);

  const includedFeaturesIcons = includedFeatures
    ? includedFeatures.map((feature) => ({ icon: feature.icon, name: feature.name }))
    : [];

  const baggageIcons = useMemo(() => {
    if (!includedProducts) {
      return [];
    }

    return getProductIconsIncludedInAllSegments(includedProducts).map((product) => ({
      name: product.luggageType,
      icon: includedBaggageIcons[product.luggageType]
    }));
  }, [includedProducts]);

  const fareFeaturesIcons = [...baggageIcons, ...includedFeaturesIcons]
    .slice(0, limit)
    .map(({ icon, name }) => <Icon key={name} svg={icon} size="large" />);

  if (!fareFeaturesIcons.length) {
    return null;
  }

  return <Frame attributes={{ "data-testid": "FareFeaturesIcons" }}>{fareFeaturesIcons}</Frame>;
};

export default FareFeaturesIcons;
