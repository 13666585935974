import {
  BagHoldIcon,
  BagPersonalItemIcon,
  CabinTrolleyIcon,
  BagPersonalItemNotAvailableIcon,
  CabinTrolleyNotAvailableIcon,
  BagHoldNotAvailableIcon
} from "@bookingcom/bui-assets-react/streamline";
import { Icon, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import {
  UIAvailableExtraProducts,
  UIBrandedFareInfo,
  UIFlightSegment,
  UIIncludedProductSegment,
  UILuggageType
} from "@flights/types/client";
import React, { Fragment, useState } from "react";
import { trackGoalWithValue } from "utils/et";
import { startCase, lowerCase } from "lodash";
import styles from "./FlightCardFullLuggageData.module.css";

const luggagesMap = [
  {
    type: "PERSONAL_ITEM" as UILuggageType,
    icon: BagPersonalItemIcon,
    unavailableIcon: BagPersonalItemNotAvailableIcon,
    labelTranslation: t("flights_sr_baggage_personal_item")
  },
  {
    type: "HAND" as UILuggageType,
    icon: CabinTrolleyIcon,
    unavailableIcon: CabinTrolleyNotAvailableIcon,
    labelTranslation: t("flights_sr_baggage_cabin_bag")
  },
  {
    type: "CHECKED_IN" as UILuggageType,
    icon: BagHoldIcon,
    unavailableIcon: BagHoldNotAvailableIcon,
    labelTranslation: t("flights_sr_baggage_checked_bag")
  }
];

const statusMap = {
  included: {
    labelTranslation: t("flights_sr_baggage_included"),
    color: "constructive" as const
  },
  available: {
    labelTranslation: t("flights_sr_baggage_extra_fee"),
    color: undefined
  },
  notAvailable: {
    labelTranslation: t("flights_sr_baggage_not_available"),
    color: "neutral_alt" as const
  },
  includedSegment: {
    labelTranslation: undefined,
    color: undefined
  }
};

type Props = {
  includedLuggagePerSegment?: UIIncludedProductSegment[];
  segments: UIFlightSegment[];
  ancillaries?: UIAvailableExtraProducts;
  brandedFareInfo?: UIBrandedFareInfo;
};

const FlightCardFullLuggageData = ({ brandedFareInfo, ...rest }: Props) => {
  const i18n = useI18n();
  const [luggageStatus] = useState(() => getLuggageStatus(rest));

  return (
    <Stack>
      {!!brandedFareInfo && (
        <Text variant="small_1">
          {`${i18n.trans(
            t("flights_branded_fare_baggage_included_sr", {
              variables: {
                branded_fare: `${startCase(lowerCase(brandedFareInfo?.fareName))}`
              }
            })
          )} `}
        </Text>
      )}
      <div className={styles.container}>
        {luggagesMap.map(({ type, icon, unavailableIcon, labelTranslation }) => {
          const { status, segments } = luggageStatus[type];

          return (
            <>
              <Stack key={type} direction="row" wrap="nowrap">
                <Icon
                  svg={status === "notAvailable" ? unavailableIcon : icon}
                  size="small"
                  color={status === "notAvailable" ? statusMap[status].color : undefined}
                />
                <Text
                  variant="small_1"
                  color={status === "notAvailable" ? statusMap[status].color : undefined}
                  className={styles.luggageLabel}
                >
                  {i18n.trans(labelTranslation)}
                </Text>
              </Stack>

              <Text key={type} variant="small_1" align="right" color={statusMap[status].color}>
                {status === "includedSegment" ? (
                  <>
                    {i18n.trans(t("flights_sr_baggage_included_flight"))}
                    {segments?.map((segment, i) => {
                      let separator = "";
                      const isLastOne = i === segments.length - 1;

                      if (!isLastOne && segments.length > 1) {
                        const isSecondToLastOne = i === segments.length - 2;

                        separator = i18n.trans(
                          isSecondToLastOne ? t("flights_sr_baggage_separator_last") : t("flights_sr_baggage_separator")
                        );
                      }

                      return (
                        <Fragment key={i}>
                          {i18n.trans(
                            t("flights_sr_baggage_included_iata", {
                              variables: { depart_airport: segment[0], arrive_airport: segment[1] }
                            })
                          )}
                          {separator}
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  i18n.trans(statusMap[status].labelTranslation)
                )}
              </Text>
            </>
          );
        })}
      </div>
    </Stack>
  );
};
const etValuePrefix = "flights_web_sr_full_luggage_data";

type LuggageStatus = keyof typeof statusMap;

export function getLuggageStatus({ includedLuggagePerSegment, segments, ancillaries }: Props) {
  if (!includedLuggagePerSegment) {
    throw new Error("Missing includedProducts data");
  }

  const luggageIncludedSegments: Record<UILuggageType, Array<string[]>> = {
    PERSONAL_ITEM: [],
    HAND: [],
    CHECKED_IN: []
  };

  includedLuggagePerSegment.forEach((segment, segmentIndex) => {
    const includedBaggagePerSegment = {
      PERSONAL_ITEM: false,
      HAND: false,
      CHECKED_IN: false
    };
    segment.forEach(({ luggageType }) => {
      if (includedBaggagePerSegment[luggageType] || !segments[segmentIndex]) {
        return;
      }
      const departureCode = segments[segmentIndex].departureAirport.code;
      const arrivalCode = segments[segmentIndex].arrivalAirport.code;
      luggageIncludedSegments[luggageType].push([departureCode, arrivalCode]);
      includedBaggagePerSegment[luggageType] = true;
    });
  });

  const result = Object.entries(luggageIncludedSegments).reduce((acc, [luggageType, segments]) => {
    if (segments.length > 0) {
      const inAllSegments = segments.length === includedLuggagePerSegment.length;
      acc[luggageType] = {
        status: inAllSegments ? "included" : "includedSegment"
      };

      if (!inAllSegments) {
        acc[luggageType].segments = segments;
      }
    }

    if (segments.length === 0) {
      const isAncillary =
        (luggageType === "HAND" && ancillaries?.cabinBaggage) ||
        (luggageType === "HAND" && ancillaries?.cabinBaggagePerTraveller) ||
        (luggageType === "CHECKED_IN" && ancillaries?.checkedInBaggage);

      acc[luggageType] = {
        status: isAncillary ? "available" : "notAvailable"
      };
    }

    return acc;
  }, {} as Record<UILuggageType, { status: LuggageStatus; segments?: Array<string[]> }>);

  Object.entries(result).forEach(([luggageType, { status }]) => {
    trackGoalWithValue(`${etValuePrefix}_${luggageType.toLowerCase()}_${status.toLowerCase()}`, 1);
  });

  return result;
}

export default FlightCardFullLuggageData;
