import { StoreState } from "../index";
import { useMemo } from "react";
import { FILTERS } from "../../constants";

export function useSelectorAppliedFilters({ search }: StoreState) {
  return useMemo(() => {
    const filterNames: Array<string> = [];
    if (search.filters.duration) {
      filterNames.push(FILTERS.duration);
    }
    if (search.filters.airlines && search.filters.airlines.length > 0) {
      filterNames.push(FILTERS.airlines);
    }
    if (typeof search.filters.stops === "number" && search.filters.stops >= 0) {
      filterNames.push(FILTERS.stops);
    }
    if (search.filters.multiSelectStops && search.filters.multiSelectStops.length > 0) {
      filterNames.push(FILTERS.stops);
    }
    if (search.filters.departureIntervals.length > 0 || search.filters.arrivalIntervals.length > 0) {
      filterNames.push(FILTERS.journeyTime);
    }
    if (search.filters.flightTimes) {
      const hasAtLeastOneSelectedFlightTimes = search.filters.flightTimes.some((flightTimes) => {
        const arrivals = flightTimes.arrival.some((flight) => flight.selected);
        const departures = flightTimes.departure.some((flight) => flight.selected);
        return arrivals || departures;
      });
      hasAtLeastOneSelectedFlightTimes && filterNames.push(FILTERS.flightTimes);
    }
    if (!!search.filters.shortLayoverConnection) {
      filterNames.push(FILTERS.shortLayoverConnection);
    }
    if (search.filters.maxBudget !== undefined) {
      filterNames.push(FILTERS.budget);
    }
    if (search.filters.maxBudgetPerAdult !== undefined) {
      filterNames.push(FILTERS.budgetPerAdult);
    }
    if (!!search.filters.includedBaggage?.length) {
      filterNames.push(FILTERS.includedBaggage);
    }
    if (search.filters.maxLayoverDuration !== undefined) {
      filterNames.push(FILTERS.layoverDuration);
    }
    if (!!search.filters.airports?.length) {
      filterNames.push(FILTERS.airports);
    }
    if (!!search.filters.preferSameAirport) {
      filterNames.push(FILTERS.preferSameAirport);
    }
    return filterNames;
  }, [search.filters]);
}
