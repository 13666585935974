import { useCallback } from "react";
import localstory from "localstory";
import useGlobalContext from "./useGlobalContext";
import { getFlightEventsContext, getLastSeenContextEventId } from "../utils/internal-events";
import useLocaleContext from "../hooks/useLocaleContext";
import useRouteName from "../hooks/useRouteName";
import { useParams } from "react-router-dom";
import useEventTracking, { AvroSchemaMapping, useContextEventTracking } from "./useEventTracking";
import { UILandingSegment } from "@flights/types/client";
import useTrackExpPagesTraffic from "./useTrackExpPagesTraffic";
import useTrackMPFlightsTraffic from "./useTrackMPFlightsTraffic";
import usePointOfSale from "./usePointOfSale";
import useEvents from "./useEvents";

const LANDING_TRACKED_FLAG_KEY = "landing_in_tracked";
export const LANDING_PAGE_NAME_KEY = "landing_in_name";

export default function useTrackPageLoad(contextName: keyof AvroSchemaMapping) {
  const { requestId } = useGlobalContext();
  const { locale } = useLocaleContext();
  const params = useParams();
  const routeName = useRouteName();
  const pointOfSale = usePointOfSale();
  const { jsError } = useEvents();
  const store = localstory(window.sessionStorage, "flights");

  //exp: flights_web_mdot_aa_pages_traffic
  const trackExpPagesTraffic = useTrackExpPagesTraffic();
  trackExpPagesTraffic.trackStages();
  //exp: flights_web_ddot_aa_pages_traffic

  //exp: mp_flights_insurance_traffic_aa
  const trackMPFlightsTraffic = useTrackMPFlightsTraffic();
  trackMPFlightsTraffic.trackStages();
  //exp: mp_flights_insurance_traffic_aa

  const trackContextEventV2 = useContextEventTracking(contextName);
  const trackV2 = useEventTracking("page_load", requestId);

  const getPrefilledRoutes = useCallback(() => {
    switch (contextName) {
      case "route":
        try {
          // In this case the pathname is something like: '/flights/route/amsterdam-ams-berlin-ber.en.html'
          // .split("/")[3] returns: amsterdam-ams-berlin-ber.en.html
          // finally .split(".")[0] returns: amsterdam-ams-berlin-ber
          return location.pathname.split("/")[3].split(".")[0];
        } catch (error) {
          jsError(error);
          return "";
        }
      default:
        return (params || {})["airports"] || "";
    }
  }, [contextName, params, jsError]);

  const getPreFilledSearchParams = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);

    const fromField = urlParams.get("from");
    const toField = urlParams.get("to");

    const type = urlParams.get("type");

    const fromIATA = fromField ? fromField.split(".")[0] : "";
    const toIATA = toField ? toField.split(".")[0] : "";

    const onwardDate = urlParams.get("depart") || "";
    const returnDate = urlParams.get("return") || "";

    const multiStopDates = urlParams.get("multiStopDates");
    const segments: UILandingSegment[] = [];

    if (type === "ONEWAY" || type === "ROUNDTRIP") {
      segments.push({
        origin: fromIATA,
        destination: toIATA,
        date: onwardDate
      });

      if (returnDate) {
        segments.push({
          origin: toIATA,
          destination: fromIATA,
          date: returnDate
        });
      }
    }

    if (type === "MULTISTOP" && multiStopDates) {
      multiStopDates.split("|").forEach((date, index) => {
        segments.push({
          origin: fromIATA.split("|")[index],
          destination: toIATA.split("|")[index],
          date: date
        });
      });
    }

    return segments;
  }, []);

  // track landing_in action event
  // this event should fire once on landing
  // if referrer is not booking.com

  const trackLandingInEvent = useCallback(() => {
    if (Boolean(store.get(LANDING_TRACKED_FLAG_KEY))) return;
    const referrer = document.referrer || "";
    const isNotFromFlights = !referrer.includes("flights.booking.com") && !referrer.includes("booking.com/flights");
    if (isNotFromFlights) {
      store.set(LANDING_TRACKED_FLAG_KEY, 1, { ttl: "1d" });
      trackV2("landing_in", {
        referrer,
        prefilled_language: locale?.code || "",
        prefilled_routes: getPrefilledRoutes(),
        pageload_event_id: getLastSeenContextEventId(),
        prefilled_search_params: {
          segments: getPreFilledSearchParams()
        }
      });
    }
  }, [store, trackV2, locale.code, getPrefilledRoutes, getPreFilledSearchParams]);

  const setFirstLandingPage = useCallback(() => {
    if (Boolean(store.get(LANDING_PAGE_NAME_KEY))) return;
    store.set(LANDING_PAGE_NAME_KEY, routeName, { ttl: "1d" });
  }, [routeName, store]);

  // track page_load event
  return useCallback(() => {
    trackContextEventV2(getFlightEventsContext(contextName, requestId, "PAGE_LOAD", undefined, undefined, pointOfSale));
    trackLandingInEvent();
    setFirstLandingPage();
  }, [requestId, trackContextEventV2, contextName, trackLandingInEvent, setFirstLandingPage, pointOfSale]);
}
