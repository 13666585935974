import React, { useEffect, useRef } from "react";
import useIsInViewport from "hooks/useIsInViewport";

/**
 * Wrapper for tracking component intersection with viewport
 * and firing a callback
 */
export function InViewportTrackingWrapper({
  children,
  onEnterViewport
}: React.PropsWithChildren<{ onEnterViewport?: () => void }>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(containerRef.current);
  const wasOnceViewed = useRef<boolean>(false); // prevent distribution issue in ET

  useEffect(() => {
    if (!wasOnceViewed.current && isInViewport && typeof onEnterViewport === "function") {
      onEnterViewport();
      wasOnceViewed.current = true;
    }
  }, [isInViewport, onEnterViewport]);

  return <div ref={containerRef}>{children}</div>;
}
