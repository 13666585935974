/* istanbul ignore file */
import { useMemo } from "react";
import { useStore } from "../../store";
import useUserAgent from "hooks/useUserAgent";
import useRouteName from "hooks/useRouteName";
import { UIXComponentType, UIXAccommodationsSearchRequestParams } from "../types";
import { MDOT_NUMBER_OF_RESULTS, NUMBER_OF_RESULTS, PaxTypes } from "../constants";

export const useSearchParamsData = (componentType: UIXComponentType, numberOfResults?: number) => {
  const store = useStore();
  const { isMobile } = useUserAgent();
  const routeName = useRouteName();

  const { order } = store.order;
  const { userInfo } = store.user;
  const geniusLevel = userInfo?.geniusLevel ? userInfo?.geniusLevel : 0;

  return useMemo(() => {
    const resultsCount = numberOfResults ?? (isMobile ? MDOT_NUMBER_OF_RESULTS : NUMBER_OF_RESULTS);

    const searchParams: UIXAccommodationsSearchRequestParams = {
      numberOfResults: resultsCount,
      adults: 0,
      children: 0,
      childrenAges: [],
      flightSegments: []
    };

    if (order?.passengers?.length && order.airOrder?.flightSegments?.length) {
      order.passengers.forEach((pax) => {
        if (pax.type === PaxTypes.ADULT) {
          searchParams.adults++;
        } else if (pax.type === PaxTypes.KID) {
          searchParams.children++;
          if (pax.age) {
            searchParams.childrenAges.push(pax.age);
          }
        }
      });

      order.airOrder?.flightSegments?.forEach((segment) => {
        searchParams.flightSegments.push({
          departureTime: segment.departureTime,
          arrivalTime: segment.arrivalTime,
          from: {
            code: segment.departureAirport.code,
            city: segment.departureAirport.city,
            cityName: segment.departureAirport.cityName || "",
            country: segment.departureAirport.country,
            type: segment.departureAirport.type,
            countryName: segment.departureAirport.countryName || ""
          },
          to: {
            code: segment.arrivalAirport.code,
            city: segment.arrivalAirport.city,
            cityName: segment.arrivalAirport.cityName || "",
            country: segment.arrivalAirport.country,
            type: segment.arrivalAirport.type,
            countryName: segment.arrivalAirport.countryName || ""
          }
        });
      });

      return {
        searchParams,
        metaInfo: {
          geniusLevel: geniusLevel,
          currencyCode: order.orderCurrency,
          orderId: order.orderId,
          publicReference: order.publicReference,
          componentType: componentType,
          soylentId: order.soylentEmail,
          source: routeName
        }
      };
    }

    return undefined;
  }, [geniusLevel, isMobile, order, numberOfResults, componentType, routeName]);
};
