/* istanbul ignore file */
import React, { useEffect } from "react";
import { Spinner, Stack, Text, useViewport } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";

import { useActions, useStore } from "../../../store";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import { t } from "@bookingcom/lingojs-core";
import IndicativeCard from "./IndicativeCard";
import { XSell } from "cross-sell";
import useModalDialog from "../../../hooks/useModalDialog";
import { mcn } from "utils/mergeClassnames";
import styles from "./CrossSellLoadingScreen.module.css";

import {
  TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
  TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_STAGES
} from "../../../constants/experiments";
import { trackExperiment, trackExperimentStage } from "utils/et";
import useUserAgent from "hooks/useUserAgent";

const CrossSellLoadingScreen = () => {
  const store = useStore();
  const i18n = useI18n();
  const Modal = useModalDialog();
  const { isSmall } = useViewport();
  const { isMobile } = useUserAgent();
  const ariaLiveActions = useActions(AriaLiveActions);
  const exposureXsellIndicativeCardEnabled =
    trackExperiment(TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF) === 1;

  const { visible } = store.loadingScreen;

  useEffect(() => {
    if (visible && i18n) {
      ariaLiveActions.setMessage({
        message: i18n.trans(t("flights_xsell_booking_modal_header")),
        type: "assertive"
      });
    } else {
      ariaLiveActions.reset();
    }
  }, [ariaLiveActions, i18n, visible]);

  useEffect(() => {
    if (visible) {
      trackExperimentStage(
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
        TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_STAGES.ALL_USERS
      );
      isMobile
        ? trackExperimentStage(
            TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
            TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_STAGES.MDOT
          )
        : trackExperimentStage(
            TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF,
            TRIPS_INTIATIVE_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_ABU_USP_TF_STAGES.DDOT
          );
    }
  }, [visible, isMobile]);

  return (
    <Modal
      className={mcn(!isSmall ? styles.sheet : styles.sheetSmall)}
      closeAriaLabel=""
      position={isSmall ? "fullScreen" : "center"}
      active={visible}
      size={780}
      footer={exposureXsellIndicativeCardEnabled ? <XSell component="ExposureCrossSell" isModal /> : <IndicativeCard />}
      hideClose
      fill
    >
      <>
        <Stack justifyContent="center">
          <Stack.Item className={styles.top}>
            <Stack justifyContent="center" alignItems="center">
              <Spinner
                size="large"
                color="action"
                ariaLabel={i18n.trans(t("a11y_flights_xsell_booking_modal_loading_spinner"))}
              />
            </Stack>
            <Text variant="headline_2" align="center" className={styles.text}>
              {i18n.trans(t("flights_xsell_booking_modal_header"))}
            </Text>
          </Stack.Item>
        </Stack>
      </>
    </Modal>
  );
};

export default CrossSellLoadingScreen;
