import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_bf_selection_cta_mdot",
  stages: {
    users_on_bf_page_mdot: 1,
    users_on_bf_page_no_preselected_fare: 2,
    users_on_bf_page_with_preselected_fare: 3,
    meta_users: 4
  },
  goals: {
    users_progress_from_bf: 1,
    users_progress_from_bf_with_upsell_fare: 2,
    users_go_back_from_pax_page: 3,
    users_progress_with_lower_fare: 4,
    users_click_price_breakdown_btn: 5
  },
  goalsWithValue: []
});

export default utils;
