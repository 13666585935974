/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useRef } from "react";
import { startCase, lowerCase } from "lodash";
import { useTheme, Text, Icon } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { BagPersonalItemIcon, CabinTrolleyIcon, BagHoldIcon } from "@bookingcom/bui-assets-react/streamline";

import { UIBrandedFareInfo, UIFlightSegment } from "@flights/types/client";
import { getLeastAvailableLuggagePerTrip } from "../../../../store/selectors/searchResultsAvailableLuggage";
import useUserAgent from "../../../../hooks/useUserAgent";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import useLocaleContext from "hooks/useLocaleContext";
import { trackCustomGoal } from "utils/et";
import Frame from "components/elements/Frame";

type FlightCardLuggageProps = {
  segments: UIFlightSegment[];
  horizontal?: boolean;
  brandedFareInfo?: UIBrandedFareInfo;
};

const FlightCardLuggage = (props: FlightCardLuggageProps) => {
  const { segments, horizontal = false, brandedFareInfo } = props;
  const { isMobile } = useUserAgent();
  const { supportsChildrenTickets } = useGlobalContext();
  const i18n = useI18n() as I18nChildContext;
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = useCallback(() => {
    timerRef.current = setTimeout(() => {
      trackCustomGoal("flights_web_ddot_condensed_one_way_search_results", 3);
    }, 250);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timerRef.current && clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  if (!segments.length) {
    return null;
  }
  const { cabinLuggage, personalItem, checkedInLuggage } = getLeastAvailableLuggagePerTrip(
    segments,
    supportsChildrenTickets
  );

  const availableBaggageList: string[] = [];

  if (personalItem) {
    availableBaggageList.push(i18n.trans(t("flights_baggage_personal_item")));
  }

  if (cabinLuggage) {
    availableBaggageList.push(i18n.trans(t("flights_baggage_cabin_bag")));
  }

  if (checkedInLuggage) {
    availableBaggageList.push(i18n.trans(t("flights_baggage_checked_bag")));
  }

  if (!availableBaggageList.length) {
    return null;
  }

  return (
    <div
      onMouseEnter={horizontal ? handleMouseEnter : undefined}
      onMouseLeave={horizontal ? handleMouseLeave : undefined}
    >
      <Frame
        direction={horizontal ? "row" : "column"}
        alignItems={horizontal ? "center" : undefined}
        mt={horizontal ? 4 : 0}
      >
        <Frame
          grow={horizontal ? 0 : 3}
          direction="row"
          alignItems="flex-end"
          justifyContent={isMobile || horizontal ? "flex-start" : "flex-end"}
          pb={isMobile || horizontal ? 0 : 2}
          mr={horizontal ? 2 : 0}
        >
          {personalItem && <Icon svg={BagPersonalItemIcon} size={horizontal ? "medium" : "large"} />}
          {cabinLuggage && <Icon svg={CabinTrolleyIcon} size={horizontal ? "medium" : "large"} />}
          {checkedInLuggage && <Icon svg={BagHoldIcon} size={horizontal ? "medium" : "large"} />}
        </Frame>
        <LuggageDescription
          horizontal={horizontal}
          brandedFareInfo={brandedFareInfo}
          availableBaggageList={availableBaggageList}
        />
      </Frame>
    </div>
  );
};

type LuggageDescriptionProps = {
  availableBaggageList: string[];
  horizontal?: boolean;
  brandedFareInfo?: UIBrandedFareInfo;
};

const LuggageDescription = (props: LuggageDescriptionProps) => {
  const { availableBaggageList, horizontal, brandedFareInfo } = props;
  const { isRTL } = useLocaleContext();
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const theme = useTheme();

  let style: React.CSSProperties = {
    marginBottom: horizontal ? 0 : theme.units.spacing_4x
  };

  if (isMobile) {
    style = {
      marginBottom: 0,
      marginRight: isRTL ? 0 : theme.units.spacing_4x,
      marginLeft: isRTL ? theme.units.spacing_4x : 0
    };
  }

  const getBaggageInfoTags = () => {
    switch (availableBaggageList.length) {
      case 1:
        return <span>{availableBaggageList[0]}</span>;

      case 2:
        return (
          <span>
            {i18n.trans(
              t("flights_baggage_included_placeholder_combo_1", {
                variables: {
                  baggage_1: availableBaggageList[0],
                  baggage_2: availableBaggageList[1]
                }
              })
            )}
          </span>
        );

      case 3:
        return (
          <span>
            {i18n.trans(
              t("flights_baggage_included_placeholder_combo_2", {
                variables: {
                  baggage_1: availableBaggageList[0],
                  baggage_2: availableBaggageList[1],
                  baggage_3: availableBaggageList[2]
                }
              })
            )}
          </span>
        );
      default:
        return;
    }
  };

  return (
    <div style={style}>
      {availableBaggageList.length > 0 && (
        <div
          style={{
            display: "block",
            color: theme.colors.color_foreground_alt,
            textAlign: isMobile || horizontal ? "start" : "end"
          }}
        >
          <Text variant={horizontal ? "small_1" : "small_2"}>
            {!!brandedFareInfo ? (
              <span>
                {`${i18n.trans(
                  t("flights_branded_fare_baggage_included_sr", {
                    variables: {
                      branded_fare: `${startCase(lowerCase(brandedFareInfo?.fareName))}`
                    }
                  })
                )} `}
              </span>
            ) : (
              <span>{`${i18n.trans(t("flights_baggage_included"))} `}</span>
            )}
            <span>{getBaggageInfoTags()}</span>
          </Text>
        </div>
      )}
    </div>
  );
};

export default FlightCardLuggage;
