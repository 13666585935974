import { UIBrandedFareFeatureAvailability, UIBrandedFareFeatureName, UIFlightSegment } from "@flights/types/client";
import { SIMPLE_FEATURES_UI_CONFIG } from "constants/brandedFares";
import memoize from "lodash/memoize";
import { SimpleFeatureConfig } from "@flights/types/brandedFares";
import { hasCarrier } from "utils/carriers";
import { SELLABLE_FEATURES_AIRLINES } from "constants/ancillaries";

export const getFeatureConfigsByName = memoize(() => {
  const featureConfigsByName: Partial<Record<UIBrandedFareFeatureName, SimpleFeatureConfig>> = {};

  for (const config of SIMPLE_FEATURES_UI_CONFIG) {
    featureConfigsByName[config.name] = config;
  }

  return featureConfigsByName;
});

export const getLabelCopy = (featureName: UIBrandedFareFeatureName, availability: UIBrandedFareFeatureAvailability) => {
  const config = getFeatureConfigsByName()[featureName];

  if (!config) return undefined;

  switch (availability) {
    case "SELLABLE":
      return config.sellableFeatureCopy;
    case "NOT_INCLUDED":
      return config.nonIncludedFeatureCopy;
    default:
      return config.includedFeatureCopy;
  }
};

export const areSellableFeaturesRequiredByAirline = (segments: UIFlightSegment[]) =>
  hasCarrier(segments, SELLABLE_FEATURES_AIRLINES);
