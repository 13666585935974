import React, { useCallback } from "react";
import { Button, Icon, Text, useTheme } from "@bookingcom/bui-react";
import { SearchRemoveIcon } from "@bookingcom/bui-assets-react/streamline";
import { Trans } from "@bookingcom/lingojs-react";
import useCreateUrl from "hooks/useCreateUrl";
import useGlobalContext from "hooks/useGlobalContext";
import { FLIGHTS_INDEX_BASE_URL } from "../../../constants";
import { trackCustomGoal } from "utils/et";

const NotFoundInner = () => {
  const theme = useTheme();
  const { createUrl } = useCreateUrl();
  const { lang } = useGlobalContext();

  const handleRedirect = useCallback(() => {
    trackCustomGoal("flights_web_cat_not_found_page", 4); // clicked btn
    window.location.href = createUrl(`${FLIGHTS_INDEX_BASE_URL}.${lang}.html`);
  }, [createUrl, lang]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 72,
        marginBottom: theme.units.spacing_16x
      }}
    >
      <Icon svg={SearchRemoveIcon} size="large" />
      <Text
        tagName="h2"
        variant="headline_2"
        color="neutral"
        attributes={{
          style: { marginBottom: 0 }
        }}
      >
        <Trans tag="flights_not_found_no_page_title" />
      </Text>
      <Text
        variant="body_1"
        color="neutral_alt"
        attributes={{
          style: { marginBottom: theme.units.spacing_6x }
        }}
      >
        <Trans tag="flights_not_found_no_page_subtitle" />
      </Text>
      <Button size="large" onClick={handleRedirect}>
        <Trans tag="flights_not_found_no_page_cta" />
      </Button>
    </div>
  );
};

export default NotFoundInner;
