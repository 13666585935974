import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtils } from "..";
import useUserAgent from "hooks/useUserAgent";
import { useEffect } from "react";

const utils = createTrackingUtils({
  name: "flights_web_flight_time_filter",
  stages: {
    main: 1,
    desktop: 2,
    mdot: 3,
    direct: 4,
    meta: 5
  },
  goals: {
    used_2_filters: 1,
    used_3_plus_filters: 2
  },
  goalsWithValue: []
});

const { stages, goals } = utils;

export const useTrackTimeFilterStages = (appliedFiltersCount: number) => {
  const { isMobile } = useUserAgent();

  useEffect(() => {
    stages.main();

    isOfMetaOrigin() ? stages.meta() : stages.direct();
    isMobile ? stages.mdot() : stages.desktop();
  }, [isMobile]);

  useEffect(() => {
    if (appliedFiltersCount === 2) {
      goals.used_2_filters();
    }

    if (appliedFiltersCount >= 3) {
      goals.used_3_plus_filters();
    }
  }, [appliedFiltersCount]);
};

export default utils;
