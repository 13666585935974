import React, { useCallback, useState } from "react";
import { Banner } from "@bookingcom/bui-react";

import { getCampaignBannerAsset } from "utils/flightCampaignConfig";
import type { UICampaignBanner } from "@flights/types/client";

import { FlightCampaignTCBannerModal } from "./FlightCampaignTCBannerModal";
import { getImageAssetUrl } from "@bookingcom/bui-assets-react";

type FlightCampaignTCBannerProps = {
  campaignBanner: UICampaignBanner;
  onCtaClick?: () => void;
};

export const FlightCampaignTCBanner = ({ campaignBanner, onCtaClick }: FlightCampaignTCBannerProps) => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowModal = useCallback(() => {
    setShowModal(true);
    typeof onCtaClick === "function" && onCtaClick();
  }, [onCtaClick]);

  return (
    <>
      <Banner
        title={campaignBanner.title}
        text={campaignBanner.text}
        startImage={{
          src: getImageAssetUrl(getCampaignBannerAsset(campaignBanner.style)),
          alt: ""
        }}
        dismissible={false}
        bleed={false}
        actions={[
          {
            text: campaignBanner.action.ctaText,
            variant: "tertiary",
            onClick: onShowModal
          }
        ]}
      />
      <FlightCampaignTCBannerModal isActive={showModal} onModalClose={onCloseModal} campaignBanner={campaignBanner} />
    </>
  );
};
