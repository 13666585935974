/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { memo } from "react";
import { Spinner, Text } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { trackExperiment } from "utils/et";
import Frame from "../Frame";
import styles from "./SearchLoadingMessage.module.css";
import { t } from "@bookingcom/lingojs-core";
import { useSelector } from "react-redux";
import { getSearchSegments, getSearchType } from "store/search/selectors";
import { UIAirportLocation, UICityLocation } from "@flights/types/client";
import { getSearchFetchStatus } from "store/searchResults/selectors";

const _SearchLoadingMessage = () => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const searchType = useSelector(getSearchType);
  const searchSegments = useSelector(getSearchSegments);
  const searchFetchStatus = useSelector(getSearchFetchStatus);

  const contextualizedSRExp = isMobile
    ? "flights_web_contextualized_sr_loading_mdot"
    : "flights_web_contextualized_sr_loading_desktop";

  const shouldRender = searchFetchStatus !== "success" && trackExperiment(contextualizedSRExp);
  if (!shouldRender) {
    return null;
  }

  const destinationLocations = searchSegments?.[0]?.to?.map(
    (item) => (item as UIAirportLocation)?.cityName || (item as UICityLocation)?.name
  );
  const destinationsList = destinationLocations?.join(i18n.trans(t("flights_sr_airline_separator")));

  let title;
  if (trackExperiment(contextualizedSRExp) === 1) {
    title = i18n.trans(t("flights_sr_loading_almost_done"));
  } else {
    if (searchType === "ONEWAY") {
      title = i18n.trans(t("flights_sr_loading_one_way", { variables: { destination: destinationsList } }));
    } else if (searchType === "ROUNDTRIP") {
      title = i18n.trans(t("flights_sr_loading_rt", { variables: { destination: destinationsList } }));
    } else {
      title = i18n.trans(t("flights_sr_loading_multi"));
    }
  }

  return (
    <div data-testid="loading_message_sr" className={isMobile ? styles.rootMobile : styles.rootDesktop}>
      <Frame direction="row">
        <Spinner size="medium" />
        <Text variant="body_2" className={styles.text}>
          {title}
        </Text>
      </Frame>
    </div>
  );
};

export const SearchLoadingMessage = memo(_SearchLoadingMessage);
