import { useStore } from "../store";

const useInsuranceServerSide = (isOrder: boolean = false) => {
  const store = useStore();
  const travelInsuranceContent = isOrder
    ? store?.order?.order?.ancillaries?.travelInsurance?.content
    : store?.extraProducts?.extraProducts?.travelInsurance?.content;

  return { travelInsuranceContent };
};

export default useInsuranceServerSide;
