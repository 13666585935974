import { createTrackingUtilsV2 } from "..";
import { useEffect, useState } from "react";
import useProfiler from "hooks/useProfiler";
import useIsInViewport from "hooks/useIsInViewport";

const utils = createTrackingUtilsV2({
  name: "flights_web_ddot_last_available_seats_v3",
  stages: {
    search_results: 1,
    last_available_seats_rendered_sr: 2, // “last available seats” message is renderes on SR and there are 2 travellers at most
    last_available_seats_rendered_is_visible_sr: 3, // scrolled to a rendered “last available seats” message and there are 2 travellers at most
    last_available_seats_rendered_sr_direct: 4,
    last_available_seats_rendered_sr_meta: 5,
    last_available_seats_rendered_is_visible_sr_direct: 6,
    last_available_seats_rendered_is_visible_sr_meta: 7
  },
  goals: {
    book_offer_with_last_available_seats: 1,
    open_fd_with_last_available_seats: 2
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackAvailableSeatsDesktop = ({
  canRenderLastAvailableSeats,
  travellersCount,
  availableSeatsFlightIndex
}: {
  canRenderLastAvailableSeats: boolean;
  travellersCount: number;
  availableSeatsFlightIndex: number;
}) => {
  const { isDirect, isMeta } = useProfiler();
  const isInViewPort = useIsInViewport(document.getElementById(`flight-card-${availableSeatsFlightIndex}`));
  const isMessageVisible = canRenderLastAvailableSeats && travellersCount <= 2;
  const [viewportTracked, setViewportTracked] = useState(false);

  useEffect(() => {
    stages.search_results();

    if (isMessageVisible) {
      stages.last_available_seats_rendered_sr();
      isDirect() && stages.last_available_seats_rendered_sr_direct();
      isMeta() && stages.last_available_seats_rendered_sr_meta();
    }
  }, [canRenderLastAvailableSeats, isDirect, isMessageVisible, isMeta, travellersCount]);

  useEffect(() => {
    if (isInViewPort && isMessageVisible && !viewportTracked) {
      stages.last_available_seats_rendered_is_visible_sr();
      isDirect() && stages.last_available_seats_rendered_is_visible_sr_direct();
      isMeta() && stages.last_available_seats_rendered_is_visible_sr_meta();
      setViewportTracked(true);
    }
  }, [isDirect, isInViewPort, isMessageVisible, isMeta, viewportTracked]);
};

export default utils;
