/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect, useRef } from "react";
import Airlines from "../Input/Airlines";
import useSearchFiltersController from "../useSearchFiltersController";
import { Button, Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { trackExperimentStage } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import trackSelectDeselectDesktop, {
  trackGoalDeselectAll,
  trackGoalSelectAll
} from "utils/experiments/funnel/flights_web_airlines_filter_select_deselect_desktop";
export default function AirlinesFilter() {
  const i18n = useI18n();
  const { airlines } = useSearchFiltersController();
  const { isMobile } = useUserAgent();
  const isMeta = isOfMetaOrigin();
  const canRender = airlines.options.length;
  const selectAllRef = useRef<HTMLButtonElement>(null);
  const deSelectAllRef = useRef<HTMLButtonElement>(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!canRender) return;
    trackExperimentStage("flights_web_airlines_filter_price", 1);
    trackExperimentStage("flights_web_airlines_filter_price", isMobile ? 3 : 2);
    trackExperimentStage("flights_web_airlines_filter_price", isMeta ? 5 : 4);
  }, [canRender, isMeta, isMobile]);
  /* Control focused for  flights_web_airlines_filter_select_deselect_desktop exp */
  useEffect(() => {
    if (!trackSelectDeselectDesktop.variant()) return;
    // not focus when first rendering
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (!airlines.touched) {
      deSelectAllRef.current?.focus();
    }
    if (airlines.value.length === 0) {
      selectAllRef.current?.focus();
    }
  }, [airlines.touched, airlines.value.length]);

  if (!canRender) return null;

  return (
    <Stack attributes={{ "data-testid": "airlines_filter" }}>
      <WithSuffix
        suffix={
          airlines.touched ? (
            <Link attributes={{ "data-testid": "search_filter_airline_reset" }} onClick={airlines.reset}>
              {i18n.trans(t("flights_filter_reset"))}
            </Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filter_airlines"))}</Text>
      </WithSuffix>
      {isMobile ? (
        <Stack direction="row">
          <Link
            onClick={() => {
              airlines.set(airlines.options.map((a) => a.value));
            }}
            disabled={!airlines.touched}
          >
            {i18n.trans(t("flights_select_all_toggle"))}
          </Link>
          <Link
            onClick={() => {
              airlines.set([]);
            }}
            disabled={airlines.value.length === 0}
          >
            {i18n.trans(t("flights_deselect_all_toggle"))}
          </Link>
        </Stack>
      ) : !!trackSelectDeselectDesktop.variant() ? (
        <Stack direction="row">
          <Button.Aligner alignment="start">
            <Button
              ref={selectAllRef}
              variant="tertiary"
              onClick={() => {
                airlines.set(airlines.options.map((a) => a.value));
                !isMobile && trackGoalSelectAll();
              }}
              disabled={!airlines.touched}
            >
              {i18n.trans(t("flights_select_all_toggle"))}
            </Button>
          </Button.Aligner>
          <Button
            ref={deSelectAllRef}
            variant="tertiary"
            onClick={() => {
              airlines.set([]);
              !isMobile && trackGoalDeselectAll();
            }}
            disabled={airlines.value.length === 0}
          >
            {i18n.trans(t("flights_deselect_all_toggle"))}
          </Button>
        </Stack>
      ) : null}
      <Airlines
        limit
        singleToggle={!isMobile}
        options={airlines.options}
        value={airlines.value}
        onChange={airlines.set}
        hideCount={false}
      />
    </Stack>
  );
}
