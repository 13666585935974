/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text } from "@bookingcom/bui-react";
import FlightDetailsInfoSection from "../FlightDetailsInfoSection";
import { ATOLLogo } from "./ATOLLogo";
import useUserAgent from "hooks/useUserAgent";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useATOL from "hooks/useATOL";
import { UIFlightData, UITripRebookOption } from "@flights/types/client";
import Frame from "../Frame";

type ATOLFlightDetailsProps = {
  flight: UIFlightData | UITripRebookOption;
};

const ATOLFlightDetails = ({ flight }: ATOLFlightDetailsProps) => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n() as I18nChildContext;
  const { isATolProtectedFD } = useATOL();
  const isAtol = isATolProtectedFD(flight);

  if (!isAtol) return null;

  return (
    <FlightDetailsInfoSection
      header={i18n.trans(t("flights_atol_details_header"))}
      content={
        <Frame direction="row">
          <Frame mr={4}>
            <ATOLLogo />
          </Frame>
          <Frame mb={isMobile ? 4 : undefined}>
            <Text variant="body_2">{i18n.trans(t("flights_atol_details_description"))}</Text>
          </Frame>
        </Frame>
      }
    />
  );
};

export default ATOLFlightDetails;
