import { UIFlightData } from "@flights/types/client";

// NOTE: Why build an offer identifier instead of using token?
// Every new request to ETG creates new token even for offers that are exactly
// the same. According to them (ETG) the token doesn't expire, so it's safe to
// keep the old ones.
export function getFlightOfferString(offer: UIFlightData): string {
  const offerSlices = [];
  for (const segment of offer.segments) {
    for (const leg of segment.legs) {
      offerSlices.push(leg.departureAirport.code);
      offerSlices.push(leg.departureTime);
      offerSlices.push(leg.arrivalAirport.code);
      offerSlices.push(leg.arrivalTime);
      offerSlices.push(leg.cabinClass);
      offerSlices.push(leg.flightInfo.flightNumber || "");
      offerSlices.push(leg.flightInfo.carrierInfo?.marketingCarrier || "");
      offerSlices.push(leg.flightInfo.carrierInfo?.operatingCarrier || "");
    }
  }
  offerSlices.push(offer.priceBreakdown.total.currencyCode);
  offerSlices.push(offer.priceBreakdown.total.units);
  offerSlices.push(offer.priceBreakdown.total.nanos);
  return offerSlices.join("_");
}
