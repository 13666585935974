import { etV2 } from "./etV2";
import clientFetch from "./client-fetch";

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
// todo: @mruseev remove this after debugging
// temporary duplicated from useEvents hook in order to debug new tracking library in non react function
const logGrumble = (message: string, payload: any) =>
  clientFetch("/events/grumble", {
    method: "post",
    body: JSON.stringify({
      message,
      payload
    }),
    headers: {
      "Content-Type": "application/json",
      "X-Booking-Referrer": location.href || ""
    }
  });
// Updating ET tracking library's state after receiving new clientside payload
export const checkForNewClientsidePayload = (newClientsidePayload: string | null, fetchUrl: string) => {
  if (newClientsidePayload) {
    if (newClientsidePayload !== window?.__GLOBAL_CONTEXT__?.clientsidePayload) {
      try {
        etV2.updateStateBlobAndVariants(newClientsidePayload);
        window.__GLOBAL_CONTEXT__.clientsidePayload = newClientsidePayload;
      } catch (error) {
        void logGrumble("EtV2 threw an error while updating stateBlob and variants", {
          newClientsidePayload,
          oldClientsidePayload: window.__GLOBAL_CONTEXT__.clientsidePayload,
          error
        });
      }
    } else {
      void logGrumble("Etv2 info. Attempted to update stateBlob and variants with the same payload", {
        newClientsidePayload,
        fetchUrl
      });
    }
  }
};

export const updateClientSideContext = (soylentExperiments?: string | null) => {
  if (soylentExperiments) {
    try {
      window.__GLOBAL_CONTEXT__.experiments = {
        ...window.__GLOBAL_CONTEXT__.experiments,
        ...JSON.parse(soylentExperiments)
      };
    } catch {
      void logGrumble("Error parsing soylent experiments payload", {
        soylentExperiments
      });
    }
  }
};
