import { useI18n, t } from "@bookingcom/lingojs-react";
import { useCallback } from "react";
import formatting, { SimpleI18n, weight } from "utils/formatting";
import { UIFlightData, UILuggageAllowance } from "@flights/types/client";
import useBaggages from "./useBaggages";
import { BaggagesDetails, BaggageType } from "./utils";

type Props = Pick<
  UIFlightData,
  "travellers" | "travellerPrices" | "ancillaries" | "includedProductsBySegment" | "segments"
>;

const INCLUDED = "included" as const;
const NOT_INCLUDED = "not_included" as const;

const useBaggageForFare = (data: Props) => {
  const i18n = useI18n();
  const { getBaggageDetails } = useBaggages(data as unknown as UIFlightData);
  const { adult, kid } = getBaggageDetails();

  const _getList = useCallback(() => {
    if (!adult || !adult.eachSegment) {
      return undefined;
    }

    const { personalItem, cabin, checkedIn } = adult.eachSegment;

    return [personalItem, cabin, checkedIn].filter((item): item is BaggagesDetails => Boolean(item));
  }, [adult]);

  const getBaggageBFList = useCallback(() => {
    const list = _getList();

    if (!list) {
      return undefined;
    }

    return list.map((item) => ({
      type: item.type,
      variant: Boolean(item.includedProduct) ? INCLUDED : NOT_INCLUDED,
      label: luggageBFCopy({ type: item.type, includedProduct: item?.includedProduct, i18n })
    }));
  }, [_getList, i18n]);

  const getBaggageSRList = useCallback(
    (fareName: string) => {
      const list = _getList();

      if (!list) {
        return undefined;
      }

      return list.map((item) => ({
        type: item.type,
        variant: Boolean(item.includedProduct) ? INCLUDED : NOT_INCLUDED,
        label: luggageSRCopy({ type: item.type, includedProduct: item?.includedProduct, i18n, fareName })
      }));
    },
    [_getList, i18n]
  );

  return { hasInfantWithBaggage: Boolean(kid), getBaggageBFList, getBaggageSRList };
};

type LuggageBFCopy = {
  type: BaggageType;
  includedProduct: UILuggageAllowance | undefined;
  i18n: SimpleI18n;
};

const luggageBFCopy = ({ type, includedProduct, i18n }: LuggageBFCopy) => {
  if (includedProduct) {
    return formatting.piecesOfLuggageWithWeight(includedProduct, i18n);
  }

  switch (type) {
    case "personalItem":
      return i18n.trans(t("flights_choose_fare_personal_item_zero"));
    case "cabin":
      return i18n.trans(t("flights_choose_fare_cabin_bag_zero"));
    case "checkedIn":
      return i18n.trans(t("flights_choose_fare_checked_bag_zero"));
  }
};

const luggageSRCopy = ({ type, includedProduct, i18n, fareName }: LuggageBFCopy & { fareName: string }) => {
  if (includedProduct) {
    const { maxWeightPerPiece, massUnit } = includedProduct;
    switch (type) {
      case "personalItem":
        return i18n.trans(t("flights_sr_baggage_bf_personal_included"));
      case "cabin":
        if (maxWeightPerPiece) {
          return i18n.trans(
            t("flights_sr_baggage_bf_cabin_included", {
              variables: { max_weight: weight(maxWeightPerPiece, i18n, massUnit) }
            })
          );
        }
        return i18n.trans(t("flights_sr_baggage_cabin_bag"));
      case "checkedIn":
        if (maxWeightPerPiece) {
          return i18n.trans(
            t("flights_sr_baggage_bf_checked_included", {
              variables: { weight: weight(maxWeightPerPiece, i18n, massUnit) }
            })
          );
        }
        return i18n.trans(t("flights_sr_baggage_checked_bag"));
    }
  }

  switch (type) {
    case "personalItem":
      return i18n.trans(t("flights_sr_bf_personal_item_not_available", { variables: { fare_name: fareName } }));
    case "cabin":
      return i18n.trans(t("flights_sr_bf_cabin_bag_not_available", { variables: { fare_name: fareName } }));
    case "checkedIn":
      return i18n.trans(t("flights_sr_bf_checked_bag_not_available", { variables: { fare_name: fareName } }));
  }
};

export default useBaggageForFare;
