/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Button, Text, ListItem } from "@bookingcom/bui-react";
import useModalDialog from "hooks/useModalDialog";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { UIFlightSegment } from "@flights/types/client";
import useATOL from "hooks/useATOL";
import Frame from "../Frame";

type ATOLModalProps = {
  active: boolean;
  onClose: () => void;
  segments: UIFlightSegment[];
  hideCTA?: boolean;
};

const ATOLModal = (props: ATOLModalProps) => {
  const { active, onClose, segments, hideCTA = false } = props;
  const Modal = useModalDialog();
  const i18n = useI18n() as I18nChildContext;
  const { getAtolPdfUrl } = useATOL();

  const pdfUrl = getAtolPdfUrl();

  return (
    <Modal title={i18n.trans(t("flights_atol_check_header"))} onClose={onClose} active={active}>
      {segments?.map((segment) => (
        <Frame key={segment.arrivalTime} mt={3} mb={3}>
          <ListItem
            endSlot={
              <Text variant="emphasized_2">
                {segment.isAtolProtected
                  ? i18n.trans(t("flights_atol_protected_yes"))
                  : i18n.trans(t("flights_atol_protected_no"))}
              </Text>
            }
            roundedCorners={true}
          >
            {i18n.trans(
              t("flights_details_route", {
                variables: { city_name: String(segment.arrivalAirport.cityName) }
              })
            )}
          </ListItem>
        </Frame>
      ))}
      <br />
      <Text variant="small_1">{i18n.trans(t("flights_atol_modal_disclaimer"))}</Text>
      <br />
      {!!pdfUrl && !hideCTA ? (
        <Button attributes={{ rel: "nofollow", target: "_blank" }} href={getAtolPdfUrl()} wide>
          {i18n.trans(t("flights_atol_download_action"))}
        </Button>
      ) : null}
    </Modal>
  );
};

export default ATOLModal;
