import { createTrackingUtils } from "../index";
import { SBSearchType } from "@bookingcom/flights-searchbox/@types/client";
import { EU_COUNTRIES, isFlightWithin30daysBookingWindow } from "utils/experiments/ace/tracking-helpers";

const utils = createTrackingUtils({
  name: "flights_ace_web_fast_track",
  stages: {
    all_eu_users_on_extras_page_with_30d_booking_window: 1,
    all_eu_users_on_extras_page_with_30d_booking_window_with_ft: 2, // main
    single_traveler: 3,
    couple_traveler: 4,
    traveler_with_kids: 5,
    desktop: 6,
    mobile: 7,
    one_way: 8,
    round_trip: 9
  },
  goals: {
    open_price_breakdown: 1,
    user_selects_ft: 2,
    order_created: 3,
    order_created_failed: 4
  },
  goalsWithValue: []
});

export function trackFastTrackOnExtrasPage(
  pos: string,
  departureDate: string,
  fastTrackAvailable: boolean,
  noOfAdults: number,
  noOfChildren: number,
  isMobile: boolean,
  searchType: SBSearchType
) {
  if (EU_COUNTRIES.includes(pos) && isFlightWithin30daysBookingWindow(departureDate)) {
    utils.stages.all_eu_users_on_extras_page_with_30d_booking_window();

    if (fastTrackAvailable) {
      utils.stages.all_eu_users_on_extras_page_with_30d_booking_window_with_ft();

      if (noOfAdults === 1) {
        utils.stages.single_traveler();
      }

      if (noOfAdults === 2) {
        utils.stages.couple_traveler();
      }

      if (noOfChildren > 0) {
        utils.stages.traveler_with_kids();
      }

      if (isMobile) {
        utils.stages.mobile();
      } else {
        utils.stages.desktop();
      }

      if (searchType === "ONEWAY") {
        utils.stages.one_way();
      }

      if (searchType === "ROUNDTRIP") {
        utils.stages.round_trip();
      }
    }
  }
}

export default utils;
