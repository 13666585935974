import useEvents from "hooks/useEvents";
import useGlobalContext from "hooks/useGlobalContext";
import useProfiler from "hooks/useProfiler";
import useUserAgent from "hooks/useUserAgent";
import { useEffect } from "react";
import { createTrackingUtils } from "utils/experiments";

export const trackIndexCampaign = createTrackingUtils({
  name: "flights_web_cat_banner_campaign_index",
  stages: {
    main: 1, // Origin countries && non-paid traffic (excl: meta & ppc)
    desktop: 2,
    mobile: 3,
    loggedIn: 4,
    notLoggedIn: 5,
    norAm: 6,
    eu: 7
  },
  goals: {
    search_button_click: 1,
    exposed_to_sr_campaign: 2, // User is exposed to campaign on SR
    view_terms_click: 3, // User clicked “View Terms”,
    carousels_click: 4 // User clicked “Pop. Fl. Near You” OR “Trending Cities” Carousels
  },
  goalsWithValue: []
});

export function useTrackIndexCampaignStages() {
  const { isMobile } = useUserAgent();
  const { indexPageCampaign, ipCountry } = useGlobalContext();
  const { isLoggedIn } = useProfiler();
  const { squeak } = useEvents();

  useEffect(() => {
    if (!indexPageCampaign) return;

    trackIndexCampaign.stages.main();
    isMobile ? trackIndexCampaign.stages.mobile() : trackIndexCampaign.stages.desktop();

    isLoggedIn() ? trackIndexCampaign.stages.loggedIn() : trackIndexCampaign.stages.notLoggedIn();

    switch (ipCountry?.toUpperCase()) {
      case "US":
      case "CA":
        trackIndexCampaign.stages.norAm();
        break;
      case "AT":
      case "BE":
      case "CZ":
      case "FR":
      case "DE":
      case "GR":
      case "HU":
      case "IE":
      case "IT":
      case "NL":
      case "NO":
      case "RO":
      case "ES":
      case "SE":
      case "GB":
        trackIndexCampaign.stages.eu();
        break;
      default:
        squeak(`${ipCountry} ipCountry was exposed to Index deal campaign experiment`);
        break;
    }
  }, [isMobile, ipCountry, indexPageCampaign, isLoggedIn, squeak]);
}
